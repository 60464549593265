import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {FirebaseService} from '../../services/firebase.service';
import {AdminService, ComModules, Module} from '../../services/admin.service';
import {
  BlockString, ComOrg, LocationData, InterfaceService, LangStringBlock, Links, OrgLegacy,
  OrgLegacyExtra
} from '../../services/interface.service';
import {GeofireService} from '../../services/geofire.service';
import {Languages} from '../../services/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AngularFireAuth} from '@angular/fire/auth';
import {RoehttpService} from '../../services/roehttp.service';

@Component({
  selector: 'app-comorg',
  templateUrl: './comorg.component.html',
  styleUrls: ['./comorg.component.scss']
})
export class ComorgComponent implements OnInit, OnDestroy {
  oControl: FormControl;
  pControl: FormControl;
  orgslist;
  curOrg;
  commodules = ComModules;
  $sub1;
  languages = Languages;

  iscom = true;

  orgpicked: boolean;

  mode: boolean;
  comorg: string;
  tmpOrg;

  loc: string;
  type: string;

  org: BehaviorSubject<string | null> = new BehaviorSubject('');
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');

  form = new FormGroup({
    ukey: new FormControl(''),
  });

  get ukey() {
    return this.form.get('ukey');
  }

  constructor(private titleIXdb: FirebaseService,
              public authx: AngularFireAuth,
              private router: Router,
              private geo: GeofireService,
              private http: RoehttpService,
              private htp: HttpClient,
              private is: InterfaceService,
              private admin: AdminService) {
    this.oControl = new FormControl();
    this.pControl = new FormControl();
    this.orgpicked = false;
    this.comorg = '';

    if (this.router.url.includes('corp')) {
      this.loc = 'Corp';
    } else {
      this.loc = 'Community';
    }
    console.log('loc', this.loc)
  }


  ngOnInit() {
    this.$sub1 = this.is.getBehaviorList(this.startAt, this.endAt, 'OrgsByType/Community/')
      .subscribe(orgs => {
        this.orgslist = orgs;
      });
  }

  updateorgslist(sc) {
    this.org.next(sc);
    let obj;
    this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/Community', ref => {
      return ref.orderByChild('ukey').equalTo(sc);
    })
      .valueChanges().subscribe(data => {
      obj = data[0];
      this.curOrg = <ComOrg> obj;
      if (this.curOrg) {
        this.is.getObjectFS(`Community/${this.curOrg.ukey}`).take(1).subscribe(org => {

          this.tmpOrg = org;
        });

        this.comorg = this.curOrg.ukey;
      }
    });
  }

  search() {
    if (this.oControl.value.length === 1) {
      this.oControl.setValue(this.oControl.value.toUpperCase());
    }
    const q = this.oControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  updateparentlist(sc) {
    this.org.next(sc);
    let obj;
    this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/Community', ref => {
      return ref.orderByChild('ukey').equalTo(sc);
    })
      .valueChanges().subscribe(data => {
      obj = data[0];
      this.tmpOrg.parentkey = obj.ukey;

    });
  }

  searchparent() {
    if (this.pControl.value.length === 1) {
      this.pControl.setValue(this.pControl.value.toUpperCase());
    }
    const q = this.pControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  setValue(tmp) {
    this.form.setValue({
      ukey: tmp.ukey,
    });
  }

  onEditOrg() {
    this.mode = false;
    this.setValue(this.tmpOrg);
    this.orgpicked = true;
  }

  onDelOrg() {
    this.orgpicked = false;
    const ukey = this.curOrg.ukey;
    this.oControl.setValue('');
    this.comorg = '';
    // Todo: this doesn't delete the orgs from ALL the Edlists or from all OrgsbyType lists. more care has to be taken here.
    console.log('curOrg', this.curOrg);
    if (this.curOrg.orglegacyextra && this.curOrg.orglegacyextra.kwabid) {
      this.is.deleteObj(`LegacyLookup/${this.curOrg.orglegacyextra.kwabid}`, 'Legacy', '');
    }
    this.is.deleteObjFS(`Community/${ukey}`, 'Legacy', '');
    this.is.deleteObj(`OrgsByType/Community/${ukey}`, 'Legacy', '');

  }

  onAddOrg() {
    this.mode = true;
    this.tmpOrg = new ComOrg();
    this.tmpOrg.ukey = '';
    this.tmpOrg.parentrelationship = '';
    this.tmpOrg.parentkey = '';
    this.tmpOrg.children = [];
    this.tmpOrg.active = true;
    if (this.loc === 'Corp') {
      this.tmpOrg.isCorp = true;
    } else {
      this.tmpOrg.isCorp = false;
    }

    this.tmpOrg.langDesc = new LangStringBlock();
    this.tmpOrg.langHead = new LangStringBlock();
    this.tmpOrg.abbrDesc = new LangStringBlock();
    this.languages.forEach(l => {
      this.tmpOrg.langDesc[l.abbr] = new BlockString();
      this.tmpOrg.langDesc[l.abbr].description = '';
      this.tmpOrg.langDesc[l.abbr].block = false;

      this.tmpOrg.langHead[l.abbr] = new BlockString();
      this.tmpOrg.langHead[l.abbr].description = '';
      this.tmpOrg.langHead[l.abbr].block = false;

      this.tmpOrg.abbrDesc[l.abbr] = new BlockString();
      this.tmpOrg.abbrDesc[l.abbr].description = '';
      this.tmpOrg.abbrDesc[l.abbr].block = false;
    });


    this.tmpOrg.emailextentions = '';
    this.tmpOrg.religiousaffiliation = '';
    this.tmpOrg.rfctoresourceid = '';
    this.tmpOrg.rfctoreporterid = '';
    this.tmpOrg.rfcfrom = '';
    this.tmpOrg.orgtype = 'Not-for-profit Local';
    this.tmpOrg.timezone = '';
    this.tmpOrg.publickey = '';
    this.tmpOrg.icon = '';

    this.tmpOrg.jsonld = '';
    this.tmpOrg.favorite = false;
    this.tmpOrg.client = false;
    this.tmpOrg.type = 'organization';
    this.tmpOrg.content = ''; // this field is for tying a RO App to a Community resources

    this.tmpOrg.privatekey = '';
    this.tmpOrg.aes = '';
    this.tmpOrg.class = '';
    this.tmpOrg.fullpath = '';
    this.tmpOrg.disaster = false;

    this.tmpOrg.orglegacy = new OrgLegacy();
    this.tmpOrg.orglegacy.name = '';
    this.tmpOrg.orglegacy.nickname = '';
    this.tmpOrg.orglegacy.logo = '';
    this.tmpOrg.orglegacy.sponsored = false;
    this.tmpOrg.orglegacy.sponsor_text = '';
    this.tmpOrg.orglegacy.sponsor_url = '';
    this.tmpOrg.orglegacy.homepage_type = 'catalog';
    this.tmpOrg.orglegacy.photo = '';
    this.tmpOrg.orglegacy.smalllogo = '';
    this.tmpOrg.orglegacy.primarycolor = '#99add0';
    this.tmpOrg.orglegacy.secondarycolor = '#000000';

    this.tmpOrg.orglegacyextra = new OrgLegacyExtra();
    this.tmpOrg.orglegacyextra.resource_super_type_slug = 'organization';
    this.tmpOrg.orglegacyextra.short_description = '';
    this.tmpOrg.orglegacyextra.schedule = '';
    this.tmpOrg.orglegacyextra.is24 = false;
    this.tmpOrg.orglegacyextra.kwabid = null;


    this.tmpOrg.orgdata = new LocationData();
    this.tmpOrg.orgdata.name = '';
    this.tmpOrg.orgdata.nickname = '';
    this.tmpOrg.orgdata.street1 = '';
    this.tmpOrg.orgdata.street2 = '';
    this.tmpOrg.orgdata.city = '';
    this.tmpOrg.orgdata.state = '';
    this.tmpOrg.orgdata.zip = '';
    this.tmpOrg.orgdata.country = '';
    this.tmpOrg.orgdata.emailextentions = '';

    this.tmpOrg.cappparams = [];

    this.tmpOrg.links = new Links();
    this.tmpOrg.links.url = '';
    this.tmpOrg.links.facebook = '';
    this.tmpOrg.links.twitter = '';
    this.tmpOrg.links.instagram = '';
    this.tmpOrg.links.linkedin = '';
    this.tmpOrg.links.wikilink = '';
    this.tmpOrg.links.googleplus = '';
    this.tmpOrg.links.pinterest = '';
    this.tmpOrg.links.snapchat = '';
    this.tmpOrg.links.tumblr = '';
    this.tmpOrg.links.feedback = '';
    this.tmpOrg.links.youtube = '';

    //todo: BizYear --- Yikes
    this.tmpOrg.hours = null;

    this.tmpOrg.locations = [];
    this.tmpOrg.programs = [];

    this.setValue(this.tmpOrg);
    this.orgpicked = true;

  }

  onSelectOrg() {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postROEMarketing';

    this.authx.auth.currentUser.getIdToken().then(authToken => {
      const headersv = new HttpHeaders({Authorization: 'Bearer ' + authToken});
      return this.htp.post(url,
        {
          or: this.curOrg.ukey,

        },
        {headers: headersv}).toPromise();
    })
      .then(res => {
        console.log(res);
        this.is.onSelectOrg(this.curOrg.ukey, this.tmpOrg);
        this.router.navigateByUrl('comparent');

      });
    // this.is.setObjNoLog(`Marketing/-LPgtb9Y2W3QT3WyeNbd/orgs/0/organ`, this.curOrg.ukey);

  }

  onCancel() {
    this.orgpicked = false;
  }

  onDone() {
    this.orgpicked = false;
  }


  onKwabSave() {
    const pth = `Community/${this.tmpOrg.ukey}`;
    console.log('1', this.tmpOrg);
    if (!this.tmpOrg.orglegacyextra.kwabid) {
      console.log(2);
      this.admin.makeKwabId(pth).then(val => {
        this.tmpOrg.orglegacyextra.kwabid = <number> val;
        this.is.setObjFS(pth, this.tmpOrg);
        // need to add search tags
        this.http.postAlgoliaCommunity('Community', '', this.tmpOrg.ukey, this.tmpOrg.orglegacyextra.kwabid, '', '', '');
        this.is.setObj(`OrgsByType/Community/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey}, 'Legacy', '');
      });
    } else {
      console.log(3, this.tmpOrg);
      this.is.setObjFS(pth, this.tmpOrg);
      this.is.setObj(`OrgsByType/Community/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey}, 'Legacy', '');
    }
  }

  onSave() {

    if (this.loc === 'Community' || this.iscom) {
      this.tmpOrg.ukey = this.ukey.value;
      this.tmpOrg.orgdata.name = this.ukey.value;
      if (this.mode) {
        this.is.getObjectFS(`Community/${this.ukey.value}`).take(1).subscribe(val => {
          if (!val) {
            this.onKwabSave();
            this.orgpicked = false;
          } else {
            alert('Not a valid ukey.');
          }
        });
      } else {
        console.log('kwb save');
        this.onKwabSave();
        this.orgpicked = false;
      }
    }


    if (this.loc === 'Corp') {
      const to = {};
      to['ukey'] = this.tmpOrg.ukey;
      to['profile'] = {};
      to['profile']['type'] = this.type;
      const pth = `CorpsPri/${this.tmpOrg.ukey}`;
      console.log('to path', to);
      this.is.setObjNoLog(pth, to);
      this.is.setObjNoLog(`OrgsByType/Corp/${this.tmpOrg.ukey}`, {'ukey': this.tmpOrg.ukey});
    }
  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
  }

}
