import {Injectable, OnDestroy} from '@angular/core';
import {FirebaseService} from './firebase.service';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DaysPerMonth, Languages} from './constants';
import {BizDay, BizHour, BizMonth, BizYear} from './bizhours.service';
import * as _ from 'lodash';
import {CappTeamMsg, LocationData, JobList, Office, LangStringBlock, Phone, ObjName} from './interface.service';
import FroalaEditor from 'froala-editor';
import {parsePhoneNumberFromString, PhoneNumber, CountryCode} from 'libphonenumber-js';
import {MatBottomSheet} from '@angular/material';
import {HelpBottomComponent} from '../help-bottom/help-bottom.component';

export const IncidentColors = [
  {back: '337a89', text: '#ffffff'},
  {back: 'f4ba44', text: '#000000'},
  {back: '7b1850', text: '#ffffff'},
  {back: 'e99d99', text: '#000000'},
  {back: '8ccbcc', text: '#ffffff'},
  {back: 'ece2cd', text: '#000000'},
  {back: '5a48aa', text: '#ffffff'},
];

export class QuickFind {
  back: string;
  text: string;
  abbr: string;
  pic: string;
}


export class Friend {
  user: string;  // heir name
  ukey: string;
  photo?: string;

  accpt: string;
  phone: string;
  theirukey: string;
  backukey: string;
  h?: boolean;        // Bystande helper
  idukey?: string;     // the CA token for the user  - this does double duty here. it provides descriptive information abbout the resources
  path?: string;       // this field used by ROE to get extradetails about a person/resource in Chat.

  cmp?: any;      // for Butterflies compatibility
  gal?: any;    // addresses to photos associated with that person
  s?: boolean;        // For CM Memories - Shared, status. True = the memory appears in everybody’s feed and all can contribbute
                      // If added as shared then they can accept or delete
                      // The creator can remove them at any time

  // fp?: CAPermFriend;
}

export class MMIncident {
  ukey: string;
  toukeys: string; // a sorted concatenated string of all the members of a conversation.
  parties: MMRocParty[];
  delps: MMRocParty[];  // deleted parties
  messagelog: MMMes[];
  type: string;
  initiator: string;
  principal: string;
  anonymous: boolean;
  to: string;
  lr: number;
  admins?: string; // comma deliminated string of ukeys of the people who have admin access.
}

export class MMRocParty {
  name: string;
  phone: string;
  profilepic: string; // firebase locations
  abbreviation: string;
  ukey: string;
  role: string; // principal, helpers, bystander, host, authority
  fcmkey?: string;
}

export class MMMes {
  f: string; // ukey
  m: string;    // msg
  d: number;     // date
  t: string;     // type
  e: string;    // emoji
  b: boolean;   // blue or fade which means message disappears 10 secs after being viewed
  a?: string; // if this has a value then the user is anonymou
}

export const AdminModules = [
  {code: 'createorg', name: 'Select Org', icon: 'create', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'newcomorg/com', name: 'Select Com Res', icon: 'nature_people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'newcomorg/corp', name: 'Select Corp', icon: 'business', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'editwidget', name: 'Edit Widget', icon: 'nature_people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'createadmin', name: 'Admins', icon: 'people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'errmsg', name: 'Error Messages', icon: 'people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'caforms', name: 'CA Forms', icon: 'list_alt', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'import', name: 'Import', icon: 'assignment', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'archive', name: 'Archive', icon: 'insert_photo', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'create', name: 'Create Ad', icon: 'create', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'blogs', name: 'For Bloggers', icon: 'rss_feed', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'topics', name: 'Public Topics', icon: 'view_headline', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'holiday', name: 'Holidays', icon: 'perm_contact_calendar', value: false, color: '#000000', back: '#C5CAE9'},
];

/*
{code: 'people', name: 'People', icon: 'people', value: false, color: '#000000', back: '#d5d7e7'},
{code: 'climate', name: 'Surveys', icon: 'assignment', value: false, color: '#000000', back: '#d5d7e7'},
{code: 'notifications', name: 'Notifications', icon: 'message', value: false, color: '#000000', back: '#d5d7e7'},
    {code: 'library', name: 'Library', icon: 'photo_library', value: false, color: '#000000', back: '#C5CAE9'},

*/
export class Admin {
  name: string;
  firebaseuid: string;
  ukey: string;
  email: string;
  orgemail: string;
  orgname: string;
  type: string;
  subtype: string;
  role: string;
  deviceid: number;
  indivukey: string;
  client: boolean;
  org: string;
  sponsor?: string;
  phone?: string;
  password: string;
  programs?: string;
  staff?: string;
  locations?: string;
  modules: Module[];
  orgs: Adminy[];
  empid: EmpId[]; // unique employer id and dept assigned to each an admin

  constructor() {
    this.empid = [];
  }
}

export class EmpId {
  org: string;
  id: string;
  dept: string;
}

export class Adminy {
  organ: string;
  name: string;
  modules: Module[];
}

export class Module {
  name: string;
  code: string;
  val: boolean;
}

export class Article {
  Id: string;
  Date: string;
  Name: string;
  State: string;
  CollegeNewspaper: string;
  Status: string;
  DateAdded: string;
  Current: number;
  Use: number;
  Category: string;
  Population: string;
  Geography: string;
  MustRead: string;
  Threads: string;
  Publication: string;
  Author: string;
  Lawsuit: string;
  Headline: string;
  Link: string;
  Summary: string;
  RaceGender: string;
  ReferenceTitle: string;
  ReferenceLink: string;
  CaseTitle: string;
  Victim: string;
  Accused: string;
  Role: string;
  Arrest: string;
  Notes: string;
  LawyerforAccused: string;
  LawyerforVictim: string;
  Case: string;
  CaseStatus: string;
}

export class PhoneObj {
  phdescription: string;
  digits: string;
  note: string;
  private: boolean;
  notify: boolean;
}

export class ComPerson {
  ukey: string;
  details: ComPersonDetails;
  jobs?: ComJob[];
  had?: Address;
  emergukeys?: EmergencyContact[]; // pointer to the emergency contact people
  profile?: ComProfile;
  indivukey: string;
}

export class Address {
  street1: string;  // street1
  street2: string;  // street2
  city: string;  // city
  state: string;  // state
  country?: string;  // country
  zip: string;  // zipt
  county?: string; // points to the CA form
  long?: number;
  lat?: number;
}

export class ComPersonDetails {
  fname: string;
  mname: string;
  lname: string;
  zname: string;
  fullname?: string; // this is for indexing - never shown to user LastFirstMiddleZnameCityStateDate
  description?: string;
  photo?: string;

  deviceid?: string;
  email?: string;
  phone?: string;

  modules?: any;  // these are the console features available to the individual

}

export class ComProfile {
  sponsor: string;
  sponsoremail: string;
  age: string;
  race: string;
  survivor: boolean;
  latino: boolean;
  gender: string;
  preference: string;
  disability: string;
}

export class EmergencyContact {
  fullname: string;
  indivukey: string;
  relationship: string;
}

// this class is the begining of enabling of our social media effort
export class ComFriend {
  relationship: string; // friend, follower, donor, partner
  acceptemail: boolean;
}

export class ComJob {
  jobid: string;
  ukey: string;
  organization: string;
  startdate: number;
  enddate: string;
  reason?: string;
  present: boolean;
  title: string; // todo: shouldnt this be langstring
  description: string; // todo: shouldnt this be langstring
  phones: PhoneObj[];
  phoneprivate: boolean;
  photo?: string;
  email: string;
  emailprivate: boolean;
  volunteer: boolean;
  address: Address;
  acceptemail?: boolean;
  locations?: string;
  programs?: string;
  cappguide?: boolean;
}

/*
{code: 'map', name: 'Map', icon: 'place', value: false, color: '#000000', back: '#C5CAE9'},
    {code: 'gsp', name: 'Good Samaritan', icon: 'star', value: false, color: '#000000', back: '#d5d7e7'},
    {code: 'resumes', name: 'Resumes', icon: 'account_box', value: false, color: '#000000', back: '#d5d7e7'},

*/


export const OrgModules = [
  {code: 'profile', name: 'Profile', icon: 'fingerprint', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'resources', name: 'Resources', icon: 'accessibility', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'roplus/orgs', name: 'Editions', icon: 'apps', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'rowidgets', name: 'Widgets', icon: 'widgets', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'guides/org', name: 'Guides', icon: 'compass_calibration', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'reports', name: 'Reports', icon: 'file_copy', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'surveys/org', name: 'Surveys', icon: 'question_answer', value: false, color: '#000000', back: '#d5d7e7'},
  {code: 'events/orgs', name: 'Calendar', icon: 'perm_contact_calendar', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'exhibits/orgs', name: 'Exhibits', icon: 'filter', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'topics/orgs', name: 'Topics', icon: 'topic', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'lms', name: 'LMS', icon: 'computer', value: false, color: '#000000', back: '#d5d7e7'},
  {code: 'orgchat', name: 'Comm Center', icon: 'chat', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'usersshell', name: 'Users', icon: 'people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'cares', name: 'Cares', icon: 'cloud_circle', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'incidents', name: 'Incidents', icon: 'description', value: false, color: '#000000', back: '#d5d7e7'},
  {code: 'tandc', name: 'Terms & Cond', icon: 'reorder', value: false, color: '#000000', back: '#d5d7e7'},
  {code: 'orgpartners', name: 'Partners', icon: 'wb_sunny ', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comstorage/orgs', name: 'Storage', icon: 'insert_photo', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'admins', name: 'Admins', icon: 'face', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comrec', name: 'Community', icon: 'account_balance', value: false, color: '#000000', back: '#C5CAE9'},
];

// {code: 'legacy', name: 'Catalogs', icon: 'create', value: false, color: '#000000', back: '#d5d7e7'},


export const HCModules = [
  {code: 'healthparent', name: 'Client Profile', icon: 'fingerprint', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'hccalendar', name: 'Calendar', icon: 'perm_contact_calendar', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'surveys', name: 'Surveys', icon: 'question_answer', val: false, color: '#000000', back: '#d5d7e7'},
  {code: 'hccom', name: 'Notifications', icon: 'chat', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'hcsetup', name: 'Setup', icon: 'description', val: false, color: '#000000', back: '#d5d7e7'},
  {code: 'hcresources', name: 'Resources', icon: 'accessible', val: false, color: '#000000', back: '#d5d7e7'},
  {code: 'hcinter', name: 'Interventions', icon: 'local_hospital', val: false, color: '#000000', back: '#d5d7e7'},
  {code: 'hcadmins', name: 'Admins', icon: 'face', val: false, color: '#000000', back: '#C5CAE9'},
];

export const InstModules = [
  {code: 'instparent', name: 'Dashboard', icon: 'notifications', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'instprofile', name: 'Profile', icon: 'account_balance', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'exhibits/inst', name: 'Exhibits', icon: 'filter', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'events/inst', name: 'Calendar', icon: 'perm_contact_calendar', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'topics/inst', name: 'Topics', icon: 'topic', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'map', name: 'Map', icon: 'room', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'directory', name: 'Directory', icon: 'contact_page', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'content/inst', name: 'Content', icon: 'history_edu', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'roplus/inst', name: 'Editions', icon: 'apps', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'createad', name: 'Create Ad', icon: 'create', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comstorage/inst', name: 'Storage', icon: 'insert_photo', value: false, color: '#000000', back: '#C5CAE9'},
];

export const CorpModules = [
  {code: 'corpparent', name: 'Profile', icon: 'fingerprint', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'corpuse', name: 'Dashboard', icon: 'dashboard', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'covidemployees', name: 'People', icon: 'people', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'covidcasemgt', name: 'Case Management', icon: 'work', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'covidnodes', name: 'Nodes', icon: 'blur_circular', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'covidcom', name: 'Notifications', icon: 'chat', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'corptemplates', name: 'Templates', icon: 'list', val: false, color: '#000000', back: '#C5CAE9'},
  {code: 'surveys/corp', name: 'Surveys', icon: 'question_answer', val: false, color: '#000000', back: '#d5d7e7'},
  {code: 'guides/corp', name: 'Guides', icon: 'compass_calibration', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'pubtopics', name: 'Public Topics', icon: 'view_headline', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'covidadmins', name: 'Admins', icon: 'face', val: false, color: '#000000', back: '#C5CAE9'},
];

/*
    {code: 'hcresults', name: 'Results', icon: 'account_balance', val: false, color: '#000000', back: '#C5CAE9'},

    {code: 'shopping', name: 'Shopping Cart', icon: 'shopping_cart', value: false, color: '#000000', back: '#C5CAE9'},
    {code: 'legacy', name: 'Catalogs', icon: 'create', value: false, color: '#000000', back: '#d5d7e7'},



{code: 'comclients', name: 'Clients', icon: 'face', value: false, color: '#000000', back: '#C5CAE9'},

*/

export const SecurityModules = [
  {code: 'clerydashboard', name: 'Dashboard', icon: 'dashboard', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'clerycrimelog', name: 'Daily Crime Log', icon: 'list', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'clerygeo', name: 'Clery Geo', icon: 'add_location', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'clerytimely', name: 'Timely Warning', icon: 'alarm', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'cleryims', name: 'Clery IMS', icon: 'dns', value: false, color: '#000000', back: '#C5CAE9'},
];

// {code: 'comdashboard', name: 'Dashboard', icon: 'dashboard', value: false, color: '#000000', back: '#C5CAE9'},

export const InvModules = [
  {code: 'invperms', name: 'Permissions', icon: 'security', value: false, color: '#000000', back: '#C5CAE9'}
];

export const ComModules = [
  {code: 'comparent', name: 'Profile', icon: 'description', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comlocations', name: 'Locations', icon: 'fingerprint', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comprograms', name: 'Programs', icon: 'book', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'compeople', name: 'People', icon: 'people', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comevents', name: 'Calendar', icon: 'perm_contact_calendar', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'exhibits/community', name: 'Exhibits', icon: 'filter', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comcontact', name: 'Links', icon: 'link', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comstorage/community', name: 'Storage', icon: 'insert_photo', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comservicearea', name: 'Service Area', icon: 'place', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comlists', name: 'Lists', icon: 'list', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'compartners', name: 'Partners', icon: 'wb_sunny ', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'guides/com', name: 'Guides', icon: 'compass_calibration', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'surveys/com', name: 'Surveys', icon: 'question_answer', value: false, color: '#000000', back: '#d5d7e7'},
  {code: 'connect', name: 'Interventions', icon: 'headset', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comims', name: 'IMS', icon: 'accessibility_new', value: false, color: '#000000', back: '#C5CAE9'},
  {code: 'comadmins', name: 'Admins', icon: 'face', value: false, color: '#000000', back: '#C5CAE9'},
];

export const PeopleModules = [];

@Injectable({
  providedIn: 'root'
})
export class AdminService implements OnDestroy {
  public admin: Admin;
  public indiv: ComPerson;
  staff: JobList[];
  public loggedin: boolean;
  // todo: implement generalization
  curlang = 'en';
  roc = false;
  tmpmodules: Module[];
  articles: Article[];
  modules = OrgModules;
  commodules = ComModules;
  dayspermonth = DaysPerMonth;
  public curperiod: string;
  languageon: boolean;
  language: string;
  fulllanguage: string;
  scope: string;
  preview: boolean;
  editchild = false;
  incidentalert = false;
  partneralerts: string[];
  partentofcurrentorg: string;
  mock = false;
  nextcom: string;
  t;
  roct;

  pulse = false; // used to turn on and off the help system


  $sub1;
  $sub2;
  $sub11;
  $sub12;
  $sub13;

  frnds: Friend[];
  conversations: any[];
  incidents: MMIncident[];
  curincident: MMIncident;
  conarr = {};
  frndchat = false;
  qf;
  messages: MMMes[];
  key = -1;
  chat = false;
  factor = 1;

  recent = false;
  report = false;
  mpos = -1;
  gpos = -1;
  curmenu = -1;

  quarantinePeriod = 14;
  isolationPeriod = 10;
  logo: string;

  adjust = 0;
  appwidth = 415;

  c;

  phoneNumber;
  curphone: Phone;

  ada = false; // Americans with disability act
  isgovt: boolean;

  inbox: CappTeamMsg[];

  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    height: 250,
    enter: FroalaEditor.ENTER_DIV,
    htmlAllowedAttrs: ['title', 'href', 'alt', 'src', 'style'],
    charCounterCount: true,
    language: this.language,
    toolbarButtons: ['bold', 'italic', 'underline', 'outdent', 'indent', 'formatOL', 'formatUL', 'color', 'align', 'fontFamily', 'fontSize', 'html', 'insertLink']
  };

  fontsel = [
    {old: '0.9vw', new: '0.9vw'},
    {old: '1.0vw', new: '1.0vw'},
    {old: '1.5vw', new: '1.5vw'},
    {old: '1.75vw', new: '5vw'}
  ];

  compareLangStrBlock(first: LangStringBlock, second: LangStringBlock): boolean {
    let val = false;
    Languages.forEach(lang => {
      if (!val &&
        (first[lang.abbr].block !== second[lang.abbr].block) ||
        (first[lang.abbr].description !== second[lang.abbr].description)) {
        val = true;
      }
    });

    return val;
  }


  getTime(d: number): string {
    const x = (new Date().getTime() - d) / (60 * 1000);
    if (x < 60) {
      return `${Math.trunc(x)}m`;
    } else if (x < 24 * 60) {
      return `${Math.trunc(x / 60)}h`;
    } else {
      return `${Math.trunc(x / (24 * 60))}d`;
    }
  }

  convertObjNames(objs: ObjName[]) {
    if (objs && objs.length) {
      const carr = [];
      objs.forEach(c => {
        carr.push(c.fullname);
      });
      return carr.join(',');
    } else {
      return '';
    }
  }

  // gets the current intl time
  getIntTime(val?: number): number {
    const tz = new Date().getTimezoneOffset();
    if (val) {
      return new Date(val).getTime() + tz * 60000;
    } else {
      return new Date().getTime() + tz * 60000;
    }
  }

  getLongNameCountry(addrComponents) {
    let str = '';
    addrComponents.forEach(comp => {
      const ty = comp.types.join(',');
      if (ty && (ty.includes('country'))) {
        str = comp.long_name;
      }
    });

    return str;
  }

  getCountry(addrComponents, notlower ?: boolean) {
    let str = '';
    addrComponents.forEach(comp => {
      const ty = comp.types.join(',');
      if (ty && (ty.includes('country'))) {
        str = comp.short_name;
      }
    });

    if (!notlower && str && str.length > 0) {
      str = str.toLowerCase();
    }
    return str;
  }

  getLongNameState(addrComponents) {
    let str = '';
    for (let i = 0; i < addrComponents.length; i++) {
      const ty = addrComponents[i].types.join(',');
      if (ty && ty.includes('administrative_area_level_1')) {
        str = addrComponents[i].long_name;
      }
    }

    return str;
  }

  getState(addrComponents, notlower ?: boolean) {
    let str = '';
    for (let i = 0; i < addrComponents.length; i++) {
      const ty = addrComponents[i].types.join(',');
      if (ty && ty.includes('administrative_area_level_1')) {
        str = addrComponents[i].short_name;
      }
    }

    if (!notlower && str && str.length > 0) {
      str = str.toLowerCase();
    }
    return str;
  }

  getCity(addrComponents, notlower ?: boolean) {
    let str = '';
    for (let i = 0; i < addrComponents.length; i++) {
      if (!str && addrComponents[i].types.includes('locality')) {
        str = addrComponents[i].long_name;
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {

        if (!str && addrComponents[i].types.includes('administrative_area_level_2')) {
          str = addrComponents[i].short_name;
        }
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {
        if (!str && addrComponents[i].types.includes('administrative_area_level_1')) {
          str = addrComponents[i].short_name;
        }
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {
        if (!str && addrComponents[i].types.includes('sublocality')) {
          str = addrComponents[i].long_name;
        }
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {
        if (!str && addrComponents[i].types.includes('neighborhood')) {
          str = addrComponents[i].short_name;
        }
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {
        if (!str && addrComponents[i].types.includes('colloquial_area')) {
          str = addrComponents[i].short_name;
        }
      }
    }

    if (!str) {
      for (let i = 0; i < addrComponents.length; i++) {
        if (!str && addrComponents[i].types.includes('natural_feature')) {
          str = addrComponents[i].short_name;
        }
      }
    }


    /*
        if (str.length === 0 && addrComponents[0]) {
          str = addrComponents[0].short_name;
        }
    */

    if (!notlower && str && str.length > 0) {
      str = str.toLowerCase();
    }
    return str;
  }

  toIntTime(date, offset): number {
    const tz = new Date().getTimezoneOffset() * 60 * 1000;
    const off = offset * 60 * 1000;
    return date - tz - off; //+ (this.tz - off);
  }

  // converts from local to internation
  fromLocalToIntTime(date: number): number {
    const tz = new Date().getTimezoneOffset() * 60000;
    return date + tz;
  }

  // converts from international time to local time
  fromIntToLocalTime(date: number): number {
    const tz = new Date().getTimezoneOffset() * 60000;
    return date - tz;
  }

  // converts from international time to local time
  static toLocalTime(date): number {
    return date;
  }

  shadeColor(color: string, percent) {

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.trunc(R * (100 + percent) / 100);
    G = Math.trunc(G * (100 + percent) / 100);
    B = Math.trunc(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16));

    return '#' + RR + GG + BB;
  }

  getPrimary(): string {
    const str = '#0000FF';
    return str;
  }

  getSecondary() {
    const str = '#FFFFFF';
    return str;
  }

  calcDaysSince(startdate, enddate): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(startdate);
    const secondDate = new Date(enddate);

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
  }

  calcDays(date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date();
    const secondDate = new Date(date);

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
  }

  validLink(link: string): boolean {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return re.test(String(link).toLowerCase());
  }

  validateEmail(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  parsePhoneNumberFromString(phoneString: string, countryCode?: CountryCode): PhoneNumber {
    let validatedPhone;
    try {
      if (!countryCode) {
        countryCode = 'US';
      }
      validatedPhone = parsePhoneNumberFromString(phoneString, countryCode);
    } catch (error) {
      console.error('Phone error', error);
      // Not a phone number, non-existent country, etc.
    }
    return validatedPhone;
  }

  isValidPhone(phone) {
    try {
      // todo: what about other countries
      this.phoneNumber = this.parsePhoneNumberFromString(phone); // parsePhoneNumber(phone, 'US');
      return true;
    } catch (error) {
      return false;
    }
  }

  randomString(full, length): string {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZ';
    if (full) {
      chars = chars + '0123456789';
    }
    const stringlength = length;
    let randomstring = '';
    for (let i = 0; i < stringlength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  fontfactor(val): string {
    return `${val * this.factor}vw`;
  }

  fontReplace(t): string {
    if (window.innerWidth <= 768) {
      this.fontsel.forEach(fs => {
        const regex = new RegExp(fs['old'], 'g');
        t = t.replace(regex, fs['new']);
      });
    }
    return t;
  }

  /*
    makeAMPM(d: Date) {
      let str = '';
      let cap = '';
      if (d.getMinutes() < 10) {
        cap = '0'
      }
      if (d.getHours() > 12) {
        str = `${d.getHours() - 12}:${cap}${d.getMinutes()}PM`;
      } else if (d.getHours() === 12) {
        str = `${d.getHours()}:${cap}${d.getMinutes()}PM`;
      } else {
        str = `${d.getHours()}:${cap}${d.getMinutes()}AM`;
      }

      return str;
    }
  */


  makeBuildingString(off) {
    let str = off.location;

    if (str && off.street1) {
      str = str + ', ' + off.street1;
    } else {
      str = off.street1;
    }
    return str;
  }

  makePhone(p: PhoneObj) {
    let
      str = '';

    if (p.phdescription) {
      str = p.phdescription;
    }

    if (str.length > 0) {
      str = str + ': ';
    }

    if (p.digits) {
      str = str + p.digits;
    }

    return str;
  }

  getLoc(url: string) {
    if (url.includes('community')) {
      return 'Community';
    } else if (url.includes('orgs')) {
      return 'Orgs';
    } else if (url.includes('inst')) {
      return 'Inst';
    } else if (url.includes('corp')) {
      return 'CorpsPri';
    }
  }

  makePhoneNumberList(off) {
    const str = [];

    if (off) {
      off.forEach(p => {
        const pst = this.makePhone(p);
        str.push(pst);
      });
    }
    return str;
  }

  makeOfficeAddress(office: Office): string {
    let str = '';
    if (str && office.street1) {
      str = str + ', ' + office.street1;
    } else {
      str = office.street1;
    }

    const s = this.makeCityLine(office);
    if (s && s.length > 0) {
      str = str + ', ' + s;
    }
    return str;
  }

  makeAddress(add) {
    return this.makeBuildingString(add) + ', ' + this.makeCityLine(add);
  }

  public makeFullCityLine(city, state, zip, country): string {
    let str = '';
    if (city) {
      str = city;
    }

    if (state) {
      if (str) {
        str = str + ', ' + state;
      } else {
        str = state;
      }
    }

    if (zip) {
      if (str) {
        str = str + ' ' + zip;
      } else {
        str = zip;
      }
    }

    if (country) {
      if (str) {
        str = str + ' ' + country;
      } else {
        str = country;
      }
    }

    return str;
  }

  makeCityLine(off) {
    let str = off.city;

    if (str.length > 0 && off.state) {
      str = str + ', ' + off.state + ' ';
    }

    if (off.zip) {
      str = str + off.zip;
    }

    return str;
  }


  makeComAddress(loc: LocationData): string {
    let str = '';
    if (str && loc.street1) {
      str = str + ', ' + loc.street1;
    } else {
      str = loc.street1;
    }

    const s = this.makeCityLine(loc);
    if (s && s.length > 0) {
      str = str + ', ' + s;
    }
    return str;
    console.log('str', str);

  }

  makeComCityLine(off) {
    let str = off.city;

    if (str.length > 0 && off.state) {
      str = str + ', ' + off.state + ' ';
    }

    if (off.zip) {
      str = str + off.zip;
    }

    return str;
  }


  public makeName(staff) {
    let str = staff.fname;

    if (str && staff.mname) {
      str = str + ' ' + staff.mname;
    } else if (staff.mname) {
      str = staff.mname;
    }

    if (str && staff.lname) {
      str = str + ' ' + staff.lname;
    } else if (staff.lname) {
      str = staff.lname;
    }

    if (str && staff.zname) {
      str = str + ' ' + staff.zname;
    } else if (staff.zname) {
      str = staff.zname;
    }

    return str;
  }


  public postDeleteUser(delukey, validemailextensions, org) {
    const url = 'https://us-central1-library-titleix.cloudfunctions.net/postDeleteUser';

    this.authx.auth.currentUser.getIdToken()
      .then(authToken => {
        const headers = new HttpHeaders({'Authorization': 'Bearer ' + authToken});
        return this.http.post(url,
          {
            orgukey: this.admin.org,
            userukey: delukey,
            valid: validemailextensions,
            roc: org,
          },
          {headers: headers}).toPromise();
      })
      .then(res => console.log(res));
  }

  public async makeKwabId(pth) {
    const promise = new Promise((resolve, reject) => {
      this.fs.titleIXdb.object(`Legacy/Counters/resources`).valueChanges().take(1).subscribe(c => {
        let x = <number> c;
        this.fs.titleIXdb.object(`Legacy/Counters/resources`).set(x + 1);
        this.fs.titleIXdb.object(`LegacyLookup/${x}`).set(pth);
        resolve(x);
      });
    });
    return promise;
  }

  async postTrans(langv, textv: string) {
    // const url = `https://www.googleapis.com/language/translate/v2?key=AIzaSyCZYeiSVEp-gqu4av3j1277i9d7gIqsQcg&source=en&target=${langv}&q=${textv}`;
    const url = `https://www.googleapis.com/language/translate/v2?key=AIzaSyCZYeiSVEp-gqu4av3j1277i9d7gIqsQcg&target=en&q=${textv}`;
    return this.http.post(url, {}).toPromise();
  }

  async onTransEl(desc: LangStringBlock, native: string) {
    await Languages.forEach(lang => {
      if (lang.abbr !== native && desc && desc[native] && desc[native].description && !desc[lang.abbr].block) {
        this.postTrans(lang.abbr, desc[native].description).then(val => {
          desc[lang.abbr].description = val['data'].translations[0].translatedText;
        });
      }
    });


  }

  extractInitialsFromName(nam: string) {
    if (nam.length < 3) {
      return nam;
    } else {
      let name = nam.replace(/Jr|Sr|I|II|III|IV|V|Esq|Md|PhD|Dr.|Mr.|Ms.|Mrs./g, '');
      name = name.replace(/[^A-Z]/g, '').toUpperCase();
      if (nam.length > 2) {
        name = name[0] + name[name.length - 1];
      } else {
        name = nam;
      }
      return name;
    }
  }

  uptodateConversations() {
    if (this.$sub13) {
      this.$sub13.unsubscribe();
    }

    this.$sub13 = this.fs.titleIXdb.object(`ROEDevices/${this.admin.deviceid}/convo`).valueChanges()
      .subscribe(conversations => {
        this.frndchat = false;
        if (conversations) {
          const arr = Object.keys(conversations);
          if (arr && arr.length > 0) {
            this.conarr = [];
            arr.forEach(a => {
              if (conversations[a].status && conversations[a].status === 'new') {

                this.fs.titleIXdb.object(`ROEDevices/${this.admin.deviceid}/conversations/${a}`).valueChanges()
                  .take(1)
                  .subscribe(fcon => {

                    if (!this.conversations) {
                      this.conversations = [];
                    }
                    this.conversations[a] = fcon;

                    const d = new Date().getTime();

                    this.fs.titleIXdb.object(`ROEDevices/${this.admin.deviceid}/convo/${a}/lr`).set(d);
                    this.fs.titleIXdb.object(`ROEDevices/${this.admin.deviceid}/convo/${a}/lw`).set(d + 100);

                    this.fs.titleIXdb.object(`ROEDevices/${this.admin.deviceid}/convo/${a}/status`).remove();


                  });
              } else {
                if (conversations[a].lw > conversations[a].lr || (conversations[a].lw && !conversations[a].lr)) {
                  this.conarr[a] = true;
                  this.frndchat = true;
                }
              }
            });
          }
        }

      });
  }

  setupChat() {
    if (this.admin.deviceid && this.admin.deviceid > 0) {
      if (this.$sub11) {
        this.$sub11.unsubscribe();
      }
      if (this.$sub12) {
        this.$sub12.unsubscribe();
      }

      this.$sub11 = this.fs.titleIXdb.list<any>(`ROEDevices/${this.admin.deviceid}/frndmsg`).valueChanges().subscribe(is => {
        this.incidents = _.orderBy(is, ['lr'], ['desc']);
      });

      this.$sub12 = this.fs.titleIXdb.list<any>(`ROEDevices/${this.admin.deviceid}/myfriends`).valueChanges().subscribe(frs => {
        this.frnds = frs;
      });

      this.uptodateConversations();

    }
  }


  getIncidentAlerts() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }

    this.setupChat();

    this.incidentalert = false;
    if (this.$sub1) {
      this.$sub1.unsubscribe();
      this.$sub1 = this.fs.titleIXdb.list(`Orgs/${this.admin.org}/incidentalert`).valueChanges().subscribe(lst => {
          if (lst && lst.length > 0) {
            this.incidentalert = true;
          } else {
            this.incidentalert = false;
          }
        },
        err => console.log(''));
    } else {
      this.$sub1 = this.fs.titleIXdb.list(`Orgs/${this.admin.org}/incidentalert`).valueChanges().subscribe(lst => {
          if (lst && lst.length > 0) {
            this.incidentalert = true;
          } else {
            this.incidentalert = false;
          }
        },
        err => console.log(''));
    }

    this.partneralerts = [];
    if (this.$sub2) {
      this.$sub2.unsubscribe();
      this.$sub2 = this.fs.titleIXdb.list(`Orgs/${this.admin.org}/partneralert`).valueChanges().subscribe(lst => {
          this.partneralerts = [];
          if (lst && lst.length > 0) {
            lst.forEach(l => {
              this.partneralerts.push(<string> l);
            });
          }
        },
        err => console.log(''));
    } else {
      this.$sub2 = this.fs.titleIXdb.list(`Orgs/${this.admin.org}/partneralert`).valueChanges().subscribe(lst => {
          this.partneralerts = [];
          this.partneralerts = [];
          if (lst && lst.length > 0) {
            lst.forEach(l => {
              this.partneralerts.push(<string> l);
            });
          }
        },
        err => console.log(''));
    }
  }

  getCovidDays(d: number): number {
    const x = (d - new Date().getTime()) / (60 * 1000);
    return Math.trunc(x / (24 * 60));
    /*
        else if (x < 24 * 60) {
          return 0;
        } else {
          return Math.trunc(x / (24 * 60));
        }
    */

  }

  getstartix(hours, y, m, d) {
    const date = new Date(y, m, d);

    const utcYear = date.getUTCFullYear();
    const utcMonth = date.getUTCMonth();
    const utcDay = date.getUTCDate();

    const x = Date.UTC(utcYear, utcMonth, utcDay, 0, 0, 0);

    return hours.findIndex(h => h.ukey * 1 === x);
  }

  getstartindex(hours, y, m, d): boolean {
    console.log('y m d', y, m, d);
    if (hours && hours[0]) {
      const x = new Date(hours[0].ukey * 1);
      const yr = x.getFullYear();
      const mt = x.getMonth();
      const dy = x.getDate();
      if ((dy === d) && (y === yr) && (mt === m)) {
        return true;
      }
    }

    return false;
  }

  makeFileName(str: string): string {
    return `https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Assets%2Fcatalog-icons%2F${str}?alt=media`;
  }

  makenPersonName(f, m, l, s): string {
    let str = f;
    if (m) {
      str = str + ' ' + m + ' ' + l;
    } else {
      str = str + ' ' + l;
    }

    if (s) {
      str = str + ' ' + s;
    }

    return str;
  }


  // todo: need to do for community as well - set pth below
  isOrgOpenNow() {
    let res = false;
    const curday = new Date();
    curday.setHours(0, 0, 0, 0);
    const x = curday.getTime();
    const pth = `Orgs/${this.admin.org}/twentyfourseven`;
    this.fs.titleIXdb.object(pth).valueChanges().take(1).subscribe(tf => {
      if (tf) {
        res = true;
      } else {
        const pth1 = `Orgs/${this.admin.org}/hours/${x}`;
        const curtime = new Date().getTime();


        this.fs.titleIXdb.object(pth1).valueChanges().take(1).subscribe(v => {
          const vals = Object.keys(v).map(function(key) {
            return v[key];
          });

          vals.forEach(hr => {
            if (hr.open) {
              if (curtime >= hr.time && curtime < hr.time * (hr.len * 59999)) {
                res = true;
              }
            }
          });

        });

      }
    });

    return res;
  }

  makeBlocks(): BizHour[] {
    const blocks = new Array();
    const b = new BizHour();
    b.time = new Date();
    b.open = false;
    b.time.setHours(0, 0, 0, 0);
    b.len = 359;
    /*
                    b.tm = b.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
    */
    b.tm = b.time.toTimeString().substring(0, 8);
    blocks.push(b);

    for (let ix = 6; ix < 20; ix++) {
      const bh = new BizHour();
      bh.time = new Date();
      bh.open = false;
      bh.len = 59;
      bh.time.setHours(ix, 0, 0);
      /*
                          bh.tm = bh.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
      */
      bh.tm = bh.time.toTimeString().substring(0, 8);
      blocks.push(bh);
    }

    return blocks;
  }


  setuphours(hours, regulardays, twentyfourseven): BizYear {
    console.log('hours', hours);
    this.adjust = 0;
    /* * * might need to restore
    if (hours && hours.length > 0) {
      const cday = new Date().setHours(0, 0, 0, 0);
      const curmod = cday % (24 * 60 * 60 * 1000);
      const mod = parseInt((hours[0].ukey), 10) % (24 * 60 * 60 * 1000);
      this.adjust = curmod - mod;
    }
    * * */

    const bzh = new BizYear();

    const curday = new Date();
    const mth = curday.getMonth();
    const year = curday.getFullYear();
    let pos = -1;
    let len = -1;
    if (hours) {
      len = hours.length;
    }

    if (twentyfourseven) {
      bzh.twentyfourseven = twentyfourseven;
    } else {
      bzh.twentyfourseven = false;
    }


    bzh.regulardays = new Array();
    for (let i = 0; i < 7; i++) {
      let op = false;
      const newday = new BizDay();
      const newblocks = new Array();

      if (regulardays && regulardays.length > 0 && regulardays[i] && regulardays[i].length > 0) {
        // ???there is some confusion about this: are there blocks or not. community hours doesn't seem to have them for regular days
        regulardays[i].forEach(hr => {
          const tmphr = new BizHour();
          tmphr.description = (hr as BizHour).description;
          if (!tmphr.description) {
            tmphr.description = '';
          }
          tmphr.len = (hr as BizHour).len;
          tmphr.open = (hr as BizHour).open;
          if (tmphr.open) {
            op = true;
          }
          tmphr.tm = (hr as BizHour).tm;
          tmphr.time = new Date((hr as BizHour).time);
          newblocks.push(tmphr);
        });
        newday.open = op;
        newday.blocks = newblocks;
      } else {

        /*
                        const blocks = new Array();
                        const b = new BizHour();
                        b.time = new Date();
                        b.open = false;
                        b.time.setHours(0, 0, 0, 0);
                        b.len = 359;
                        /!*
                                        b.tm = b.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
                        *!/
                        b.tm = b.time.toTimeString().substring(0, 8);

                        blocks.push(b);

                        for (let ix = 6; ix < 20; ix++) {
                            const bh = new BizHour();
                            bh.time = new Date();
                            bh.open = false;
                            bh.len = 59;
                            bh.time.setHours(ix, 0, 0);
                            /!*
                                                bh.tm = bh.time.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*!/, '$1');
                            *!/
                            bh.tm = bh.time.toTimeString().substring(0, 8);
                            blocks.push(bh);
                        }
        */

        newday.open = false;
        newday.blocks = this.makeBlocks();
      }

      bzh.regulardays.push(newday);
    }

    bzh.months = new Array();
    for (let i = 0; i < 12; i++) {
      const month = new BizMonth();
      month.month = new Date(year, mth + i, 1, 0, 0, 0, 0);
      month.days = new Array();

      /*
                  month.month.setFullYear(year, mth + i, 1);
      */
      const y = month.month.getFullYear();
      const m = month.month.getMonth();
      for (let d = 0; d < this.dayspermonth[m]; d++) {
        const nbd = new BizDay();
        nbd.day = new Date(y, m, d + 1, 0, 0, 0, 0);
        if (pos === -1) {
          pos = this.getstartix(hours, y, m, d + 1);
          /*
                    if (this.getstartindex(hours, y, m, d + 1)) {
                      pos = 0;
                    }
          */
        }

        if ((pos > -1) && (pos < len)) {
          let op = false;
          const blocks = new Array();
          hours[pos].forEach(hr => {
            const b = new BizHour();
            b.time = new Date(hr.time);
            b.open = hr.open;
            if (b.open) {
              op = true;
            }
            b.len = hr.len;
            b.tm = hr.tm;
            blocks.push(b);
            nbd.open = op;
            nbd.blocks = blocks;
          });
          pos = pos + 1;

        } else {
          const blocks = new Array();
          const b = new BizHour();
          b.time = new Date();
          b.open = false;
          b.time.setHours(0, 0, 0);
          b.len = 359;
          b.tm = b.time.toTimeString().substring(0, 8);

          blocks.push(b);

          for (let ix = 6; ix < 20; ix++) {
            const bh = new BizHour();
            bh.time = new Date();
            bh.open = false;
            bh.len = 59;
            bh.time.setHours(ix, 0, 0);
            bh.tm = bh.time.toTimeString().substring(0, 8);

            blocks.push(bh);
          }
          nbd.open = false;
          nbd.blocks = blocks;
        }

        nbd.color = '#000000';
        month.days.push(nbd);


      }
      bzh.months.push(month);
    }
    return bzh;
  }

  packstring(em: Array<any>): string {
    let s = '';
    em.forEach(e => {
      if (e.value) {
        const str = e.img.split('/').pop().replace('emoji-', '').replace('.png', '');
        if (s.length === 0) {
          s = str;
        } else {
          s = s + ',' + str;
        }
      }
    });
    return s;
  }

  packit(em: Array<any>): string {
    let s = '';
    em.forEach(e => {
      if (e.value) {
        if (s.length === 0) {
          s = e.view;
        } else {
          s = s + ', ' + e.view;
        }
      }
    });
    return s;
  }

  unpackit(st: string, emj: Array<any>): Array<any> {
    emj.forEach(em => em.value = false);
    const spl = st.split(', ');
    spl.forEach(s => {
      emj.forEach(em => {
        if (em.view === s) {
          em.value = true;
        }
      });
    });

    return emj;
  }


  unpackstring(st: string, emj: Array<any>): Array<any> {
    emj.forEach(em => em.value = false);
    const spl = st.split(',');
    spl.forEach(s => {
      emj.forEach(em => {
        const str = em.img.split('/').pop().replace('emoji-', '').replace('.png', '');
        if (str === s) {
          em.value = true;
        }
      });
    });

    return emj;
  }

  // private db: AngularFireDatabase
  constructor(private fs: FirebaseService,
              private router: Router,
              public authx: AngularFireAuth,
              private http: HttpClient,
              private schooldb: FirebaseService) {
    this.admin = <Admin> {};
    this.preview = false;
    this.loggedin = true;  // Todo: in production this needs to be false
  }

  isIncidents(): boolean {
    let x = false;
    this.admin.modules.forEach(mod => {
      if (mod.name === 'incidents' && mod.val) {
        x = true;
      }
    });
    return x;
  }

  getObject(pth): Observable<any> {
    return this.fs.titleIXdb.object(pth).valueChanges();
  }

  async mockRoc() {
    await this.authx.auth.signInWithEmailAndPassword('anita.hill@harvard.edu', 'HighViewDrive1960');
    await this.getObject(`Bystander/admin/0`)
      .take(1).subscribe(a => {
        this.admin = a;
        this.preview = false;
        this.loggedin = true;
        this.roc = true;
        this.router.navigate(['rochome']);
      });

  }


  async mockCom() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    // todo: i think scope is used to limit the choice of services when doing a search
    this.scope = 'IN';
    await this.authx.auth.signInWithEmailAndPassword('richard@capptivation.com', 'HighViewDrive1960');

    await this.getObject(`Marketing/-LPgtb9Y2W3QT3WyeNbd`) // -LPgtb9Y2W3QT3WyeNbd // -LrxzF1QPyJkLJn1ld0a
      .take(1).subscribe(a => {
        this.admin = a;
        this.admin.org = this.admin.orgs[0].organ;
        this.admin.modules = this.admin.orgs[0].modules;
        this.preview = false;
        this.loggedin = true;

        //  this.router.navigate(['comdashboard']);
        this.router.navigateByUrl('/comparent');
      });

  }

  async mockHealth() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    // todo: i think scope is used to limit the choice of services when doing a search
    this.scope = 'IN';
    await this.authx.auth.signInWithEmailAndPassword('richard@capptivation.com', 'HighViewDrive1960');

    await this.getObject(`Marketing/-Lp4tMzFSOYOLDl6S9YC`) // -LPgtb9Y2W3QT3WyeNbd // -LrxzF1QPyJkLJn1ld0a
      .take(1).subscribe(a => {
        this.admin = a;
        this.admin.org = this.admin.orgs[0].organ;
        this.admin.modules = this.admin.orgs[0].modules;
        this.preview = false;
        this.loggedin = true;

        //  this.router.navigate(['comdashboard']);
        this.router.navigateByUrl('/corpparent');
      });

  }

  async mockTesting() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    this.scope = 'IN';
    await this.authx.auth.signInWithEmailAndPassword('richard@capptivation.com', 'HighViewDrive1960');
    await this.getObject(`Marketing/testing`) // -LPgtb9Y2W3QT3WyeNbd // -LrxzF1QPyJkLJn1ld0a
      .take(1).subscribe(a => {
        this.admin = a;
        console.log('a', a);
        this.admin.org = this.admin.orgs[0].organ;
        this.admin.modules = this.admin.orgs[0].modules;
        this.preview = false;
        this.loggedin = true;

        //  this.router.navigate(['comdashboard']);
        this.router.navigateByUrl('/corpparent');
      });

  }


  async mockHR() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    // todo: i think scope is used to limit the choice of services when doing a search
    this.scope = 'IN';
    await this.authx.auth.signInWithEmailAndPassword('richard@capptivation.com', 'HighViewDrive1960');
    let ukey;
    if (this.isgovt) {
      ukey = 'govukeyexample';
    } else {
      ukey = '-Lp4tMzFSOYOLDl6S9ZZ';
    }
    await this.getObject(`Marketing/${ukey}`) // -LPgtb9Y2W3QT3WyeNbd // -LrxzF1QPyJkLJn1ld0a
      .take(1).subscribe(a => {
        this.admin = a;
        console.log('admin', a);

        this.admin.org = this.admin.orgs[0].organ;
        this.admin.modules = this.admin.orgs[0].modules;
        this.preview = false;
        this.loggedin = true;

        //  this.router.navigate(['comdashboard']);
        this.router.navigateByUrl('/corpparent');
      });

  }

  async mockCorp() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    // todo: i think scope is used to limit the choice of services when doing a search
    this.scope = 'IN';
    await this.authx.auth.signInWithEmailAndPassword('1607035265141@capptivation.com', '1607035265141');
    await this.getObject(`Marketing/1607035265141`)
      .take(1).subscribe(a => {
        this.admin = a;
        const arr = Object.values(this.admin.orgs);
        const len = arr.length - 1;
        this.admin.org = arr[len].organ;
        this.admin.modules = arr[len].modules;
        this.preview = false;
        this.loggedin = true;
        this.router.navigateByUrl('/instparent');
      });

  }

  async mockInv() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    await this.authx.auth.signInWithEmailAndPassword('1004580r@capptivation.com', '-MFGKxKeka3eY4A8Xhs4');
    await this.getObject(`Marketing/individual`) //   -LphaG2x6jy-rG4-keot
      .take(1).subscribe(a => {
        console.log('this.authx.auth', this.authx.auth.currentUser.uid);
        this.admin = a;
        this.admin.org = this.admin.orgs[0].organ;
        this.admin.modules = this.admin.orgs[0].modules;
        this.preview = false;
        this.loggedin = true;
        this.router.navigate(['invperms']);
      });
  }

  async mockOrg() {
    this.languageon = true;
    this.language = 'en';
    this.fulllanguage = 'English';
    this.scope = 'IN';

    await this.authx.auth.signInWithEmailAndPassword('richard@capptivation.com', 'HighViewDrive1960');
    await this.getObject(`Marketing/richardorg`)
      .take(1).subscribe(a => {
        this.admin = a;
        this.admin.org = this.admin.orgs[0].organ;

        this.getIncidentAlerts();
        this.admin.modules = this.admin.orgs[0].modules;

        this.preview = false;
        this.loggedin = true;
        this.router.navigate(['profile']);
      });

  }


  permission(r: string): boolean {
    if (this.admin.type === 'Admin' || r === 'profile') {
      return true;
    } else if (!this.admin.modules) {
      return false;
    } else {
      const mod = this.admin.modules.findIndex(x => x.code === r);
      if (mod > -1 && this.admin.modules[mod].val) {
        return true;
      } else {
        return false;
      }
    }
  }


  isClimate(): boolean {
    let tmp = false;
    if (this.admin) {
      this.admin.modules.forEach(m => {
        if (m.name === 'climate') {
          tmp = m.val;
        }
      });
    }

    return tmp;
  }


  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub11) {
      this.$sub11.unsubscribe();
    }
    if (this.$sub12) {
      this.$sub12.unsubscribe();
    }
    if (this.$sub13) {
      this.$sub13.unsubscribe();
    }

  }
}
