import {stringify} from '@angular/compiler/src/util';

export const Classifications = [
  {kingdom: 'Admin', action: '', phylum: 'Capital', class: '', google: 'capital'},
  {kingdom: 'Admin', action: '', phylum: 'City', class: '', google: 'locality'},
  {kingdom: 'Admin', action: '', phylum: 'Commune', class: '', google: 'commune'},
  {kingdom: 'Admin', action: '', phylum: 'Autonomous Community', class: '', google: 'autonomous_community'},
  {kingdom: 'Admin', action: '', phylum: 'Census Designated Area', class: '', google: 'census_designated_place'},
  {kingdom: 'Admin', action: '', phylum: 'Country', class: '', google: 'country'},
  {kingdom: 'Admin', action: '', phylum: 'County', class: '', google: 'administrative_area_level_2'},
  {kingdom: 'Admin', action: '', phylum: 'Department', class: '', google: 'administrative_area_level_2'},
  {kingdom: 'Admin', action: '', phylum: 'District', class: '', google: 'administrative_area_level_3'},
  {kingdom: 'Admin', action: '', phylum: 'Municipality', class: '', google: 'administrative_area_level_4'},
  {kingdom: 'Admin', action: '', phylum: 'Neighborhood', class: '', google: 'neighborhood'},
  {kingdom: 'Admin', action: '', phylum: 'Territory', class: '', google: 'Territory'},
  {kingdom: 'Admin', action: '', phylum: 'Township', class: '', google: 'township'},
  {kingdom: 'Admin', action: '', phylum: 'Oblast', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Parish', class: '', google: 'administrative_area_level_4'},
  {kingdom: 'Admin', action: '', phylum: 'Prefecture', class: '', google: 'administrative_area_level_2'},
  {kingdom: 'Admin', action: '', phylum: 'Province', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Region', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'State', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Governorate', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Emirate', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Canton', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Special Administrative Region', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Autonomous Region', class: '', google: 'administrative_area_level_1'},
  {kingdom: 'Admin', action: '', phylum: 'Barangay', class: '', google: 'administrative_area_level_5'},
  {kingdom: 'Admin', action: '', phylum: 'Ward', class: '', google: 'ward'},
  {kingdom: 'Admin', action: '', phylum: 'Postal Town', class: '', google: 'postal_town'},
  {kingdom: 'Admin', action: '', phylum: 'Colloquial Area', class: '', google: 'colloquial_area'},
  {kingdom: 'Admin', action: '', phylum: 'Town', class: '', google: 'locality'},
  {kingdom: 'Admin', action: '', phylum: 'Borough', class: '', google: ''},
  {kingdom: 'Admin', action: '', phylum: 'Village', class: '', google: 'locality'},
  {kingdom: 'Admin', action: '', phylum: 'Arrondissement', class: '', google: 'administrative_area_level_3'},
  {kingdom: 'Admin', action: '', phylum: 'Metropolitan City', class: '', google: 'administrative_area_level_2'},
  {
    "kingdom": "POI",
    "action": "crafts",
    "phylum": "Jeweler",
    "class": "",
    "google": ""
  },

  {
    "kingdom": "POI",
    "action": "crafts",
    "phylum": "Sculpture",
    "class": "",
    "google": ""
  },
  {
    "kingdom": "POI",
    "action": "crafts",
    "phylum": "Glass Blower",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Archipelago',
    'class': '',
    'google': 'archipelago'
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Badlands',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Bay',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Bay',
    'class': 'Cove',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Bay',
    'class': 'Sound',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': 'Black Sand Beach',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': 'Pebble Beach',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': 'Pink Sand Beach',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': 'Sea Glass Beach',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Beach',
    'class': 'White Sand Beach',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Cape',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Cave',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Cenote',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Channel',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Cliff',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Desert',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Dunes',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Feature',
    'class': '',
    'google': 'natural_feature'
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Fjord',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Forest',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Forest',
    'class': 'Rainforest',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Glacier',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Gulf',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Harbour',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Headland',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Heath',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Highland',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Hill',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Hot Spring',
    'class': '',
    'google': ''
  },

  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Island',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lake',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lake',
    'class': 'Alpine Lake',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lake',
    'class': 'Artificial Lake',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lake',
    'class': 'Lagoon',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Oasis',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lake',
    'class': 'Pond',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Mountain',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Mountain Pass',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Peninsula',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Plateau',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Reef',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'River',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'River',
    'class': 'Estuary',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Lagoon',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'River',
    'class': 'Stream',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Rock Formation',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'River',
    'class': 'Tributary',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Salt',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Salt',
    'class': 'Salt Flat',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Salt',
    'class': 'Salt Lake',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Salt',
    'class': 'Salt Pan',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Swamp',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Valley',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Valley',
    'class': 'Canyon',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Valley',
    'class': 'Gorge',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Valley',
    'class': 'Ravine',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Volcano',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Volcano',
    'class': 'Shield Volcando',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Volcano',
    'class': 'Stratovolcano',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Waterfall',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Geography',
    'action': 'geography',
    'phylum': 'Wetland',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Accommodation',
    'class': '',
    'google': 'lodging'
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Airbnb',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Campground',
    'class': '',
    'google': 'campground'
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Cottages',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Hostel',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Hotel',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Hotel',
    'class': 'Inn',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Hotel',
    'class': 'Bed & Breakfast',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Hotel',
    'class': 'Exotic',
    'google': ''
  },

  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Lodge',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Ranch',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Resort',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Cabins',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'Retreat',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'accommodations',
    'phylum': 'RV Park',
    'class': '',
    'google': 'rv_park'
  },
  {
    "kingdom": "POI",
    "action": "accommodations",
    "phylum": "Shelter",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Amusement Park Ride',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Amusement Park Ride',
    'class': 'Ferris Wheel',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Amusement Park Ride',
    'class': 'Roller Coaster',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Authority',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Bowling',
    'class': '',
    'google': 'bowling_alley'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Casino',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Comedy Club',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Cultural Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Cultural Center',
    'class': 'Art Center',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Fairground',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Golf',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Golf',
    'class': 'Miniature Golf',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Arcade',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Aquatics Center',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Escape Room',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Haunted House',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Historic Village',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Ice Skating Rink',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Laser Tag',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Indoor Activity',
    'class': 'Go-Kart Track',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Night Club',
    'class': '',
    'google': 'night_club'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Outdoor Activity',
    'class': 'Labyrinth',
    'google': ''
  },{
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Outdoor Activity',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Recreation Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Ski Area',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Ski Resort',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Spa',
    'class': '',
    'google': 'spa'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Theater',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Theme Park',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Theme Park',
    'class': 'Amusement Park',
    'google': ''
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "ATV Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Balloon Rides",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Bike Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Dog Sled Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Ghost Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Bird Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Bus Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Boat Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Climbing Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Fishing Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Heli Skiing",
    "google": ""
  },

  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Kayaking Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Food Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Hiking Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Horse Riding",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Nature Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Off Road Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Rafting",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class":"Rail Bike Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Safaris",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Sailing Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Scuba Diving Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Spirits Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Walking Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Whale Watching Tours",
    "google": ""
  },
  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Wilderness Tours",
    "google": ""
  },

  {
    "kingdom": "Service",
    "action": "experiences",
    "phylum": "Tour Agency",
    "class": "Wine Tours",
    "google": ""
  },
  {
    'kingdom': 'Service',
    'action': 'experiences',
    'phylum': 'Tour Agency',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Travel Agency',
    'class': '',
    'google': 'travel_agency'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Amphitheater',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Arena',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Auditorium',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Bullring',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Car Racing Track',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Civic Center',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Concert Hall',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Convention Center',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Event Venue',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Flamenco Theater',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Movie Theater',
    'google': 'movie_theater'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Music Hall',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Opera House',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Performing Arts Center',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Race Track',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Sports Complex',
    'google': 'stadium'
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Venue',
    'class': 'Theater',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Water Park',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'experiences',
    'phylum': 'Yoga',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Abbey',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Amusement Center',
    'class': '',
    'google': 'amusement_park'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Aquarium',
    'class': '',
    'google': 'aquarium'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Aqueduct',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Archaeological Site',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Archive',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Archive',
    'class': 'Library',
    'google': 'library'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Artwork',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Arch Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Bascule Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Cable-Stayed Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Footbridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Girder Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Pedestrian Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Suspension Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Bridge',
    'class': 'Tied-arch Bridge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Building',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Building',
    'class': 'Skyscraper',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Canal',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Castle',
    'class': '',
    'google': 'casino'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cemetery',
    'class': '',
    'google': 'cemetery'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cemetery',
    'class': 'Catacombs',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cemetery',
    'class': 'Memorial',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cemetery',
    'class': 'Necropolis',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cemetery',
    'class': 'Tomb',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Church',
    'class': '',
    'google': 'church'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Church',
    'class': 'Basilica',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Church',
    'class': 'Cathedral',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'City Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Convent',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Cultural Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Dam',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Factory',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Factory',
    'class': 'Car',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Factory',
    'class': 'Chocolate',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Factory',
    'class': 'Mill',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Farm',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Farm',
    'class': 'Orchard',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Fort',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Fort',
    'class': 'Citadel',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Fountain',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Gallery',
    'class': '',
    'google': 'art_gallery'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Garden',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Garden',
    'class': 'Arboretum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Garden',
    'class': 'Botanical Garden',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Garden',
    'class': 'Indoor Garden',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Garden',
    'class': 'Sculpture Garden',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Golf Course',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Harbour',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Hiking Area',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Birthplace',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Château',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Country House',
    'google': 'courthouse'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Manor House',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Mansion',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Palace',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Building',
    'class': 'Royal Residence',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Landmark',
    'class': '',
    'google': 'landmark'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Historical Site',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Lighthouse',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Marina',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Military',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Military',
    'class': 'Battlefield',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Military',
    'class': 'Military Cemetery',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Monastery',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Monument',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Monument',
    'class': 'Fountain',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Monument',
    'class': 'Sculpture',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Monument',
    'class': 'Statue',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Mosque',
    'class': '',
    'google': 'mosque'
  },
  {
    "kingdom": "POI",
    "action": "pois",
    "phylum": "Street Art",
    "class": "",
    "google": ""
  },
  {
    "kingdom": "POI",
    "action": "pois",
    "phylum": "Street Art",
    "class": "Mural",
    "google": ""
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': '',
    'google': 'museum'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Art Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Aviation Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Ceramic Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Children\\\'s Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Ethnographic Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Sculpture Garden',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Farm Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Film Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Handicraft Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Local History Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Maritime Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Military Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Modern Art Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Natural History Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Open Air Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Rail Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Science Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Technology Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Museum',
    'class': 'Toy Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Observatory',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': '',
    'google': 'park'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'City Park',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'Hiking Area',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'National Forest',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'National Park',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'Picnic Ground',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'Playground',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Park',
    'class': 'State Park',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Pier',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Planetarium',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'POI',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Pool',
    'class': 'Indoor Pool',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Pool',
    'class': 'Outdoor Pool',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Preserve',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Preserve',
    'class': 'Reserve',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Preserve',
    'class': 'Wildlife Park',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Promenade',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Promenade',
    'class': 'Boardwalk',
    'google': ''
  },

  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Promenade',
    'class': 'Esplanade',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Public Square',
    'class': '',
    'google': 'town_square'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Recreation Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Scientific Institute',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Religious Place',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Religious Place',
    'class': 'Shrine',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Scenic Spot',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Synagogue',
    'class': '',
    'google': 'synagogue'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': 'Buddhist Temple',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': 'Hindu Temple',
    'google': 'hindu_temple'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': 'Jain Temple',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': 'Sikh Temple',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Temple',
    'class': 'Taoist Temple',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Tourist Attraction',
    'class': '',
    'google': 'tourist_attraction'
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Tourist Attraction',
    'class': 'Wax Museum',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Tourist Attraction',
    'class': 'Windmill',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Tower',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Travel Lounge',
    'class': '',
    'google': ''
  },
  {
    "kingdom": "POI",
    "action": "experiences",
    "phylum": "Beach Club",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Travel Lounge',
    'class': 'Airport Lounge',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Tunnel',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Vineyard',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Vineyard',
    'class': 'Winery',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Water Park',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'pois',
    'phylum': 'Zoo',
    'class': '',
    'google': 'zoo'
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bakery',
    'class': '',
    'google': 'bakery'
  },
  {
    'kingdom': 'POI',
    'action': 'crafts',
    'phylum': 'Crafts',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'crafts',
    'phylum': 'Jeweler',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bar',
    'class': '',
    'google': 'bar'
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bar',
    'class': 'Beer Garden',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bar',
    'class': 'Gay Bar',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bar',
    'class': 'Rooftop Bar',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Bar',
    'class': 'Wine Bar',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Brewery',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Cafe',
    'class': '',
    'google': 'cafe'
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Cafe',
    'class': 'Bistro',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Cafe',
    'class': 'Coffee Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Distillery',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': '',
    'google': 'restaurant'
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'American Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'BBQ',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Chilean Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Deli',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Diner',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Fast Food',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'French',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'French Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'German Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Greek Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Hot Dog Stand',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Italian',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Italian Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Japanese',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Japanese Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Mexican Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Michelin Star',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Peruvian Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Pizza Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Polish',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Polish Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Pub',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Sandwich Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Seafood Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Spanish',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Spanish Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Steak House',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Swedish Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Thai',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Thai Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Vegetarian Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Vegan Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Venezuelan Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Vietnamese Restaurant',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'restaurants',
    'phylum': 'Restaurant',
    'class': 'Ice Cream Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'service',
    'phylum': 'Rail',
    'class': 'Mountain Cable Car',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Mall',
    'class': '',
    'google': 'shopping_mall'
  },
  {
    "kingdom": "POI",
    "action": "shops",
    "phylum": "Food Court",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Christmas Market',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Farmers\' Market',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Flea',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Flower',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Food',
    'google': 'food'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Market',
    'class': 'Night Market',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': '',
    'google': 'store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Bicycle Shop',
    'google': 'bicycle_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Bookstore',
    'google': 'book_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Cannabis Store',
    'google': 'cannabis'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Candy Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Grocery Store',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Ceramics',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Cheese Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Chocolate',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Chocolate Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Clothing Store',
    'google': 'clothing_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Convenience Store',
    'google': 'convenience_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Department Store',
    'google': 'department_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Florist',
    'google': 'florist'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Furniture Store',
    'google': 'furniture_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Gas Station',
    'google': 'gas_station'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Gift Shop',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Jewelry Store',
    'google': 'jewelry_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Liquor Store',
    'google': 'liquor_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Pet Store',
    'google': 'pet_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Pharmacy',
    'google': 'pharmacy'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Shoe Store',
    'google': 'shoe_store'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Souvenir',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Supermarket',
    'google': 'supermarket'
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Specialty Retail',
    'google': ''
  },
  {
    'kingdom': 'POI',
    'action': 'shops',
    'phylum': 'Store',
    'class': 'Toy Store',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Road',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Road',
    'class': 'Avenue',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Road',
    'class': 'Highway',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Trail',
    'class': '',
    'google': ''
  },
  {
    "kingdom": "Route",
    "action": "routes",
    "phylum": "Cultural Trail",
    "class": "",
    "google": ""
  },
  {
    "kingdom": "Route",
    "action": "routes",
    "phylum": "Byway",
    "class": "",
    "google": ""
  },
  {
    "kingdom": "Route",
    "action": "routes",
    "phylum": "Pilgrimage",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Trail',
    'class': 'Bike',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Trail',
    'class': 'Bike and Hiking',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Trail',
    'class': 'Hiking',
    'google': ''
  },
  {
    'kingdom': 'Route',
    'action': 'routes',
    'phylum': 'Trail',
    'class': 'Trailhead',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Airport',
    'class': '',
    'google': 'airport'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Bank',
    'class': '',
    'google': 'bank'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Bank',
    'class': 'ATM',
    'google': 'atm'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Beauty Salon',
    'class': '',
    'google': 'beauty_salon'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': '',
    'google': 'local_government_office'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Courthouse',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Department of Tourism',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Embassy',
    'google': 'embassy'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Fire Department',
    'google': 'fire_station'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Police',
    'google': 'police'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Post Office',
    'google': 'post_office'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Public Works',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'State Capitol',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Tourist Information Center',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Government Office',
    'class': 'Town Hall',
    'google': 'city_hall'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Laundry',
    'class': '',
    'google': 'laundry'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Academy',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Art School',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Ballet School',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Class',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'College',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Dance School',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Horse Riding School',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Music School',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Private University',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Public University',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'School',
    'google': 'school'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'Surfing Lessons',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Learning',
    'class': 'University',
    'google': 'university'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': 'Clinic',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': 'Dentist',
    'google': 'dentist'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': 'Doctor',
    'google': 'doctor'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': 'Hospital',
    'google': 'hospital'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Medical',
    'class': 'Veterinary Care',
    'google': 'veterinary_care'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Parking',
    'class': '',
    'google': 'parking'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Public Bath',
    'class': '',
    'google': ''
  },
  {
    "kingdom": "POI",
    "action": "experiences",
    "phylum": "Thermal Bath",
    "class": "",
    "google": ""
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rail',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rail',
    'class': 'Funicular',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rail',
    'class': 'Subway',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rail',
    'class': 'Train',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rail',
    'class': 'Tram',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': 'ATV Rental',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': 'Bike Rental',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': 'Canoe Rental',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': 'Car Rental',
    'google': 'car_rental'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Rental',
    'class': 'Ski Rental',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Repair',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Repair',
    'class': 'Car Repair',
    'google': 'car_repair'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Repair',
    'class': 'Clock',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Bus Station',
    'google': 'bus_station'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Gondola Lift Station',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Metro Station',
    'google': 'subway_station'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Terminal',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Train Station',
    'google': 'train_station'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Station',
    'class': 'Transit Station',
    'google': 'transit_station'
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Tourist Information Center',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Town Hall',
    'class': '',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Transportation',
    'class': 'Car Service',
    'google': ''
  },
  {
    'kingdom': 'Service',
    'action': 'service',
    'phylum': 'Transportation',
    'class': 'Ferry',
    'google': ''
  },
];


export const Meal = [	{name: 'Breakfast', val: false},
  {name: 'Bruch', val: false},
  {name: 'Lunch', val: false},
  {name: 'Dinner', val: false}]

export const Spreads = [
  {name: 'Butter', val: false},
  {name: 'Cream Cheese', val: false},
  {name: 'Dressing', val: false},
  {name: 'Extract', val: false},
  {name: 'Honey', val: false},
  {name: 'Hot Sauce', val: false},
  {name: 'Jam', val: false},
  {name: 'Hummus', val: false},
  {name: 'Mayonnaise', val: false},
  {name: 'Nutella', val: false},
  {name: 'Peanut Butter', val: false},
  {name: 'Spread', val: false},
  {name: 'Syrup', val: false},
  {name: 'Vegemite', val: false},
  ]


export const Cuisine = [
  {name: 'Afghan', val: false},
  {name: 'Austrian', val: false},
  {name: 'British', val: false},
   {name: 'Brazilian', val: false},
  {name: 'Cajun', val: false},
  {name: 'Chinese', val: false},
  {name: 'Dominican Republic', val: false},
   {name: 'Ethiopian', val: false},
  {name: 'Filipino', val: false},
   {name: 'French', val: false},
   {name: 'Greek', val: false},
  {name: 'German', val: false},
  {name: 'Hungarian', val: false},
  {name: 'Indian', val: false},
  {name: 'Indonesian', val: false},
   {name: 'Israeli', val: false},
  {name: 'Italian', val: false},
  {name: 'Jamaican', val: false},
  {name: 'Lao', val: false},
  {name: 'Japanese', val: false},
  {name: 'Kosher', val: false},
  {name: 'Korean', val: false},
   {name: 'Lebanese', val: false},
  {name: 'Mexican', val: false},
  {name: 'Middle Eastern', val: false},
  {name: 'Mediterranean', val: false},
   {name: 'Moroccan', val: false},
  {name: 'North African', val: false},
  {name: 'Norwegian', val: false},
  {name: 'Persian', val: false},
  {name: 'Polish', val: false},
  {name: 'Portuguese', val: false},
  {name: 'Puerto Rican', val: false},
  {name: 'Romanian', val: false},
  {name: 'Russian', val: false},
  {name: 'Southern', val: false},
  {name: 'Spanish', val: false},
   {name: 'Thai', val: false},
  {name: 'Turkish', val: false},
   {name: 'Vietnamese', val: false}]

export const Diet = [	{name: 'Dairy Free', val: false},
  {name: 'Egg Free', val: false},
  {name: 'Keto', val: false},
  {name: 'FODMAP', val: false},
  {name: 'GF & Dairy Free', val: false},
  {name: 'GF & Vegan', val: false},
  {name: 'Gluten Free', val: false},
  {name: 'Low Carb', val: false},
  {name: 'Low Fat', val: false},
  {name: 'Meal Prep', val: false},
  {name: 'Nut Free', val: false},
  {name: 'Paleo', val: false},
  {name: 'Soy Free', val: false},
  {name: 'Sugar Free', val: false},
  {name: 'Tomato Free', val: false},
  {name: 'Vegetarian', val: false},
  {name: 'Vegan', val: false}]

export const Everyday = [
  {name: 'Food and Nutrition', val: false},
  {name: 'Burger', val: false},
  {name: 'Casserole', val: false},
  {name: 'Copycat', val: false},
  {name: 'Culture', val: false},
  {name: 'Curry', val: false},
  {name: 'Foraging', val: false},
  {name: 'Good to Know', val: false},
  {name: 'Hot Dog', val: false},
  {name: 'Mac and Cheese', val: false},
  {name: 'Pancakes', val: false},
  {name: 'Medicinal', val: false},
  {name: 'Juice', val: false},
  {name: 'Nachos', val: false},
  {name: 'Pizza', val: false},
  {name: 'Sandwich', val: false},
  {name: 'Tacos', val: false},
  {name: 'Weight Watchers', val: false},
  {name: 'Eating Out', val: false},
  {name: 'Christmas', val: false},
  {name: 'Halloween', val: false},
  {name: 'Rosh Hashanah', val: false},
  {name: 'Thanksgiving', val: false},
  {name: 'Yom Kippur', val: false}

]

export const Course = [	{name: 'Appetizers', val: false},
  {name: 'Dessert', val: false},
  {name: 'Dips, Condiments & Sauces', val: false},
  {name: 'Salads', val: false},
  {name: 'Sides', val: false},
  {name: 'Snacks', val: false},
  {name: 'Soups', val: false},
  {name: 'Entree', val: false}]

export const Bakery = [	{name: 'Bread', val: false},
  {name: 'Brownies & Bars', val: false},
  {name: 'Cakes', val: false},
  {name: 'Cheesecake', val: false},
  {name: 'Cookies', val: false},
  {name: 'Cupcakes', val: false},
  {name: 'Donuts', val: false},
  {name: 'Frosting', val: false},
  {name: 'Muffins, Scones, & Biscuits', val: false},
  {name: 'Pastries', val: false},
  {name: 'Pies, Crisps, & Tarts', val: false},
  {name: 'Quiche', val: false}]

export const Sweets = [
  {name: 'Candy', val: false},
  {name: 'Chocolate', val: false},
  {name: 'Caramel', val: false},
  {name: 'Frozen Treats', val: false},
  {name: 'Pudding, Custard', val: false},
  {name: 'Ice Cream', val: false}]

export const Gear = [	{name: 'Cookware', val: false},
  {name: 'Utensils', val: false},
  {name: 'Kitchen', val: false},
  {name: 'Knives', val: false},
  {name: 'Bakeware', val: false},
  {name: 'Small Appliances', val: false},
  {name: 'Kitchen Tools', val: false},
  {name: 'Cook\'s Tools', val: false},
  {name: 'Cookware Sets', val: false},
  {name: 'Outdoor Cooking', val: false},
  {name: 'Storage', val: false},
  {name: 'Large Appliances', val: false},
  {name: 'Tableware', val: false}];


export const FlavorMatch = [
  {name: 'Absinthe', match: 'Absinthe'},
  {name: 'Amaretto', match: 'Amaretto'},
  {name: 'Aperol', match: 'Aperol'},
  {name: 'Aquavit', match: 'Aquavit'},
  {name: 'Baijiu', match: 'Baijiu'},
  {name: 'Bourbon', match: 'Bourbon'},
  {name: 'Brandy', match: 'Brandy'},
  {name: 'Campari', match: 'Campari'},
  {name: 'Cognac', match: 'Cognac'},
  {name: 'Fernet', match: 'Fernet'},
  {name: 'Gin', match: 'Gin'},
  {name: 'Grappa', match: 'Grappa'},
  {name: 'Irish Whiskey', match: 'Irish Whiskey'},
  {name: 'Limoncello', match: 'Limoncello'},
  {name: 'Mezcal', match: 'Mezcal'},
  {name: 'Ouzo', match: 'Ouzo'},
  {name: 'Pisco', match: 'Pisco'},
  {name: 'Rum', match: 'Rum'},
  {name: 'RumChata', match: 'RumChata'},
  {name: 'Rye Whiskey', match: 'Rye Whiskey'},
  {name: 'Sake', match: 'Sake'},
  {name: 'Sambuca', match: 'Sambuca'},
  {name: 'Schnapps', match: 'Schnapps'},
  {name: 'Scotch', match: 'Scotch'},
  {name: 'Single Malt Scotch', match: 'Single Malt Scotch'},
  {name: 'Slivovitz', match: 'Slivovitz'},
  {name: 'Soju', match: 'Soju'},
  {name: 'Tequila', match: 'Tequila'},
  {name: 'Triple Sec', match: 'Triple Sec'},
  {name: 'Vermouth', match: 'Vermouth'},
  {name: 'Whiskey', match: 'Whiskey'},
  {name: 'Vodka', match: 'Vodka'},
  {name: 'Vodka', match: 'Vodka'},
]



export const Flavor = [
  {name: 'Absinthe', val: false},
  {name: 'Amaretto', val: false},
  {name: 'Aperol', val: false},
  {name: 'Aquavit', val: false},
  {name: 'Baijiu', val: false},
  {name: 'Bourbon', val: false},
  {name: 'Brandy', val: false},
  {name: 'Campari', val: false},
  {name: 'Cognac', val: false},
  {name: 'Fernet', val: false},
  {name: 'Gin', val: false},
  {name: 'Grappa', val: false},
  {name: 'Irish Whiskey', val: false},
  {name: 'Limoncello', val: false},
  {name: 'Mezcal', val: false},
  {name: 'Ouzo', val: false},
  {name: 'Pisco', val: false},
  {name: 'Rum', val: false},
  {name: 'RumChata', val: false},
  {name: 'Rye Whiskey', val: false},
  {name: 'Sake', val: false},
  {name: 'Sambuca', val: false},
  {name: 'Schnapps', val: false},
  {name: 'Scotch', val: false},
  {name: 'Single Malt Scotch', val: false},
  {name: 'Slivovitz', val: false},
  {name: 'Soju', val: false},
  {name: 'Tequila', val: false},
  {name: 'Triple Sec', val: false},
  {name: 'Vermouth', val: false},
  {name: 'Whiskey', val: false},
  {name: 'Vodka', val: false},
  {name: 'Cabernet Sauvignon', val: false},
  {name: 'Merlot', val: false},
  {name: 'Pinot Noir', val: false},
  {name: 'Zinfandel', val: false},
  {name: 'Malbec', val: false},
  {name: 'Chardonnay', val: false},
  {name: 'Sauvignon Blanc', val: false},
  {name: 'Pinot Grigio', val: false},
  {name: 'Riesling', val: false},
  {name: 'Prosecco', val: false},
  {name: 'Champagne', val: false},
  {name: 'Madeira', val: false},

]

// v,
// , , , Syrah/Shiraz, , Tempranillo, Sangiovese, Barbera, , , Semillon, Muscat, /Pinot Gris, Gewurztraminer, , Viognier, Chenin Blanc, , ,
// Cava, Moscato d'Asti, Port, Sherry, , Marsala, Sauternes, Ice Wine



export const Drinks = [
  {name: 'Beer', val: false},
  {name: 'Cocktail', val: false},
  {name: 'Cold Drink', val: false},
  {name: 'Hot Drink', val: false},
  {name: 'Shake', val: false},
  {name: 'Smoothie', val: false},
  {name: 'Spirits', val: false},
  {name: 'Wine', val: false}]

export const DrinksMatch = [
  {name: 'Beer', match: 'Beer'},
  {name: 'Cocktail', match: 'Cocktail'},
  {name: 'Cold Drink', match: 'Cold Drink'},
  {name: 'Hot Drink', match: 'Hot Drink'},
  {name: 'Hot Drink', match: 'Coffee'},
  {name: 'Shake', match: 'Shake'},
  {name: 'Smoothie', match: 'Smoothie'},
  {name: 'Spirits', match: 'Spirits'},
  {name: 'Wine', match: 'Wine'}]

export const MealMatch = [
  {name: 'Breakfast', match: 'Breakfast'},
  {name: 'Breakfast', match: 'starting my day'},
  {name: 'Breakfast', match: 'start my day'},
      {name: 'Bruch', match: 'Bruch'},
  {name: 'Lunch', match: 'Lunch'},
  {name: 'Dinner', match: 'Dinner'}]

export const SpreadsMatch = [
  {name: 'Butter', match: 'Butter'},
  {name: 'Cream Cheese', match: 'Cream Cheese'},
  {name: 'Dressing', match: 'Dressing'},
  {name: 'Honey', match: 'Honey'},
  {name: 'Hot Sauce', match: 'Hot Sauce'},
  {name: 'Jam', match: 'Jam'},
  {name: 'Hummus', match: 'Hummus'},
  {name: 'Mayonnaise', match: 'Mayonnaise'},
  {name: 'Nutella', match: 'Nutella'},
  {name: 'Peanut Butter', match: 'Peanut Butter'},
  {name: 'Spread', match: 'Spread'},
  {name: 'Syrup', match: 'Syrup'},
  {name: 'Extract', match: 'Extract'},
  {name: 'Vegemite', match: 'Vegemite'},
]

export const CuisineMatch = [	{name: 'Italian', match: 'Italian'},
  {name: 'Italian', match: 'Pasta'},
  {name: 'Italian', match: 'Pizza'},
  {name: 'Dominican Republic', match: 'Dominican Republic'},
  {name: 'Chinese', match: 'Chinese'},
  {name: 'Indian', match: 'Indian'},
  {name: 'Mexican', match: 'Mexican'},
  {name: 'Persian', match: 'Persian'},
  {name: 'Persian', match: 'Iranian'},
  {name: 'Romanian', match: 'Romanian'},
  {name: 'Russian', match: 'Russian'},
  {name: 'Polish', match: 'Polish'},
  {name: 'German', match: 'German'},
  {name: 'Austrian', match: 'Austrian'},
  {name: 'Lao', match: 'Lao'},
  {name: 'Hungarian', match: 'Hungarian'},
  {name: 'Portuguese', match: 'Portuguese'},
  {name: 'Puerto Rican', match: 'Puerto Rican'},
  {name: 'Mexican', match: 'Taco'},
  {name: 'Japanese', match: 'Japanese'},
  {name: 'Japanese', match: 'Sushi'},
  {name: 'Thai', match: 'Thai'},
  {name: 'French', match: 'French'},
  {name: 'Spanish', match: 'Spanish'},
  {name: 'Greek', match: 'Greek'},
  {name: 'Turkish', match: 'Turkish'},
  {name: 'Lebanese', match: 'Lebanese'},
  {name: 'Kosher', match: 'Kosher'},
  {name: 'Korean', match: 'Korean'},
  {name: 'Vietnamese', match: 'Vietnamese'},
  {name: 'Moroccan', match: 'Moroccan'},
  {name: 'Brazilian', match: 'Brazilian'},
  {name: 'Cajun', match: 'Cajun'},
  {name: 'Ethiopian', match: 'Ethiopian'},
  {name: 'Filipino', match: 'Filipino'},
  {name: 'Southern', match: 'Southern'},
  {name: 'Afghan', match: 'Afghan'},
  {name: 'British', match: 'British'},
  {name: 'Indonesian', match: 'Indonesian'},
  {name: 'Middle Eastern', match: 'Middle Eastern'},
  {name: 'Mediterranean', match: 'Mediterranean'},
  {name: 'North African', match: 'North African'},
]

export const DietMatch = [	{name: 'Dairy free', match: 'Dairy free'},
  {name: 'Egg free', match: 'Egg free'},
  {name: 'Keto', match: 'Keto'},
  {name: 'GF & Dairy free', match: 'GF & Dairy free'},
  {name: 'GF & Vegan', match: 'GF & Vegan'},
  {name: 'Gluten Free', match: 'Gluten free'},
  {name: 'Low Carb', match: 'Low Carb'},
  {name: 'FODMAP', match: 'FODMAP'},
  {name: 'Low Fat', match: 'Low Fat'},
  {name: 'Meal Prep', match: 'Meal Prep'},
  {name: 'Nut Free', match: 'Nut free'},
  {name: 'Paleo', match: 'Paleo'},
  {name: 'Soy Free', match: 'Soy free'},
  {name: 'Sugar Free', match: 'Sugar free'},
  {name: 'Tomato Free', match: 'Tomato free'},
  {name: 'Vegetarian', match: 'Vegetarian'},
  {name: 'Vegan', match: 'Vegan'}]

export const EverydayMatch = [	{name: 'Food and Nutrition', match: 'Food and Nutrition'},
  {name: 'Pizza', match: 'Pizza'},
  {name: 'Tacos', match: 'Taco'},
  {name: 'Sandwich',  match: 'Sandwich'},
  {name: 'Sandwich',  match: 'Wrap'},
  {name: 'Sandwich',  match: 'Lobster roll'},
  {name: 'Halloween', match: 'Halloween'},
  {name: 'Thanksgiving', match: 'Thanksgiving'},
  {name: 'Burger',  match: 'Burger'},
  {name: 'Burger',  match: 'Slider'},
  {name: 'Casserole',  match: 'Casserole'},
  {name: 'Copycat', match: 'Copycat'},
  {name: 'Curry',  match: 'Curry'},
  {name: 'Curry',  match: 'Curries'},
  {name: 'Hot Dog', match: 'Hot Dog'},
  {name: 'Juice', match: 'Juice'},
  {name: 'Mac and Cheese', match: 'Mac and Cheese'},
  {name: 'Pancakes', match: 'Pancake'},
  {name: 'Nachos', match: 'Nachos'},
  {name: 'Weight Watchers', match: 'Low Calorie'},
  {name: 'Weight Watchers', match: 'Weight Watchers'},
  {name: 'Eating Out', match: 'Restaurant'},
  {name: 'Christmas', match: 'Christmas'},
  {name: 'Rosh Hashanah', match: 'Rosh Hashanah'},
  {name: 'Yom Kippur', match: 'Yom Kippur'}
]

export const CourseMatch = [	{name: 'Appetizers', match: 'Appetizers'},
  {name: 'Dessert', match: 'Dessert'},
  {name: 'Dips, Condiments & Sauces', match: 'Dips & Sauces'},
  {name: 'Dips, Condiments & Sauces', match: 'Dip'},
  {name: 'Dips, Condiments & Sauces', match: 'Condiment'},
  {name: 'Dips, Condiments & Sauces', match: 'Sauce'},
  {name: 'Salads', match: 'Salad'},
  {name: 'Sides', match: 'Sides'},
  {name: 'Snacks', match: 'Snack'},
  {name: 'Soups', match: 'Soup'},
  {name: 'Soups', match: 'Chowder'},
  {name: 'Soups', match: 'Gazpacho'},
  {name: 'Entree', match: 'Entree'},
  {name: 'Main Course', match: 'Main Course'}]

export const BakeryMatch = [	{name: 'Bread', match: 'Bread'},
  {name: 'Brownies & Bars', match: 'Brownies & Bars'},
  {name: 'Cakes', match: 'Cake'},
  {name: 'Cheesecake', match: 'Cheesecake'},
  {name: 'Cookies', match: 'Cookie'},
  {name: 'Cupcakes', match: 'Cupcake'},
  {name: 'Donuts', match: 'Donut'},
  {name: 'Frosting', match: 'Frosting'},
  {name: 'Pastries', match: 'Pastries'},
  {name: 'Pastries', match: 'Pastry'},
  {name: 'Pastries', match: 'Galette'},
  {name: 'Pastries', match: 'Danish'},
  {name: 'Pastries', match: 'Éclair'},
  {name: 'Pastries', match: 'Palmier'},
  {name: 'Pastries', match: 'Baklava'},
  {name: 'Pastries', match: 'Cannoli'},
  {name: 'Pastries', match: 'Mille-feuille'},
  {name: 'Pastries', match: 'Profiterole'},
  {name: 'Pastries', match: 'Beignet'},
  {name: 'Pastries', match: 'Turnover'},
  {name: 'Muffins, Scones, & Biscuits', match: 'Muffin'},
  {name: 'Muffins, Scones, & Biscuits', match: 'Scone'},
  {name: 'Muffins, Scones, & Biscuits', match: 'Biscuit'},
  {name: 'Pies, Crisps, & Tarts', match: 'Pie'},
  {name: 'Pies, Crisps, & Tarts', match: 'Crisp'},
  {name: 'Pies, Crisps, & Tarts', match: 'Tart'},
  {name: 'Pies, Crisps, & Tarts', match: 'Galette'},
  {name: 'Quiche', match: 'Quiche'}]

export const SweetsMatch = [
  {name: 'Candy', match: 'Candy'},
  {name: 'Caramel', match: 'Caramel'},
  {name: 'Chocolate', match: 'Chocolate'},
  {name: 'Frozen Treats', match: 'Frozen'},
  {name: 'Frozen Treats', match: 'Popsicle'},
  {name: 'Frozen Treats', match: 'Gelato'},
  {name: 'Frozen Treats', match: 'Sorbet'},
  {name: 'Frozen Treats', match: 'Sherbet'},
  {name: 'Frozen Treats', match: 'Snow Cone'},
  {name: 'Frozen Treats', match: 'Shaved Ice'},
  {name: 'Frozen Treats', match: 'Ice Lolly'},
  {name: 'Frozen Treats', match: 'Granita'},
  {name: 'Frozen Treats', match: 'Italian Ice'},
  {name: 'Pudding, Custard', match: 'Crème Brûlée'},
  {name: 'Pudding, Custard', match: 'Bavarian Cream'},
  {name: 'Pudding, Custard', match: 'Tapioca'},
  {name: 'Pudding, Custard', match: 'Pot de Crème'},
  {name: 'Pudding, Custard', match: 'Crème Caramel'},
  {name: 'Pudding, Custard', match: 'Flan'},
  {name: 'Pudding, Custard', match: 'Blancmange'},
  {name: 'Pudding, Custard', match: 'Panna Cotta'},
  {name: 'Pudding, Custard', match: 'Mousse'},
  {name: 'Pudding, Custard', match: 'Custard'},
  {name: 'Pudding, Custard', match: 'Pudding'},
  {name: 'Ice Cream', match: 'Milkshake'},
  {name: 'Ice Cream', match: 'Frappe'},
  {name: 'Ice Cream', match: 'Ice Cream'}]

export const GearMatch = [	{name: 'Cookware', match: 'Cookware'},
  {name: 'Cookware', match: 'Pots'},
  {name: 'Cookware', match: 'Pans'},
  {name: 'Cookware', match: 'Skillets'},
  {name: 'Cookware', match: 'Dutch Ovens'},
  {name: 'Cookware', match: 'Roasting Pans'},
  {name: 'Cookware', match: 'Frying Pans'},
  {name: 'Tableware', match: 'Tableware'},
  {name: 'Utensils', match: 'Utensils'},
  {name: 'Utensils', match: 'Spatulas'},
  {name: 'Utensils', match: 'Spoons'},
  {name: 'Utensils', match: 'Tongs'},
  {name: 'Utensils', match: 'Whisks'},
  {name: 'Utensils', match: 'Ladles'},
  {name: 'Utensils', match: 'Slotted Spoons'},
  {name: 'Knives', match: 'Knives'},
  {name: 'Knives', match: 'Chef\'S Knife'},
  {name: 'Knives', match: 'Paring Knife'},
  {name: 'Knives', match: 'Serrated Knife'},
  {name: 'Knives', match: 'Utility Knife'},
  {name: 'Knives', match: 'Boning Knife'},
  {name: 'Bakeware', match: 'Bakeware'},
  {name: 'Bakeware', match: 'Baking Sheets'},
  {name: 'Bakeware', match: 'Cake Pans'},
  {name: 'Bakeware', match: 'Muffin Tins'},
  {name: 'Bakeware', match: 'Loaf Pans'},
  {name: 'Bakeware', match: 'Baking Dishes'},
  {name: 'Small Appliances', match: 'Small Appliances'},
  {name: 'Small Appliances', match: 'Blenders'},
  {name: 'Small Appliances', match: 'Mixers'},
  {name: 'Small Appliances', match: 'Food Processors'},
  {name: 'Small Appliances', match: 'Toasters'},
  {name: 'Small Appliances', match: 'Coffee Makers'},
  {name: 'Kitchen Tools', match: 'Kitchen Tools'},
  {name: 'Kitchen Tools', match: 'Can Openers'},
  {name: 'Kitchen Tools', match: 'Cutting Boards'},
  {name: 'Kitchen Tools', match: 'Peelers'},
  {name: 'Kitchen Tools', match: 'Graters'},
  {name: 'Kitchen Tools', match: 'Measuring Cupsspoons'},
  {name: 'Kitchen Tools', match: 'Colanders'},
  {name: 'Kitchen Tools', match: 'Sieves'},
  {name: 'Cook\'s Tools', match: 'Cook\'s Tools'},
  {name: 'Cook\'s Tools', match: 'Thermometers'},
  {name: 'Cook\'s Tools', match: 'Timers'},
  {name: 'Cook\'s Tools', match: 'Kitchen Scales'},
  {name: 'Cook\'s Tools', match: 'Kitchen Timers'},
  {name: 'Cook\'s Tools', match: 'Garlic Presses'},
  {name: 'Cook\'s Tools', match: 'Citrus Juicers'},
  {name: 'Cook\'s Tools', match: 'Avocado Slicers'},
  {name: 'Cook\'s Tools', match: 'Egg Separators'},
  {name: 'Cookware Sets', match: 'Cookware Sets'}]


export const IngredientMatch = [	{name: 'Poultry', match: 'Poultry'},
  {name: 'Chicken', match: 'Chicken'},
  {name: 'Turkey', match: 'Turkey'},
  {name: 'Duck', match: 'Duck'},
  {name: 'Goose', match: 'Goose'},
  {name: 'Quail', match: 'Quail'},
  {name: 'Pheasant', match: 'Pheasant'},
  {name: 'Cornish Hen', match: 'Cornish Hen'},
  {name: 'Guinea Fowl', match: 'Guinea Fowl'},
  {name: 'Seafood', match: 'Seafood'},
  {name: 'Fish', match: 'Fish'},
  {name: 'Shrimp', match: 'Shrimp'},
  {name: 'Crab', match: 'Crab'},
  {name: 'Lobster', match: 'Lobster'},
  {name: 'Scallop', match: 'Scallop'},
  {name: 'Clam', match: 'Clam'},
  {name: 'Mussel', match: 'Mussel'},
  {name: 'Oyster', match: 'Oyster'},
  {name: 'Squid', match: 'Squid'},
  {name: 'Octopus', match: 'Octopus'},
  {name: 'Mackerel', match: 'Mackerel'},
  {name: 'Tuna', match: 'Tuna'},
  {name: 'Salmon', match: 'Salmon'},
  {name: 'Cod', match: 'Cod'},
  {name: 'Sardine', match: 'Sardine'},
  {name: 'Trout', match: 'Trout'},
  {name: 'Haddock', match: 'Haddock'},
  {name: 'Herring', match: 'Herring'},
  {name: 'Mahi-Mahi', match: 'Mahi-Mahi'},
  {name: 'Snapper', match: 'Snapper'},
  {name: 'Swordfish', match: 'Swordfish'},
  {name: 'Grouper', match: 'Grouper'},
  {name: 'Tilapia', match: 'Tilapia'},
  {name: 'Catfish', match: 'Catfish'},
  {name: 'Anchovy', match: 'Anchovy'},
  {name: 'Beef', match: 'Beef'},
  {name: 'Hamburger', match: 'Hamburger'},
  {name: 'Ribeye', match: 'Ribeye'},
  {name: 'Tenderloin', match: 'Tenderloin'},
  {name: 'Striploin', match: 'Striploin'},
  {name: 'T-Bone', match: 'T-Bone'},
  {name: 'Porterhouse', match: 'Porterhouse'},
  {name: 'Sirloin', match: 'Sirloin'},
  {name: 'Chuck', match: 'Chuck'},
  {name: 'Brisket', match: 'Brisket'},
  {name: 'Short Rib', match: 'Short Rib'},
  {name: 'Flank Steak', match: 'Flank Steak'},
  {name: 'Skirt Steak', match: 'Skirt Steak'},
  {name: 'Filet Mignon', match: 'Filet Mignon'},
  {name: 'Flat Iron', match: 'Flat Iron'},
  {name: 'Tri-Tip', match: 'Tri-Tip'},
  {name: 'Top Round', match: 'Top Round'},
  {name: 'Bottom Round', match: 'Bottom Round'},
  {name: 'Eye of Round', match: 'Eye of Round'},
  {name: 'Rump Steak', match: 'Rump Steak'},
  {name: 'Ground Beef', match: 'Ground Beef'},
  {name: 'Minced Beef', match: 'Minced Beef'},
  {name: 'Prime Rib', match: 'Prime Rib'},
  {name: 'Hanger Steak', match: 'Hanger Steak'},
  {name: 'Rib Roast', match: 'Rib Roast'},
  {name: 'Rump Roast', match: 'Rump Roast'},
  {name: 'Ribeye Cap', match: 'Ribeye Cap'},
  {name: 'Tomahawk Steak', match: 'Tomahawk Steak'},
  {name: 'Steak', match: 'Steak'},
  {name: 'Beef Shank', match: 'Beef Shank'},
  {name: 'Pork', match: 'Pork'},
  {name: 'Pancetta', match: 'Pancetta'},
  {name: 'Guanciale', match: 'Guanciale'},
  {name: 'Prosciutto', match: 'Prosciutto'},
  {name: 'Ham', match: 'Ham'},
  {name: 'Bacon', match: 'Bacon'},
  {name: 'Sausage', match: 'Sausage'},
  {name: 'Lamb', match: 'Lamb'},
  {name: 'Liver', match: 'Liver'},
  {name: 'Mutton', match: 'Mutton'},
  {name: 'Goat', match: 'Goat'},
  {name: 'Veal', match: 'Veal'},
  {name: 'Venison', match: 'Venison'},
  {name: 'Bison', match: 'Bison'},
  {name: 'Rabbit', match: 'Rabbit'},
  {name: 'Pasta', match: 'Pasta'},
  {name: 'Tofu', match: 'Tofu'},
  {name: 'Spaghetti', match: 'Spaghetti'},
  {name: 'Penne', match: 'Penne'},
  {name: 'Fusilli', match: 'Fusilli'},
  {name: 'Macaroni', match: 'Macaroni'},
  {name: 'Noodle', match: 'Noodle'},
  {name: 'Linguine', match: 'Linguine'},
  {name: 'Farfalle', match: 'Farfalle'},
  {name: 'Rigatoni', match: 'Rigatoni'},
  {name: 'Fettuccine', match: 'Fettuccine'},
  {name: 'Lasagna', match: 'Lasagna'},
  {name: 'Ravioli', match: 'Ravioli'},
  {name: 'Tortellini', match: 'Tortellini'},
  {name: 'Orzo', match: 'Orzo'},
  {name: 'Vermicelli', match: 'Vermicelli'},
  {name: 'Angel hair', match: 'Angel hair'},
  {name: 'Tagliatelle', match: 'Tagliatelle'},
  {name: 'Cannelloni', match: 'Cannelloni'},
  {name: 'Rotini', match: 'Rotini'},
  {name: 'Ziti', match: 'Ziti'},
  {name: 'Pappardelle', match: 'Pappardelle'},
  {name: 'Capellini', match: 'Capellini'},
  {name: 'Grain', match: 'Grain'},
  {name: 'Rice', match: 'Rice'},
  {name: 'Wheat', match: 'Wheat'},
  {name: 'Bulgur', match: 'Bulgur'},
  {name: 'Couscous', match: 'Couscous'},
  {name: 'Semolina', match: 'Semolina'},
  {name: 'Barley', match: 'Barley'},
  {name: 'Oats', match: 'Oats'},
  {name: 'Oatmeal', match: 'Oatmeal'},
  {name: 'Corn', match: 'Corn'},
  {name: 'Herbs', match: 'Herbs'},
  {name: 'Vegetables', match: 'Vegetables'},
  {name: 'Vegetables', match: 'Veggie'},
  {name: 'Polenta', match: 'Polenta'},
  {name: 'Quinoa', match: 'Quinoa'},
  {name: 'Millet', match: 'Millet'},
  {name: 'Buckwheat', match: 'Buckwheat'},
  {name: 'Amaranth', match: 'Amaranth'},
  {name: 'Farro', match: 'Farro'},
  {name: 'Sorghum', match: 'Sorghum'},
  {name: 'Teff', match: 'Teff'},
  {name: 'Wild Rice', match: 'Wild Rice'},
  {name: 'Spelt', match: 'Spelt'},
  {name: 'Kamut', match: 'Kamut'},
  {name: 'Freekeh', match: 'Freekeh'},
  {name: 'Fruit', match: 'Fruit'},
  {name: 'Apple', match: 'Apple'},
  {name: 'Banana', match: 'Banana'},
  {name: 'Fig', match: 'Fig'},
  {name: 'Orange', match: 'Orange'},
  {name: 'Grape', match: 'Grape'},
  {name: 'Pineapple', match: 'Pineapple'},
  {name: 'Pumpkin', match: 'Pumpkin'},
  {name: 'Mango', match: 'Mango'},
  {name: 'Strawberry', match: 'Strawberry'},
  {name: 'Watermelon', match: 'Watermelon'},
  {name: 'Avocado', match: 'Avocado'},
  {name: 'Blueberry', match: 'Blueberry'},
  {name: 'Raspberry', match: 'Raspberry'},
  {name: 'Blackberry', match: 'Blackberry'},
  {name: 'Kiwi', match: 'Kiwi'},
  {name: 'Nectarine', match: 'Nectarine'},
  {name: 'Peach', match: 'Peach'},
  {name: 'Pear', match: 'Pear'},
  {name: 'Cherry', match: 'Cherry'},
  {name: 'Lemon', match: 'Lemon'},
  {name: 'Lime', match: 'Lime'},
  {name: 'Grapefruit', match: 'Grapefruit'},
  {name: 'Papaya', match: 'Papaya'},
  {name: 'Plum', match: 'Plum'},
  {name: 'Coconut', match: 'Coconut'},
  {name: 'Apricot', match: 'Apricot'},
  {name: 'Cranberry', match: 'Cranberry'},
  {name: 'Lychee', match: 'Lychee'},
  {name: 'Pomegranate', match: 'Pomegranate'},
  {name: 'Cantaloupe', match: 'Cantaloupe'},
  {name: 'Carrot', match: 'Carrot'},
  {name: 'Onion', match: 'Onion'},
  {name: 'Tomato', match: 'Tomato'},
  {name: 'Potato', match: 'Potato'},
  {name: 'Potato', match: 'Tater'},
  {name: 'Chipotle', match: 'Chipotle'},
  {name: 'Bell Pepper', match: 'Bell Pepper'},
  {name: 'Basil', match: 'Basil'},
  {name: 'Basil', match: 'Pesto'},
  {name: 'Broccoli', match: 'Broccoli'},
  {name: 'Spinach', match: 'Spinach'},
  {name: 'Cauliflower', match: 'Cauliflower'},
  {name: 'Chickpeas', match: 'Chickpeas'},
  {name: 'Cucumber', match: 'Cucumber'},
  {name: 'Squash', match: 'Squash'},
  {name: 'Zucchini', match: 'Zucchini'},
  {name: 'Eggplant', match: 'Eggplant'},
  {name: 'Beans', match: 'Beans'},
  {name: 'Legumes', match: 'Legume'},
  {name: 'Legumes', match: 'Soybean'},
  {name: 'Legumes', match: 'Peas'},
  {name: 'Legumes', match: 'Lentil'},
  {name: 'Cabbage', match: 'Cabbage'},
  {name: 'Brussels Sprouts', match: 'Brussels Sprouts'},
  {name: 'Kale', match: 'Kale'},
  {name: 'Asparagus', match: 'Asparagus'},
  {name: 'Sweet potato', match: 'Sweet potato'},
  {name: 'Mushroom', match: 'Mushroom'},
  {name: 'Celery', match: 'Celery'},
  {name: 'Radish', match: 'Radish'},
  {name: 'Corn', match: 'Corn'},
  {name: 'Lettuce', match: 'Lettuce'},
  {name: 'Garlic', match: 'Garlic'},
  {name: 'Beetroot', match: 'Beetroot'},
  {name: 'Feta', match: 'Feta'},
  {name: 'Cheese', match: 'Cheese'},
  {name: 'Goat Cheese', match: 'Goat Cheese'},
  {name: 'Cheese', match: 'Cheesy'},
  {name: 'Cottage Cheese', match: 'Cottage Cheese'},
  {name: 'Yogurt', match: 'Yogurt'},
  {name: 'Egg', match: 'Egg'},
  {name: 'Oil', match: 'Oil'},
  {name: 'Chocolate Chip', match: 'Chocolate Chip'},
  {name: 'Dates', match: 'Dates'},
  {name: 'Nuts', match: 'Nuts'},
  {name: 'Nuts', match: 'Nuts'},
  {name: 'Nuts', match: 'Almond'},
  {name: 'Nuts', match: 'Macadamia'},
  {name: 'Nuts', match: 'Pecan'},
  {name: 'Nuts', match: 'Pistachios'},
  {name: 'Seasoning', match: 'Seasoning'},
  {name: 'Pretzels', match: 'Pretzel'},
]

export const MethodMatch = [
  {name: 'Air Fry', match: 'Air Fry'},
  {name: 'Air Fry', match: 'Air-Fry'},
  {name: 'Bake', match: 'Bake'},
  {name: 'Barbecue', match: 'Barbecue'},
  {name: 'Barbecue', match: 'Barbeque'},
  {name: 'Barbecue', match: 'BBQ'},
  {name: 'Blanche', match: 'Blanche'},
  {name: 'Boil', match: 'Boil'},
  {name: 'Broil', match: 'Broil'},
  {name: 'Braise', match: 'Braise'},
  {name: 'Charbroil', match: 'Charbroil'},
  {name: 'Deep-fry', match: 'Deep-fry'},
  {name: 'Deep-fry', match: 'Deep fry'},
  {name: 'Dry Brining', match: 'Dry Brining'},
  {name: 'Gratin', match: 'Gratin'},
  {name: 'Griddle', match: 'Griddle'},
  {name: 'Grill', match: 'Grill'},
  {name: 'Instant Pot', match: 'Instant Pot'},
  {name: 'Microwave', match: 'Microwave'},
  {name: 'Pan-fry', match: 'Pan-fry'},
  {name: 'Pan-fry', match: 'Pan fry'},
  {name: 'Pickle', match: 'Pickle'},
  {name: 'Poach', match: 'Poach'},
  {name: 'Pressure Cook', match: 'Pressure cook'},
  {name: 'Roast', match: 'Roast'},
  {name: 'Sauté', match: 'Sauté'},
  {name: 'Sheet Pan', match: 'Sheet Pan'},
  {name: 'Skillet', match: 'Skillet'},
  {name: 'Smoke', match: 'Smoke'},
  {name: 'Slow Cooker', match: 'Slow Cooker'},
  {name: 'Slow Cooker', match: 'Crockpot'},
  {name: 'Sous Vide', match: 'Sous Vide'},
  {name: 'Stew', match: 'Stew'},
  {name: 'Steam', match: 'Steam'},
  {name: 'Stir-fry', match: 'Stir-fry'},
  {name: 'Stir-fry', match: 'Stir fry'},
  {name: 'Fry', match: 'Fry'},
  {name: 'Fry', match: 'Fried'},
]

export const Method = [
  {name: 'Air Fry', val: false},
  {name: 'Bake', val: false},
  {name: 'Barbecue', val: false},
  {name: 'Blanche', val: false},
  {name: 'Boil', val: false},
  {name: 'Broil', val: false},
  {name: 'Braise', val: false},
  {name: 'Dry Brining', val: false},
  {name: 'Caramelize', val: false},
  {name: 'Charbroil', val: false},
  {name: 'Deep-fry', val: false},
  {name: 'Gratin', val: false},
  {name: 'Griddle', val: false},
  {name: 'Grill', val: false},
  {name: 'Instant Pot', val: false},
  {name: 'Microwave', val: false},
  {name: 'Pan-fry', val: false},
  {name: 'Pickle', val: false},
  {name: 'Poach', val: false},
  {name: 'Pressure cook', val: false},
  {name: 'Roast', val: false},
  {name: 'Sauté', val: false},
  {name: 'Sheet Pan', val: false},
  {name: 'Skillet', val: false},
  {name: 'Smoke', val: false},
  {name: 'Slow Cooker', val: false},
  {name: 'Sous Vide', val: false},
  {name: 'Stew', val: false},
  {name: 'Steam', val: false},
  {name: 'Stir-fry', val: false},
  {name: 'Fry', val: false},
]

export const Ingredient = [	{name: 'Poultry', val: false},
  {name: 'Chicken', val: false},
  {name: 'Turkey', val: false},
  {name: 'Duck', val: false},
  {name: 'Goose', val: false},
  {name: 'Quail', val: false},
  {name: 'Pheasant', val: false},
  {name: 'Cornish Hen', val: false},
  {name: 'Guinea Fowl', val: false},
  {name: 'Seafood', val: false},
  {name: 'Fish', val: false},
  {name: 'Shrimp', val: false},
  {name: 'Crab', val: false},
  {name: 'Lobster', val: false},
  {name: 'Scallop', val: false},
  {name: 'Clam', val: false},
  {name: 'Mussel', val: false},
  {name: 'Oyster', val: false},
  {name: 'Squid', val: false},
  {name: 'Octopus', val: false},
  {name: 'Mackerel', val: false},
  {name: 'Tuna', val: false},
  {name: 'Salmon', val: false},
  {name: 'Cod', val: false},
  {name: 'Sardine', val: false},
  {name: 'Trout', val: false},
  {name: 'Haddock', val: false},
  {name: 'Herring', val: false},
  {name: 'Mahi-Mahi', val: false},
  {name: 'Snapper', val: false},
  {name: 'Swordfish', val: false},
  {name: 'Grouper', val: false},
  {name: 'Tilapia', val: false},
  {name: 'Catfish', val: false},
  {name: 'Anchovy', val: false},
  {name: 'Beef', val: false},
  {name: 'Hamburger', val: false},
  {name: 'Ribeye', val: false},
  {name: 'Tenderloin', val: false},
  {name: 'Striploin', val: false},
  {name: 'T-Bone', val: false},
  {name: 'Porterhouse', val: false},
  {name: 'Sirloin', val: false},
  {name: 'Chuck', val: false},
  {name: 'Brisket', val: false},
  {name: 'Short Rib', val: false},
  {name: 'Flank Steak', val: false},
  {name: 'Skirt Steak', val: false},
  {name: 'Filet Mignon', val: false},
  {name: 'Flat Iron', val: false},
  {name: 'Tri-Tip', val: false},
  {name: 'Top Round', val: false},
  {name: 'Bottom Round', val: false},
  {name: 'Eye of Round', val: false},
  {name: 'Rump Steak', val: false},
  {name: 'Ground Beef', val: false},
  {name: 'Minced Beef', val: false},
  {name: 'Prime Rib', val: false},
  {name: 'Hanger Steak', val: false},
  {name: 'Rib Roast', val: false},
  {name: 'Rump Roast', val: false},
  {name: 'Ribeye Cap', val: false},
  {name: 'Tomahawk Steak', val: false},
  {name: 'Steak', val: false},
  {name: 'Beef Shank', val: false},
  {name: 'Pork', val: false},
  {name: 'Pancetta', val: false},
  {name: 'Guanciale', val: false},
  {name: 'Prosciutto', val: false},
  {name: 'Ham', val: false},
  {name: 'Bacon', val: false},
  {name: 'Sausage', val: false},
  {name: 'Lamb', val: false},
  {name: 'Liver', val: false},
  {name: 'Mutton', val: false},
  {name: 'Goat', val: false},
  {name: 'Veal', val: false},
  {name: 'Venison', val: false},
  {name: 'Bison', val: false},
  {name: 'Rabbit', val: false},
  {name: 'Spaghetti', val: false},
  {name: 'Pasta', val: false},
  {name: 'Tofu', val: false},
  {name: 'Penne', val: false},
  {name: 'Fusilli', val: false},
  {name: 'Macaroni', val: false},
  {name: 'Noodle', val: false},
  {name: 'Linguine', val: false},
  {name: 'Farfalle', val: false},
  {name: 'Rigatoni', val: false},
  {name: 'Fettuccine', val: false},
  {name: 'Lasagna', val: false},
  {name: 'Ravioli', val: false},
  {name: 'Tortellini', val: false},
  {name: 'Orzo', val: false},
  {name: 'Vermicelli', val: false},
  {name: 'Angel hair', val: false},
  {name: 'Tagliatelle', val: false},
  {name: 'Cannelloni', val: false},
  {name: 'Rotini', val: false},
  {name: 'Ziti', val: false},
  {name: 'Pappardelle', val: false},
  {name: 'Capellini', val: false},
  {name: 'Grain', val: false},
  {name: 'Rice', val: false},
  {name: 'Wheat', val: false},
  {name: 'Bulgur', val: false},
  {name: 'Couscous', val: false},
  {name: 'Semolina', val: false},
  {name: 'Barley', val: false},
  {name: 'Oats', val: false},
  {name: 'Oatmeal', val: false},
  {name: 'Corn', val: false},
  {name: 'Polenta', val: false},
  {name: 'Quinoa', val: false},
  {name: 'Millet', val: false},
  {name: 'Buckwheat', val: false},
  {name: 'Rye', val: false},
  {name: 'Amaranth', val: false},
  {name: 'Farro', val: false},
  {name: 'Sorghum', val: false},
  {name: 'Teff', val: false},
  {name: 'Wild rice', val: false},
  {name: 'Spelt', val: false},
  {name: 'Kamut', val: false},
  {name: 'Freekeh', val: false},
  {name: 'Fruit', val: false},
  {name: 'Apple', val: false},
  {name: 'Banana', val: false},
  {name: 'Orange', val: false},
  {name: 'Grape', val: false},
  {name: 'Strawberry', val: false},
  {name: 'Watermelon', val: false},
  {name: 'Pineapple', val: false},
  {name: 'Pumpkin', val: false},
  {name: 'Mango', val: false},
  {name: 'Avocado', val: false},
  {name: 'Blueberry', val: false},
  {name: 'Raspberry', val: false},
  {name: 'Blackberry', val: false},
  {name: 'Kiwi', val: false},
  {name: 'Peach', val: false},
  {name: 'Fig', val: false},
  {name: 'Nectarine', val: false},
  {name: 'Pear', val: false},
  {name: 'Cherry', val: false},
  {name: 'Lemon', val: false},
  {name: 'Lime', val: false},
  {name: 'Grapefruit', val: false},
  {name: 'Papaya', val: false},
  {name: 'Plum', val: false},
  {name: 'Coconut', val: false},
  {name: 'Apricot', val: false},
  {name: 'Cranberry', val: false},
  {name: 'Lychee', val: false},
  {name: 'Pomegranate', val: false},
  {name: 'Cantaloupe', val: false},
  {name: 'Carrot', val: false},
  {name: 'Onion', val: false},
  {name: 'Tomato', val: false},
  {name: 'Potato', val: false},
  {name: 'Chipotle', val: false},
  {name: 'Bell Pepper', val: false},
  {name: 'Basil', val: false},
  {name: 'Broccoli', val: false},
  {name: 'Spinach', val: false},
  {name: 'Cauliflower', val: false},
  {name: 'Chickpeas', val: false},
  {name: 'Cucumber', val: false},
  {name: 'Eggplant', val: false},
  {name: 'Squash', val: false},
  {name: 'Zucchini', val: false},
  {name: 'Beans', val: false},
  {name: 'Legumes', val: false},
  {name: 'Cabbage', val: false},
  {name: 'Brussels Sprouts', val: false},
  {name: 'Kale', val: false},
  {name: 'Asparagus', val: false},
  {name: 'Sweet potato', val: false},
  {name: 'Mushroom', val: false},
  {name: 'Celery', val: false},
  {name: 'Radish', val: false},
  {name: 'Corn', val: false},
  {name: 'Lettuce', val: false},
  {name: 'Herbs', val: false},
  {name: 'Garlic', val: false},
  {name: 'Beetroot', val: false},
  {name: 'Vegetables', val: false},
  {name: 'Feta', val: false},
  {name: 'Cheese', val: false},
  {name: 'Goat Cheese', val: false},
  {name: 'Cottage Cheese', val: false},
  {name: 'Yogurt', val: false},
  {name: 'Egg', val: false},
  {name: 'Chocolate Chip', val: false},
  {name: 'Dates', val: false},
  {name: 'Nuts', val: false},
  {name: 'Seasoning', val: false},
  {name: 'Pretzels', val: false},
]

export const PlacesStayMatch = [
  {name: 'Accommodations', match: 'Accommodation'},
  {name: 'Accommodations', match: 'Places To Stay'},
  {name: 'Accommodations', match: 'Where To Stay'},
  {name: 'Accommodations', match: 'Hotel'},
  {name: 'Accommodations', match: 'Motel'},
  {name: 'Accommodations', match: 'Tree House'},
  {name: 'Accommodations', match: ' Inn '},
  {name: 'Accommodations', match: 'Caravansary'},
  {name: 'Accommodations', match: 'Lodging'},
  {name: 'Accommodations', match: 'Lodges'},
  {name: 'Accommodations', match: 'Hostel'},
  {name: 'Accommodations', match: 'Stayed In'},
  {name: 'Accommodations', match: 'Slept In'},
  {name: 'Airbnb', match: 'Airbnb'},
  {name: 'Hostel', match: 'Hostel'},
  {name: 'Lodge', match: 'Lodge'},
  {name: 'Resort', match: 'Resort'},
  {name: 'Retreat', match: 'Retreat'},
  {name: 'Campground', match: 'Campground'},
  {name: 'RV Park', match: 'RV Park'},
  {name: 'RV Park', match: 'Caravan'},
]

export const PlacesStay = [
  {name: 'Accommodations', val: false},
  {name: 'Airbnb', val: false},
  {name: 'Campground', val: false},
  {name: 'Hostel', val: false},
  {name: 'Lodge', val: false},
  {name: 'Resort', val: false},
  {name: 'Retreat', val: false},
  {name: 'RV Park', val: false},
]


export const TravelTipsMatch = [
  {name: 'Air Travel', match: 'Air '},
  {name: 'Air Travel', match: 'Flying To'},
  {name: 'Air Travel', match: 'Airplane'},
  {name: 'Air Travel', match: 'Airport'},
  {name: 'Air Travel', match: 'Flights'},
  {name: 'Air Travel', match: 'Flight'},
  {name: 'Air Travel', match: 'Airline'},
  {name: 'Air Travel', match: ' TSA'},
  {name: 'Apps', match: 'Apps'},
  {name: 'Budget', match: 'Budget'},
  {name: 'Budget', match: 'Cheap'},
  {name: 'Budget', match: 'Low Cost'},
  {name: 'Budget', match: 'Free'},
  {name: 'Budget', match: 'Hostels'},
  {name: 'Contest', match: 'Contest'},
  {name: 'Deals', match: 'Deal'},
  {name: 'Deals', match: 'Upgrade'},
  {name: 'Gear', match: 'Gear'},
  {name: 'Gear', match: 'Equipment'},
  {name: 'Gear', match: 'Gifts'},
  {name: 'Gear', match: 'Gadgets'},
  {name: 'Health and Safety', match: 'Health'},
  {name: 'Health and Safety', match: 'Safety'},
  {name: 'Health and Safety', match: 'Scam'},
  {name: 'Money Matters', match: 'Money'},
  {name: 'Official', match: 'Official'},
  {name: 'Packing Lists', match: 'Packing Lists'},
  {name: 'Packing List', match: 'Packing List'},
  {name: 'Planning an Event', match: 'Planning an Event'},
  {name: 'Rental Car', match: 'Rental Car'},
  {name: 'Rental Car', match: 'Renting A Car'},
  {name: 'Rental Car', match: 'Rent A Car'},
  {name: 'Rental Car', match: 'Car Rental'},
  {name: 'Reward Points', match: 'Points'},
  {name: 'Reward Points', match: 'Mileage'},
  {name: 'Reward Points', match: 'Credit Card'},
  {name: 'Reward Points', match: 'Business Card'},
  {name: 'Sustainability', match: 'Sustainability'},
  {name: 'Sustainability', match: 'Sustainably'},
  {name: 'Sustainability', match: 'Sustainable'},
  {name: 'Travel tips', match: 'Travel tip'},
  {name: 'Travel tips', match: 'Best Tips'},
  {name: 'Travel tips', match: 'Hack'},
  {name: 'Travel tips', match: 'Pro Tips'},
  {name: 'Travel tips', match: 'The Don\'ts'}
]

export const TravelTips = [
  {name: 'Air Travel', val: false},
  {name: 'Apps', val: false},
  {name: 'Beauty and Fashion', val: false},
  {name: 'Budget', val: false},
  {name: 'Contest', val: false},
  {name: 'Deals', val: false},
  {name: 'Etiquette', val: false},
  {name: 'Gear', val: false},
  {name: 'Health and Safety', val: false},
  {name: 'How To', val: false},
  {name: 'Money Matters', val: false},
  {name: 'Official', val: false},
  {name: 'Packing Lists', val: false},
  {name: 'Planning an Event', val: false},
  {name: 'Rental Car', val: false},
  {name: 'Reward Points', val: false},
  {name: 'Sustainability', val: false},
  {name: 'Transportation', val: false},
  {name: 'Travel tips', val: false},
  ]

export const NogoSeasonMatch = [
  {name: 'Fall', match: 'Fall', nogo: 'Fall In Love'},
  {name: 'Fall', match: 'Fall', nogo: 'Fall Out'}
]

export const SeasonMatch = [
  {name: 'Best Time', match: 'Best Time'},
  {name: 'Off Season', match: 'Off Season'},
  {name: 'Off Season', match: 'Quiet Season'},
  {name: 'Fall', match: ' Fall,'},
  {name: 'Fall', match: ' Fall '},
  {name: 'Fall', match: 'Autumn'},
  {name: 'Fall', match: 'October'},
  {name: 'Holidays', match: 'Holiday'},
  {name: 'Labor Day', match: 'Labor Day'},
  {name: 'Valentine\'s Day', match: 'Valentine\'s Day'},
  {name: 'Halloween', match: 'Halloween'},
  {name: 'Independence Day', match: 'Independence Day'},
  {name: 'Mother\'s Day', match: 'Mother\'s Day'},
  {name: 'Christmas', match: 'Christmas'},
  {name: 'Easter', match: 'Easter'},
  {name: 'Spring', match: ' Spring'},
  {name: 'Summer', match: 'Summer'},
  {name: 'Winter', match: 'Winter'},
]

export const Season = [
  {name: 'Best Time', val: false},
  {name: 'Off Season', val: false},
  {name: 'Fall', val: false},
  {name: 'Holidays', val: false},
  {name: 'Spring', val: false},
  {name: 'Summer', val: false},
  {name: 'Winter', val: false},
  {name: 'Thanksgiving', val: false},
  {name: 'Independence Day', val: false},
  {name: 'Valentine\'s Day', val: false},
  {name: 'Halloween', val: false},
  {name: 'Mother\'s Day', val: false},
  {name: 'Christmas', val: false},
  {name: 'Labor Day', val: false},
  {name: 'Easter', val: false},
]

export const NogoTravelOtherMatch = [
  {name: 'Book', match: 'Book', nogo: 'Book It'},
  {name: 'Book', match: 'Book', nogo: 'To Book'},
  {name: 'Book', match: 'Book', nogo: 'Book The'},
  ]

export const TravelOtherMatch = [
  {name: 'Awards', match: 'Award'},
  {name: 'Book', match: 'Book'},
  {name: 'Book', match: 'Author'},
  {name: 'Crafts', match: 'Craft'},
  {name: 'Ghoulish', match: 'Ghost'},
  {name: 'Ghoulish', match: 'Haunted'},
  {name: 'Indigenous People', match: 'Indigenous People'},
  {name: 'Inspiration', match: 'Inspiration'},
  {name: 'Personalities', match: 'Interview'},
  {name: 'Photography', match: 'Photograph'},
  {name: 'Off The Beaten Track', match: 'Beaten Track'},
  {name: 'Off The Beaten Track', match: 'Beaten Path'},
  {name: 'Off The Beaten Track', match: 'Under-the-radar'},
  {name: 'Off The Beaten Track', match: 'Overlooked'},
  {name: 'Off The Beaten Track', match: 'Hidden Gem'},
  {name: 'Off The Beaten Track', match: 'Off-the-radar'},
  {name: 'Romance', match: 'Romance'},
  {name: 'Romance', match: 'Romantic'},
  {name: 'Romance', match: 'Honeymoon'},
  {name: 'Slow Travel', match: 'Slow Travel'},
  {name: 'Slow Travel', match: 'Slow Travelers'},
  {name: 'Slow Travel', match: 'Slow Traveling'},
  {name: 'Travel Trends', match: 'Trends'},
  {name: 'Travel Trends', match: 'Next Big Thing'},
  {name: 'UNESCO', match: 'UNESCO'},
]


//Marriott Bonvoy
//Chase Saphire
// Citi Premier
//Delta Skymiles - Delta Skymiles Gold Amex Card; Delta Skymiles Reserve Business Card
//Capital One Venture X Rewards Credit Card
//Capital One Savorone Credit Card
// Allegiant World Mastercard

export const TravelOther = [
  {name: 'Adrenaline', val: false},
  {name: 'Awards', val: false},
  {name: 'Book', val: false},
  {name: 'Capp Pick', val: false},
  {name: 'Crafts', val: false},
  {name: 'Dark Tourism', val: false},
  {name: 'Death', val: false},
  {name: 'Discovery', val: false},
  {name: 'FAQ', val: false},
  {name: 'Fashion', val: false},
  {name: 'Game', val: false},
  {name: 'Ghoulish', val: false},
  {name: 'Good to Know', val: false},
  {name: 'History of Travel', val: false},
  {name: 'Included In', val: false},
  {name: 'Indigenous People', val: false},
  {name: 'Inspiration', val: false},
  {name: 'Ideas', val: false},
  {name: 'Local culture', val: false},
  {name: 'Local specialty', val: false},
  {name: 'Off The Beaten Track', val: false},
  {name: 'Other', val: false},
  {name: 'Our Planet', val: false},
  {name: 'Paid', val: false},
  {name: 'Personalities', val: false},
  {name: 'Photography', val: false},
  {name: 'Romance', val: false},
  {name: 'Routes', val: false},
  {name: 'Slow Travel', val: false},
  {name: 'Sponsored', val: false},
  {name: 'Travel Trends', val: false},
  {name: 'UNESCO', val: false},
  {name: 'Unique', val: false},
]

export const WhoForMatch = [
  {name: 'Accessible Travel', match: 'Accessible'},
  {name: 'Adult Only', match: 'Adult Only'},
  {name: 'Adult Only', match: 'Nude'},
  {name: 'Adult Only', match: 'Naked'},
  {name: 'Business', match: 'Business Class'},
  {name: 'Couples', match: 'Couples'},
  {name: 'Couples', match: 'Honeymoon'},
  {name: 'Couples', match: 'Romantic'},
  {name: 'Expat', match: 'Expat'},
  {name: 'Expat', match: 'Moving To'},
  {name: 'Expat', match: 'Living In'},
  {name: 'Family', match: 'Family'},
  {name: 'Family', match: 'Families'},
  {name: 'Family', match: 'Children'},
  {name: 'Family', match: 'Toddler'},
  {name: 'Family', match: 'Kids'},
  {name: 'Family', match: 'Teens'},
  {name: 'Family', match: 'Kid-friendly'},
  {name: 'Family', match: 'For All Ages'},
  {name: 'Female', match: 'Female'},
  {name: 'Female', match: 'For Women'},
  {name: 'LGBTQ+', match: 'LGBTQ+'},
  {name: 'LGBTQ+', match: 'LGBTQ'},
  {name: 'LGBTQ+', match: ' Gay '},
  {name: 'LGBTQ+', match: 'Queer'},
  {name: 'Over 50', match: 'Over 50'},
  {name: 'Over 50', match: '55+'},
  {name: 'Over 50', match: 'Seniors'},
  {name: 'Over 50', match: 'Retiring'},
  {name: 'Pets', match: 'Pets'},
  {name: 'Pets', match: 'Dogs'},
  {name: 'Pregnant', match: 'Pregnant'},
  {name: 'Remote Working', match: 'Remote Work'},
  {name: 'Solo', match: 'Solo'},
  {name: 'Solo', match: 'Alone'},
  {name: 'Vehicle Life', match: 'Vehicle Life'},
  {name: 'Vehicle Life', match: ' Van '},
  {name: 'Vehicle Life', match: ' ATV '},
  {name: 'Vehicle Life', match: ' RV '},
  {name: 'Vehicle Life', match: 'RVers'},
  {name: 'Vehicle Life', match: 'RVing'},
  {name: 'Vehicle Life', match: 'Airstream'},
  {name: 'Vehicle Life', match: 'Campervan'},
  {name: 'Working Abroad', match: 'Working Abroad'},
  {name: 'Anxiety Traveler', match: 'Anxiety Traveler'},
  {name: 'Group', match: 'Group Travel'},
  {name: 'Millennial', match: 'Millennial'},
  {name: 'Minority', match: 'African American'},
  {name: 'Plus-Size', match: 'Plus-Size'},
  {name: 'Single Parent', match: 'Single Parent'},
  {name: 'Sports Fans', match: 'Sports Fan'},
  {name: 'Volunteering', match: 'Volunteering'},
]

export const WhoFor = [
  {name: 'Accessible Travel', val: false},
  {name: 'Adult Only', val: false},
  {name: 'Beginner', val: false},
  {name: 'Business', val: false},
  {name: 'Couples', val: false},
  {name: 'Expat', val: false},
  {name: 'Family', val: false},
  {name: 'Female', val: false},
  {name: 'Illness', val: false},
  {name: 'LGBTQ+', val: false},
  {name: 'Over 50', val: false},
  {name: 'Pets', val: false},
  {name: 'Pregnant', val: false},
  {name: 'Remote Working', val: false},
  {name: 'Solo', val: false},
  {name: 'Vehicle Life', val: false},
  {name: 'Working Abroad', val: false},
  {name: 'Anxiety Traveler', val: false},
  {name: 'Group', val: false},
  {name: 'Millennial', val: false},
  {name: 'Minority', val: false},
  {name: 'Plus-Size', val: false},
  {name: 'Single Parent', val: false},
  {name: 'Sports Fans', val: false},
  {name: 'Volunteering', val: false},
]

export const AutoDelete = [
  'Airbnbs',
  'Recipe'
]

export const PlacesEatDrinkShopMatch = [
  {name: 'Food', match: 'Food'},
  {name: 'Food', match: 'Cooking'},
  {name: 'Market', match: 'Market'},
  {name: 'Rental', match: 'Rental'},
  {name: 'Shopping', match: 'Shopping'},
  {name: 'Shopping', match: 'Stores'},
  {name: 'Souvenir', match: 'Souvenir'},
  {name: 'Gluten-free', match: 'Gluten-free'},
  {name: 'Vegetarian or Vegan', match: 'Vegetarian'},
  {name: 'Vegetarian or Vegan', match: 'Vegan'},
  {name: 'Wine and Spirits', match: 'Wine'},
  {name: 'Wine and Spirits', match: 'Champagne'},
  {name: 'Wine and Spirits', match: 'Beer'},
  {name: 'Wine and Spirits', match: 'Whiskey'},
  {name: 'Wine and Spirits', match: 'Scotch'},
  {name: 'Wine and Spirits', match: 'Winery'},
  {name: 'Wine and Spirits', match: 'Wineries'},
  {name: 'Wine and Spirits', match: 'Vineyard'},
  {name: 'Wine and Spirits', match: 'Vineyards'},
  {name: 'Wine and Spirits', match: 'Distillery'},
  {name: 'Wine and Spirits', match: 'Distilleries'},
  {name: 'Places to Eat or Drink', match: 'Places to Eat'},
  {name: 'Places to Eat or Drink', match: 'Restaurant'},
  {name: 'Places to Eat or Drink', match: 'Bar'},
  {name: 'Places to Eat or Drink', match: 'Cafeteria'},
  {name: 'Places to Eat or Drink', match: 'Coffee Shop'},
  {name: 'Places to Eat or Drink', match: 'Diner'},
  {name: 'Places to Eat or Drink', match: 'Saloon'},
  {name: 'Places to Eat or Drink', match: 'Canteen'},
  {name: 'Places to Eat or Drink', match: 'Grill'},
  {name: 'Places to Eat or Drink', match: 'Pizzeria'},
  {name: 'Places to Eat or Drink', match: 'Luncheonette'},
  {name: 'Places to Eat or Drink', match: ' Tea '},
]


export const PlacesEatDrinkShop = [
  {name: 'Food', val: false},
  {name: 'Market', val: false},
  {name: 'Gluten-free', val: false},
  {name: 'Rental', val: false},
  {name: 'Shopping', val: false},
  {name: 'Vegetarian or Vegan', val: false},
  {name: 'Wine and Spirits', val: false},
  {name: 'Places to Eat or Drink', val: false},
  {name: 'Street Food', val: false},
  {name: 'Souvenir', val: false},
]

// Day Trips
export const ThingsSeeDoMatch = [
  {name: 'Adventure', match: 'Adventure'},
  {name: 'Agritourism', match: 'Agritourism'},
  {name: 'Antiquity', match: 'Antiquity'},
  {name: 'Antiquity', match: 'Archaeologists'},
  {name: 'Architecture', match: 'Architecture'},
  {name: 'Hiking', match: 'Backpack'},
  {name: 'Beach', match: 'Beach'},
  {name: 'Biking', match: 'Biking'},
  {name: 'Biking', match: 'Cyclist'},
  {name: 'Birds', match: 'Birds'},
  {name: 'Camping', match: 'Camping'},
  {name: 'Climbing', match: 'Climbing'},
  {name: 'Climbing', match: 'Climbers'},
  {name: 'Cruises', match: 'Cruise'},
  {name: 'Cruises', match: 'Cruises'},
  {name: 'Cruises', match: 'Cruising'},
  {name: 'Cruises', match: 'Cunard'},
  {name: 'Cruises', match: 'Royal Caribbean'},
  {name: 'Cruises', match: 'Virgin Voyages'},
  {name: 'Cruises', match: 'Phoenix Reisen'},
  {name: 'Culture', match: 'Culture'},
  {name: 'Dark Sky', match: 'Dark Sky'},
  {name: 'Dark Sky', match: 'Night Sky'},
  {name: 'Disney', match: 'Disney'},
  {name: 'Disney', match: 'Epcot'},
  {name: 'Drones', match: 'Drone'},
  {name: 'Entertainment', match: 'Entertainment'},
  {name: 'Events', match: 'Events'},
  {name: 'Exhibits', match: 'Exhibit'},
  {name: 'Festivals', match: 'Festival'},
  {name: 'Festivals', match: 'Pageant'},
  {name: 'Film+ Locations', match: 'Movie'},
  {name: 'Foraging', match: 'Foraging'},
  {name: 'Gambling', match: 'Gambling'},
  {name: 'Garden', match: 'Garden'},
  {name: 'Garden', match: 'Jardin'},
  {name: 'Glamping', match: 'Glamping'},
  {name: 'Golf', match: 'Golf'},
  {name: 'Hiking', match: 'Hiking'},
  {name: 'Hiking', match: 'Hike'},
  {name: 'Hiking', match: 'Trek'},
  {name: 'Hiking', match: 'Trail'},
  {name: 'Hitchhiking', match: 'Hitchhiking'},
  {name: 'Horse Riding', match: 'Horse Riding'},
  {name: 'Hot Spring', match: 'Hot Spring'},
  {name: 'Hunting and Fishing', match: 'Hunting and Fishing'},
  {name: 'Hunting and Fishing', match: 'Hunting'},
  {name: 'Hunting and Fishing', match: 'Fishing'},
  {name: 'Itineraries', match: 'Itineraries'},
  {name: 'Itineraries', match: 'Itinerary'},
  {name: 'Literary Places', match: 'Literary Places'},
  {name: 'Medical Tourism', match: 'Medical Tourism'},
  {name: 'Military', match: 'Military'},
  {name: 'Monuments', match: 'Monument'},
  {name: 'Monuments', match: 'Statue'},
  {name: 'Monuments', match: 'Memorial'},
  {name: 'Museums', match: 'Museum'},
  {name: 'Museums', match: 'Museu'},
  {name: 'Museums', match: 'Museo'},
  {name: 'Museums', match: 'Gallery'},
  {name: 'National Parks', match: 'National Park'},
  {name: 'National Parks', match: 'Yosemite'},
  {name: 'Natural world', match: 'Natural world'},
  {name: 'Natural world', match: 'Waterfall'},
  {name: 'Natural world', match: 'Nature Lover'},
  {name: 'Nightlife', match: 'Nightlife'},
  {name: 'Ocean Adventure', match: 'Ocean Adventure'},
  {name: 'Off Road', match: 'Off Road'},
  {name: 'Off Road', match: 'ATV'},
  {name: 'On the Water', match: 'Canoeing'},
  {name: 'On the Water', match: 'Kayaking'},
  {name: 'On the Water', match: 'Rafting'},
  {name: 'On the Water', match: 'Jetskiing'},
  {name: 'On the Water', match: 'Tubing'},
  {name: 'Outdoors', match: 'Outdoor'},
  {name: 'Parks', match: 'State Park'},
  {name: 'Pilgrimage', match: 'Pilgrimage'},
  {name: 'Places to Visit', match: 'Places to Visit'},
  {name: 'Rainy Day', match: 'Rainy Day'},
  {name: 'Rainy Day', match: 'When It Rains'},
  {name: 'River adventure', match: 'Rafting'},
  {name: 'River Cruise', match: 'River Cruise'},
  {name: 'Road Trips', match: 'Road Trip'},
  {name: 'Road Trips', match: 'Drives'},
  {name: 'Safari', match: 'Safari'},
  {name: 'Sailing', match: 'Sailing'},
  {name: 'Scenic Spots', match: 'Views'},
  {name: 'Scenic Spots', match: 'Viewpoints'},
  {name: 'Scenic Spots', match: 'Sunset'},
  {name: 'Scenic Spots', match: 'Sunrise'},
  {name: 'Short Trips', match: 'Day Trip'},
  {name: 'Short Trips', match: '24 Hours'},
  {name: 'Scuba', match: 'Scuba'},
  {name: 'Scuba', match: 'Diving'},
  {name: 'Sightseeing', match: 'Sightseeing'},
  {name: 'Skiing', match: 'Skiing'},
  {name: 'Skiing', match: 'Ski'},
  {name: 'Swimming', match: 'Swimming'},
  {name: 'Spa', match: 'Spa'},
  {name: 'Spa', match: 'Spas'},
  {name: 'Spa', match: 'Bathhouses'},
  {name: 'Sports', match: 'Sports'},
  {name: 'Surfing', match: 'Surfing'},
  {name: 'Theme Parks', match: 'Theme Park'},
  {name: 'Theme Parks', match: 'Legoland'},
  {name: 'Theme Parks', match: 'Sesame Place'},
  {name: 'Theme Parks', match: 'Dollywood'},
  {name: 'Things to See and Do',  match: 'See and Do'},
  {name: 'Things to See and Do',  match: 'Things to Do'},
  {name: 'Things to See and Do',  match: 'Hidden Gems'},
  {name: 'Things to See and Do',  match: 'Amazing Experiences'},
  {name: 'Things to See and Do',  match: 'What To Do'},
  {name: 'Things to See and Do',  match: 'Incredible Experiences'},
  {name: 'Trains', match: 'Train'},
  {name: 'Trains', match: 'Rail '},
  {name: 'Trains', match: 'Railroad'},
  {name: 'Trains', match: 'Railway'},
  {name: 'Walking Tour', match: 'Walking Tour'},
  {name: 'Water Park', match: 'Water Park'},
  {name: 'Wildlife', match: 'Wildlife'},
  {name: 'Wildlife', match: 'Elephants'},
  {name: 'Wildlife', match: 'Lions'},
  {name: 'Wellness', match: 'Wellness'},
  {name: 'Wellness', match: 'Yoga'},
  {name: 'Tours', match: 'Tours'},
  {name: 'Winter activities', match: 'Winter activities'},
  {name: 'Classes', match: 'Classes'},
]


export const ThingsSeeDo = [
  {name: 'Adventure', val: false},
  {name: 'Agritourism', val: false},
  {name: 'Antiquity', val: false},
  {name: 'Architecture', val: false},
  {name: 'Beach', val: false},
  {name: 'Biking', val: false},
  {name: 'Birds', val: false},
  {name: 'Camping', val: false},
  {name: 'Classes', val: false},
  {name: 'Climbing', val: false},
  {name: 'Cruises', val: false},
  {name: 'Culture', val: false},
  {name: 'Dark Sky', val: false},
  {name: 'Disney', val: false},
  {name: 'Drones', val: false},
  {name: 'Entertainment', val: false},
  {name: 'Events', val: false},
  {name: 'Exhibits', val: false},
  {name: 'Festivals', val: false},
  {name: 'Film+ Locations', val: false},
  {name: 'Foraging', val: false},
  {name: 'Gambling', val: false},
  {name: 'Garden', val: false},
  {name: 'Glamping', val: false},
  {name: 'Golf', val: false},
  {name: 'Guide', val: false},
  {name: 'Hiking', val: false},
  {name: 'Hot Spring', val: false},
  {name: 'Hitchhiking', val: false},
  {name: 'Horse Riding', val: false},
  {name: 'Hunting and Fishing', val: false},
  {name: 'Itineraries', val: false},
  {name: 'Literary Places', val: false},
  {name: 'Medical Tourism', val: false},
  {name: 'Military', val: false},
  {name: 'Monuments', val: false},
  {name: 'Museums', val: false},
  {name: 'National Parks', val: false},
  {name: 'Natural world', val: false},
  {name: 'Nightlife', val: false},
  {name: 'Ocean Adventure', val: false},
  {name: 'Off Road', val: false},
  {name: 'On the Water', val: false},
  {name: 'Outdoors', val: false},
  {name: 'Parks', val: false},
  {name: 'Pilgrimage', val: false},
  {name: 'Places to Visit', val: false},
  {name: 'Rainy Day', val: false},
  {name: 'River Adventure', val: false},
  {name: 'River Cruises', val: false},
  {name: 'Road Trips', val: false},
  {name: 'Safari', val: false},
  {name: 'Sailing', val: false},
  {name: 'Scenic Spots', val: false},
  {name: 'Scuba', val: false},
  {name: 'Short Trips', val: false},
  {name: 'Sightseeing', val: false},
  {name: 'Skiing', val: false},
  {name: 'Spa', val: false},
  {name: 'Sports', val: false},
  {name: 'Surfing', val: false},
  {name: 'Swimming', val: false},
  {name: 'Theme Parks', val: false},
  {name: 'Things to See and Do', val: false},
  {name: 'Tours', val: false},
  {name: 'Trains', val: false},
  {name: 'Walking Tour', val: false},
  {name: 'Water Park', val: false},
  {name: 'Wellness', val: false},
  {name: 'Wildlife', val: false},
  {name: 'Winter activities', val: false},
]

// 'Drone Tour'
// 'Walking Tour',
// 'Site Tour





export const ContentTypes = [
  'BreadCrumbs',
  'TimeLine',
  'Gallery',
  'Map',
  'Article'
]

export const MapIcons = [
  'castle.svg',
  'guide.svg',
  'museum.svg',
  'monument.svg',
  'webcam.svg',
];


/*
export const ViatorDest = [
  {vcountry: 10, country: 'Namibia', ccode: 'NA'},
  {vcountry:4471, country: 'Albania', ccode: 'AL'},
  {vcountry:21801, country: 'Algeria', ccode: 'DZ'},
  {vcountry:810, country: 'Anguilla', ccode: 'AI'},
  {vcountry:5202, country: 'Antarctica', ccode: 'AQ'},
  {vcountry:27, country: 'Antigua and Barbuda', ccode: 'AG'},
  {vcountry:78, country: 'Argentina', ccode: 'AR'},
  {vcountry:21802, country: 'Armenia', ccode: 'AM'},
  {vcountry:28, country: 'Aruba', ccode: 'AW'},
  {vcountry:22, country: 'Australia', ccode: 'AU'},
  {vcountry:44, country: 'Austria', ccode: 'AT'},
  {vcountry:22315, country: 'Azerbaijan', ccode: 'AZ'},
  {vcountry:29, country: 'Bahamas', ccode: 'BS'},
  {vcountry:4458, country: 'Bahrain', ccode: 'BH'},
  {vcountry:21768, country: 'Bangladesh', ccode: 'BD'},
  {vcountry:30, country: 'Barbados', ccode: 'BB'},
  {vcountry:21470, country: 'Belarus', ccode: 'BY'},
  {vcountry:45, country: 'Belgium', ccode: 'BE'},
  {vcountry:746, country: 'Belize', ccode: 'BZ'},
  {vcountry:966, country: 'Bermuda', ccode: 'BM'},
  {vcountry:5026, country: 'Bolivia', ccode: 'BO'},
  {vcountry:4308, country: 'Bonaire', ccode: 'BQ'},
  {vcountry:22158, country: 'Bosnia and Herzegovina', ccode: 'BA'},
  {vcountry:79, country: 'Brazil', ccode: 'BR'},
  {vcountry:809, country: 'British Virgin Islands', ccode: 'VG'},
  {vcountry:4616, country: 'Brunei', ccode: 'BN'},
  {vcountry:46, country: 'Bulgaria', ccode: 'BG'},
  {vcountry:12, country: 'Cambodia', ccode: 'KH'},
  {vcountry:75, country: 'Canada', ccode: 'CA'},
  {vcountry:4461, country: 'Cape Verde', ccode: 'CV'},
  {vcountry:31, country: 'Cayman Islands', ccode: 'KY'},
  {vcountry:32314, country: 'Channel Islands', ccode: 'JE'},
  {vcountry:80, country: 'Chile', ccode: 'CL'},
  {vcountry:13, country: 'China', ccode: 'CN'},
  {vcountry:4497, country: 'Colombia', ccode: 'CO'},
  {vcountry:747, country: 'Costa Rica', ccode: 'CR'},
  {vcountry:730, country: 'Croatia', ccode: 'HR'},
  {vcountry:725, country: 'Curacao', ccode: 'CW'},
  {vcountry:47, country: 'Cyprus', ccode: 'CY'},
  {vcountry:48, country: 'Czech Republic', ccode: 'CZ'},
  {vcountry:49, country: 'Denmark', ccode: 'DK'},
  {vcountry:814, country: 'Dominica', ccode: 'DM'},
  {vcountry:32, country: 'Dominican Republic', ccode: 'DO'},
  {vcountry:727, country: 'Ecuador', ccode: 'EC'},
  {vcountry:722, country: 'Egypt', ccode: 'EG'},
  {vcountry:5069, country: 'El Salvador', ccode: 'SV'},
  {vcountry:731, country: 'England', ccode: 'GB'},
  {vcountry:4146, country: 'Estonia', ccode: 'EE'},
  {vcountry:21891, country: 'Ethiopia', ccode: 'ET'},
  {vcountry:23, country: 'Fiji', ccode: 'FJ'},
  {vcountry:50, country: 'Finland', ccode: 'FI'},
  {vcountry:51, country: 'France', ccode: 'FR'},
  {vcountry:5212, country: 'French Polynesia', ccode: 'PF'},
  {vcountry:21901, country: 'Georgia', ccode: 'GE'},
  {vcountry:52, country: 'Germany', ccode: 'DE'},
  {vcountry:4439, country: 'Ghana', ccode: 'GH'},
  {vcountry:50813, country: 'Gibraltar', ccode: 'GI'},
  {vcountry:53, country: 'Greece', ccode: 'GR'},
  {vcountry:4492, country: 'Greenland', ccode: 'GL'},
  {vcountry:967, country: 'Grenada', ccode: 'GD'},
  {vcountry:4311, country: 'Guadeloupe', ccode: 'GP'},
  {vcountry:4774, country: 'Guam', ccode: 'GU'},
  {vcountry:748, country: 'Guatemala', ccode: 'GT'},
  {vcountry:4324, country: 'Haiti', ccode: 'HT'},
  {vcountry:4131, country: 'Honduras', ccode: 'HN'},
  {vcountry:54, country: 'Hungary', ccode: 'HU'},
  {vcountry:55, country: 'Iceland', ccode: 'IS'},
  {vcountry:723, country: 'India', ccode: 'IN'},
  {vcountry:15, country: 'Indonesia', ccode: 'ID'},
  {vcountry:56, country: 'Ireland', ccode: 'IE'},
  {vcountry:919, country: 'Israel', ccode: 'IL'},
  {vcountry:57, country: 'Italy', ccode: 'IT'},
  {vcountry:34, country: 'Jamaica', ccode: 'JM'},
  {vcountry:16, country: 'Japan', ccode: 'JP'},
  {vcountry:744, country: 'Jordan', ccode: 'JO'},
  {vcountry:801, country: 'Kenya', ccode: 'KE'},
  {vcountry:26852, country: 'Kosovo', ccode: 'XK'},
  {vcountry:5438, country: 'Laos', ccode: 'LA'},
  {vcountry:4479, country: 'Latvia', ccode: 'LV'},
  {vcountry:58, country: 'Lithuania', ccode: 'LT'},
  {vcountry:21797, country: 'Luxembourg', ccode: 'LU'},
  {vcountry:21800, country: 'Macedonia', ccode: 'MK'},
  {vcountry:17, country: 'Malaysia', ccode: 'MY'},
  {vcountry:4141, country: 'Malta', ccode: 'MT'},
  {vcountry:4316, country: 'Martinique', ccode: 'MQ'},
  {vcountry:4463, country: 'Mauritius', ccode: 'MU'},
  {vcountry:76, country: 'Mexico', ccode: 'MX'},
  {vcountry:21900, country: 'Moldova', ccode: 'MD'},
  {vcountry:948, country: 'Monaco', ccode: 'MC'},
  {vcountry:21767, country: 'Mongolia', ccode: 'MN'},
  {vcountry:4475, country: 'Montenegro', ccode: 'ME'},
  {vcountry:825, country: 'Morocco', ccode: 'MA'},
  {vcountry:5411, country: 'Myanmar', ccode: 'MM'},
  {vcountry:724, country: 'Nepal', ccode: 'NP'},
  {vcountry:60, country: 'Netherlands', ccode: 'NL'},
  {vcountry:24, country: 'New Zealand', ccode: 'NZ'},
  {vcountry:4499, country: 'Nicaragua', ccode: 'NI'},
  {vcountry:22316, country: 'Nigeria', ccode: 'NG'},
  {vcountry:40330, country: 'Northern Ireland', ccode: 'GB'},
  {vcountry:61, country: 'Norway', ccode: 'NO'},
  {vcountry:745, country: 'Oman', ccode: 'OM'},
  {vcountry:22417, country: 'Palestinian Territories', ccode: 'PS'},
  {vcountry:749, country: 'Panama', ccode: 'PA'},
  {vcountry:927, country: 'Peru', ccode: 'PE'},
  {vcountry:4603, country: 'Philippines', ccode: 'PH'},
  {vcountry:62, country: 'Poland', ccode: 'PL'},
  {vcountry:63, country: 'Portugal', ccode: 'PT'},
  {vcountry:36, country: 'Puerto Rico', ccode: 'PR'},
  {vcountry:4452, country: 'Qatar', ccode: 'QA'},
  {vcountry:64, country: 'Romania', ccode: 'RO'},
  {vcountry:65, country: 'Russia', ccode: 'RU'},
  {vcountry:21908, country: 'Rwanda', ccode: 'RW'},
  {vcountry:4770, country: 'Samo', ccode: 'AS'},
  {vcountry:732, country: 'Scotland', ccode: 'GB'},
  {vcountry:4448, country: 'Senegal', ccode: 'SN'},
  {vcountry:21912, country: 'Serbia', ccode: 'RS'},
  {vcountry:4868, country: 'Seychelles', ccode: 'SC'},
  {vcountry:18, country: 'Singapore', ccode: 'SG'},
  {vcountry:66, country: 'Slovakia', ccode: 'SK'},
  {vcountry:734, country: 'Slovenia', ccode: 'SI'},
  {vcountry:11, country: 'South Africa', ccode: 'ZA'},
  {vcountry:972, country: 'South Korea', ccode: 'KR'},
  {vcountry:67, country: 'Spain', ccode: 'ES'},
  {vcountry:19, country: 'Sri Lanka', ccode: 'LK'},
  {vcountry:37, country: 'St Kitts and Nevis', ccode: 'KN'},
  {vcountry:38, country: 'St Lucia', ccode: 'LC'},
  {vcountry:728, country: 'St Maarten', ccode: 'MF'},
  {vcountry:4321, country: 'St Vincent and the Grenadines', ccode: 'VC'},
  {vcountry:68, country: 'Sweden', ccode: 'SE'},
  {vcountry:69, country: 'Switzerland', ccode: 'CH'},
  {vcountry:778, country: 'Taiwan', ccode: 'TW'},
  {vcountry:5589, country: 'Tanzania', ccode: 'TZ'},
  {vcountry:20, country: 'Thailand', ccode: 'TH'},
  {vcountry:39, country: 'Trinidad & Tabago', ccode: 'TT'},
  {vcountry:4393, country: 'Tunisia', ccode: 'TN'},
  {vcountry:70, country: 'Turkey', ccode: 'TR'},
  {vcountry:963, country: 'Turks and Caicos', ccode: 'TC'},
  {vcountry:5575, country: 'Uganda', ccode: 'UG'},
  {vcountry:781, country: 'Ukraine', ccode: 'UA'},
  {vcountry:743, country: 'United Arab Emirates', ccode: 'AE'},
  {vcountry:4514, country: 'Uruguay', ccode: 'UY'},
  {vcountry:40, country: 'US Virgin Islands', ccode: 'US'},
  {vcountry:77, country: 'USA', ccode: 'US'},
  {vcountry:21936, country: 'Uzbekistan', ccode: 'UZ'},
  {vcountry:4772, country: 'Vanuatu', ccode: 'VU'},
  {vcountry:21, country: 'Vietnam', ccode: 'VN'},
  {vcountry:5157, country: 'Wales', ccode: 'GB'},
  {vcountry:779, country: 'Zambia', ccode: 'ZM'},
  {vcountry:5308, country: 'Zimbabwe', ccode: 'ZW'},
]
*/


/*
export const CountryCodes = [
  {country: 'Alabama', code: 'AL', extra: 'US'},
  {country: 'Alaska', code: 'AK', extra: 'US'},
  {country: 'Arizona', code: 'AZ', extra: 'US'},
  {country: 'Arkansas', code: 'AR', extra: 'US'},
  {country: 'California', code: 'CA', extra: 'US'},
  {country: 'Colorado', code: 'CO', extra: 'US'},
  {country: 'Connecticut', code: 'CT', extra: 'US'},
  {country: 'Delaware', code: 'DE', extra: 'US'},
  {country: 'District of Columbia', code: 'DC', extra: 'US'},
  {country: 'Florida', code: 'FL', extra: 'US'},
  {country: 'Georgia', code: 'GA', extra: 'US'},
  {country: 'Hawaii', code: 'HI', extra: 'US'},
  {country: 'Idaho', code: 'ID', extra: 'US'},
  {country: 'Illinois', code: 'IL', extra: 'US'},
  {country: 'Indiana', code: 'IN', extra: 'US'},
  {country: 'Iowa', code: 'IA', extra: 'US'},
  {country: 'Kansas', code: 'KS', extra: 'US'},
  {country: 'Kentucky', code: 'KY', extra: 'US'},
  {country: 'Louisiana', code: 'LA', extra: 'US'},
  {country: 'Maine', code: 'ME', extra: 'US'},
  {country: 'Maryland', code: 'MD', extra: 'US'},
  {country: 'Massachusetts', code: 'MA', extra: 'US'},
  {country: 'Michigan', code: 'MI', extra: 'US'},
  {country: 'Minnesota', code: 'MN', extra: 'US'},
  {country: 'Mississippi', code: 'MS', extra: 'US'},
  {country: 'Missouri', code: 'MO', extra: 'US'},
  {country: 'Montana', code: 'MT', extra: 'US'},
  {country: 'Nebraska', code: 'NE', extra: 'US'},
  {country: 'Nevada', code: 'NV', extra: 'US'},
  {country: 'New Hampshire', code: 'NH', extra: 'US'},
  {country: 'New Jersey', code: 'NJ', extra: 'US'},
  {country: 'New Mexico', code: 'NM', extra: 'US'},
  {country: 'New York', code: 'NY', extra: 'US'},
  {country: 'North Carolina', code: 'NC', extra: 'US'},
  {country: 'North Dakota', code: 'ND', extra: 'US'},
  {country: 'Ohio', code: 'OH', extra: 'US'},
  {country: 'Oklahoma', code: 'OK', extra: 'US'},
  {country: 'Oregon', code: 'OR', extra: 'US'},
  {country: 'Pennsylvania', code: 'PA', extra: 'US'},
  {country: 'Rhode Island', code: 'RI', extra: 'US'},
  {country: 'South Carolina', code: 'SC', extra: 'US'},
  {country: 'South Dakota', code: 'SD', extra: 'US'},
  {country: 'Tennessee', code: 'TN', extra: 'US'},
  {country: 'Texas', code: 'TX', extra: 'US'},
  {country: 'Utah', code: 'UT', extra: 'US'},
  {country: 'Vermont', code: 'VT', extra: 'US'},
  {country: 'Virginia', code: 'VA', extra: 'US'},
  {country: 'Washington', code: 'WA', extra: 'US'},
  {country: 'Washington, D.C.', code: 'DC', extra: 'US'},
  {country: 'Washington, DC', code: 'DC', extra: 'US'},
  {country: 'Wisconsin', code: 'WI', extra: 'US'},
  {country: 'Wyoming', code: 'WY', extra: 'US'},
  {country: 'Afghanistan', code: 'AF', extra: ''},
  {country: 'Aland Islands', code: 'AX', extra: ''},
  {country: 'Albania', code: 'AL', extra: ''},
  {country: 'Algeria', code: 'DZ', extra: ''},
  {country: 'American Samoa', code: 'AS', extra: ''},
  {country: 'Andorra', code: 'AD', extra: ''},
  {country: 'Angola', code: 'AO', extra: ''},
  {country: 'Anguilla', code: 'AI', extra: ''},
  {country: 'Antarctica', code: 'AQ', extra: ''},
  {country: 'Antigua and Barbuda', code: 'AG', extra: ''},
  {country: 'Argentina', code: 'AR', extra: ''},
  {country: 'Armenia', code: 'AM', extra: ''},
  {country: 'Aruba', code: 'AW', extra: ''},
  {country: 'Australia', code: 'AU', extra: ''},
  {country: 'Austria', code: 'AT', extra: ''},
  {country: 'Azerbaijan', code: 'AZ', extra: ''},
  {country: 'Bahamas', code: 'BS', extra: ''},
  {country: 'Bahrain', code: 'BH', extra: ''},
  {country: 'Bangladesh', code: 'BD', extra: ''},
  {country: 'Barbados', code: 'BB', extra: ''},
  {country: 'Belarus', code: 'BY', extra: ''},
  {country: 'Belgium', code: 'BE', extra: ''},
  {country: 'Belize', code: 'BZ', extra: ''},
  {country: 'Benin', code: 'BJ', extra: ''},
  {country: 'Bermuda', code: 'BM', extra: ''},
  {country: 'Bhutan', code: 'BT', extra: ''},
  {country: 'Bolivia', code: 'BO', extra: ''},
  {country: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', extra: ''},
  {country: 'Bosnia and Herzegovina', code: 'BA', extra: ''},
  {country: 'Botswana', code: 'BW', extra: ''},
  {country: 'Bouvet Island', code: 'BV', extra: ''},
  {country: 'Brazil', code: 'BR', extra: ''},
  {country: 'British Indian Ocean Territory', code: 'IO', extra: ''},
  {country: 'British Virgin Islands', code: 'VG', extra: ''},
  {country: 'Brunei', code: 'BN', extra: ''},
  {country: 'Bulgaria', code: 'BG', extra: ''},
  {country: 'Burkina Faso', code: 'BF', extra: ''},
  {country: 'Burundi', code: 'BI', extra: ''},
  {country: 'Cambodia', code: 'KH', extra: ''},
  {country: 'Cameroon', code: 'CM', extra: ''},
  {country: 'Canada', code: 'CA', extra: ''},
  {country: 'Cape Verde', code: 'CV', extra: ''},
  {country: 'Cayman Islands', code: 'KY', extra: ''},
  {country: 'Central African Republic', code: 'CF', extra: ''},
  {country: 'Chad', code: 'TD', extra: ''},
  {country: 'Chile', code: 'CL', extra: ''},
  {country: 'China', code: 'CN', extra: ''},
  {country: 'Christmas Island', code: 'CX', extra: ''},
  {country: 'Cocos Islands', code: 'CC', extra: ''},
  {country: 'Colombia', code: 'CO', extra: ''},
  {country: 'Comoros', code: 'KM', extra: ''},
  {country: 'Cook Islands', code: 'CK', extra: ''},
  {country: 'Costa Rica', code: 'CR', extra: ''},
  {country: 'Côte d\'Ivoire', code: 'CI', extra: ''},
  {country: 'Croatia', code: 'HR', extra: ''},
  {country: 'Cuba', code: 'CU', extra: ''},
  {country: 'Curaçao', code: 'CW', extra: ''},
  {country: 'Cyprus', code: 'CY', extra: ''},
  {country: 'Czech Republic', code: 'CZ', extra: ''},
  {country: 'Democratic Republic of the Congo', code: 'CD', extra: ''},
  {country: 'Denmark', code: 'DK', extra: ''},
  {country: 'Djibouti', code: 'DJ', extra: ''},
  {country: 'Dominica', code: 'DM', extra: ''},
  {country: 'Dominican Republic', code: 'DO', extra: ''},
  {country: 'Ecuador', code: 'EC', extra: ''},
  {country: 'Egypt', code: 'EG', extra: ''},
  {country: 'El Salvador', code: 'SV', extra: ''},
  {country: 'Equatorial Guinea', code: 'GQ', extra: ''},
  {country: 'Eritrea', code: 'ER', extra: ''},
  {country: 'Estonia', code: 'EE', extra: ''},
  {country: 'Eswatini', code: 'SZ', extra: ''},
  {country: 'Ethiopia', code: 'ET', extra: ''},
  {country: 'Falkland Islands', code: 'FK', extra: ''},
  {country: 'Faroe Islands', code: 'FO', extra: ''},
  {country: 'Fiji', code: 'FJ', extra: ''},
  {country: 'Finland', code: 'FI', extra: ''},
  {country: 'France', code: 'FR', extra: ''},
  {country: 'French Guiana', code: 'GF', extra: ''},
  {country: 'French Polynesia', code: 'PF', extra: ''},
  {country: 'French Southern Territories', code: 'TF', extra: ''},
  {country: 'Gabon', code: 'GA', extra: ''},
  {country: 'Gambia', code: 'GM', extra: ''},
  {country: 'Georgia', code: 'GE', extra: ''},
  {country: 'Germany', code: 'DE', extra: ''},
  {country: 'Ghana', code: 'GH', extra: ''},
  {country: 'Gibraltar', code: 'GI', extra: ''},
  {country: 'Greece', code: 'GR', extra: ''},
  {country: 'Greenland', code: 'GL', extra: ''},
  {country: 'Grenada', code: 'GD', extra: ''},
  {country: 'Guadeloupe', code: 'GP', extra: ''},
  {country: 'Guam', code: 'GU', extra: ''},
  {country: 'Guatemala', code: 'GT', extra: ''},
  {country: 'Guernsey', code: 'GG', extra: ''},
  {country: 'Guinea', code: 'GN', extra: ''},
  {country: 'Guinea-Bissau', code: 'GW', extra: ''},
  {country: 'Guyana', code: 'GY', extra: ''},
  {country: 'Haiti', code: 'HT', extra: ''},
  {country: 'Heard and Mcdonald Islands', code: 'HM', extra: ''},
  {country: 'Holy See', code: 'VA', extra: ''},
  {country: 'Honduras', code: 'HN', extra: ''},
  {country: 'Hong Kong', code: 'HK', extra: ''},
  {country: 'Hungary', code: 'HU', extra: ''},
  {country: 'Iceland', code: 'IS', extra: ''},
  {country: 'India', code: 'IN', extra: ''},
  {country: 'Indonesia', code: 'ID', extra: ''},
  {country: 'Iran', code: 'IR', extra: ''},
  {country: 'Iraq', code: 'IQ', extra: ''},
  {country: 'Ireland', code: 'IE', extra: ''},
  {country: 'Isle of Man', code: 'IM', extra: ''},
  {country: 'Israel', code: 'IL', extra: ''},
  {country: 'Italy', code: 'IT', extra: ''},
  {country: 'Jamaica', code: 'JM', extra: ''},
  {country: 'Japan', code: 'JP', extra: ''},
  {country: 'Jersey', code: 'JE', extra: ''},
  {country: 'Jordan', code: 'JO', extra: ''},
  {country: 'Kazakhstan', code: 'KZ', extra: ''},
  {country: 'Keeling Islands', code: 'CC', extra: ''},
  {country: 'Kenya', code: 'KE', extra: ''},
  {country: 'Kiribati', code: 'KI', extra: ''},
  {country: 'Kosovo', code: 'XK', extra: ''},
  {country: 'Kuwait', code: 'KW', extra: ''},
  {country: 'Kyrgyzstan', code: 'KG', extra: ''},
  {country: 'Laos', code: 'LA', extra: ''},
  {country: 'Latvia', code: 'LV', extra: ''},
  {country: 'Lebanon', code: 'LB', extra: ''},
  {country: 'Lesotho', code: 'LS', extra: ''},
  {country: 'Liberia', code: 'LR', extra: ''},
  {country: 'Libya', code: 'LY', extra: ''},
  {country: 'Liechtenstein', code: 'LI', extra: ''},
  {country: 'Lithuania', code: 'LT', extra: ''},
  {country: 'Luxembourg', code: 'LU', extra: ''},
  {country: 'Macau', code: 'MO', extra: ''},
  {country: 'Macedonia', code: 'MK', extra: ''},
  {country: 'Madagascar', code: 'MG', extra: ''},
  {country: 'Malawi', code: 'MW', extra: ''},
  {country: 'Malaysia', code: 'MY', extra: ''},
  {country: 'Maldives', code: 'MV', extra: ''},
  {country: 'Mali', code: 'ML', extra: ''},
  {country: 'Malta', code: 'MT', extra: ''},
  {country: 'Marshall Islands', code: 'MH', extra: ''},
  {country: 'Martinique', code: 'MQ', extra: ''},
  {country: 'Mauritania', code: 'MR', extra: ''},
  {country: 'Mauritius', code: 'MU', extra: ''},
  {country: 'Mayotte', code: 'YT', extra: ''},
  {country: 'Mexico', code: 'MX', extra: ''},
  {country: 'Micronesia', code: 'FM', extra: ''},
  {country: 'Moldova', code: 'MD', extra: ''},
  {country: 'Monaco', code: 'MC', extra: ''},
  {country: 'Mongolia', code: 'MN', extra: ''},
  {country: 'Montenegro', code: 'ME', extra: ''},
  {country: 'Montserrat', code: 'MS', extra: ''},
  {country: 'Morocco', code: 'MA', extra: ''},
  {country: 'Mozambique', code: 'MZ', extra: ''},
  {country: 'Myanmar', code: 'MM', extra: ''},
  {country: 'Namibia', code: 'NA', extra: ''},
  {country: 'Nauru', code: 'NR', extra: ''},
  {country: 'Nepal', code: 'NP', extra: ''},
  {country: 'Netherlands', code: 'NL', extra: ''},
  {country: 'Netherlands Antilles', code: 'AN', extra: ''},
  {country: 'New Caledonia', code: 'NC', extra: ''},
  {country: 'New Zealand', code: 'NZ', extra: ''},
  {country: 'Nicaragua', code: 'NI', extra: ''},
  {country: 'Niger', code: 'NE', extra: ''},
  {country: 'Nigeria', code: 'NG', extra: ''},
  {country: 'Niue', code: 'NU', extra: ''},
  {country: 'Norfolk Island', code: 'NF', extra: ''},
  {country: 'North Korea', code: 'KP', extra: ''},
  {country: 'Northern Ireland', code: 'UK', extra: ''},
  {country: 'Northern Mariana Islands', code: 'CM', extra: ''},
  {country: 'Northern Mariana Islands', code: 'MP', extra: ''},
  {country: 'Norway', code: 'NO', extra: ''},
  {country: 'Oman', code: 'OM', extra: ''},
  {country: 'Pakistan', code: 'PK', extra: ''},
  {country: 'Palau', code: 'PW', extra: ''},
  {country: 'Palestine', code: 'PS', extra: ''},
  {country: 'Panama', code: 'PA', extra: ''},
  {country: 'Papua New Guinea', code: 'PG', extra: ''},
  {country: 'Paraguay', code: 'PY', extra: ''},
  {country: 'Peru', code: 'PE', extra: ''},
  {country: 'Philippines', code: 'PH', extra: ''},
  {country: 'Pitcairn', code: 'PN', extra: ''},
  {country: 'Poland', code: 'PL', extra: ''},
  {country: 'Portugal', code: 'PT', extra: ''},
  {country: 'Puerto Rico', code: 'PR', extra: ''},
  {country: 'Qatar', code: 'QA', extra: ''},
  {country: 'Republic of the Congo', code: 'CG', extra: ''},
  {country: 'Réunion', code: 'RE', extra: ''},
  {country: 'Romania', code: 'RO', extra: ''},
  {country: 'Russia', code: 'RU', extra: ''},
  {country: 'Rwanda', code: 'RW', extra: ''},
  {country: 'Saint Helena', code: 'SH', extra: ''},
  {country: 'Saint Kitts and Nevis', code: 'KN', extra: ''},
  {country: 'Saint Lucia', code: 'LC', extra: ''},
  {country: 'Saint Pierre and Miquelon', code: 'PM', extra: ''},
  {country: 'Saint Vincent and Grenadines', code: 'VC', extra: ''},
  {country: 'Saint-Barthélemy', code: 'BL', extra: ''},
  {country: 'Saint-Martin', code: 'MF', extra: ''},
  {country: 'Samoa', code: 'WS', extra: ''},
  {country: 'San Marino', code: 'SM', extra: ''},
  {country: 'Sao Tome and Principe', code: 'ST', extra: ''},
  {country: 'Saudi Arabia', code: 'SA', extra: ''},
  {country: 'Scotland', code: 'UK', extra: ''},
  {country: 'Senegal', code: 'SN', extra: ''},
  {country: 'Serbia', code: 'RS', extra: ''},
  {country: 'Seychelles', code: 'SC', extra: ''},
  {country: 'Sierra Leone', code: 'SL', extra: ''},
  {country: 'Singapore', code: 'SG', extra: ''},
  {country: 'Sint Maarten', code: 'SX', extra: ''},
  {country: 'Slovakia', code: 'SK', extra: ''},
  {country: 'Slovenia', code: 'SI', extra: ''},
  {country: 'Solomon Islands', code: 'SB', extra: ''},
  {country: 'Somalia', code: 'SO', extra: ''},
  {country: 'South Africa', code: 'ZA', extra: ''},
  {country: 'South Georgia and the South Sandwich Islands', code: 'GS', extra: ''},
  {country: 'South Korea', code: 'KR', extra: ''},
  {country: 'South Sudan', code: 'SS', extra: ''},
  {country: 'Spain', code: 'ES', extra: ''},
  {country: 'Sri Lanka', code: 'LK', extra: ''},
  {country: 'Sudan', code: 'SD', extra: ''},
  {country: 'Suriname', code: 'SR', extra: ''},
  {country: 'Svalbard and Jan Mayen Islands', code: 'SJ', extra: ''},
  {country: 'Swaziland', code: 'SZ', extra: ''},
  {country: 'Sweden', code: 'SE', extra: ''},
  {country: 'Switzerland', code: 'CH', extra: ''},
  {country: 'Syria', code: 'SY', extra: ''},
  {country: 'Taiwan', code: 'TW', extra: ''},
  {country: 'Tajikistan', code: 'TJ', extra: ''},
  {country: 'Tanzania', code: 'TZ', extra: ''},
  {country: 'Thailand', code: 'TH', extra: ''},
  {country: 'Timor-Leste', code: 'TL', extra: ''},
  {country: 'Togo', code: 'TG', extra: ''},
  {country: 'Tokelau', code: 'TK', extra: ''},
  {country: 'Tonga', code: 'TO', extra: ''},
  {country: 'Trinidad and Tobago', code: 'TT', extra: ''},
  {country: 'Trust Territories', code: 'TT', extra: ''},
  {country: 'Tunisia', code: 'TN', extra: ''},
  {country: 'Turkey', code: 'TR', extra: ''},
  {country: 'Turkmenistan', code: 'TM', extra: ''},
  {country: 'Turks and Caicos Islands', code: 'TC', extra: ''},
  {country: 'Tuvalu', code: 'TV', extra: ''},
  {country: 'Uganda', code: 'UG', extra: ''},
  {country: 'Ukraine', code: 'UA', extra: ''},
  {country: 'United Arab Emirates', code: 'AE', extra: ''},
  {country: 'United Kingdom', code: 'UK', extra: ''},
  {country: 'United States', code: 'US', extra: ''},
  {country: 'United States Virgin Islands', code: 'VI', extra: ''},
  {country: 'Uruguay', code: 'UY', extra: ''},
  {country: 'US Minor Outlying Islands', code: 'UM', extra: ''},
  {country: 'Uzbekistan', code: 'UZ', extra: ''},
  {country: 'Vanuatu', code: 'VU', extra: ''},
  {country: 'Venezuela', code: 'VE', extra: ''},
  {country: 'Vietnam', code: 'VN', extra: ''},
  {country: 'Virgin Islands', code: 'VI', extra: ''},
  {country: 'Wales', code: 'UK', extra: ''},
  {country: 'Wallis and Futuna Islands', code: 'WF', extra: ''},
  {country: 'West Virginia', code: 'WV', extra: ''},
  {country: 'Western Sahara', code: 'EH', extra: ''},
  {country: 'Yemen', code: 'YE', extra: ''},
  {country: 'Zambia', code: 'ZM', extra: ''},
  {country: 'Zimbabwe', code: 'ZW', extra: ''}
]
*/

export const PlaceAttributes = [
  {icon: 'kids.svg', name: 'Good for kids'},
  {icon: 'heart.svg', name: 'Good for dates'},
  {icon: 'group.svg', name: 'Good for a group'},
  {icon: 'rain.svg', name: 'Good on a rainy day'},
  {icon: 'pets.svg', name: 'Pet friendly'},
  {icon: 'gift.svg', name: 'Free'},
  {icon: 'dollar.svg', name: 'Inexpensive'},
  {icon: 'daffodil.svg', name: 'Spring'},
  {icon: 'summer.svg', name: 'Summer'},
  {icon: 'winter.svg', name: 'Winter'},
  {icon: 'autumn.svg', name: 'Fall'},
  {icon: 'wheelchair.svg', name: 'Wheelchair accessible'},
  {icon: 'indoor.svg', name: 'Indoors'},
  {icon: 'reservation.svg', name: 'Book ahead'},
  {icon: 'outdoor.svg', name: 'Outdoor'},
  {icon: 'fooddrink.svg', name: 'Food & drink'},
  {icon: 'solo.svg', name: 'Solo travel'},
];



export const Transmodes = [
  {name: 'Airplane', mode: 'flight_takeoff', country: '', color: '#ef5350'},
  {name: 'Auto', mode: 'driving', country: '', color: '#ab47bc'},
  {name: 'Boat', mode: 'boat', country: '', color: '#ff7043'},
  {name: 'Bike', mode: 'bicycling', country: '', color: '#29b6f6'},
  {name: 'Bus', mode: 'bus', country: '', color: '#26a69a'},
  {name: 'Subway', mode: 'subway', country: '', color: '#9ccc65'},
  {name: 'Train', mode: 'train', country: '', color: '#5c6bc0'},
  {name: 'Tram', mode: 'tram', country: '', color: '#ffa726'},
  {name: 'walking', mode: 'walking', country: '', color: '#ec407a'},
  {name: 'other', mode: 'other', country: '', color: '#7e57c2'}];

export const Diseases = [
 'COVID-19',
  'Tuberculosis',
 'Measles',
 'HIV',
 'Ebola',
 'SARS-CoV',
 'Syphilis',
 'H1N1'
]

export const RepeatOptions = [
    'Daily',
    'Weekly',
    'Bi-weekly',
    'Same day every month',
    'Same date every month',
    'Once'
];

export const Filters = ['None', 'CappTeam', 'Principal', 'Role', 'Program Admin'];

export const Roles = [
    'Admin',
    'Principal',
    'Family',
    'Friend',
    'Caretaker',
    'Contact',
    'Colleague',
    'Advocate',
    'Doctor',
    'Lawyer',
    'Partner',
    'Other'
]


export const LeftReason = [
    'For cause',
    'Layoff',
    'Other internal change',
    'Promotion',
    'Quit',
    'Retirement',
]

export const GSPCategories = [
    'Activity',
    'Action',
    'Education Module',
    'Event',
]

export const Ages = ['Under 18', '18-29', '30+'];

export const Races = [
    'American Indian or Alaska Native',
    'Asian',
    'Black or African American',
    'Native Hawaiian or Other Pacific Islander',
    'White',
    'Not listed'];

export const Genders = [
    'Woman',
    'Man',
    'Transgender woman',
    'Transgender man',
    'Genderqueer or gender non-conforming',
    'Questioning',
    'Not listed',
    'Decline to state'];

export const Preferences = [
    'Heterosexual',
    'Gay or lesbian',
    'Bisexual',
    'Asexual',
    'Questioning',
    'Not listed',
    'Decline to state'];

export const Disabilities = [
    'Hearing impared',
    'Sight impared',
    'Physically impared',
    'Mentally impared',
    'Emotionally inmpared',
    'None',
    'Not listed'];

export const Experiences = [
    'Bullying',
    'Hazing',
    'Interpersonal Violence',
    'Sexual Misconduct',
    'Sexual Violence',
    'Stalking',
    'Other Violent Crime'
];

export const Focum = [
    'Campus Safety',
    'Campus Sexual Violence',
    'Child abuse',
    'Eating Disorders',
    'Elder abuse',
    'Food Insecurity',
    'Human Trafficking',
    'Immigration',
    'Law Enforcement',
    'LGBTQ+',
    'Mental Health',
    'Sexual and Interpersonal Violence',
    'Sexual and Relationship Health',
    'Substance Abuse',
    'Trauma',
    'Women\'s Health & Rights'
];

export const AdvJob = [
    'Activism/Advocacy',
    'Care',
    'Crisis intervention',
    'Legal',
    'Information/Resources',
    'Professional Training',
    'Primary Education'
];


export const GuideToc = [
    'None',
    'List',
    'Chicklet',
];

export const PolResourceCodes = [
    {desc: 'Alcohol and Drug Policy', value: false, view: '10710'},
    {desc: 'Amnesty Policy', value: false, view: '10010'},
    {desc: 'Anti-Retaliation Policy', value: false, view: '10060'},
    {desc: 'Brochure', value: false, view: '10390'},
    {desc: 'Code of Conduct', value: false, view: '10090'},
    {desc: 'Conduct Process Confidentiality', value: false, view: '10290'},
    {desc: 'Definition of Consent', value: false, view: '10720'},
    {desc: 'Human Rights Policy', value: false, view: '10730'},
    {desc: 'Interim Measures', value: false, view: '10270'},
    {desc: 'Leave of Absence', value: false, view: '10810'},
    {desc: 'Non Discrimination', value: false, view: '10245'},
    {desc: 'Other Policy', value: false, view: '10111'},
    {desc: 'Parental Notification Policy', value: false, view: '10110'},
    {desc: 'Pregnancy and Parenting Policy', value: false, view: '10780'},
    {desc: 'Resource Confidentiality', value: false, view: '10100'},
    {desc: 'Responsible Employee', value: false, view: '10140'},
    {desc: 'Sexual Discrimination Policy', value: false, view: '10770'},
    {desc: 'Sexual Harassment Policy', value: false, view: '10130'},
    {desc: 'Sexual Misconduct Policy', value: false, view: '10160'},
    {desc: 'Standard of Proof', value: false, view: '10190'},
    {desc: 'Student Conduct Process', value: false, view: '10240'},
    {desc: 'Student Handbook', value: false, view: '10200'},
    {desc: 'Student Rights', value: false, view: '10250'},
    {desc: 'Victim Rights', value: false, view: '10220'}
];

export const GuideElements = [
    'Text',
    'Expansion Box',
    'More Information',
    'Photo',
    'Question',
    'Resource',
    'Request for Contact',
    'Submit Button',
    'Video',
    'Side Card',
    'Top Card'
];

export const InvitationShapes = [
    'Square',
    'Rectangle',
    'Smartphone',
    'Thin'
];

export const SquareInvites = [
    {name: 'app-cares-right-basic', height: 300, width: 300},
    {name: 'app-cares-right-blank', height: 300, width: 300},
    {name: 'app-cares-right-flip', height: 300, width: 300},
];

export const RectangleInvites = [
    {name: 'app-cares-right-basic', height: 250, width: 500},
    {name: 'app-cares-right-blank', height: 250, width: 500},
    {name: 'app-cares-right-flip', height: 250, width: 500},
];

export const ThinInvites = [
    {name: 'app-cares-right-basic', height: 500, width: 150},
    {name: 'app-cares-right-blank', height: 350, width: 150},
    {name: 'app-cares-right-flip', height: 750, width: 150},
];

export const SmartphoneInvites = [
    {name: 'app-cares-right-basic', height: 667, width: 375},
    {name: 'app-cares-right-blank', height: 667, width: 375},
    {name: 'app-cares-right-flip', height: 667, width: 375},
];

export const OfficeCards = [
    {name: 'Full', val: 'app-res-office'},
    {name: 'Lite', val: 'app-cares-office-lite'},
    {name: 'Expansion Panel', val: 'app-cares-office-expansion'}
];

export const StaffCards = [
    {name: 'Full', val: 'app-res-office'},
    {name: 'Lite', val: 'app-cares-office-lite'},
    {name: 'Expansion Panel', val: 'app-cares-office-expansion'}
];

export const PolicyCards = [
    {name: 'Full', val: 'app-res-office'},
    {name: 'Lite', val: 'app-cares-office-lite'},
    {name: 'Expansion Panel', val: 'app-cares-office-expansion'}
];

export const LinkCards = [
    {name: 'Full', val: 'app-res-office'},
    {name: 'Lite', val: 'app-cares-office-lite'},
    {name: 'Expansion Panel', val: 'app-cares-office-expansion'}
];

export const CardCards = [
    {name: 'Full', val: 'app-res-office'},
    {name: 'Lite', val: 'app-cares-office-lite'},
    {name: 'Expansion Panel', val: 'app-cares-office-expansion'}
];

export const FileTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png'
];

export const ResourceTypes = [
    'Categories',
    'RO Reports',
    'RO Surveys',
    'RO Guides',
    'RO Widgets',
    'RO Courses',
    'Campus Resources',
    'Local Resources - By Distance',
    'Local Resources - By Name',
    'State Resources',
    'National Resources',
];


export const NonCampusRT = [
    'Local Resources - By Distance',
    'Local Resources - By Name',
    'State Resources',
    'National Resources',
];

export const MediumResourceTypes = [
    'Categories',
    'Campus Resources',
    'Local Resources - By Distance',
    'Local Resources - By Name',
    'State Resources',
    'National Resources',
];

export const ResourceTypesAbbrv = [
    'Campus Resources',
    'Local Resources - By Distance',
    'Local Resources - By Name',
];

export const CampusTypesAbbrv = [
    'Offices',
    'Staff'
];

export const ShortResourceTypes = [
    'Campus Resources',
    'Local Resources - By Distance',
    'Local Resources - By Name',
    'State Resources',
    'National Resources',
];

export const TemplateResourceTypes = [
    'Campus Resources',
    'Local',
    'State',
    'National',
];

export const CampusTypes = ['Offices',
    'Staff',
    'Policies',
    'Cards',
    'Links',
    'Videos',
    'Invitations'];

export const CampusTypesLongLat = ['Offices',
    'Staff'];

export const SlgTypes = [
    'Office',
    'Person',
    'Policy',
    'Law',
    'Card',
    'Link',
    'Local',
    'State',
    'National'
];

export const Behaviors = [
    'All',
    'Bullying',
    'Crime',
    'Elder Abuse',
    'Food Insecurity',
    'Hazing',
    'Healthy Relationships',
    'Interpersonal Violence',
    'Mental Health',
    'Pregnancy',
    'Retaliation',
    'Sexual Misconduct',
    'Sexual Violence',
    'Stalking',
    'Substance Abuse',
    'Trauma',
];

export const Topics = [
    'All',
    'Advocacy',
    'Campus Safety',
    'Care',
    'Cost of Sexual Violence',
    'Culture',
    'false Accusations',
    'Law Enforcement',
    'Prevelence',
    'Prevention',
    'Rape Kit/SANE',
    'Safety',
    'Task Force Report',
    'Title IX/SMP Policies',
    'Trauma',
];

export const PopArchive = [
    'All',
    'Accused',
    'Adults',
    'Asian',
    'Athletes',
    'Black',
    'College Students',
    'Colleges',
    'Cognatively Disabled',
    'Crime Victim',
    'Elderly',
    'For-Profit',
    'Greek Life',
    'K12',
    'Latino',
    'LGBTQ+',
    'Native People',
    'Not-For-Profit',
    'Men',
    'Military',
    'Parents',
    'Physically Disabled',
    'Religious',
    'Secondary Schools',
    'Transgender',
    'Women'
];

export const Sort = [
    'Title',
    'Publication',
    'Most Recent',
    'Oldest'
];

export const Target = [
    'All',
    'Layperson',
    'Professional'
];

export const Format = [
    'Article',
    'DOC',
    'PDF',
    'Video',
    'Webinar',
    'Website'
];


export const WhichLibrary = [
    'Archive',
    'Climate Surveys',
    'Laws'
];

export const Colors = [
    '#E53935',
    '#039BE5',
    '#FDD835',
    '#D81B60',
    '#00ACC1',
    '#FFB300',
    '#8E24AA',
    '#00897B',
    '#FB8C00',
    '#5E35B1',
    '#43A047',
    '#FB8C00',
    '#3949AB',
    '#7CB342',
    '#BCAAA4',
    '#1E88E5',
    '#C0CA33',
    '#EEEEEE'
];


export const SearchCats = [
    'Campus Resources - Offices',
    'Campus Resources - People',
    'Campus Resources - Policies',
    'Campus Resources - Cards',
    'Campus Resources - Links',
    'Campus Resources - Invitations',
    'Campus Resources - Confidential',
    'Community Resources',
    'Community Resources - Confidential',
    'My Library',
    'National Resources - Helpline',
    'National Resources - Screening',
    'National Resources - Locator',
    'National Resources - Information',
    'State Resources'
];

export const TitleIXJob = [
    'Adjudicator',
    'Appeal Board',
    'Campus Security Authority',
    'Confidential',
    'Deputy Title IX Coordinator',
    'Investigator',
    'Not a Reporter',
    'Responsible Employee',
    'RE and CSA',
    'Other',
    'Title IX Coordinator',
];


export const AdminTypes = [
    'Colleges',
    'Community',
    'Security'
];


export const RelationshipToParent = [
    'Branch',
    'Department',
    'Division',
    'Franchise',
    'Location',
    'Office',
    'Subsidiary',
];

export const ComOrgTypes = [
    'Not-for-profit Local',
    'Not-for-profit State',
    'Not-for-profit National',
    'Not-for-profit International',
    'Government Local',
    'Government State',
    'Government Military',
    'Government National',
    'For-profit'
];

export const OrgTypes = [
    'Colleges',
    'Secondary Schools',
    'Local Governments',
    'US Military',
    'International Colleges',
];


export const InitialMode = [
    'Chicklet',
    'Gallery'
];

export const Days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

export const DaysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


export const SchoolRole = [
    {value: 'Student'},
    {value: 'Employee'},
];

export const StudentSubRole = [
    {value: 'Undergraduate'},
    {value: 'Grad student'},
    {value: 'Law student'},
    {value: 'Medical student'},
    {value: 'MBA student'},
    {value: 'Dental student'},
    {value: 'Other student'},
];

export const EmployeeSubRole = [
    {value: 'Faculty'},
    {value: 'Administration'},
    {value: 'Security'},
    {value: 'Other staff'},
];


export const Population = [
    {value: 'All'},
    {value: 'All students'},
    {value: 'Undergraduates'},
    {value: 'Athletes'},
    {value: 'Fraternity/sorority'},
    {value: 'Grad students'},
    {value: 'Law students'},
    {value: 'Medical students'},
    {value: 'MBA students'},
    {value: 'Dental students'},
    {value: 'All staff'},
    {value: 'Faculty'},
    {value: 'Administration'},
    {value: 'Security'},
    {value: 'Other staff'},
];


export const ComPopulation = [
    'All',
    'Adults',
    'Athletes',
    'College Students',
    'College Staff',
    'Disabilities',
    'Ethnic',
    'Greek Life',
    'K12',
    'Law Enforcement',
    'LGBTQ+',
    'Medical Care',
    'Men',
    'Military',
    'Race',
    'Religious',
    'Transgender',
    'Women',
];

export const Emojisy = [
    'anonymous messaging',
    'campus',
    'chat',
    'community',
    'confidential',
    'deaf',
    'forensic',
    'jump',
    'multilingual',
    'police',
    'RON',
    'text',
    'video'
];

export const ComServices = [
    {desc: 'Case Management', value: false},
    {desc: 'Child Abuse Hotline', value: false},
    {desc: 'Child Protective Services', value: false},
    {desc: 'Child Shelter', value: false},
    {desc: 'Children Advocacy', value: false},
    {desc: 'Counseling', value: false},
    {desc: 'Crisis Intervention', value: false},
    {desc: 'Crisis Intervention Hotline', value: false},
    {desc: 'Disability Services', vlaue: false},
    {desc: 'Domestic Violence Crisis', value: false},
    {desc: 'Domestic Violence Hotline', value: false},
    {desc: 'Domestic Violence Shelter', value: false},
    {desc: 'Eating Disorder Hotline', value: false},
    {desc: 'Eating Disorder Services', value: false},
    {desc: 'Elder Abuse', value: false},
    {desc: 'Family Crisis', value: false},
    {desc: 'Family Services', value: false},
    {desc: 'Food Pantry', value: false},
    {desc: 'Forensic Exam', value: false},
    {desc: 'Group Counseling', value: false},
    {desc: 'Housing Services', value: false},
    {desc: 'Individual Counseling', value: false},
    {desc: 'Immigrant Services', value: false},
    {desc: 'Issue Advocacy', value: false},
    {desc: 'Job Development', value: false},
    {desc: 'Law Enforcement', value: false},
    {desc: 'Legal Advocacy', value: false},
    {desc: 'Legal Aid', value: false},
    {desc: 'LGBBTQ+ Hotline', value: false},
    {desc: 'LGBBTQ+ Services', value: false},
    {desc: 'Medical Advocacy', value: false},
    {desc: 'Medical Care', value: false},
    {desc: 'Military Services', value: false},
    {desc: 'OCR', value: false},
    {desc: 'Prosecutor', value: false},
    {desc: 'Reproductive Health', value: false},
    {desc: 'Reproductive Health Hotline', value: false},
    {desc: 'Resilience Resource', value: false},
    {desc: 'Resourcing', value: false},
    {desc: 'Sexual Assault Crisis', value: false},
    {desc: 'Sexual Assault Hotline', value: false},
    {desc: 'Social Services', value: false},
    {desc: 'Stalking Hotline', value: false},
    {desc: 'Substance Abuse Help', value: false},
    {desc: 'Substance Abuse Hotline', value: false},
    {desc: 'Victim Services', value: false},
    {desc: 'Youth Helpline', value: false}
];

//LGBBTQ+ Support Center

export const ComCommunities = [
    {desc: 'All', value: false},
    {desc: 'Adults', value: false},
    {desc: 'Children', value: false},
    {desc: 'Cognative Disabilities', value: false},
    {desc: 'College Students', value: false},
    {desc: 'Crime Victim', value: false},
    {desc: 'Elderly', value: false},
    {desc: 'Female', value: false},
    {desc: 'Immigrant', value: false},
    {desc: 'Refugee', value: false},
    {desc: 'LGBTQ+', value: false},
    {desc: 'Low Income', value: false},
    {desc: 'Physical Disabilities', value: false},
    {desc: 'Male', value: false},
    {desc: 'Transgender', value: false},
    {desc: 'African American', value: false},
    {desc: 'Arab', value: false},
    {desc: 'Asian', value: false},
    {desc: 'Indigenous', value: false},
    {desc: 'Latino', value: false},
    {desc: 'Military', value: false},
    {desc: 'Veteran', value: false},
    {desc: 'Pregnant', value: false},
    {desc: 'Jewish', value: false},
    {desc: 'Boxing Day', value: false},
    {desc: 'Catholic', value: false},
    {desc: 'Christian', value: false},
    {desc: 'Muslim', value: false},
    {desc: 'Other Religion', value: false},
];

export const Attributes = [
  'Spanish language',
  'Chinese language',
  'Vietnamese language',
  'Arabic language',
  'French language',
  'Korean language',
  'Russian language',
  'German language',
  'Portuguese language',
  'Italian language',
  'Polish language',
  'Elderly',
  'Female',
  'Immigrant',
  'Refugee',
  'LGBTQ+',
  'Low Income',
  'Physical Disabilities',
  'Male',
  'Transgender',
  'African American',
  'Arab',
  'Asian',
  'Indigenous',
  'Latino',
  'Military',
  'Veteran',
  'Pregnant',
  'Jewish',
  'Catholic',
  'Christian',
  'Muslim'
];




export const ComTags = [
    {desc: 'Confidential', value: false},
    {desc: 'Free/Low Cost', value: false},
    {desc: 'ASL Interpreter', value: false},
];


export const ROApps = [
    'RO Respondent',
    'RO Employee',
];

export const CorpFilters = [
  'All',
  'Registered',
  'Appointment Today',
  'Not Registered',
  'Currently Positive',
  'Currently Quarantined'
];


export const TimeZones = [
    {value: 'Pacific/Honolulu'},
    {value: 'America/Anchorage'},
    {value: 'America/Juneau'},
    {value: 'America/Metlakatla'},
    {value: 'America/Nom'},
    {value: 'America/Sitka'},
    {value: 'America/Yakutat'},
    {value: 'America/Los_Angeles'},
    {value: 'America/Boise'},
    {value: 'America/Denver'},
    {value: 'America/Phoenix'},
    {value: 'America/Chicago'},
    {value: 'America/Indiana/Knox'},
    {value: 'America/Indiana/Tell_City'},
    {value: 'America/Menominee'},
    {value: 'America/North_Dakota/Beulah'},
    {value: 'America/North_Dakota/Center'},
    {value: 'America/North_Dakota/New_Salem'},
    {value: 'America/Detroit'},
    {value: 'America/Indiana/Indianapolis'},
    {value: 'America/Indiana/Marengo'},
    {value: 'America/Indiana/Petersburg'},
    {value: 'America/Indiana/Vevay'},
    {value: 'America/Indiana/Vincennes'},
    {value: 'America/Indiana/Winamac'},
    {value: 'America/Kentucky/Louisville'},
    {value: 'America/Kentucky/Monticello'},
    {value: 'America/New_York'}
];


// Todo: the names listed here must match up with Jack's emoji names
// https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis/

export const Emojis = [
    {
        view: 'video',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-video.png?alt=media'
    },
    {
        view: '2',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-man.png?alt=media'
    },
    {
        view: 'confidential',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-confidential.png?alt=media'
    },
    {
        view: '3',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-jump.png?alt=media'
    },
    {
        view: 'SANE',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-forensic.png?alt=media'
    },
    {
        view: '4',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-circle.png?alt=media'
    },
    {
        view: 'favorite',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-favorite.png?alt=media'
    },
    {
        view: 'free',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-free.png?alt=media'
    },
    {
        view: '5',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-octagon.png?alt=media'
    },
    {
        view: 'community',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-community.png?alt=media'
    },
    {
        view: 'anonymous',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-anonymous.png?alt=media'
    },
    {
        view: 'campus',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-campus.png?alt=media'
    },
    {
        view: 'deaf',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-deaf.png?alt=media'
    },
    {
        view: 'pregnant',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-pregnantscholar.png?alt=media'
    },
    {
        view: 'RON',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-ron.png?alt=media'
    },
    {
        view: '$$$',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-money.png?alt=media'
    },
    {
        view: 'open',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-open.png?alt=media'
    },
    {
        view: 'languages',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-multi-lingual.png?alt=media'
    },
    {
        view: 'text',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-text.png?alt=media'
    },
    {
        view: 'chat',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-chat.png?alt=media'
    },
    {
        view: 'police',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-police.png?alt=media'
    },
    {
        view: '6',
        value: false,
        img: 'https://firebasestorage.googleapis.com/v0/b/library-titleix.appspot.com/o/Emojis%2Femoji-woman.png?alt=media'
    }
];

/*
export const Emojis = [
    {view: 'video', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-video.png'},
    {view: '2', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-man.png'},
    {view: 'confidential', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-confidential.png'},
    {view: '3', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-jump.png'},
    {view: 'SANE', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-forensic.png'},
    {view: '4', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-circle.png'},
    {view: 'favorite', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-favorite.png'},
    {view: 'free', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-free.png'},
    {view: '5', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-octagon.png'},
    {view: 'community', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-community.png'},
    {view: 'anonymous', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-anonymous.png'},
    {view: 'campus', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-campus.png'},
    {view: 'deaf', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-deaf.png'},
    {view: 'pregnant', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-pregnantscholar.png'},
    {view: 'RON', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-ron.png'},
    {view: '$$$', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-money.png'},
    {view: 'open', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-open.png'},
    {view: 'languages', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-multi-lingual.png'},
    {view: 'text', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-text.png'},
    {view: 'chat', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-chat.png'},
    {view: 'police', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-police.png'},
    {view: '6', value: false, img: 'http://ron.capptivation.com/assets/emojis/emojis/emoji-woman.png'}
];
*/


export const Icons = [
    {img: 'chiclet-eye.png'},
    {img: 'notifications.png'},
    {img: 'mapit.png'},
    {img: 'exhibits.png'},
    {img: 'directory.png'},
    {img: 'resoures.png'},
    {img: 'gsp.png'},
    {img: 'watercooler.png'},
    {img: 'survey.png'},
    {img: 'covid.png'},
    {img: 'calendar.png'},
    {img: 'chiclet-escort.png'},
    {img: 'chiclet-wave.png'},
    {img: 'chiclet-referrals.png'},
    {img: 'chiclet-clipboard-orange.png'},
    {img: 'chiclet-title-ix-survey.png'},
    {img: 'chiclet-resources.png'},
    {img: 'chiclet-nys-northcountry.png'},
    {img: 'chiclet-links.png'},
    {img: 'chiclet-lorasathletics.png'},
    {img: 'chiclet-mailpvp.png'},
    {img: 'chiclet-clipboard-navy.png'},
    {img: 'chiclet-bullying.png'},
    {img: 'chiclet-mu-admissions.png'},
    {img: 'chiclet-locatorscolored.png'},
    {img: 'chiclet-helpafriend.png'},
    {img: 'chiclet-oncall.png'},
    {img: 'chiclet-clery.png'},
    {img: 'chiclet-clipboard-aqua.png'},
    {img: 'uwf-police.png'},
    {img: 'chiclet-preventioncolored.png'},
    {img: 'chiclet-daily.png'},
    {img: 'empowerment.png'},
    {img: 'chiclet-clerydata.png'},
    {img: 'chiclet-ovs.png'},
    {img: 'chiclet-parenting.png'},
    {img: 'healthpromo.png'},
    {img: 'chiclet-medcare.png'},
    {img: 'chiclet-mystorycolored.png'},
    {img: 'sac.png'},
    {img: 'chiclet-lgbtq.png'},
    {img: 'chiclet-ror.png'},
    {img: 'chiclet-clipboard-babyblue.png'},
    {img: 'chiclet-titleix.png'},
    {img: 'chiclet-studyabroad.png'},
    {img: 'chiclet-crisis-text-line.png'},
    {img: 'chiclet-riseup.png'},
    {img: 'rangers.png'},
    {img: 'chiclet-studentathletehandbook.png'},
    {img: 'chiclet-reporting.png'},
    {img: 'chiclet-handbook.png'},
    {img: 'chiclet-adolescent.png'},
    {img: 'chiclet-veterans.png'},
    {img: 'chiclet-clipboard.png'},
    {img: 'chiclet-facebook.png'},
    {img: 'chiclet-twitter.png'},
    {img: 'chiclet-clipboard-green.png'},
    {img: 'chiclet-guidecolored.png'},
    {img: 'chiclet-event.png'},
    {img: 'chiclet-nys-southerntier.png'},
    {img: 'chiclet-clipboard-ocean.png'},
    {img: 'chiclet-victimservices.png'},
    {img: 'chiclet-linkscolored.png'},
    {img: 'sapr-chiclet.png'},
    {img: 'chiclet-southerntier.png'},
    {img: 'chiclet-campus.png'},
    {img: 'carousel.png'},
    {img: 'chiclet-hands.png'},
    {img: 'chiclet-mu-wire.png'},
    {img: 'chiclet-mystory.png'},
    {img: 'chiclet-shuttle.png'},
    {img: 'universityofwestflorida.png'},
    {img: 'chiclet-emergency.png'},
    {img: 'chiclet-clipboard-pink.png'},
    {img: 'chiclet-crisis.png'},
    {img: 'chiclet-dissabilities.png'},
    {img: 'chiclet-exclamation.png'},
    {img: 'chiclet-mentalhealth.png'},
    {img: 'chiclet-portalcolored.png'},
    {img: 'haven.png'},
    {img: 'chiclet-abroad.png'},
    {img: 'chiclet-IWU-Athletics.png'},
    {img: 'chiclet-mu-athletics.png'},
    {img: 'events.png'},
    {img: 'chiclet-change.png'},
    {img: 'chiclet-clipboard-dark-green.png'},
    {img: 'chiclet-instagram.png'},
    {img: 'hazlo.png'},
    {img: 'chiclet-childabuse.png'},
    {img: 'chiclet-home.png'},
    {img: 'chiclet-sexualviolence.png'},
    {img: 'chiclet-portal.png'},
    {img: 'uwf-education.png'},
    {img: 'chiclet-clipboard-bright-purple.png'},
    {img: 'chiclet-nys-state.png'},
    {img: 'chiclet-IWUcampuspolice.png'},
    {img: 'uwf-reporting.png'},
    {img: 'chiclet-mu-police.png'},
    {img: 'chiclet-education.png'},
    {img: 'chiclet-drugsalcohol.png'},
    {img: 'chiclet-books.png'},
    {img: 'chiclet-confidential.png'},
    {img: 'baebsafe.png'},
    {img: 'chiclet-athletics.png'},
    {img: 'chiclet-locator.png'},
    {img: 'gallery.png'},
    {img: 'chiclet-ron.png'},
    {img: 'chiclet-clipboard-yellow.png'},
    {img: 'chiclet-pledge2.png'},
    {img: 'chiclet-seniorcitizens.png'},
    {img: 'chiclet-video.png'},
    {img: 'chiclet-IWUsaferide.png'},
    {img: 'chiclet-exoffender.png'},
    {img: 'chiclet-women.png'},
    {img: 'chiclet-safeatunc.png'},
    {img: 'chiclet-maps1.png'},
    {img: 'chiclet-incidentreport.png'},
    {img: 'chiclet-pregnant.png'},
    {img: 'chiclet-homeless.png'},
    {img: 'chiclet-mask.png'},
    {img: 'chiclet-clipboard-red-pink.png'},
    {img: 'chiclet-faq.png'},
    {img: 'chiclet-heart.png'},
    {img: 'chiclet-maps.png'},
    {img: 'chiclet-single-heart.png'},
    {img: 'chiclet-guide.png'},
    {img: 'chiclet-greendot.png'},
    {img: 'chiclet-crc.png'},
    {img: 'chiclet-nys-central.png'},
    {img: 'chiclet-campus1.png'},
    {img: 'chiclet-smh.png'},
    {img: 'galleries.png'},
    {img: 'chiclet-capitol.png'},
    {img: 'chiclet-hotline.png'},
    {img: 'chiclet-nys-fingerlakes.png'},
    {img: 'chiclet-newscolored.png'},
    {img: 'chiclet-families.png'},
    {img: 'chiclet-ctl.png'},
    {img: 'chiclet-secret-user.png'},
    {img: 'chiclet-issueadv.png'},
    {img: 'chiclet-callisto.png'},
    {img: 'chiclet-information.png'},
    {img: 'oncallconfidential.png'},
    {img: 'chiclet-getinvolved.png'},
    {img: 'chiclet-menu.png'},
    {img: 'chiclet-immediate.png'},
    {img: 'chiclet-addiction.png'},
    {img: 'chiclet-uni.png'},
    {img: 'chiclet-nys-longisland.png'},
    {img: 'anoymous-report.png'},
    {img: 'uwf-assaultresources.png'},
    {img: 'chiclet-immigrant.png'},
    {img: 'chiclet-carousel.png'},
    {img: 'chiclet-eatingdisorder.png'},
    {img: 'chiclet-reportingcolored.png'},
    {img: 'chiclet-sexualmisconduct.png'},
    {img: 'chiclet-explorecampus.png'},
    {img: 'chiclet-statelaw.png'},
    {img: 'chiclet-academicsupporticon.png'},
    {img: 'chiclet-nys-capital.png'},
    {img: 'chiclet-grey-feature.png'},
    {img: 'chiclet-nys-western.png'},
    {img: 'chiclet-mentalhealth2.png'},
    {img: 'chiclet-stress-management.png'},
    {img: 'chiclet-feature.png'},
    {img: 'studentadv.png'},
    {img: 'chiclet-pledge.png'},
    {img: 'chiclet-nys-nyc.png'},
    {img: 'chiclet-foodpantries.png'},
    {img: 'chiclet-huff.png'},
    {img: 'chiclet-dining.png'},
    {img: 'chiclet-dining.png'},
    {img: 'chiclet-itsonus-upenn.png'},
    {img: 'chiclet-scale.png'},
    {img: 'chiclet-emergencypreparedness.png'},
    {img: 'chiclet-story.png'},
    {img: 'bae.png'},
    {img: 'chiclet-nys-midhudson.png'},
    {img: 'chiclet-community.png'},
    {img: 'chiclet-IWU-theden.png'},
    {img: 'chiclet-nys-mohawkvalley.png'},
    {img: 'chiclet-parking.png'}
];


/*    {img: 'http://ron.capptivation.com/assets/icons/custom-icon.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-video.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-escort.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-portal.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-IWUsaferide.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-scale.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-parking.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-callisto.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-referrals.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mystory.png'},
    {img: 'http://ron.capptivation.com/assets/icons/oncallconfidential.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-portalcolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-immediate.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-capital.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-IWUcampuspolice.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-menu.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-itsonus-upenn.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-maps.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-seniorcitizens.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-faq.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-southerntier.png'},
    {img: 'http://ron.capptivation.com/assets/icons/uwf-assaultresources.png'},
    {img: 'http://ron.capptivation.com/assets/icons/uwf-police.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-immigrant.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-guidecolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-capitol.png'},
    {img: 'http://ron.capptivation.com/assets/icons/uwf-education.png'},
    {img: 'http://ron.capptivation.com/assets/icons/uwf-reporting.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-foodpantries.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-families.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-exoffender.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-shuttle.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-longisland.png'},
    {img: 'http://ron.capptivation.com/assets/icons/bae.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-titleix.png'},
    {img: 'http://ron.capptivation.com/assets/icons/empowerment.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-confidential.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-fingerlakes.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-newscolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-reportingcolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-bullying.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-helpafriend.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-emergency.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-midhudson.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-stress-management.png'},
    {img: 'http://ron.capptivation.com/assets/icons/anoymous-report.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-event.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-crisis-text-line.png'},
    {img: 'http://ron.capptivation.com/assets/icons/universityofwestflorida.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-links.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mailpvp.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-veterans.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-ron.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-community.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-state.png'},
    {img: 'http://ron.capptivation.com/assets/icons/haven.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-abroad.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-guide.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-clerydata.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-women.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-books.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-emergencypreparedness.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-mohawkvalley.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-story.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-victimservices.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-linkscolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-crc.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-exclamation.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-hotline.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-pledge.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-handbook.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mu-police.png'},
    {img: 'http://ron.capptivation.com/assets/icons/healthpromo.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-childabuse.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-locatorscolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-adolescent.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-IWU-theden.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-sexualmisconduct.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-getinvolved.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-information.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-southerntier.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mentalhealth.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-eatingdisorder.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-central.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-homeless.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mu-admissions.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-pregnant.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-campus.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-parenting.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-ror.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-academicsupporticon.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-IWU-Athletics.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-locator.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-change.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-nyc.png'},
    {img: 'http://ron.capptivation.com/assets/icons/sapr-chiclet.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-preventioncolored.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-medcare.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-studyabroad.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-northcountry.png'},
    {img: 'http://ron.capptivation.com/assets/icons/hazlo.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-heart.png'},
    {img: 'http://ron.capptivation.com/assets/icons/studentadv.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-education.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-lgbtq.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-dissabilities.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-addiction.png'},
    {img: 'http://ron.capptivation.com/assets/icons/uwf-taxiicon.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-single-heart.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-nys-western.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-reporting.png'},
    {img: 'http://ron.capptivation.com/assets/icons/chiclet-mentalhealth2.png'},
    {img: 'http://ron.capptivation.com/assets/icons/sac.png'}
];*/


export const Languages = [
    {desc: 'English', abbr: 'en', value: false},
    {desc: 'Spanish', abbr: 'es', value: false},
    {desc: 'Chinese', abbr: 'zh_cn', value: false},
    {desc: 'Vietnamese', abbr: 'vi', value: false},
    {desc: 'Arabic', abbr: 'ar', value: false},
    {desc: 'French', abbr: 'fr', value: false},
    {desc: 'Korean', abbr: 'ko', value: false},
    {desc: 'Russian', abbr: 'ru', value: false},
    {desc: 'German', abbr: 'de', value: false},
    {desc: 'Portuguese', abbr: 'pt_pt', value: false},
    {desc: 'Italian', abbr: 'it', value: false},
    {desc: 'Polish', abbr: 'pl', value: false},
];

// todo: neeed to create state and national resources
export const ComResourceCodes = [
    {desc: 'Academic Aid', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Accused', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Addiction - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Advocacy', icon: 'http://ron.capptivation.com/assets/icons/chiclet-immediate.png', value: false},
    {desc: 'Bullying - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Bullying - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Bystander', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Care', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Child Abuse - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-childabuse.png', value: false},
    {desc: 'Child Abuse - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-childabuse.png', value: false},
    {desc: 'Child Protective Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-families.png', value: false},
    {desc: 'Children\'s Advocacy', icon: 'http://ron.capptivation.com/assets/icons/chiclet-families.png', value: false},
    {desc: 'Counseling', icon: 'http://ron.capptivation.com/assets/icons/chiclet-mentalhealth2.png', value: false},
    {desc: 'County Police', icon: 'http://ron.capptivation.com/assets/icons/chiclet-IWUcampuspolice.png', value: false},
    {desc: 'Crisis Center', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergency.png', value: false},
    {desc: 'Crisis Intervention - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergency.png', value: false},
    {desc: 'Dating Violence - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Depression/Anxiety - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-drugsalcohol.png', value: false},
    {desc: 'Disabilities', icon: 'http://ron.capptivation.com/assets/icons/chiclet-dissabilities.png', value: false},
    {desc: 'District Attorney', icon: 'http://ron.capptivation.com/assets/icons/chiclet-scale.png', value: false},
    {desc: 'Domestic Violence - Crisis', icon: 'http://ron.capptivation.com/assets/icons/chiclet-referrals.png', value: false},
    {desc: 'Domestic Violence - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-referrals.png', value: false},
    {desc: 'Domwaric Violwnxw - Shelter', icon: 'http://ron.capptivation.com/assets/icons/oncallconfidential.png', value: false},
    {desc: 'Eating Disorder', icon: 'http://ron.capptivation.com/assets/icons/chiclet-eatingdisorder.png', value: false},
    {desc: 'Eating Disorder - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-eatingdisorder.png', value: false},
    {desc: 'Eating Disorder - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-eatingdisorder.png', value: false},
    {desc: 'Education/Development', icon: 'http://ron.capptivation.com/assets/icons/chiclet-handbook.png', value: false},
    {desc: 'Elder Abuse', icon: 'http://ron.capptivation.com/assets/icons/chiclet-seniorcitizens.png', value: false},
    {desc: 'Empowerment', icon: 'http://ron.capptivation.com/assets/icons/empowerment.png', value: false},
    {desc: 'Ex-offender', icon: 'http://ron.capptivation.com/assets/icons/chiclet-exoffender.png', value: false},
    {desc: 'Facts and Stats', icon: 'http://ron.capptivation.com/assets/icons/chiclet-bullying.png', value: false},
    {desc: 'Family Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergencypreparedness.png', value: false},
    {desc: 'Fire Deartment', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergencypreparedness.png', value: false},
    {desc: 'Food Pantry', icon: 'http://ron.capptivation.com/assets/icons/chiclet-foodpantries.png', value: false},
    {desc: 'For Survivors', icon: 'http://ron.capptivation.com/assets/icons/chiclet-foodpantries.png', value: false},
    {desc: 'Healthy Relationships - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-capitol.png', value: false},
    {desc: 'Hospital', icon: 'http://ron.capptivation.com/assets/icons/chiclet-medcare.png', value: false},
    {desc: 'Housing Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-homeless.png', value: false},
    {desc: 'Immigration Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-immigrant.png', value: false},
    {desc: 'Laws', icon: 'http://ron.capptivation.com/assets/icons/chiclet-helpafriend.png', value: false},
    {desc: 'Legal Aid', icon: 'http://ron.capptivation.com/assets/icons/chiclet-helpafriend.png', value: false},
    {desc: 'LGBTQ - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-lgbtq.png', value: false},
    {desc: 'LGBTQ - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-lgbtq.png', value: false},
    {desc: 'LGBTQ - Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-community.png', value: false},
    {desc: 'Library', icon: 'http://ron.capptivation.com/assets/icons/chiclet-lgbtq.png', value: false},
    {desc: 'Local Police', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergency.png', value: false},
    {desc: 'Locator', icon: 'http://ron.capptivation.com/assets/icons/chiclet-emergency.png', value: false},
    {desc: 'Medical Care', icon: 'http://ron.capptivation.com/assets/icons/chiclet-hotline.png', value: false},
    {desc: 'Medical - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-hotline.png', value: false},
    {desc: 'Mental Health - Screening', icon: 'http://ron.capptivation.com/assets/icons/chiclet-hotline.png', value: false},
    {desc: 'OCR', icon: 'http://ron.capptivation.com/assets/icons/chiclet-titleix.png', value: false},
    {desc: 'Parenting', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Poison Control', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Pregnant/Parenting', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Preserve The Evidence', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Prevention - Information', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Retaliation', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'RON Partner', icon: 'http://ron.capptivation.com/assets/icons/healthpromo.png', value: false},
    {desc: 'Sex Offender Registry', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Sexual Assault - Crisis', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Sexual Assault Treatment Center', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Sexual Harassment - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Sexual Harassment - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Sexual Health - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-pregnant.png', value: false},
    {desc: 'Sexual Health - Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-pregnant.png', value: false},
    {desc: 'Social Work', icon: 'http://ron.capptivation.com/assets/icons/chiclet-stress-management.png', value: false},
    {desc: 'Stalking - Hotline', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Stalking - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Stress - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-sexualviolence.png', value: false},
    {desc: 'Substance Abuse - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-guidecolored.png', value: false},
    {desc: 'Substance Abuse - Screening', icon: 'http://ron.capptivation.com/assets/icons/chiclet-guidecolored.png', value: false},
    {desc: 'Support - Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-guidecolored.png', value: false},
    {desc: 'Teen Health', icon: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png', value: false},
    {desc: 'Title IX - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png', value: false},
    {desc: 'Trauma - Information', icon: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png', value: false},
    {desc: 'Treatment - Locator', icon: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png', value: false},
    {desc: 'Treatment Center', icon: 'http://ron.capptivation.com/assets/icons/chiclet-oncall.png', value: false},
    {desc: 'Veterans\'s Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-veterans.png', value: false},
    {desc: 'Victim Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-victimservices.png', value: false},
    {desc: 'Women\'s Services', icon: 'http://ron.capptivation.com/assets/icons/chiclet-women.png', value: false}
];

// todo: need to delete
export const OfficeResourceCodes = [
    {desc: 'Aboriginal Student Services', value: false, view: '20460'},
    {desc: 'Academic Support Service', value: false, view: '20380'},
    {desc: 'Advocate', value: false, view: '20000'},
    {desc: 'Athletic Program', value: false, view: '20480'},
    {desc: 'Campus Escort', value: false, view: '2000'},
    {desc: 'Campus Security', value: false, view: '20010'},
    {desc: 'Career Development Center', value: false, view: '20400'},
    {desc: 'Community Safety Office', value: false, view: '20450'},
    {desc: 'Counseling Center', value: false, view: '20030'},
    {desc: 'Department of Housing', value: false, view: '20040'},
    {desc: 'Dining Services', value: false, view: '20500'},
    {desc: 'Disability Interim Measures', value: false, view: '20410'},
    {desc: 'Education', value: false, view: '20210'},
    {desc: 'EMS (campus)', value: false, view: '20080'},
    {desc: 'Financial Aid', value: false, view: '20470'},
    {desc: 'Health Services - No SAFE', value: false, view: '20060'},
    {desc: 'Health Services - Nurse only', value: false, view: '20050'},
    {desc: 'Health Services - SAFE', value: false, view: '20070'},
    {desc: 'Hotline - Nurse on Call', value: false, view: '20090'},
    {desc: 'Hotline (Campus)', value: false, view: '1000'},
    {desc: 'Human Rights Office', value: false, view: '20420'},
    {desc: 'Interfaith Council', value: false, view: '20100'},
    {desc: 'International Students Office', value: false, view: '20110'},
    {desc: 'Legal Aid', value: false, view: '20120'},
    {desc: 'Miscellaneous Organizations', value: false, view: '20360'},
    {desc: 'Multi-Cultural Resource', value: false, view: '20270'},
    {desc: 'Office of Equal Opportunity', value: false, view: '20130'},
    {desc: 'Office of Greek Life', value: false, view: '20200'},
    {desc: 'Office of Student Conduct', value: false, view: '20140'},
    {desc: 'Office of Student Life', value: false, view: '20180'},
    {desc: 'Office of Study Abroad', value: false, view: '20390'},
    {desc: 'Office of the General Counsel', value: false, view: '20160'},
    {desc: 'Office of the President', value: false, view: '20190'},
    {desc: 'Ombuds Office', value: false, view: '00000'},
    {desc: 'Other', value: false, view: '00000'},
    {desc: 'Peer Support Team', value: false, view: '20430'},
    {desc: 'Police Department (Campus)', value: false, view: '20020'},
    {desc: 'Psychiatric Services', value: false, view: '20220'},
    {desc: 'Reporting Hotline', value: false, view: '1010'},
    {desc: 'Safe Space', value: false, view: '20280'},
    {desc: 'Sexual Assault Crisis Center', value: false, view: '20310'},
    {desc: 'Sexual and Gender Diversity', value: false, view: '20370'},
    {desc: 'Sexual Assualt Support Center', value: false, view: '20320'},
    {desc: 'Sexual Assault Response Team', value: false, view: '20350'},
    {desc: 'Sexual Harassment Office', value: false, view: '20440'},
    {desc: 'Student Activities Department', value: false, view: '20490'},
    {desc: 'Support Center', value: false, view: '20300'},
    {desc: 'Title IX Office', value: false, view: '00000'},
    {desc: 'Wellness Center', value: false, view: '20230'},
    {desc: 'Women\'s Center', vauel: false, view: '20240'}
];

// Todo: needs to be implemented
export const PersonResourceCodes = [
    {desc: 'Academic Specialist', value: false, view: '30170'},
    {desc: 'Administrator', value: false, view: '00000'},
    {desc: 'Athletic Director', value: false, view: '30140'},
    {desc: 'Campus Minister', value: false, view: '30010'},
    {desc: 'Coach', value: false, view: '00000'},
    {desc: 'Dean of Students', value: false, view: '30020'},
    {desc: 'Deputy Title IX Coordinator', value: false, view: '30030'},
    {desc: 'Director of Human Resources', value: false, view: '30040'},
    {desc: 'Equity Officer', value: false, view: '30210'},
    {desc: 'Finance Accountant', value: false, view: '30200'},
    {desc: 'Guidance Counselor', value: false, view: '30220'},
    {desc: 'Investigator', value: false, view: '00000'},
    {desc: 'Miscellaneous Person', value: false, view: '30000'},
    {desc: 'Ombudsperson', value: false, view: '20170'},
    {desc: 'Other', value: false, view: '00000'},
    {desc: 'Principal', value: false, view: '	30130'},
    {desc: 'Program Advisor', value: false, view: '30180'},
    {desc: 'Program Specialist', value: false, view: '30160'},
    {desc: 'Professor', value: false, view: '00000'},
    {desc: 'Provost', value: false, view: '30080'},
    {desc: 'Regional Director', value: false, view: '30150'},
    {desc: 'Reporting Option', value: false, view: '20340'},
    {desc: 'Security Gaurd', value: false, view: '30250'},
    {desc: 'School Nurse', value: false, view: '30230'},
    {desc: 'Social Worker', value: false, view: '30240'},
    {desc: 'Staff', value: false, view: '00000'},
    {desc: 'Student Assistance Counselor', value: false, view: '30260'},
    {desc: 'Title IX Coordinator', value: false, view: '30110'},
    {desc: 'Title IX Investigator', value: false, view: '30120'},
];


export const CardResourceCodes = [
    {desc: 'Locator', value: false, view: '4000'},
    {desc: 'CSA form', value: false, view: '7000'},
    {desc: 'Capptivation Reach Out Report Form', value: false, view: '7250'},
    {desc: 'Anonymous Form', value: false, view: '8000'},
    {desc: 'Climate Survery Tool', value: false, view: '9000'},
    {desc: 'Abroad', value: false, view: '10000'},
    {desc: 'Annual Security Report', value: false, view: '10030'},
    {desc: 'Crime Stats', value: false, view: '10040'},
    {desc: 'Anonymous Reporting', value: false, view: '10050'},
    {desc: 'Bystander Intervention', value: false, view: '10070'},
    {desc: 'Climate Survey', value: false, view: '10080'},
    {desc: 'Sexual Misconduct Report Form', value: false, view: '10170'},
    {desc: 'Silent Witness Program', value: false, view: '10180'},
    {desc: 'Mental Health Screening', value: false, view: '10400'},
    {desc: 'Miscellaneous', value: false, view: '10580'},
    {desc: 'Sexual Assault Forensic Exam', value: false, view: '10600'},
    {desc: 'It’s On Blue Pledge!', value: false, view: '10610'},
    {desc: 'Definitions', value: false, view: '10620'},
    {desc: 'Sexual Assault', value: false, view: '10630'},
    {desc: 'Reaction to Sexual Assault', value: false, view: '10640'},
    {desc: 'Male Survivors', value: false, view: '10650'},
    {desc: 'Shuttle Schedules', value: false, view: '10700'},
    {desc: 'Healthy Relationship Information', value: false, view: '10740'},
    {desc: 'Sex Offender Registry', value: false, view: '10750'},
    {desc: 'Title IX Resolution Agreement', value: false, view: '10760'},
    {desc: 'Accused Info', value: false, view: '10790'},
    {desc: 'Emergency Plan', value: false, view: '10800'},
    {desc: 'Title IX Institution Investigation', value: false, view: '10820'},
];

export const LinkResourceCodes = [
    {desc: 'Campus Lactation Location', value: false, view: '20510'},
    {desc: 'Child Care', value: false, view: '20520'},
    {desc: 'Clubs', value: false, view: '10260'},
    {desc: 'Date Rape Drugs', value: false, view: '10350'},
    {desc: 'Dating Violence', value: false, view: '10340'},
    {desc: 'Disciplinary Process FAQ', value: false, view: '10540'},
    {desc: 'Education', value: false, view: '10625'},
    {desc: 'FAQ Clery Act', value: false, view: '10480'},
    {desc: 'FAQ Dating Violence', value: false, view: '10550'},
    {desc: 'FAQ for Survivors', value: false, view: '10450'},
    {desc: 'FAQ for Friends', value: false, view: '10460'},
    {desc: 'FAQ Harassment Policy', value: false, view: '10440'},
    {desc: 'FAQ Medical Care', value: false, view: '10430'},
    {desc: 'FAQ Police Options', value: false, view: '10520'},
    {desc: 'FAQ Reporting', value: false, view: '10490'},
    {desc: 'FAQ Sexual Health', value: false, view: '10510'},
    {desc: 'FAQ Sexual Misconduct', value: false, view: '10420'},
    {desc: 'FAQ Sexual Misconduct Policy', value: false, view: '10560'},
    {desc: 'FAQ Warning Signs', value: false, view: '10500'},
    {desc: 'Get Help', value: false, view: '10300'},
    {desc: 'Help a Friend', value: false, view: '10310'},
    {desc: 'How to Report', value: false, view: '10370'},
    {desc: 'My Story', value: false, view: '3100'},
    {desc: 'Myths', value: false, view: '10360'},
    {desc: 'News', value: false, view: '3300'},
    {desc: 'Portal', value: false, view: '3000'},
    {desc: 'Resources', value: false, view: '10590'},
    {desc: 'Risk Reduction', value: false, view: '10320'},
    {desc: 'Sexual Harassment FAQ', value: false, view: '10530'},
    {desc: 'Stalking', value: false, view: '10330'},
    {desc: 'Stalking FAQ', value: false, view: '10470'},
    {desc: 'Title IX FAQ', value: false, view: '10410'},
    {desc: 'Title IX Website', value: false, view: '10210'}];


export const States = [
    {value: 'AL'},
    {value: 'AK'},
    {value: 'AZ'},
    {value: 'AR'},
    {value: 'CA'},
    {value: 'CO'},
    {value: 'CT'},
    {value: 'DE'},
    {value: 'FL'},
    {value: 'GA'},
    {value: 'HI'},
    {value: 'ID'},
    {value: 'IL'},
    {value: 'IN'},
    {value: 'IA'},
    {value: 'KS'},
    {value: 'KY'},
    {value: 'LA'},
    {value: 'ME'},
    {value: 'MD'},
    {value: 'MA'},
    {value: 'MI'},
    {value: 'MN'},
    {value: 'MS'},
    {value: 'MO'},
    {value: 'MT'},
    {value: 'NE'},
    {value: 'NV'},
    {value: 'NH'},
    {value: 'NJ'},
    {value: 'NM'},
    {value: 'NY'},
    {value: 'NC'},
    {value: 'ND'},
    {value: 'OH'},
    {value: 'OK'},
    {value: 'OR'},
    {value: 'PA'},
    {value: 'RI'},
    {value: 'SC'},
    {value: 'SD'},
    {value: 'TN'},
    {value: 'TX'},
    {value: 'UT'},
    {value: 'VT'},
    {value: 'VA'},
    {value: 'WA'},
    {value: 'WV'},
    {value: 'WI'},
    {value: 'WY'},
    {value: 'GU'},
    {value: 'PR'},
    {value: 'VI'},
];

export const ColTypes = [
    {value: 'Public, 2-year'},
    {value: 'Public, 4-year or above'},
    {value: 'Private nonprofit, 2-year'},
    {value: 'Private nonprofit, 4-year or above'},
    {value: 'Private for-profit, 2-year'},
    {value: 'Private for-profit, 4-year or above'}
];

export const ComOrgAttribs = [
    {val: false, name: 'Profile'},
    {val: false, name: 'Location'},
    {val: false, name: 'Comtact'},
    {val: false, name: 'Links'},
    {val: false, name: 'Graphics'},
];

export const OrgAttribs = [
    {val: false, name: 'Nickname'},
    {val: false, name: 'Address'},
    {val: false, name: 'Hours'},
    {val: false, name: 'Phone number(s)'},
    {val: false, name: 'Description'},
    {val: false, name: 'Contact'},
    {val: false, name: 'Url'},
    {val: false, name: 'Facebook'},
    {val: false, name: 'Twitter'},
    {val: false, name: 'Instagram'},
    {val: false, name: 'Linkedin'},
    {val: false, name: 'Google+'},
    {val: false, name: 'Pinterest'},
    {val: false, name: 'Snapchat'},
    {val: false, name: 'Graphics'},
    {val: false, name: 'Wikilink'},
    {val: false, name: 'Type'}
];


export const OfficeAttribs = [
    {val: false, name: 'Address'},
    {val: false, name: 'Description'},
    {val: false, name: 'Hours'},
    {val: false, name: 'Contact'},
    {val: false, name: 'Email'},
    {val: false, name: 'Phone number(s)'},
    {val: false, name: 'Link'},
    {val: false, name: 'Tags'},
    {val: false, name: 'Emojis'},
    {val: false, name: 'Services'},
];

export const PeopleAttribs = [
    {val: false, name: 'Address'},
    {val: false, name: 'Description'},
    {val: false, name: 'Hours'},
    {val: false, name: 'Email'},
    {val: false, name: 'Phone number(s)'},
    {val: false, name: 'Link'},
    {val: false, name: 'Tags'},
    {val: false, name: 'Services'},
    {val: false, name: 'Long/Lat'},
];

// todo: this should be available in multiple languages
export const ReportSectionStyles = [
    'Expansion',
    'Header',
    'No Decoration',
    'Shell'
];

// todo: this should be available in multiple languages
export const ReportFieldTypes = [
    'Pick List',
    'Checkboxes',
    'Checkbox',
    'Input',
    'Textarea',
    'Date',
    'Filer Contact',
    'Individual',
    'Exhibits',
    'Note'
];

export const ReportFieldCodes = [
    'None',
    'Date and Time of Incident',
    'Date Report Filed',
    'Description of Incident',
    'Incident Details',
    'Location',
    'Specific Location',
    'Person Filing Report',
    'Previously Reported',
    'Involved Party',
    'Note'
];

export const PATTERN = `/^[a-zA-Z0-9_ ]{6,20}$/`;


export const TypeOfEntry = [
    'Incident Report',
    'Note',
    'File'
];

export const MenuStyles = [
    'Basic',
    'Background Block',
    'Big Block'
];

// connections are institutions that use the data from the One Database
export const ComRelationships = [
    'Connections',
    'Employees/Volunteers',
    'Followers',
    'Friends',
    'Partners',
    'To be approved'
];

