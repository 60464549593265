import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  GooglePhoto,
  InterfaceService,
  Key, LangStringBlock, MapItem,
  PlaceLoc,
  Topix,
  Wish,
  AssignThread,
  Thread, PlaceObj, TouristBoard,
} from '../services/interface.service';
import {CappAd, CappElement, InviteService} from '../services/invite.service';
import {computeDistanceBetween} from 'spherical-geometry-js';
import {AngularFireDatabase} from '@angular/fire/database';
import {AdminService} from '../services/admin.service';
import * as _ from 'lodash';
import {RoehttpService} from '../services/roehttp.service';
import {environment} from '../../environments/environment';
import * as algoliasearch from 'algoliasearch';
import {ImageCroppedEvent} from '../services/imagecropper/interfaces';
import html2canvas from 'html2canvas';
import {AngularFireStorage} from '@angular/fire/storage';
import * as geohash from 'ngeohash';
import {FirebaseService} from '../services/firebase.service';
import * as XLSX from 'xlsx';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {DomSanitizer} from '@angular/platform-browser';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  AutoDelete,
  Classifications,
  NogoSeasonMatch,
  NogoTravelOtherMatch,
  PlacesEatDrinkShop,
  PlacesEatDrinkShopMatch,
  PlacesStay,
  PlacesStayMatch,
  Season,
  SeasonMatch,
  ThingsSeeDo,
  ThingsSeeDoMatch,
  TravelOther,
  TravelOtherMatch,
  TravelTips,
  TravelTipsMatch,
  WhoFor,
  WhoForMatch,
  Meal,
  Drinks,
  Cuisine,
  Spreads,
  Sweets,
  Bakery,
  Diet,
  Everyday,
  Course,
  Gear,
  Flavor,
  Method,
  Ingredient,
  MealMatch,
  MethodMatch,
  DrinksMatch,
  CuisineMatch,
  SpreadsMatch,
  SweetsMatch,
  BakeryMatch,
  DietMatch,
  EverydayMatch,
  CourseMatch,
  GearMatch,
  FlavorMatch,
  IngredientMatch
} from '../services/constants';
import {BehaviorSubject} from 'rxjs/index';
import {BoundingBoxService} from '../services/bounding-box.service';
import {Observable} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
import {BlogGuideComponent} from '../blog-guide/blog-guide.component';
import {ViatorService} from '../services/viator.service';

import RBush from 'rbush';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AgmMap, LatLng, MapTypeStyle, MarkerLabel} from '@agm/core';
import {GeoArticlesService} from '../services/geo-articles.service';
import {TouristBoardIncludeService} from '../services/tourist-board-include.service';
import {IdSelectionDialogComponent} from '../id-selection-dialog/id-selection-dialog.component';
import {MatDialog} from '@angular/material';
import {take} from 'rxjs/operators';

const md5 = require('md5');

declare var google: any;


export const bottomanim = [
  query(':enter, :leave', style({position: 'absolute', height: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateY(-100%)'}), animate('.3s ease-out', style({transform: 'translateY(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateY(0%)'}), animate('.3s ease-out', style({transform: 'translateY(100%)'}))], {
      optional: true,
    }),
  ]),
];

export const topanim = [
  query(':enter, :leave', style({position: 'absolute', height: '100%'}), {optional: true}),
  group([
    query(':enter', [style({transform: 'translateY(100%)'}), animate('.3s ease-out', style({transform: 'translateY(0%)'}))], {
      optional: true,
    }),
    query(':leave', [style({transform: 'translateY(0%)'}), animate('.3s ease-out', style({transform: 'translateY(-100%)'}))], {
      optional: true,
    }),
  ]),
];


const autoComleteFields = [
  'address_components',
  'formatted_address',
  'name',
  'place_id',
  'types',
  'geometry',
  'utc_offset_minutes',
  'photos',
  'website'
];


export class Winner {
  name: string; // Hilton Maui
  level: string; // 1st
  ukey: string; // winners ukey|data-pid
}

export class DesignationCategory {
  category: string; // Best Hotel In Cancun
  description: LangStringBlock;
  awardurl: string;
  winners: Winner[];

  constructor() {
    this.description = new LangStringBlock();
    this.winners = [];
  }
}

export class DesignationYear {
  period: string; // e.g. 2022
  icon: string; //
  categories: DesignationCategory[];

  constructor() {
    this.categories = [];
  }
}

export class Designation {
  name: string; // World’s Best
  description: LangStringBlock;
  icon: string;
  urltohomepage: string;
  type: string; // eg Award, Badge
  designationukey: string; // made up by me, eg. travelandleisureworldsbest
  designationyears: DesignationYear[];

  constructor() {
    this.description = new LangStringBlock();
    this.designationyears = [];
  }
}

export class Designator {
  name: string; //Travel and Leisure
  domain: string; // travelandleisure.com
  ukey: string; //travelandleisure
  designations: Designation[];
}

@Component({
  selector: 'app-invite-create',
  templateUrl: './invite-create.component.html',
  styleUrls: ['./invite-create.component.scss'],
  animations: [
    trigger('animImageSlider', [
      transition(':increment', topanim),
      transition(':decrement', bottomanim),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(50%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(50%)'}))
      ])
    ]),
    trigger('slideInLeft', [
      transition(':enter', [
        style({transform: 'translateX(-50%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-50%)'}))
      ])
    ])
  ]
})


export class InviteCreateComponent implements OnInit, AfterViewInit {
  tb: TouristBoard;
  oldtb: TouristBoard;
  tbukey: string;
  tukey: string;
  turl: string;
  timer;
  insttype: string;
  saveid: boolean;
  isinstid: boolean;
  bounds;
  showmap = false;
  redmarker = '/assets/red-dot.png';
  bluemarker = '/assets/blue-dot.png';
  greenmarker = '/assets/green-dot.png';

  foodblog = false;
  otherfeed = false;
  boozefeed = false;
  otherfeedonly = false;

  addcustom: string;
  url: string;
  exists = false;
  err: string;
  instloc: string;
  designators: Designator[];
  curdesignator: Designator;
  curdesignation: Designation;
  curyear: DesignationYear;
  curcat: DesignationCategory;
  cappidfound = false;
  hash: string;
  vexdays: number;
  photocredit: string;
  photowho: string;
  getID: string;
  photocaption: string;
  adddesignation = false;
  adddesignator = false;
  editdesignator = false;
  adddesignationyear = false;
  designationyear = false;
  winner = false;
  category = false;
  addcat = false;
  addwinner = false;
  curcati: number;
  showarticles = false;
  src: string;
  articles = [];
  wordpress = false;
  iswordpress = false;
  wordpresstheme = '';
  wordpressplugins = '';

  clipped: PlaceObj;
  notindatabase = false;

  level = '';
  datapid = '';
  datapidname = '';
  count = 0;
  checktype = '';
  alt = '';

  cruisespos: number;
  pointspos: number;
  hikingpos: number;
  disneypos: number;
  gearpos: number;
  wellnesspos: number;
  resortspos: number;
  familypos: number;

  capplat: number;
  capplng: number;
  cappID: string;
  cappaddress: string;
  cappname: string;
  capplocation: string;
  cappphoto: string;
  cappoffset: number;

  geoappify;

  country = 80;
  countryname: string;
  attractions: any[];

  savelocation: string;
  savepoiukey: string;
  newhit: any;
  tmpyelpkey: any;
  tmpyelpphoto: any;

  curwish: Wish;
  yelpexists: any;

  invites: CappAd[];
  curad: CappAd;
  detailsad = false;
  guids = 'guides';
  detailsfront = false;
  spinning = false;
  spinningfour = false;
  detailsback = false;
  showiframe = true;
  curblog;
  curelement: CappElement;
  curlang = 'en';
  tmpright = 0;
  blogtgs: string[];
  start: number;
  end: number;
  rgn: string;
  fullname: string;
  aerialview: any;

  startmove = false;
  updatemktlogo: boolean;
  updatecardimg: boolean;
  updateguideimg: boolean;
  addpoi = false;
  poi: string;
  inframe: boolean;
  canquick: boolean;
  arr = [];

  tbarr: any;
  wind: any;
  tmarr: any;

  counter = 0;
  guidedetails = false;
  pubdate: Date;
  xdate: Date;
  ipubdate: Date;
  ixdate: Date;
  curguide: Wish;
  newcurguide: Wish;
  guides: Wish[];
  fulllist: Wish[];
  altnames = '';
  alreadyoncalendar = false;

  hitpos = -1;
  hit;

  scale = 100;
  ic = false;
  color: string;
  backcolor: string;

  vendortemp: string;
  vendors: any[];
  gearvendors: any[];
  catvendors: any[];
  gearcatvendors: any[];
  vendorcat: string;
  vendor: any;
  vendorcats: string[];

  vendorgearcats: string[];
  vendorgearcat: string;

  opacity = '90%';
  curtopix: Topix;
  topixname: string;
  blogs: Topix[];

  tmpimg;
  cardimg;
  guideimg;
  imgx: number;
  imgy: number;
  needsverify = false;

  lat = 41.1855646;
  long = -73.760768;

// Corrected style array
  mystyle: MapTypeStyle[] = [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'hue': '#ff4400'
        },
        {
          'saturation': -100
        },
        {
          'lightness': -4
        },
        {
          'gamma': 0.72
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          'hue': '#ff4400'
        },
        {
          'saturation': -100
        },
        {
          'lightness': -4
        },
        {
          'gamma': 0.72
        }
      ]
    },
    {
      'featureType': 'landscape.man_made',
      'elementType': 'geometry',
      'stylers': [
        {
          'hue': '#0077ff'
        },
        {
          'gamma': 3.1
        }
      ]
    },
    {
      'featureType': 'water',
      'stylers': [
        {
          'hue': '#000000'
        },
        {
          'gamma': 0.44
        },
        {
          'saturation': -33
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'stylers': [
        {
          'hue': '#44ff00'
        },
        {
          'saturation': -23
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'hue': '#007fff'
        },
        {
          'gamma': 0.77
        },
        {
          'saturation': 65
        },
        {
          'lightness': 99
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'gamma': 0.11
        },
        {
          'weight': 5.6
        },
        {
          'saturation': 99
        },
        {
          'hue': '#0091ff'
        },
        {
          'lightness': -86
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'lightness': -48
        },
        {
          'hue': '#ff5e00'
        },
        {
          'gamma': 1.2
        },
        {
          'saturation': -23
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'saturation': -64
        },
        {
          'hue': '#ff9100'
        },
        {
          'lightness': 16
        },
        {
          'gamma': 0.47
        },
        {
          'weight': 2.7
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#222222'
        }
      ]
    },
  ];


  options = {
    key: 'wFE7nB4D3F4C3B10B6eyznxmE2cd1rsplpfG-7c1eeF6nablA3C11B8A4D2B4F3G2F3B2==',
    charCounterCount: true,
    language: this.curlang,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'color', 'align', 'formatOL', 'formatUL',
      'outdent', 'indent', 'spellChecker', 'html']
  };

  configSummernoteOptions = {
    placeholder: '',
    tabsize: 2,
    dialogsInBody: true,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['font', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      ['link']
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };

  instycats = [
    'Activity',
    'A Normal Day',
    'Autumn',
    'Beach',
    'Bird\'s Eye View',
    'Boardwalk',
    'Bridge',
    'Cave',
    'Cemetery',
    'Christmas Market',
    'Coastline',
    'Color',
    'Craft',
    'Crowds',
    'Dark Sky',
    'Desert',
    'Dog',
    'Door',
    'Family',
    'Ferris Wheel',
    'Festival',
    'Fireworks',
    'Field',
    'Flowers',
    'Food',
    'Fountain',
    'Full Moon',
    'Glacier',
    'Hiking',
    'Historical Building',
    'Holiday',
    'Hot Air Balloon',
    'Ice Cream',
    'Lake',
    'Lighthouse',
    'Local Culture',
    'Marina',
    'Monument',
    'Mountains',
    'Mountains and Lake',
    'Museum',
    'Nightlife',
    'Official Residence',
    'Old Town',
    'On the Water',
    'Open Road',
    'Out With Friends',
    'Outdoor Dining',
    'Outdoor Performance',
    'Parade',
    'Park',
    'Poster',
    'Public Square',
    'Quiet Street',
    'Rain',
    'Rainbow',
    'Restaurant',
    'Resort',
    'River',
    'Roman Site',
    'Ruins',
    'Scenic Spot',
    'Skyline',
    'Snow',
    'Spring',
    'Street Art',
    'Sunset',
    'Sunrise',
    'Swimming',
    'Tourist Attraction',
    'Tower',
    'Tree',
    'Two Chairs',
    'Umbrellas',
    'UNESCO',
    'Waterfall',
    'Wildlife',
    'Woman\'s Back',
    'Place of Worship',
  ];

  tags: string[];
  addtag = false;
  addvendor = false;
  addgearvendor = false;
  addingproduct = false;
  curvendor: any;
  curgearvendor: any;
  curproduct: any;
  curphoto: any;
  tg: string;

  yarr: any;
  yelp: any;
  foursquare: any;

  comment: string;
  placeID: string;
  moveball = false;
  timetosave = false;
  googlephotos: GooglePhoto[];
  wikiurl: string;
  aerial: any;
  localwikiurl: string;
  googleurl: string;
  briturl: string;
  weburl: string;
  touristurl: string;
  curevent: Wish;
  viator;
  isPOI: boolean;
  wiki: any[];
  chain: any[];
  tempdesc: string;
  chainstring: string;
  touristchain: any[];
  touristchainstring: string;
  newloc: PlaceLoc;

  searchConfig = {
    searchClient: algoliasearch(environment.algolia.appId, environment.algolia.apiKey),
    indexName: 'PlacesFirst',
    routing: true,
  };


  temp: any;
  ukeypick: string;
  haveloc: string;
  quickp = true;

  croppedImage: any = '';
  imageChangedEvent: any = '';
  size = 96;
  inputheight = 96;
  wd = '100%';
  sd = 100;
  cansave = false;
  includein = false;
  aboutit = false;
  countryrequired: string;
  img;
  length = 0;

  listlen: number;
  muse: any[];
  imageseditted = false;

  googletype = 'establishment';
  autoCompleteOptions = {
    fields: ['place_id'],
  };
  // IMPORTANT! add back when not doing a feed
  // types: ['establishment'],

  feed: string;
  cutout: string;
  feedarr: Wish[];

  eventcomplete;
  mentioncomplete;
  loccomplete;
  addresscomplete;
  mentionquickcomplete;
  locquickcomplete;
  poicomplete;
  location = '';
  adding: string;
  addingmention: string;
  addinggear: string;
  addingvendor: string;


  route: any;
  routes: any[];
  catroutes: any[];

  //
  artist: any;
  poiartist: any;
  poiartistnote: string;
  poiroute: any;
  poiroutenote: string;
  jumpto = '';
  nameartwork: string;

  artists: any[];

  addingthread = false;
  curthread: Thread;
  curassignthread: AssignThread;
  threads: any[];

  max = 0;
  typearr = [];
  startkey = 'ChIJWV7VgFF-ZIgRCyTSmce-qpc';


  addingartist: string;
  addingroute: string;
  addingfestival: string;

  addartist = false;
  addroute = false;
  addfestival = false;

  curartist: any;
  curroute: any;

  festival: any;
  festivals: any[];
  curfestival: any;
  festivalyear: number;
  festivalstart: number;
  festivalend: number;
  festivaltimeoffsethours = 0;
  artistcat: string;
  routecat: string;
  festivalcat: string;

  artistcats: string[];
  routecats: string[];
  festivalcats: string[];

  catartists: any[];
  catfestivals: any[];
  //

  addingevent: string;
  addingtrip = false;
  domedlink;
  doguide = false;
  urlz = 'backheight';
  cntx = -1;
  geocoder;
  cnt: number;

  items: Wish[];

  switchedit = 'TempInfo/guidescachetmp' // 'Travelblogs'; // 'Travelblogs'; // 'Travelmags';
  listpub: string[];

  Seasonarr: any[];
  seasons: string;
  PlacesStayarr: any[];
  places: string;
  TravelTipsarr: any[];
  tips: string;
  TravelOtherarr: any[];
  other: string;
  WhoForarr: any[];
  who: string;
  PlacesEatDrinkShoparr: any[];
  eat: string;
  ThingsSeeDoarr: any[];
  things: string;

  Ingredientarr: any[];
  ingredient: string[];

  Mealarr: any[];
  meal: string;
  Methodarr: any[];
  method: string;
  Drinksarr: any[];
  drink: string;
  Flavorarr: any[];
  flavor: string;
  Spreadsarr: any[];
  spreads: string;
  Cuisinearr: any[];
  cuisine: string;
  Dietarr: any[];
  diet: string;
  Everydayarr: any[];
  everyday: string;
  Coursearr: any[];
  course: string;
  Bakeryarr: any[];
  bakery: string;
  Sweetsarr: any[];
  sweets: string;
  Geararr: any[];
  gear: string;

  mag: string;
  address;

  trips: any[];

  rssboards: any[];
  rssitems: any[];
  board: any;
  okgetitems: boolean;
  boardfeed: string;
  feednote: string;

  // used to creaete new topics

  ukey: string;
  videourl;


  locwikiarr = [];
  curwiki: any;
  curi: number;
  wikilink;


  _cur = new BehaviorSubject<string>(null);
  cur$ = this._cur.asObservable();

  _crawl = new BehaviorSubject<string>(null);
  crawl$ = this._crawl.asObservable();

  el: ElementRef;

  TypeThreads = ['Biography', 'Designed By', 'Miscellaneous', 'Works Of', 'Food Shop', 'Market', 'Store', 'Theme'];

  classifications = Classifications;

  kingdomvar: string;
  phylumvar: string;
  classvar: string;
  kingdomarr: string[];
  phylumarr: string[];
  classarr: string[];

  numbersArticles: number;
  timeArticles: string;
  blogqueue: number;
  magazinequeue: number;
  vlogqueue: number;
  podcastqueue: number;


  @ViewChild('itag', {static: false}) private itag: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('cardcanvas', {static: false}) cardcanvas: ElementRef;

  @ViewChildren('addresstext', {read: ElementRef}) addresslist: QueryList<ElementRef>;


  @ViewChild('eventtext', {static: false}) eventtext: any;
  @ViewChild('poitext', {static: false}) poitext: any;
  @ViewChild('guidecanvas', {static: false}) guidecanvas: ElementRef;
  @ViewChild('serviceHelper', {static: false}) serviceHelper;
  @ViewChild('loctext', {static: false}) loctext: any;
  @ViewChild('mentiontext', {static: false}) mentiontext: any;
  @ViewChild('loctextquick', {static: false}) loctextquick: any;
  @ViewChild('mentiontextquick', {static: false}) mentiontextquick: any;

  @ViewChild(AgmMap, {static: false}) mapElement: any;

  @ViewChild('blog', {static: false}) imager: BlogGuideComponent;

  constructor(private invs: InviteService,
              private touristBoardService: TouristBoardIncludeService,
              private gs: GeoArticlesService,
              public admin: AdminService,
              private ts: TitleCasePipe,
              private http: RoehttpService,
              private httpx: HttpClient,
              private dialog: MatDialog,
              private afStorage: AngularFireStorage,
              private dom: DomSanitizer,
              private fs: FirebaseService,
              private cd: ChangeDetectorRef,
              private db: AngularFireDatabase,
              private vs: ViatorService,
              private bbs: BoundingBoxService,
              private is: InterfaceService) {
    this.showiframe = (/true/i).test(localStorage.getItem('showiframe'));
  }


  onPull() {
    this.localwikiurl = this.curwiki[1].link;
  }

  onLocalWiki(item, i) {
    this.curi = i;
    this.curwiki = item;
    this.location = this.curwiki[1].name;
    this.localwikiurl = this.curwiki[1].link;
    this.adding = 'Google';
    this.wikilink = this.dom.bypassSecurityTrustResourceUrl(this.curwiki[1]['link']);
  }

  onLoad(event) {
    console.log('iframe loaded', event);
  }

  onDeleteLocalWiki() {
    if (this.curi > -1) {
      this.locwikiarr.splice(this.curi, 1);
      this.curi = -1;
    }
    this.curwiki = null;
  }


  onDelLoc(i) {
    this.locwikiarr.splice(this.curi, 1);
  }


  modeChanged(event) {
    console.log('model ', event);
  }

  onPubChange(cf?: string) {
    //
    let cfx;
    if (cf) {
      cfx = cf;
    } else {
      cfx = this.curguide.cf;
    }
    this.is.getList(`Institutions/${cfx}/published`)
      .take(1)
      .subscribe(items => {
        this.items = items;
      });
  }

  onShowIframe() {
    this.showiframe = !this.showiframe;
    localStorage.setItem('showiframe', this.showiframe.toString());

  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const arr = Object.keys(json);
    arr.forEach(a => {
      const barr = Object.keys(json[a]);
      barr.forEach(b => {
        if (typeof json[a][b] === 'object') {
          json[a][b] = json[a][b].join(',');
        }
      });
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, 'adata.xls');
  }


  onQuickPublish(g: Wish, i: number) {

    this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${g.ukey}`);
    if (this.guids === 'guids') {
      g.ukey = g.date.toString(10);
    }
    this.is.setObjNoLog(`Topics/TravelNotifications/${g.ukey}`, g);
    this.is.setObjNoLog(`Topics/QuickPublications/${g.ukey}`, g.ukey);
    this.is.setObjNoLog(`Institutions/${g.cf}/published/${g.ukey}`, g);

    this.guidedetails = false;
    this.fulllist.splice(i, 1);


  }

  onGetCurTopix() {
    /*
        if (this.topixname && this.topixname.length) {
          this.is.getObject(`Institutions/${this.topixname}`).take(1).subscribe(topix => {
            this.curtopix = topix;
          });
        }
    */

    this.is.getFirst(`Topics/TempInfo/tempbl    ogs`, 1).take(1).subscribe(blog => {
      this.curtopix = blog[0];
      console.log('curtopix', this.curtopix);
      this.updatemktlogo = true;
      this.updatecardimg = true;

    });
  }

  onDelBlog() {
    if (this.curtopix && this.curtopix.ukey && this.curtopix.ukey.length) {
      this.is.deleteObjNoLog(`Topics/TempInfo/tempblogs/${this.curtopix.ukey}`);
    }
    this.onGetCurTopix();
  }

  onOpenFeed(url) {
    window.open(url, '_blank');
  }

  processDescription(l) {
    if (l.fullmsg && l.fullmsg.en.description) {
      let postarr = l.fullmsg.en.description.split('The post ');
      if (postarr && postarr.length > 1) {
        l.fullmsg.en.description = postarr[0];
      }

      postarr = l.fullmsg.en.description.split('This post ');
      if (postarr && postarr.length > 1) {
        l.fullmsg.en.description = postarr[0];
      }

      l.fullmsg.en.description = l.fullmsg.en.description.replace(l.cn, '').trim();
      if (l.ssoc.en.description && !l.ssoc.en.description.includes('(')) {

        l.ssoc.en.description = l.ssoc.en.description.replace('[', '');
        const loc = l.fullmsg.en.description.search(l.ssoc.en.description);
        if (loc === 0) {
          l.fullmsg.en.description = l.fullmsg.en.description.replace(l.ssoc.en.description, '');
        }
      }

      let arr = l.fullmsg.en.description.split('[&#8230;]');
      if (arr && arr.length > 1) {
        if (arr[0][arr[0].length - 1] === '&#8230;') {
          l.fullmsg.en.description = arr[0];
        } else {
          l.fullmsg.en.description = arr[0] + '&#8230;';
        }
      } else {
        arr = l.fullmsg.en.description.split('&#8230;');
        if (arr && arr.length > 1 && arr[1]) {
          if (arr[0][arr[0].length - 1] === '&#8230;') {
            l.fullmsg.en.description = arr[0];
          } else {
            l.fullmsg.en.description = arr[0] + '&#8230;';
          }
        } else {
          arr = l.fullmsg.en.description.split('[...]');
          if (arr && arr.length > 1 && arr[1]) {
            if (arr[0][arr[0].length - 1] === '&#8230;') {
              l.fullmsg.en.description = arr[0];
            } else {
              l.fullmsg.en.description = arr[0] + '&#8230;';
            }
          } else {
            arr = l.fullmsg.en.description.split('...');
            if (arr && arr.length > 1 && arr[1]) {
              if (arr[0][arr[0].length - 1] === '&#8230;') {
                l.fullmsg.en.description = arr[0];
              } else {
                l.fullmsg.en.description = arr[0] + '&#8230;';
              }
            }
          }
        }
      }

      if (l.fullmsg.en.description && l.fullmsg.en.description.length > 225) {
        l.fullmsg.en.description = l.fullmsg.en.description.slice(0, 224);
        const x = l.fullmsg.en.description.lastIndexOf(' ');
        if (x > 0) {
          l.fullmsg.en.description = l.fullmsg.en.description.slice(0, x);
          if (l.fullmsg.en.description[l.fullmsg.en.description.length - 1] !== '&#8230;') {
            l.fullmsg.en.description = l.fullmsg.en.description + '&#8230;';
          }
        }
      }

    }
  }

  onSwitchEdit() {
    this.addcustom = '';
    if (this.switchedit === 'addvideo') {
      this.addcustom = 'addvideo';
    } else if (this.switchedit === 'addarticle') {
      this.url = '';
      this.addcustom = 'addarticle';
    } else {
      this.guids = 'guides';
      if (this.switchedit === 'Travelmags/guids') {
        this.switchedit = 'Travelmags';
        this.guids = 'guids';
      }

      if (this.switchedit === 'Travelvlogs' || this.switchedit === 'Boardvlogs') {
        this.is.getFirst(`Topics/Tmp${this.switchedit}/guides`, 50).take(1).subscribe(list => {
          if (list) {
            this.fulllist = [];
            list.forEach(l => {

              if (!l.image) {
                l.image = `https://i.ytimg.com/vi/${l.ukey}/hqdefault.jpg`;
              }


              const guide = new Wish('', null);

              if (this.switchedit.includes('Food')) {
                guide.app = 'Food';
              } else {
                guide.app = 'Adventures';
              }


              guide.as = true;
              guide.cf = l.cf;
              guide.cn = l.name;
              guide.curlang = 'en';
              guide.ssoc.en.description = l.title;
              guide.ukey = l.date.toString();
              if (l.trip) {
                guide.trip = l.trip;
              }
              guide.dt = '';
              guide.flip = false;
              guide.e = l.image;
              guide.l = `https://www.youtube.com/${l.link}`;
              guide.g = '';
              guide.i = '';
              guide.icon = '';
              guide.m = {};
              guide.m.catonly = false;
              guide.m.droptitle = false;
              guide.m.sponsored = false;
              if (l.length) {
                guide.m.length = l.length;
              }
              guide.m.channel = l.input;
              guide.m.youtubeid = l.ukey;
              guide.topics = l.cf;
              guide.ukey = l.date.toString();
              guide.ty = 'vlog';
              guide.v = 'U';
              guide.vex = -1;
              guide.types = [];
              guide.types.push('Video');
              this.fulllist.push(guide);
            });

            if (this.fulllist && this.fulllist.length) {
              this.onEditGuide(this.fulllist[0]);
            }
          }
        });
      } else if (this.ukeypick && this.ukeypick.length) {
        this.is.getList(`Institutions/${this.ukeypick}/items`).take(1).subscribe(list => {
          if (list && list.length) {
            this.fulllist = [];
            list.forEach(l => {
              if (this.guids === 'guides') {
                this.is.getObject(`Topics/Tmp${this.switchedit}/${this.guids}/${l}`).take(1).subscribe(item => {
                  if (item) {
                    this.fulllist.push(item as Wish);
                    console.log('full list', this.fulllist);
                  }
                });
              } else {
                this.is.getQuery(`Topics/Tmp${this.switchedit}/${this.guids}`, 'date', l).take(1).subscribe(items => {
                  if (items) {
                    items.forEach(item => {
                      this.fulllist.push(item as Wish);
                    });
                  }
                });
              }


            });

            if (this.fulllist && this.fulllist.length && this.quickp) {
              this.onEditGuide(this.fulllist[0]);
            }
          }
        });
      } else if (this.switchedit === 'TempInfo/guidescachetmp') {
        this.is.getFirst(`Topics/${this.switchedit}`, 50).take(1).subscribe(list => {
          this.fulllist = list.filter(l => l.ukey);
          this.listlen = this.fulllist.length;
          /* todo: not sure if need this. hope NOT */
          this.fulllist.forEach(l => {
            if (l && (!l.e || !(l.fullmsg && l.fullmsg.en.description))) {
              this.http.getGuideImage(l.l).take(1).subscribe(res => {
                this.spinning = false;
                if (!l.e && res && res.image && res.image.length) {
                  l.e = res.image;
                }

                if (!(l.fullmsg && l.fullmsg.en.description && res && res.description && res.description.length)) {
                  l.fullmsg.en.description = res.description;
                  this.processDescription(l);
                }
              });
            }
          });

          this.listpub = this.fulllist.map(l => l.cf);
          if (this.fulllist && this.fulllist.length && this.quickp) {
            this.onEditGuide(this.fulllist[0]);
          }

        });
      } else {
        this.is.getFirst(`Topics/Tmp${this.switchedit}/${this.guids}`, 50).take(1).subscribe(list => {

          // this.is.getLast15(`Topics/Tmp${this.switchedit}/${this.guids}`).take(1).subscribe(list => {
          // this.is.getLast15(`Topics/Tmp${this.switchedit}/${this.guids}`).take(1).subscribe(list => {
          const badlist = list.filter(l => !l.hasOwnProperty('ukey'));
          if (badlist && badlist.length) {
            this.is.getFirstSnapN(`Topics/Tmp${this.switchedit}/${this.guids}`, 50).take(1).subscribe(lst => {

              const newlist = lst.filter(l => !l.val.hasOwnProperty('ukey'));
              if (newlist && newlist.length) {
                newlist.forEach(nl => {
                  this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${nl.ukey}`);
                });
              }

            });
          }
          this.fulllist = list.filter(l => l.ukey);
          this.listlen = this.fulllist.length;
          this.fulllist.forEach(l => {
            if (l && (!l.e || !(l.fullmsg && l.fullmsg.en.description))) {
              this.http.getGuideImage(l.l).take(1).subscribe(res => {
                this.spinning = false;
                if (!l.e && res && res.image && res.image.length) {
                  l.e = res.image;
                }

                if (!(l.fullmsg && l.fullmsg.en.description && res && res.description && res.description.length)) {
                  l.fullmsg.en.description = res.description;
                  this.processDescription(l);
                }
              });
            } else {
              // this.processDescription(l);
            }


          });

          if (this.guids === 'guids') {
            this.fulllist.forEach(f => {
              this.is.getQuery(`Institutions/${f.cf}/published`, 's', f.s).take(1).subscribe(items => {
                if (items && items.length) {
                  const x = this.fulllist.findIndex(l => l.ukey === f.ukey);
                  if (x > -1) {
                    this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${f.ukey}`);
                    this.fulllist.splice(x, 1);
                  }
                }
              });
            });


          }

          this.listpub = this.fulllist.map(l => l.cf);
          if (this.fulllist && this.fulllist.length && this.quickp) {
            this.onEditGuide(this.fulllist[0]);
          }
        });
      }
    }
  }


  onResetFeed() {
    this.curguide = new Wish('', null);
    this.zerom();

    this.is.deleteObjNoLog(`Topics/TempInfo/nextfeed`);
    this.feed = '';
    this.feedarr = [];
  }

  onCreateRSS(input: any) {
    const obj = {
      archived: false,
      date: 1433639675902,
      edition: 'Adventures',
      icon: '',
      org: 'TouristRSS',
      paid: false,
      public: true,
      servicearea: false,
      tags: '',
      type: 'touristboard',
    };

    obj['description'] = new LangStringBlock();
    obj['description'].en.block = false;
    obj['description'].en.description = '';


    obj[`domain`] = input['domain'];
    obj[`feed`] = input['feed'];
    obj[`name`] = input['name'];
    obj[`objectID`] = input['ukey'];
    obj[`ukey`] = input['ukey'];
    obj[`topic`] = input['ukey'];


    obj[`trips`] = [];

    this.is.getObject(`Places/${input['trip1']}`).take(1).subscribe(place => {
      if (place && place.location) {
        const trip1 = {
          hash: place.location.hash,
          lat: place.location.lat,
          long: place.location.long,
          name: place.name,
          objectID: place.ukey,
          propogate: true,
          jumpto: '',
          ukey: '',
        };
        if (place.moreinfo) {
          trip1['moreinfo'] = place.moreinfo;
        }

        if (place.viator) {
          trip1['viator'] = place.viator;
        } else {
          console.log('no viator', place.name, ': ', place.ukey);
        }
        obj[`trips`].push(trip1);

        if (input['trip2']) {

          this.is.getObject(`Places/${input['trip2']}`).take(1).subscribe(place2 => {
            if (place2 && place2.location) {
              const trip2 = {
                hash: place2.location.hash,
                lat: place2.location.lat,
                long: place2.location.long,
                name: place2.name,
                objectID: place2.ukey,
                propogate: true,
                jumpto: '',
                ukey: ''
              };
              if (place2.moreinfo) {
                trip2['moreinfo'] = place2.moreinfo;
              }

              if (place2.viator) {
                trip2['viator'] = place2.viator;
              } else {
                console.log('no viator 2', place2.name, ': ', place2.ukey);
              }

              obj[`trips`].push(trip2);
            }
            if (input['trip3']) {
              this.is.getObject(`Places/${input['trip3']}`).take(1).subscribe(place3 => {
                if (place3 && place3.location) {
                  const trip3 = {
                    hash: place3.location.hash,
                    lat: place3.location.lat,
                    long: place3.location.long,
                    name: place3.name,
                    objectID: place3.ukey,
                    propogate: true,
                    jumpto: '',
                    ukey: ''
                  };
                  if (place3.moreinfo) {
                    trip3['moreinfo'] = place3.moreinfo;
                  }

                  if (place3.viator) {
                    trip3['viator'] = place3.viator;
                  } else {
                    console.log('no viator 3', place3.name, ': ', place3.ukey);

                  }
                  obj[`trips`].push(trip3);
                }
                this.is.setObjNoLog(`Topics/RssNew/${input['ukey']}`, obj);

              });

            } else {
              // save it
              this.is.setObjNoLog(`Topics/RssNew/${input['ukey']}`, obj);
            }
          });


        } else {
          // save the damn thing
          this.is.setObjNoLog(`Topics/RssNew/${input['ukey']}`, obj);
        }
      } else {
        console.log('no place', input['ukey']);
      }
    });
  }

  onCutout() {
    this.guides.forEach(g => {
      g.ssoc['en'].description = g.ssoc['en'].description.replace(this.cutout, '');
    });
  }


  onDeleteItem(i) {
    console.log('delete item:', i);
  }

  onBoardSwitch() {

    this.cutout = '';

    /*

        this.boardfeed = this.board['Feed'];
        this.feednote = this.board['FeedNote'];
        if (!this.feednote) {
          this.feed = this.boardfeed;
        } else {
          this.feed = '';
        }
    */

    this.guides = Object.values(this.board.items);
  }

  onOpenGuide(guide) {
    // @ts-ignore
    window.open(guide.l as string, '_blank');
  }

  onSaveG(guide) {
    this.is.setObjNoLog(`Topics/RssNew/${this.board['ukey']}/items/${guide.date}`, guide);
    const x = this.guides.findIndex(i => i.date === guide.date);
    if (x > -1) {
      this.guides.splice(x, 1);
    }
    this.is.deleteObjNoLog(`Topics/TravelTouristRss/${this.board['ukey']}/items/${guide.date}`);
    if (this.guides.length === 0) {
      const y = this.rssboards.findIndex(b => b['ukey'] === this.board['ukey']);
      if (y > -1) {
        this.rssboards.splice(y, 1);
      }
    }
  }

  onDelG(guide) {
    // @ts-ignore
    const x = this.guides.findIndex(i => i.date === guide.date);
    if (x > -1) {
      this.guides.splice(x, 1);
    }
    this.is.deleteObjNoLog(`Topics/TravelTouristRss/${this.board['ukey']}/items/${guide.date}`);
    if (this.guides.length === 0) {
      const y = this.rssboards.findIndex(b => b['ukey'] === this.board['ukey']);
      if (y > -1) {
        this.rssboards.splice(y, 1);
      }
    }
  }


  onLoadRssLItems() {
    this.is.getList(`Topics/RssNew/${this.board['ukey']}/items`).take(1).subscribe(items => {
      this.rssitems = items;
      this.okgetitems = false;
    });

  }

  onGetFeeds() {
    this.http.getTouristFeed(this.board['Name'], this.board['ukey'], this.feed, '').then(rst => {
      this.boardfeed = '';
      this.feednote = '';
      const lastdate = new Date().getTime();
      const x = this.rssboards.findIndex(b => b['ukey'] === this.board['ukey']);
      if (x > -1) {
        this.rssboards.splice(x, 1);
      }
      this.is.setObjNoLog(`Topics/RssNew/${this.board['ukey']}/lastdate`, lastdate);

    });
  }

  zerom() {
    if (!this.curguide.m) {
      this.curguide.m = {};
    }

    if (this.curguide.m.capppick === undefined) {
      this.curguide.m.capppick = false;
    }

    if (this.curguide.m.poicard === undefined) {
      this.curguide.m.poicard = false;
    }

    if (this.curguide.m.greatphotos === undefined) {
      this.curguide.m.greatphotos = false;
    }

    if (this.curguide.m.sponsored === undefined) {
      this.curguide.m.sponsored = false;
    }

    if (this.curguide.m.propfeed === undefined) {
      this.curguide.m.propfeed = true;
    }

    if (this.curguide.m.propcats === undefined) {
      this.curguide.m.propcats = true;
    }

    if (this.curguide.m.proprepos === undefined) {
      this.curguide.m.proprepos = false;
    }

    if (this.curguide.m.proplocs === undefined) {
      this.curguide.m.proplocs = true;
    }

  }


  onMd5() {
    // Go through Places
    this.is.getFirst(`Places`, 100).subscribe(list => {
      list.forEach(place => {
        if (place && place.ukey) {
          let website;
          if (place && place.moreinfo && place.moreinfo.website) {
            website = place.moreinfo.website;
          } else if (place && place.website) {
            // If Places/website move it to moreinfo/website
            // this.is.setObjNoLog(`Places/${place.ukey}/moreinfo/website`, place.website);
            website = place.website;
          }
          if (website && place.photos && place.photos.length) {
            //  convert website url to an md5 hash
            const key = md5(website);
            //  create obj = {name, placeid, ukey: md5, photo, url
            const obj = {
              name: place.name,
              placeid: place.ukey,
              ukey: key,
              photo: place.photos[0].url,
              url: website
            };
          }
        }
      });
    });

    //   save to PlaceMd5/${md5}

  }

  onSwitchDesc() {
    this.curguide.ssoc.en.description = this.curguide['aidesc'];
  }

  onSetType(placeID, website, name, types) {
    if (website && website.length && name && name.length) {
      this.is.onPlaceMd5(placeID, website, name);
    }

    if (this.kingdomvar && this.kingdomvar.length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/kingdom`, this.kingdomvar);
    }

    if (types && types.length) {
      this.is.setObjNoLog(`Places/${placeID}/types`, types); // types
    }

    if (this.phylumvar && this.phylumvar.length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/phylum`, this.phylumvar);
    } else if (types && types.length) {
      types.forEach(y => {
        const x = this.classifications.findIndex(c => c.google === y);
        if (x > -1) {
          this.is.setObjNoLog(`Places/${placeID}/taxonomy/phylum`, this.classifications[x].phylum);
        }
      });
    }

    this.clearClassifications();

  }

  placeExists(obj) {
    // need to calculate the code
    let code = '';
    if (obj['name']) {
      code = 'N';
    }
    if (obj['location']) {
      code = 'NL';
    }
    if (obj['location'] && obj['location']['lat']) {
      code = 'NLG';
    }
    if (obj['location'] && obj['location']['lat'] && obj['location']['bounds']) {
      code = code + 'LGB';
    }
    if (obj['chain']) {
      code = code + 'C';
    }


    if (!this.isPOI) {
      // TODO: the X means that later I need to revisit the ukey and add the locality to the GEO database as well as get the chains
      // when i am done, i need to removed the X from code and add a C (indicating chain updates)
      code = code + 'X';
    }


    if (obj['ukey']) {
      this.is.setObjNoLog(`PlaceExists/${obj['ukey']}`, {code, ukey: obj['ukey']});
    }
  }


  onPlace(placeId) {
    const request = {
      placeId,
      fields: [
        'place_id',
        'address_components',
        'formatted_address',
        'name',
        'types',
        'geometry',
        'utc_offset_minutes',
        'photos',
        'website'
      ]
    };

    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {

        // 1. create a PlaceLoc
        const newloc = new PlaceLoc();
        this.address = place.formatted_address;
        const country = this.admin.getCountry(place.address_components, true);
        const state = this.admin.getState(place.address_components, true);
        const city = this.admin.getCity(place.address_components, true);
        if (country === 'US') {
          if (city) {
            newloc.loc = `${city}, ${state}`;
            newloc.location = `${city}, ${state}`;
          } else {
            newloc.loc = `${state}, ${country}`;
            newloc.location = `${state}, ${country}`;
          }
        } else {
          newloc.loc = `${city}, ${country}`;
          newloc.location = `${city}, ${country}`;
        }

        newloc.long = place.geometry.location.lng();
        newloc.lat = place.geometry.location.lat();
        if (place.geometry.bounds) {
          newloc.bounds = place.geometry.bounds;
        }
        if (place.geometry.viewport) {
          newloc.viewport = place.geometry.viewport;
        }
        newloc.hash = geohash.encode(newloc.lat, newloc.long);
        newloc.address = place.formatted_address; // this is an address
        if (place.utc_offset_minutes) {
          newloc.utc_offset_minutes = place.utc_offset_minutes;
        }
        newloc.keys = [];
        const key = new Key();
        key.id = place.place_id;
        key.name = 'Google';
        newloc.keys.push(key);
        let website = '';
        if (place.website) {
          website = place.website;
        }

        const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());

        // save record to Places
        if (place.photos && place.photos.length > 0) {
          const parr = [];
          let googlephotos;
          place.photos.forEach(p => {
            parr.push(p.getUrl());
          });
          this.http.getGoogleJustPhotos(parr).then(
            photosreturn => {

              const photos = Object.values(photosreturn);
              if (photos && photos.length > 0) {
                googlephotos = [];
                photos.forEach(p => {
                  googlephotos.push({url: p, who: 'Google'});
                });
              }

              const obj = {
                ukey: place.place_id,
                hash: newloc.hash,
                name: place.name,
                location: newloc,
                address: place.formatted_address,
                website,
                types: place.types,
                photos: googlephotos
              };

              if (newloc.country) {
                const tix = {
                  name: place.name,
                  address: place.formatted_address,
                  placeUkey: place.place_id,
                  category: '',
                  lat: newloc.lat,
                  long: newloc.long,
                  order: -1,
                  path: ''
                };

                const loc = {
                  long: newloc.long,
                  lat: newloc.lat,
                  country: newloc.country
                };
                this.touristBoardWork(loc, place.place_id, tix);
              }

              this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
              this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
              this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
              this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); //location
              this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/website`, obj.website); // website
              this.onSetType(place.place_id, obj.website, obj.name, obj.types);

              this.is.setObjNoLog(`Places/${place.place_id}/photos`, obj.photos); // photos


              this.placeExists(obj);

            });
        } else {

          const obj = {
            ukey: place.place_id,
            hash: newloc.hash,
            name: place.name,
            address: place.formatted_address,
            location: newloc,
            types: place.types,
            website
          };

          this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
          this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
          this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
          this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
          this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
          this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/website`, obj.website); // website
          this.onSetType(place.place_id, obj.website, obj.name, obj.types);

          this.placeExists(obj);
        }
      }
    });
  }

  /*
  fields: [
          'place_id',
          'address_components',
          'formatted_address',
          'name',
          'types',
          'geometry',
          'utc_offset_minutes',
          'photos',
          'website'
        ]
   */


  onTrigger() {


    const arr = ['21spicesdining.com'];

    arr.forEach(a => {
      const request = {
        query: a,
        fields: [
          'place_id',
        ]
      };

      const service = new google.maps.places.PlacesService($('#service-helper').get(0));
      service.findPlaceFromQuery(request, (plase, stats) => {
        if (stats === google.maps.places.PlacesServiceStatus.OK && plase && plase[0] && plase[0].place_id) {

          this.is.getObject(`PlaceExists/${plase.place_id}/ukey`).take(1).subscribe(ukey => {
            if (!ukey) {
              const req = {
                placeId: plase[0].place_id,
                fields: [
                  'place_id',
                  'address_components',
                  'formatted_address',
                  'name',
                  'types',
                  'geometry',
                  'website'
                ]
              };

              const service = new google.maps.places.PlacesService($('#service-helper').get(0));
              service.getDetails(req, (place, status) => {
                console.log('placeid', plase[0].place_id);

                if (status === google.maps.places.PlacesServiceStatus.OK && place.place_id && place.place_id.length) {
                  // 1. create a PlaceLoc
                  const newloc = new PlaceLoc();
                  const country = this.admin.getCountry(place.address_components, true);
                  const state = this.admin.getState(place.address_components, true);
                  const city = this.admin.getCity(place.address_components, true);
                  if (country === 'US') {
                    if (city) {
                      newloc.loc = `${city}, ${state}`;
                      newloc.location = `${city}, ${state}`;
                    } else {
                      newloc.loc = `${state}, ${country}`;
                      newloc.location = `${state}, ${country}`;
                    }
                  } else {
                    newloc.loc = `${city}, ${country}`;
                    newloc.location = `${city}, ${country}`;
                  }


                  newloc.long = place.geometry.location.lng();
                  newloc.lat = place.geometry.location.lat();
                  if (place.geometry.viewport) {
                    newloc.viewport = place.geometry.viewport;
                  }
                  newloc.hash = geohash.encode(newloc.lat, newloc.long);
                  newloc.address = place.formatted_address; // this is an address
                  if (place.utc_offset_minutes) {
                    newloc.utc_offset_minutes = place.utc_offset_minutes;
                  }

                  newloc.keys = [];
                  const key = new Key();
                  key.id = place.place_id;
                  key.name = 'Google';
                  newloc.keys.push(key);
                  let website = '';
                  if (place.website) {
                    website = place.website;
                  }


                  const obj = {
                    ukey: place.place_id,
                    hash: newloc.hash,
                    name: place.name,
                    address: place.formatted_address,
                    location: newloc,
                    types: place.types,
                    website
                  };

                  this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
                  this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
                  this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
                  this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
                  this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
                  this.onSetType(place.place_id, obj.website, obj.name, obj.types);

                  if (website && website.length) {
                    this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/website`, website); // website
                  }

                  this.placeExists(obj);
                }

              });
            } else {
              console.log('exits');
            }
          });
        } else {
          console.log('no pid');
        }

      });
    });
  }

  // this goal of this function is to find out the main place that a travel article is talking about
  // desc is the text we wish to extract the place names from
  findPlace(desc: string) {

    // todo: get places
    const places = [];

    // todoL get mentions
    const mentions = [];

    const matches = desc.match(/[A-ZÀ-Ú]+[a-zà-ú]+[ \-]?(?:d[a-u].)?(?:[A-ZÀ-Ú]+[a-zà-ú]+)*/g);

    /*
        first cycle through Places then Mentions using the regex used by geotext()
        if doesn’t take long, get all matches, else just get the first one
        once have matchesarray do a forEach until find something satisfying
        matchesarray.forEach(match => {
          it get a match on a name that requires country (or state) then search for it.

    */
  }

  findPlaces(str) {
    // todo: not sure what \b does
    const regex = /\b[A-ZÀ-Ú]+[a-zà-ú]+[ \-]?(?:d[a-u].)?(?:[A-ZÀ-Ú]+[a-zà-ú]+)*\b/;
    const matches = str.match(regex);
  }

  onUpdateQueue() {
    this.is.getList(`Topics/TempInfo/guidescache`).take(1).subscribe(list => {
      this.numbersArticles = 0;
      this.timeArticles = 'O';
      if (list) {
        this.numbersArticles = list.length;
        const minutes = this.numbersArticles * 5;
        this.timeArticles = `${Math.trunc(minutes / 60).toString(10)} hours and ${minutes % 60} minutes`;
      }
    });
  }

  onUpdateContentQueue() {
    this.blogqueue = 0;
    this.magazinequeue = 0;
    this.vlogqueue = 0;
    this.podcastqueue = 0;
    this.is.getList(`Topics/TmpTravelblogs/guides`).take(1).subscribe(list => {
      if (list) {
        this.blogqueue = list.length;
      }
    });
    this.is.getList(`Topics/TmpTravelmags/guides`).take(1).subscribe(list => {
      if (list) {
        this.magazinequeue = list.length;
      }
      this.is.getList(`Topics/TmpTravelmags/guids`).take(1).subscribe(listy => {
        if (listy) {
          this.magazinequeue += listy.length;
        }
      });

    });
    this.is.getList(`Topics/TmpTravelvlogs/guides`).take(1).subscribe(list => {
      if (list) {
        this.vlogqueue = list.length;
      }
    });
    this.is.getList(`Topics/TmpFoodblogs/guides`).take(1).subscribe(list => {
      if (list) {
        this.podcastqueue = list.length;
      }
    });
  }

  //https://cruiseradio.net/wp-content/uploads/2018/01/favicon.png
  //https://cdn.cruiseradio.net/wp-content/uploads/2018/01/favicon.png

  fixGuide() {
    const icon = 'https://www.familytravelmagazine.com/wp-content/uploads/2022/07/cropped-Favicon-180x180.png';
    console.log('familytravelmagazine', `Institutions/familytravelmagazine/published`);

    this.is.getList(`Institutions/familytravelmagazine/published`).take(1).subscribe(wishes => {
      let cnt = 0;
      wishes.forEach(wsh => {
        setTimeout(() => {
          this.http.rssfixInFrame(wsh, icon, 'icon').take(1).subscribe(res => {
            console.log('resy', res);
          });
        }, 3000);
      });
    });

  }

  fixSingleGuide() {

    this.is.getObject(`Topics/TravelNotifications/1675667618478`).take(1).subscribe(wsh => {
      if (wsh) {
        const desc = 'My boys on a doubledecker bus in London. Hotel phone: +44 20 7769 7766 Luxurious 2 and 3 bedroom suites (with turn-down service) have full kitchens…\n';
        this.http.rssfixInFrame(wsh, desc, 'desc').take(1).subscribe(res => {
          console.log('resy', res);
        });
      }
    });
  }

  onViewArticles() {
    this.location = this.curguide.cn;
    this.showarticles = true;
    this.src = 'Institutions';
    this.ukey = this.curguide.cf;
  }

  onGetBoundary(l) {
    console.log('getting bounding', `Places/${l.ukey}/location/keys/Geoapify`);
    this.is.getObject(`Places/${l.ukey}/location/keys/Geoapify`).take(1).subscribe(geo => {
      if (!geo) {
        this.http.getBoundary(l.ukey).then(res => {
          if (res && res.id) {
            this.is.setObjNoLog(`Places/${l.ukey}/location/keys/Geoapify`, res);
            console.log('onGetBoundary');
          }
        });
      }
    });
  }

  onShowArticles(t) {
    if (t) {


      this.location = t.name;
      this.ukey = t.ukey;
      this.src = 'Topics';
      this.showarticles = true;
    }
  }

  onFixHelp() {

    this.is.getObject(`Capptivation/Adventures/notes/1677105095734`).take(1).subscribe(note => {
      if (note && note['ukey']) {
        note['date'] = 1677031010000;
        note['ukey'] = '1677031010000';
        this.is.setObjNoLog(`Capptivation/Adventures/notes/1677031010000`, note);
      }
    });
  }

  getDomainFromUrl(url: string): string | null {
    try {
      const urlObject = new URL(url);
      const hostname = urlObject.hostname;
      const parts = hostname.split('.');
      let domain = null;
      if (parts.length >= 2) {
        domain = parts[parts.length - 2];
        if (domain === 'www') {
          domain = parts[parts.length - 3];
        }
      }
      return domain.toLowerCase();
    } catch (error) {
      return null;
    }
  }

  onFetchVideo() {
    this.err = '';
    navigator.clipboard.readText().then(val => {
      if (val) {
        const l = JSON.parse(val);
        const domain = l['cf'];
        this.is.getObject(`Topics/Travelvlogs/${domain}/ukey`).take(1).subscribe(ukey => {
          if (!ukey) {
            const guide = new Wish('', null);
            guide.app = 'Adventures';
            guide.date = l['date'];
            guide.as = true;
            guide.cf = l['cf'];
            guide.cn = l['name'];
            guide.curlang = 'en';
            guide.ssoc.en.description = l['name'];
            guide.ukey = l['date'].toString();
            guide.flip = false;
            guide.e = l['image'];
            guide.l = l['link'];
            guide.g = '';
            guide.i = '';
            guide.icon = '';
            guide.m = {};
            guide.m.catonly = false;
            guide.m.droptitle = false;
            guide.m.sponsored = false;
            if (l.length) {
              guide.m.length = l.length;
            }
            guide.m.channel = l['cf'];
            guide.m.youtubeid = l['ukey'];
            guide.topics = l['cf'];
            guide.ukey = l['date'].toString();
            guide.ty = 'vlog';
            guide.v = 'U';
            guide.vex = -1;
            guide.types = [];
            guide.types.push('Video');

            console.log('guide', guide);
            this.addcustom = '';
            this.processDescription(guide);
            this.onEditGuide(guide);

          } else {
            this.err = 'This channel already exists';
          }
        });
      }
    });
  }


  onFetchArticle() {
    if (this.url && this.url.length) {
      this.spinning = true;
      this.exists = false;
      this.err = '';
      this.url = this.url.split('?')[0];
      const domain = this.getDomainFromUrl(this.url);
      if (domain) {
        this.is.getObject(`Topics/Travelblogs/${domain}/ukey`).take(1).subscribe(ukey => {
          if (!ukey) {
            this.is.getObject(`Topics/Travelmags/${domain}/ukey`).take(1).subscribe(mags => {
              this.spinning = false;
              if (!mags) {
                this.http.parseArticle(this.url, domain).then(res => {
                  if (res && res['type'] && res['type'] === 'article' && res['pubdate'] && res['pubdate'] > 0) {
                    const guide = new Wish('', null);
                    guide.app = 'Adventures';
                    guide.as = true;
                    if (res['CF'] && res['CF'].length) {
                      guide.cf = res['CF'];
                    }
                    if (res['CN'] && res['CN'].length) {
                      guide.cn = res['CN'];
                    }
                    if (res['title'] && res['title'].length) {
                      guide.ssoc.en.description = res['title'];
                    }
                    if (res['description'] && res['description'].length) {
                      guide.fullmsg.en.description = res['description'];
                    }
                    guide.curlang = 'en';
                    if (res['pubdate'] && res['pubdate'] > 0) {
                      guide.date = res['pubdate'];
                    }
                    guide.dt = '';
                    guide.flip = false;
                    guide.g = '';
                    guide.i = '';
                    if (res['image'] && res['image'].length) {
                      guide.e = res['image'];
                    }
                    guide.l = this.url;
                    guide.icon = '';
                    guide.m = {};
                    guide.m.catonly = false;
                    guide.m.droptitle = false;
                    guide.m.sponsored = false;
                    guide.topics = 'random';
                    if (res['pubdate'] && res['pubdate'] > 0) {
                      guide.ukey = res['pubdate'].toString();
                    }
                    guide.ty = 'article';
                    guide.v = 'U';
                    guide.vex = -1;
                    this.addcustom = '';
                    this.processDescription(guide);
                    this.onEditGuide(guide);
                  } else {
                    this.err = 'This is not a valid article.';
                  }
                });
              } else {
                this.err = `We already screape this domain: ${domain}`;
                this.exists = true;
              }
            });
          } else {
            this.err = `We already screape this domain: ${domain}`;
            this.spinning = false;
            this.exists = true;
          }
        });
      }
    } else {
      this.err = 'Please enter a valid URL';
    }
  }

  getViewport() {

    const places = ['ChIJaeS6OAOLj0gR1a-pqVcpzx4',
      'ChIJJwkEHMWXlIgRHG6LhRTk3xI',
      'ChIJbbv-qUB-12oREH2gMKR5BQQ',
      'ChIJvyKV6_YDXIYRy-YphbNYrzA',
      'ChIJp2CD9qJFj0cRzho9eqWJgOU',
      'ChIJX6BmfgY9hEcRJ2zfX6RJmII',
      'ChIJmc9-Koba64ARTt9KZFIRrjY',
      'ChIJMVSsr_8UWxMREBmiCH5e1dY'];

    places.forEach(placeId => {

      const request = {
        placeId,
        fields: [
          'place_id',
          'geometry',
        ]
      };


      const service = new google.maps.places.PlacesService($('#service-helper').get(0));
      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.viewport) {

          const obj = {
            swlat: place.geometry.viewport.getSouthWest().lat(),
            swlng: place.geometry.viewport.getSouthWest().lng(),
            nelat: place.geometry.viewport.getNorthEast().lat(),
            nelng: place.geometry.viewport.getNorthEast().lng()
          };
          this.is.setObjNoLog(`Places/${placeId}/location/viewport`, obj);

          obj['ukey'] = placeId;

          this.is.setObjNoLog(`Topics/TempInfo/viewport/${placeId}`, obj);
        } else {
          console.log('busted', placeId);
        }
      });
    });
  }

  onDeleteEmbedNew(wish: Wish) {
    this.is.deleteObjNoLog(`Topics/TmpTravelInstagrams/${wish.ukey}`);
    const x = this.guides.findIndex(g => g.ukey === wish.ukey);
    if (x > -1) {
      this.guides.splice(x, 1);
    }
  }

  onDeleteEmbed() {
    this.is.deleteObjNoLog(`Topics/TmpTravelInstagrams/${this.curguide.ukey}`);
    this.curguide = null;
  }

  finishInstySave(where: number, oldukey, old: boolean) {
    // save photo to tourist board


    // TODO: Need to build in Insty for the food feed
    // this is a crap cludge

    if (this.curguide && this.curguide.mentions && !this.curguide.mentions[0].class) {
      this.curguide.mentions[0].class = '';
    }

    let uku;
    if (this.curguide && this.curguide.mentions) {
      uku = this.curguide.mentions[0].ukey;
    } else {
      uku = this.curguide.trip[0].ukey;
    }

    if (where === 0) {
      // save to cache
      console.log('SAVING X', this.curguide);

      if (old) {
        this.is.getObject(`Places/${uku}/ukey`).take(1).subscribe(uk => {
          if (!uk) {
            this.is.setObjNoLog(`Topics/TempInfo/getplaces/${uku}`, uku);
          }
        });
      }

      this.is.setObjNoLog(`Topics/InstyTravelFeed/${this.curguide.ukey}`, this.curguide);
      this.is.setObjNoLog(`Institutions/${this.curguide.m.adminboardukey}/socialmediaposts/instagrams/${this.curguide.ukey}`, this.curguide);

      // when add a guide, delete a guide so there are always 120 in the feed
      this.is.getFirst(`Topics/InstyTravelFeed/`, 1).take(1).subscribe(glist => {
        glist.forEach(g => {
          this.is.deleteObjNoLog(`Topics/InstyTravelFeed/${g.ukey}`);
        });
      });

      // guidescache is the quee prior to periodic publishing
      // save for seven days
      this.curguide.findate = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
      // keeping this code means tht the Insty will be save to BOTH InstyTravelFeed and to the guidescache
      console.log('SAVING INSTY');
      this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
    } else if (where === 1) {
      this.is.setObjNoLog(`Institutions/${this.curguide.m.adminboardukey}/socialmediaposts/instagrams/${this.curguide.ukey}`, this.curguide);
    } else if (where === 2) {
      this.curguide.findate = new Date().getTime() + 30 * 24 * 60 * 60 * 1000;
      this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
    }
    this.is.deleteObjNoLog(`Topics/TmpTravelInstagrams/${oldukey}`);

    const x = this.guides.findIndex(g => g.ukey === this.curguide.ukey);
    if (x > -1) {
      this.guides.splice(x, 1);
    }

    this.clearIt();
    this.clipped = null;
    this.curguide = null;
  }

  onSaveInstyNew(card: Wish) {
    this.curguide = card;
    this.insttype = this.curguide['insttype'];
    this.curguide.fullmsg.en.description = this.curguide.ssoc.en.description;
    this.vexdays = 1;
    this.onSaveInsty(0, false);
  }

  // 0 save to fee
  // 1 save but no feed
  // 2 save 30 days
  onSaveInsty(where: number, old: boolean) {
    const oldukey = this.curguide.ukey;
    if (old) {
      const tempdate = this.ipubdate.getTime();
      if (tempdate !== this.curguide.date) {
        const fact = Math.trunc(Math.random() * 10000);
        this.curguide.date = tempdate + fact;
        this.curguide.ukey = this.curguide.date.toString(10);
      }
    }
    if (this.vexdays > 0) {
      this.curguide.vex = new Date().getTime() + this.vexdays * 24 * 60 * 60 * 1000;
    }
    if (this.ixdate) {
      this.curguide.findate = new Date(this.ixdate).getTime() - Math.floor(Math.random() * 10000);
      this.ixdate = null;
    }
    // save the photos to two places
    let caption = '';
    if (this.curguide.fullmsg && this.curguide.fullmsg.en.description.length) {
      caption = this.curguide.fullmsg.en.description;
    }


    const photo = {who: this.curguide['photocredit'], caption, url: this.curguide.e};
    this.is.pushObj(`Institutions/${this.curguide.m.adminboardukey}/photos`, photo);

    if (old) {
      if (this.curguide.trip && this.curguide.trip.length) {
        this.is.pushObj(`Places/${this.curguide.trip[0].ukey}/photos`, photo);
        if (!this.curguide.loc && this.curguide.trip[0].loc) {
          this.curguide.loc = this.curguide.trip[0].loc;
        }
      }
      if (this.curguide.mentions && this.curguide.mentions.length) {
        this.is.pushObj(`Places/${this.curguide.mentions[0].ukey}/photos`, photo);
        if (!this.curguide.loc && this.curguide.mentions[0].loc) {
          this.curguide.loc = this.curguide.mentions[0].loc;
        }
      }
    }

    this.is.getObject(`Topics/TempInfo/countinsty`).take(1).subscribe(cnt => {
      this.is.setObjNoLog(`Topics/TempInfo/countinsty`, cnt + 1);
    });

    let ity = '';
    if (this.insttype) {
      this.curguide.types = [];
      this.curguide.types.push(`inst-${this.insttype}`);
      ity = this.insttype;
    }

    let link = '';
    if (this.curguide.l) {
      link = this.curguide.l;
    }

    if (this.curguide.m && this.curguide.m.time && this.curguide.m.likes) {
      const stats = {
        time: this.curguide.m.time,
        category: ity,
        likes: this.curguide.m.likes,
        board: this.curguide.m.adminboardukey,
        instagramkey: this.curguide.cn,
        link,
        ukey: this.curguide.ukey
      };
      this.is.setObjNoLog(`Topics/Instagrams/${stats.ukey}`, stats);
    }


    this.finishInstySave(where, oldukey, old);


    /*
        if (!this.curguide.trip && !this.curguide.mentions) {
          // need to process
          this.is.getObject(`Institutions/${this.curguide.m.adminboardukey}/destination`).take(1).subscribe(destination => {

            this.is.getObject(`Institutions/${this.curguide.m.adminboardukey}/locations/0`).take(1).subscribe(location => {

              // TODO: fix rss delete guide so that it uses reverse path

              if (destination.county) {
                console.log('BAD COUNTRY')
              }

              const tp = {
                country: destination.country,
                name: destination.name,
                lat: location.lat,
                long: location.long,
                hash: location.hash,
                loc: location.loc,
                photo: this.curguide.e,
                propogate: true,
                aboutit: true,
                objectID: destination.ukey,
                ukey: destination.ukey
              };


              this.curguide.mentions = [];
              this.curguide.mentions.push(tp);
              if (!this.curguide.loc && this.curguide.mentions[0].loc) {
                this.curguide.loc = this.curguide.mentions[0].loc;


              this.finishInstySave(where, oldukey);
            })
          });
        } else {
          this.finishInstySave(where, oldukey);
        }


      }
    */

  }

  onSearch() {
    this.isinstid = true;
    window.open(`https://www.google.com/search?q=${this.curguide.m.loc}`, '_blank');

  }

  filterPossibles = (possibles: any[]): any[] => {
    const filtered: Map<string, any> = new Map();

    possibles.forEach(possible => {
      const existing = filtered.get(possible.objectID);

      // If it doesn't exist, or it exists without an address and the current one has an address, add/replace it
      if (!existing || (!existing.address && possible.address)) {
        filtered.set(possible.objectID, possible);
      }
    });

    // Convert the Map values back to an array
    return Array.from(filtered.values());
  };

  onGetInstagrams() {
    this.is.getFirst('Topics/TmpTravelInstagrams', 30).take(1).subscribe(list => {
      this.guides = [];
      if (list && list.length) {
        list.forEach(l => {

          l['photocredit'] = '';
          if (l.ssoc && l.ssoc.en.description) {
            const regex = /📸: @(\w+)/;
            const match = l.ssoc.en.description.match(regex);
            if (match && match[1]) {
              l['photocredit'] = '@' + match[1];
            } else {
              const regx = /@(\w+)/;
              const matc = l.ssoc.en.description.match(regx);
              if (matc && matc[1]) {
                l['photocredit'] = '@' + matc[1];
              }
            }
          }

          if (l['aidesc']) {
            l.ssoc.en.description = l['aidesc'];
          }

          const tp = {};
          tp['ukey'] = l.m.adminboardukey;
          tp['objectID'] = l.m.adminboardukey;
          tp['aboutit'] = false;
          tp['propogate'] = true;
          tp['hash'] = '';
          tp['lat'] = l.m.lat;
          tp['long'] = l.m.lng;
          tp['country'] = '';
          tp['name'] = '';
          tp['loc'] = l.m.loc;
          l.mentions = [];
          l.mentions.push(tp);
          this.guides.push(l);
        });
      }
    });
  }

  onGetInstagramsOld() {
    this.err = '';
    this.alt = '';
    this.insttype = '';
    this.isinstid = false;
    this.saveid = false;
    this.instloc = '';
    this.curguide = null;
    this.is.getFirst('Topics/TmpTravelInstagrams', 1).take(1).subscribe(list => {
      if (list && list.length) {
        const l = list[0];

        if (l['possibles']) {
          l['possibles'] = this.filterPossibles(l['possibles']);
        }
        this.curguide = l;

        if (this.curguide['possibles'] && this.curguide['possibles'].length) {
          this.curguide.trip = [];
          this.curguide.trip.push(this.curguide['possibles'][0]);
          this.curguide['possibles'] = null;
          this.clipped = new PlaceObj();
          this.clipped.name = this.curguide.trip[0].name;
          this.clipped.datapid = this.curguide.trip[0].objectID;
          this.curguide.loc = this.curguide.trip[0]['vicinity'];
        }

        if (this.curguide.m.alt) {
          this.alt = this.curguide.m.alt;
        }
        if (this.curguide.m.loc) {
          this.instloc = this.curguide.m.loc;
        }


        if (this.curguide['insttype'] && this.curguide['insttype'] !== 'na') {
          this.insttype = this.curguide['insttype'];
        }

        if (this.curguide.m.landmark) {
          this.location = this.curguide.m.landmark;
        }
        if (this.curguide.m.score) {
          this.err = this.curguide.m.score;
        }
        if (this.curguide.date) {
          this.ipubdate = new Date(this.curguide.date);
          console.log('ipubdate', this.ipubdate);
        }

        if (this.curguide.findate) {
          this.ixdate = new Date(this.curguide.findate);
        }
        this.vexdays = 0;

        console.log('oktillhere', this.curguide.findate, this.ixdate);

        this.photocredit = '';
        if (this.curguide.ssoc && this.curguide.ssoc.en.description) {
          console.log('SSOC', this.curguide.ssoc.en.description);
          const regex = /📸: @(\w+)/;
          const match = this.curguide.ssoc.en.description.match(regex);
          console.log('MATCH', match);
          if (match && match[1]) {
            this.photocredit = '@' + match[1];
          } else {
            const regx = /@(\w+)/;
            const matc = this.curguide.ssoc.en.description.match(regx);
            console.log('MATC', matc);
            if (matc && matc[1]) {
              this.photocredit = '@' + matc[1];
            }
          }
        }
      } else {
        console.log('INSTY EMPTY');
      }
    });
    ``;
  }

  onWebSite() {
    window.open(this.tb.websiteurl, 'blank');
  }

  onGetBoard() {
    /*
        this.is.getObject(`Institutions/${this.tbukey}`).take(1).subscribe(tb => {
          this.tb = tb;
          this.domedlink = this.dom.bypassSecurityTrustResourceUrl(this.tb.websiteurl);

          this.oldtb = _.cloneDeep(tb);
          this.vendortemp = '';

        });
    */
    //4

    this.is.getObject(`Topics/TempInfo/newboards/${this.turl}`).take(1).subscribe(tb => {
      console.log(`Topics/TempInfo/newboards/${this.turl}`, 'onGETNEWBOARD', tb);
      if (tb) {
        this.tb = new TouristBoard();
        this.tb.websiteurl = tb.tourist;
        this.domedlink = this.dom.bypassSecurityTrustResourceUrl(this.tb.websiteurl);

        this.tb.destination.ukey = tb.ukey;
        this.tb.destination.countrycode = '';
        this.tb.destination.name = '';
        this.tb.destination.country = '';
        this.tb.destination.loc = '';
        this.vendortemp = '';
      }
    });


  }

  onCreateBoard() {
    //4
    this.is.getObject(`Topics/TempInfo/newboards/ChIJmx9UMXtxE0cRMK0UZg-vAAM`).take(1).subscribe(tb => {
      if (tb) {
        this.tb = new TouristBoard();
        this.tb.websiteurl = tb.tourist;
        this.domedlink = this.dom.bypassSecurityTrustResourceUrl(this.tb.websiteurl);

        this.tb.destination.ukey = tb.ukey;
        this.tb.destination.countrycode = '';
        this.tb.destination.name = '';
        this.tb.destination.country = '';
        this.tb.destination.loc = '';
        this.vendortemp = '';
      }
    });


  }

  onSaveBoard() {
    this.tbukey = this.tb.ukey;

    /*    const obj = { nelat: 45.678924, nelng: -111.043805, swlat: 41.996214, swlng: -117.243026  }
        if (!this.tb.locations) {
          this.tb.locations = []
        }
        const lat = (obj.swlat + obj.nelat) / 2;
        const long = (obj.swlng + obj.nelng) / 2;
        const hash = geohash.encode(lat, long);
        const loci = this.tb.destination.loc;
        const location = {
          hash,
          lat,
          long,
          viewport: obj,
          loc: loci
        }
        this.is.setObjNoLog(`Institutions/${this.tbukey}/locations/0`, location);
        this.is.setObjNoLog(`Topics/TempInfo/touristboardsviewports/${this.tb.ukey}`, location);*/

    if (!this.oldtb) {
      console.log('SAVING TO Topics/TempInfo/touristboards');
      this.is.setObjNoLog(`Topics/TempInfo/touristboards/${this.tb.ukey}`, this.tb.ukey);
      this.is.setObjNoLog(`Topics/TempInfo/touristboardsviewports/${this.tb.ukey}/ukey`, this.tb.ukey);
    }


    // viewport
    if (!this.oldtb && this.tb.destination.ukey) {


      const placeId = this.tb.destination.ukey;
      const request = {
        placeId,
        fields: [
          'place_id',
          'geometry',
        ]
      };

      const service = new google.maps.places.PlacesService($('#service-helper').get(0));
      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place && place.geometry && place.geometry.viewport) {

          const obj = {
            swlat: place.geometry.viewport.getSouthWest().lat(),
            swlng: place.geometry.viewport.getSouthWest().lng(),
            nelat: place.geometry.viewport.getNorthEast().lat(),
            nelng: place.geometry.viewport.getNorthEast().lng()
          };
          this.is.setObjNoLog(`Places/${placeId}/location/viewport`, obj);

          this.tb.locations = [];

          const lat = (obj.swlat + obj.nelat) / 2;
          const long = (obj.swlng + obj.nelng) / 2;
          const hash = geohash.encode(lat, long);
          const loci = this.tb.destination.loc;
          const location = {
            hash,
            lat,
            long,
            viewport: obj,
            loc: loci
          };
          this.tb.locations.push(location);
          this.is.setObjNoLog(`Institutions/${this.tbukey}/locations/0`, location);

          this.is.setObjNoLog(`Topics/TempInfo/touristboardsviewports/${this.tb.ukey}`, obj);
          this.is.setObjNoLog(`Topics/TempInfo/touristboardsviewports/${this.tb.ukey}/ukey`, this.tb.ukey);

        }
      });
    }

    if (this.tb.destination.ukey && this.tb.destination.ukey.length) {
      // save to places
      this.is.setObjNoLog(`Places/${this.tb.destination.ukey}/moreinfo/touristukey`, this.tbukey);
    }

    if (!this.oldtb) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/ukey`, this.tb.ukey);
      this.is.setObjNoLog(`Institutions/${this.tbukey}/type`, 'Tourist Board');
    }

    if (!this.oldtb || this.tb.org.name !== this.oldtb.org.name) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/org/name`, this.tb.org.name);
    }
    if (!this.oldtb || this.tb.org.email !== this.oldtb.org.email) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/org/email`, this.tb.org.email);
    }
    if (!this.oldtb || this.tb.websitename !== this.oldtb.websitename) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/websitename`, this.tb.websitename);
    }
    if (!this.oldtb || this.tb.websiteurl !== this.oldtb.websiteurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/websiteurl`, this.tb.websiteurl);
    }
    if (!this.oldtb || this.tb.logo.url !== this.oldtb.logo.url) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/logo/url`, this.tb.logo.url);
    }
    if (!this.oldtb || this.tb.icon !== this.oldtb.icon) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/icon`, this.tb.icon);
    }
    if (!this.oldtb || this.tb.logo.backgroundcolor !== this.oldtb.logo.backgroundcolor) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/logo/backgroundcolor`, this.tb.logo.backgroundcolor);
    }
    if (!this.oldtb || this.tb.destination.name !== this.oldtb.destination.name) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/destination/name`, this.tb.destination.name);
    }
    if (!this.oldtb || this.tb.destination.loc !== this.oldtb.destination.loc) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/destination/loc`, this.tb.destination.loc);
      this.is.setObjNoLog(`Institutions/${this.tbukey}/locations/0/loc`, this.tb.destination.loc);
    }
    if (!this.oldtb || this.tb.destination.ukey !== this.oldtb.destination.ukey) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/destination/ukey`, this.tb.destination.ukey);
    }

    if (!this.oldtb || this.tb.socialmedia.instagram !== this.oldtb.socialmedia.instagram) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/instagram`, this.tb.socialmedia.instagram);
    }
    if (!this.oldtb || this.tb.socialmediakeys.instagram !== this.oldtb.socialmediakeys.instagram) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/instagram`, this.tb.socialmediakeys.instagram);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/instagram`, this.tb.socialmediakeys.instagram);

    }

    if (!this.oldtb || this.tb.socialmedia.youtube !== this.oldtb.socialmedia.youtube) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/youtube`, this.tb.socialmedia.youtube);
    }
    if (!this.oldtb || this.tb.socialmediakeys.youtube !== this.oldtb.socialmediakeys.youtube) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/youtube`, this.tb.socialmediakeys.youtube);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/youtube`, this.tb.socialmediakeys.youtube);
    }

    console.log('saving TB');
    if (this.tb.socialmedia.youtube && this.tb.socialmediakeys.youtube) {
      const vlog = {
        feed: this.tb.socialmedia.youtube,
        inactive: false,
        name: this.tb.websitename,
        objectID: this.tb.socialmediakeys.youtube,
        topic: this.tb.socialmediakeys.youtube,
        ukey: this.tb.socialmediakeys.youtube,
        placeId: this.tb.destination.ukey,
        boardukey: this.tb.ukey
      };
      console.log('saving Boardvlogs', vlog);
      this.is.setObjNoLog(`Topics/Boardvlogs/${vlog.ukey}`, vlog);
    }


    if (!this.oldtb || this.tb.socialmedia.tiktok !== this.oldtb.socialmedia.tiktok) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/tiktok`, this.tb.socialmedia.tiktok);
    }
    if (!this.oldtb || this.tb.socialmediakeys.tiktok !== this.oldtb.socialmediakeys.tiktok) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/tiktok`, this.tb.socialmediakeys.tiktok);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/tiktok`, this.tb.socialmediakeys.tiktok);

    }

    if (!this.oldtb || this.tb.socialmedia.pinterest !== this.oldtb.socialmedia.pinterest) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/pinterest`, this.tb.socialmedia.pinterest);
    }

    if (!this.oldtb || this.tb.socialmedia.snapchat !== this.oldtb.socialmedia.snapchat) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/snapchat`, this.tb.socialmedia.snapchat);
    }

    if (!this.oldtb || this.tb.socialmediakeys.pinterest !== this.oldtb.socialmediakeys.pinterest) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/pinterest`, this.tb.socialmediakeys.pinterest);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/pinterest`, this.tb.socialmediakeys.pinterest);

    }

    if (!this.oldtb || this.tb.socialmediakeys.snapchat !== this.oldtb.socialmediakeys.snapchat) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/snapchat`, this.tb.socialmediakeys.snapchat);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/snapchat`, this.tb.socialmediakeys.snapchat);
    }


    if (!this.oldtb || this.tb.socialmedia.twitter !== this.oldtb.socialmedia.twitter) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/twitter`, this.tb.socialmedia.twitter);
    }
    if (!this.oldtb || this.tb.socialmediakeys.twitter !== this.oldtb.socialmediakeys.twitter) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmediakeys/twitter`, this.tb.socialmediakeys.twitter);
      this.is.setObjNoLog(`Topics/SocialMediaKeys/${this.tbukey}/twitter`, this.tb.socialmediakeys.twitter);

    }

    if (!this.oldtb || this.tb.socialmedia.facebook !== this.oldtb.socialmedia.facebook) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/socialmedia/facebook`, this.tb.socialmedia.facebook);
    }

    if (!this.oldtb || this.tb.website.livechat !== this.oldtb.website.livechat) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/livechat`, this.tb.website.livechat);
    }

    if (!this.oldtb || this.tb.website.attractionsurl !== this.oldtb.website.attractionsurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/attractionsurl`, this.tb.website.attractionsurl);
    }

    if (!this.oldtb || this.tb.website.eventurl !== this.oldtb.website.eventurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/eventurl`, this.tb.website.eventurl);
    }

    if (!this.oldtb || this.tb.website.offersurl !== this.oldtb.website.offersurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/offersurl`, this.tb.website.offersurl);
    }

    if (!this.oldtb || this.tb.website.somethingspecialurl !== this.oldtb.website.somethingspecialurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/somethingspecialurl`, this.tb.website.somethingspecialurl);
    }

    if (!this.oldtb || this.tb.website.accessibleurl !== this.oldtb.website.accessibleurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/accessibleurl`, this.tb.website.accessibleurl);
    }

    if (!this.oldtb || this.tb.website.tourguidesurl !== this.oldtb.website.tourguidesurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/tourguidesurl`, this.tb.website.tourguidesurl);
    }

    if (!this.oldtb || this.tb.website.UNESCOurl !== this.oldtb.website.UNESCOurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/UNESCOurl`, this.tb.website.UNESCOurl);
    }

    if (!this.oldtb || this.tb.website.downloadsurl !== this.oldtb.website.downloadsurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/downloadsurl`, this.tb.website.downloadsurl);
    }

    if (!this.oldtb || this.tb.website.luggageurl !== this.oldtb.website.luggageurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/luggageurl`, this.tb.website.luggageurl);
    }

    if (!this.oldtb || this.tb.website.publictoiletsurl !== this.oldtb.website.publictoiletsurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/publictoiletsurl`, this.tb.website.publictoiletsurl);
    }

    if (!this.oldtb || this.tb.website.faqurl !== this.oldtb.website.faqurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/faqurl`, this.tb.website.faqurl);
    }

    if (!this.oldtb || this.tb.website.muslimurl !== this.oldtb.website.muslimurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/muslimurl`, this.tb.website.muslimurl);
    }

    if (!this.oldtb || this.tb.website.officesurl !== this.oldtb.website.officesurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/officesurl`, this.tb.website.officesurl);
    }

    if (!this.oldtb || this.tb.website.beachesurl !== this.oldtb.website.beachesurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/beachesurl`, this.tb.website.beachesurl);
    }

    if (!this.oldtb || this.tb.website.LGBTQurl !== this.oldtb.website.LGBTQurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/LGBTQurl`, this.tb.website.LGBTQurl);
    }

    if (!this.oldtb || this.tb.website.itinerariesurl !== this.oldtb.website.itinerariesurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/itinerariesurl`, this.tb.website.itinerariesurl);
    }

    if (!this.oldtb || this.tb.website.mapurl !== this.oldtb.website.mapurl) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/mapurl`, this.tb.website.mapurl);
    }

    if (!this.oldtb || this.tb.website.scenicspots !== this.oldtb.website.scenicspots) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/website/scenicspots`, this.tb.website.scenicspots);
    }

    if (!this.oldtb || this.tb.blog.url !== this.oldtb.blog.url) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/blog/url`, this.tb.blog.url);
    }
    if (!this.oldtb || this.tb.blog.note !== this.oldtb.blog.note) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/blog/note`, this.tb.blog.note);
    }
    if (!this.oldtb || this.tb.blog.feed !== this.oldtb.blog.feed) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/blog/feed`, this.tb.blog.feed);
    }
    if (!this.oldtb || this.tb.introvideo !== this.oldtb.introvideo) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/introvideo`, this.tb.introvideo);
    }
    if (!this.oldtb || this.tb.appstemp !== this.oldtb.appstemp) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/appstemp`, this.tb.appstemp);
    }
    if (!this.oldtb || this.tb.cardtemp !== this.oldtb.cardtemp) {
      this.is.setObjNoLog(`Institutions/${this.tbukey}/cardtemp`, this.tb.cardtemp);
    }

    if (this.vendortemp && this.vendortemp.length) {
      const date = new Date().getTime();
      const vendor = {name: this.vendortemp, status: {date, status: 'Yes'}};
      this.is.pushObj(`Institutions/${this.tbukey}/vendors`, vendor);
    }

  }

  createRBush() {
    const tree = new RBush(40);
    this.is.getList(`Topics/TempInfo/touristboardsviewports`).take(1).subscribe(boundingBoxes => {
      console.log('start');
      boundingBoxes.forEach(bbox => {
        if (bbox.viewport) {
          tree.insert({
            minX: bbox.viewport.swlng,
            minY: bbox.viewport.swlat,
            maxX: bbox.viewport.nelng,
            maxY: bbox.viewport.nelat,
            id: bbox.ukey,
          });
        } else {
          tree.insert({
            minX: bbox.swlng,
            minY: bbox.swlat,
            maxX: bbox.nelng,
            maxY: bbox.nelat,
            id: bbox.ukey,
          });
        }
      });
      const bloa = tree.toJSON();
      console.log('tree', tree);
      console.log('bloa', bloa);
      // this.is.setObjNoLog(`Topics/TempInfo/rbush1`, bloa);
      this.is.setObjNoLog(`Topics/TempInfo/rbush`, bloa);
    });
  }

  // helper function to calculate the size of a bounding box
  bboxSize(bbox: { nelat: number, nelng: number, swlat: number, swlng: number, id: string }): number {
    const width = Math.abs(bbox.nelng - bbox.swlng);
    const height = Math.abs(bbox.nelat - bbox.swlat);
    return width * height;
  }

  stopBoardArticles() {
    clearInterval(this.timer);
  }


  fixArticlesHash() {
    this.timer = setInterval(() => {


      // 1. get the last processed article from Topics/TempInfo/rbushkey
      this.is.getObject('Topics/TempInfo/ahashkey').take(1).subscribe(ahashkey => {
        console.log('processing', ahashkey);
        // 2. get the next batch of articles to process from Topics/TravelNotifications, initially do a few at a time to make sure it's working
        this.is.getFirstSnapNKeys(10, `ArticlesHash`, ahashkey.toString()).take(1).subscribe(articleslist => {
          // reversepath is a string array of all the paths where we will be storing the article; this will be used if we need to delete the article.
          if (articleslist.length === 5) {
            const lastart = articleslist.pop();

            // need to save a new rbushkey; because is the next place i will start off
            this.is.setObjNoLog(`Topics/TempInfo/ahashkey`, lastart.ux);

            articleslist.forEach(a => {
              const aux = a.ux.substring(0, 6);
              console.log('BBBX', a);
              // save to HashArticles
              if (a && a.val && a.val.articles && a.ux) {
                const arr = Object.values(a.val.articles);
                if (arr && arr.length) {
                  arr.forEach(ar => {
                    ar['cat'] = 'admin';
                    ar['ux'] = a.ux;
                    this.is.setObjNoLog(`HashArticles/${aux}/${ar['ukey']}`, ar);
                    // todo: save to HashArticlesArray
                    console.log(`HashArticlesArray/${aux}`, aux);
                    this.is.setObjNoLog(`HashArticlesArray/${aux}`, aux);
                    this.is.pushObj(`Topics/TravelNotifications/${ar['ukey']}/reversepath`, `HashArticles/${aux}/${ar['ukey']}`); //.push(artpath)

                  });
                }
              }


            });

          } else {
            clearInterval(this.timer);
          }
          clearInterval(this.timer);

        });
      });
    }, 2500);
  }

  boardArticles() {
    // find the smallest bounding box that contains a given location
    /*
    nelat	nelng	swlat	swlng
    maxY	maxX	minY	minX
     */

    // 0. get the RBush index
    let cnt = 0;
    console.log('board');
    this.is.getObject(`Topics/TempInfo/rbush`).take(1).subscribe(data => {
      const index = new RBush(40).fromJSON(data);
      console.log('index', index);

      this.timer = setInterval(() => {


        // 1. get the last processed article from Topics/TempInfo/rbushkey
        this.is.getObject('Topics/TempInfo/rbushkey').take(1).subscribe(rbushkey => {
          console.log('processing', rbushkey);
          // 2. get the next batch of articles to process from Topics/TravelNotifications, initially do a few at a time to make sure it's working
          this.is.getLimitToNextnKeys(10, 'Topics/TravelNotifications', rbushkey.toString()).take(1).subscribe(articleslist => {
            // reversepath is a string array of all the paths where we will be storing the article; this will be used if we need to delete the article.
            if (articleslist.length === 10) {
              const lastart = articleslist.pop();

              // need to save a new rbushkey; because is the next place i will start off
              this.is.setObjNoLog(`Topics/TempInfo/rbushkey`, lastart.ukey);

              articleslist.forEach(article => {
                cnt += 1;
                const reversepath = [];
                if (article.trip && article.ty !== 'instaimage') {
                  article.trip.forEach(tr => {
                    if (tr && tr.hash) {

                      /*
                      const artpath = `ArticlesHash/${tr.hash}/articles/${article.ukey}`;
                      const xm = reversepath.findIndex(r => r === artpath);
                      if (xm === -1) {
                        reversepath.push(artpath);
                        const na = _.cloneDeep(article);
                        na['cat'] = 'admin';
                        if (artpath && artpath.length > 20) {
                          this.is.setObjNoLog(artpath, na);
                          this.is.pushObj(`Topics/TravelNotifications/${article.ukey}/reversepath`, artpath);
                        }
                      }
                      */

                      // for each trip and mention find out what tourist boards it belongs to
                      // searchResultarr contains an array of all (if any) boards this long.lat pair belong to

                      const searchResultarr = index.search({minX: tr.long, minY: tr.lat, maxX: tr.long, maxY: tr.lat});
                      if (searchResultarr && searchResultarr.length) {
                        searchResultarr.forEach(result => {
                          const path = `Institutions/${result.id}/guides/${article.ukey}`;
                          // push this path onto reversepath
                          const sr = reversepath.findIndex(r => r === path);
                          if (sr === -1) {
                            reversepath.push(path);
                            console.log('CHECK PATH', `Institutions/${result.id}/destination/country`);
                            this.is.getObject(`Institutions/${result.id}/destination/country`).take(1).subscribe(country => {
                              if (country && tr.country && country.trim() === tr.country.trim()) {
                                if (path && path.length > 20) {
                                  console.log('TRIP PATHasdf', path);
                                  this.is.setObjNoLog(path, article);
                                  this.is.pushObj(`Topics/TravelNotifications/${article.ukey}/reversepath`, path);
                                }
                              } else {
                                console.log('trip fuck all', `Institutions/${result.id}/destination/country`, article.ukey, country, tr.country);
                              }
                            });
                          }
                        });
                      }
                    }
                  });
                }
                if (article.mentions && article.ty !== 'instaimage') {

                  article.mentions.forEach(tr => {
                    if (tr && tr.hash) {

                      /*
                      const artpath = `ArticlesHash/${tr.hash}/articles/${article.ukey}`;
                      const xm = reversepath.findIndex(r => r === artpath);
                      if (xm === -1) {
                        reversepath.push(artpath);
                        const na = _.cloneDeep(article);
                        na['cat'] = 'place';
                        if (artpath && artpath.length > 20) {
                          this.is.setObjNoLog(artpath, na);
                          this.is.pushObj(`Topics/TravelNotifications/${article.ukey}/reversepath`, artpath);
                        }
                      }
                       */

                      // for each trip and mention find out what tourist boards it belongs to
                      // searchResultarr contains an array of all (if any) boards this long.lat pair belong to
                      const searchResultarr = index.search({minX: tr.long, minY: tr.lat, maxX: tr.long, maxY: tr.lat});

                      if (searchResultarr && searchResultarr.length) {
                        searchResultarr.forEach(result => {
                          const path = `Institutions/${result.id}/guides/${article.ukey}`;

                          // push this path onto reversepath
                          const sr = reversepath.findIndex(r => r === path);
                          if (sr === -1) {
                            reversepath.push(path);
                            this.is.getObject(`Institutions/${result.id}/destination/country`).take(1).subscribe(country => {
                              if (country && tr.country && country.trim() === tr.country.trim()) {
                                if (path && path.length > 20) {
                                  this.is.setObjNoLog(path, article);
                                  this.is.pushObj(`Topics/TravelNotifications/${article.ukey}/reversepath`, path);
                                }
                              } else {
                                console.log('mention fuck all', article.ukey, country, tr.country);
                              }
                            });
                          }
                        });
                      }
                    }
                  });
                }

                // todo: update the cloud function in rss that deletes articles to use the reversepath to delete articles.
              });
            } else {
              console.log('STOPPING', rbushkey);
              clearInterval(this.timer);
            }

          });
        });

      }, 5000);

    });
  }


  onCountry() {
    this.is.getObject('Topics/TempInfo/rbushkey').take(1).subscribe(rbushkey => {
      console.log('start on country');
      this.is.getLimitToNextnKeys(10, 'Topics/TravelNotifications', rbushkey.toString()).take(1).subscribe(articleslist => {
        // reversepath is a string array of all the paths where we will be storing the article; this will be used if we need to delete the article.
        const lastart = articleslist.pop();

        // need to save a new rbushkey; because is the next place i will start off
        this.is.setObjNoLog(`Topics/TempInfo/rbushkey`, lastart.ukey);
        articleslist.forEach(article => {
          console.log('Article:', article.ukey);
          if (article.trip) {
            const karr = Object.keys(article.trip);

            karr.forEach(key => {
              const tr = article.trip[key];
              if (!tr.lat) {
                console.log('admin no lat', article.ukey);
                this.is.getObject(`Places/${tr.objectID}/location`).take(1)
                  .subscribe(location => {
                    if (location.hash) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/trip/${key}/hash`, location.hash);
                    }
                    if (location.lat) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/trip/${key}/lat`, location.lat);
                    }
                    if (location.long) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/trip/${key}/long`, location.long);
                    }
                    if (location.country) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/trip/${key}/country`, location.country);
                    }
                  });
              } else if (!tr.country) {
                this.is.getObject(`Places/${tr.objectID}/location/country`).take(1)
                  .subscribe(country => {
                    if (country) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/trip/${key}/country`, country);
                    }
                  });
              }
            });
          }

          if (article.mentions) {
            const karr = Object.keys(article.mentions);

            karr.forEach(key => {
              const tr = article.mentions[key];
              if (!tr.lat) {
                console.log('mention no lat', article.ukey);
                this.is.getObject(`Places/${tr.objectID}/location`).take(1)
                  .subscribe(location => {
                    if (location.hash) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/mentions/${key}/hash`, location.hash);
                    }
                    if (location.lat) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/mentions/${key}/lat`, location.lat);
                    }
                    if (location.long) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/mentions/${key}/long`, location.long);
                    }
                    if (location.country) {
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/mentions/${key}/country`, location.country);
                    }
                  });
              } else if (!tr.country) {
                this.is.getObject(`Places/${tr.objectID}/location/country`).take(1)
                  .subscribe(country => {
                    if (country) {
                      console.log('mention no country', article.ukey);
                      this.is.setObjNoLog(`Topics/TravelNotifications/${article.ukey}/mentions/${key}/country`, country);
                    }
                  });
              }
            });
          }
        });
      });
    });
  }

  onFix() {
    this.is.getObject('Topics/TempInfo/rbushkey').take(1).subscribe(rbushkey => {
      this.is.getLimitToNextnKeys(7, `Topics/TempInfo/fixbad`, rbushkey).take(1).subscribe(list => {
        const lastart = list.pop();

        // need to save a new rbushkey; because is the next place i will start off
        this.is.setObjNoLog(`Topics/TempInfo/rbushkey`, lastart.ukey);

        this.length = list.length;
        list.forEach(l => {

          console.log(l.ukey);
          const request = {
            placeId: l.ukey,
            fields: [
              'address_components',
            ]
          };


          const service = new google.maps.places.PlacesService($('#service-helper').get(0));
          service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {

              const newloc = new PlaceLoc();

              const country = this.admin.getCountry(place.address_components, true);
              newloc.countrycode = country;
              const state = this.admin.getState(place.address_components, true);
              const city = this.admin.getCity(place.address_components, true);
              let ctry = 'US';
              if (country === 'US') {
                if (city) {
                  newloc.loc = `${city}, ${state}`;
                  newloc.location = `${city}, ${state}`;
                } else {
                  newloc.loc = `${state}, ${country}`;
                  newloc.location = `${state}, ${country}`;
                }
              } else {
                newloc.loc = `${city}, ${country}`;
                newloc.location = `${city}, ${country}`;
                ctry = country;
              }

              let countrylong = this.admin.getLongNameCountry(place.address_components);
              const statelong = this.admin.getLongNameState(place.address_components);
              if (countrylong === 'United States') {
                countrylong = statelong;
              }

              if (countrylong && countrylong.length && newloc && newloc.loc && newloc.loc.length && newloc.location && newloc.location.length) {
                console.log('here');
                this.is.setObjNoLog(`Places/${l.ukey}/location/country`, countrylong);
                this.is.setObjNoLog(`Places/${l.ukey}/location/loc`, newloc.loc);
                this.is.setObjNoLog(`Places/${l.ukey}/location/location`, newloc.location);
              }
            } else {
              console.log('google.maps.places.PlacesServiceStatus', google.maps.places.PlacesServiceStatus);
              this.is.setObjNoLog(`Topics/TempInfo/fix/${l.ukey}`, {ukey: l.ukey});
            }
          });


        });
      });
    });
  }


  onRenderSetup(item) {
    console.log('curguide', this.curguide);
    console.log('item', item);
    this.is.getObject(`Places/${item.ukey}/location/address`).take(1).subscribe(add => {
      if (add) {
        console.log({address: add, datapid: item.ukey, name: item.name});
        this.onRender(false, {address: add, datapid: item.ukey, name: item.name});
      }
    });
  }

  onRender(fromClipped: boolean, data?) {
    // const address = '125 E 2nd St, Casper, WY 82601'
    let address;
    let placeId;
    let name;
    if (fromClipped) {
      address = this.clipped.address;
      name = this.clipped.name;
      placeId = this.clipped.datapid;
    } else {
      address = data.address;
      placeId = data.datapid;
      name = data.name;
    }
    const payload = {
      address
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const requestOptions = {
      headers: headers
    };
    this.aerial = null;
    const apiUrl = 'https://aerialview.googleapis.com/v1beta/videos:renderVideo';
    this.httpx.post(`${apiUrl}?key=${environment.GOOGLE_MAPS_KEY}`, payload, requestOptions).take(1).subscribe(response => {
      console.log('response', response);
      if (response) {
        if (response['state'] === 'ACTIVE') {
          console.log('ACTIVE');
          // problem here is dont have the poster
          const vapiUrl = `https://aerialview.googleapis.com/v1beta/videos?key=${environment.GOOGLE_MAPS_KEY}&videoId=${response['metadata']['videoId']}`;
          this.httpx.get(vapiUrl).take(1).subscribe(res => {

            this.aerial = {
              videoId: response['metadata']['videoId'],
              poster: res['uris']['IMAGE'],
            };
            const obj = {
              name,
              address,
              videoId: response['metadata']['videoId'],
              poster: res['uris']['IMAGE'],
              placeId,
              articleId: this.curguide.ukey
            };
            this.is.setObjNoLog(`Topics/aerialviews/${placeId}`, obj);
            this.is.setObjNoLog(`Places/${placeId}/aerialview`, this.aerial);
            if (!fromClipped) {
              const x = this.curguide.mentions.findIndex(m => m.ukey === placeId);
              if (x > -1) {
                this.curguide.mentions[x]['aerialview'] = this.aerial;
              }
            }

          });


        } else if (response['state'] === 'PROCESSING') {
          console.log('Processing');
          this.aerial = {
            videoId: response['metadata']['videoId'],
          };
          const obj = {
            name,
            address,
            videoId: response['metadata']['videoId'],
            placeId,
            articleId: this.curguide.ukey
          };
          // later on have to go through these to make sure they worked and then save them
          this.is.setObjNoLog(`Topics/TempInfo/aerialview/${obj.placeId}`, obj);

          if (!fromClipped) {
            const x = this.curguide.mentions.findIndex(m => m.ukey === placeId);
            if (x > -1) {
              this.curguide.mentions[x]['aerialtemp'] = this.aerial;
            }
          }

        }
      }
    });
  }


  shakenKing() {
    const arr = [];

    arr.forEach(a => {
      this.is.pushObj(`Shanken/categories`, a);
    });
  }

  buildtaxonomy() {
    const taxes = [
      'waiting'
    ];
    taxes.forEach(tax => {
      if (tax && tax['ukey'] && tax['ukey'].length) {
        this.is.setObjNoLog(`Taxonomy/${tax['ukey']}`, tax);
      }
    });
  }

  namemd5() {
    this.is.getObject('Topics/TempInfo/Counts/namemd5').take(1).subscribe(place => {
      console.log('place', place);
      this.is.getLimitToNextnKeys(2, 'PlaceExists', place).take(1).subscribe(vals => {
        console.log('VALS LENGTH H', vals.length);
        const endkey = vals[vals.length - 1].ukey;
        console.log('endkey', endkey);
        this.is.setObjNoLog('Topics/TempInfo/Counts/namemd5', endkey);
        vals.pop();

        let cnt = 0;
        vals.forEach(v => {
          if (v.ukey) {
            this.is.getObject(`Places/${v.ukey}/name`).take(1).subscribe(name => {

              this.is.getObject(`Places/${v.ukey}/taxonomy`).take(1).subscribe(taxonomy => {
                cnt = cnt + 1;

                if (name && name.length) {
                  this.is.getObject(`Places/${v.ukey}/location`).take(1).subscribe(location => {
                    /*
                    store in
                    {md5, ukey: datapid, country, loc, address}
                     */
                    const ukey = md5(name);
                    const obj = {
                      name,
                      datapid: v.ukey,
                      ukey,
                    };

                    if (taxonomy) {
                      obj['taxonomy'] = taxonomy;
                    }

                    if (location && location.country) {
                      obj['country'] = location.country;
                    }
                    if (location && location.loc) {
                      obj['loc'] = location.loc;
                    }

                    console.log(cnt, 'md5old', obj);
                    console.log(cnt, 'path', `PlacesNamesMd5/${ukey}/${v.ukey}`);
                    //this.is.setObjNoLog(`PlacesNamesMd5/${ukey}/${v.ukey}`, obj);

                    if (name.includes(',')) {
                      const newname = name.split(',')[0];
                      const newukey = md5(newname);
                      const newobj = {
                        name: newname,
                        datapid: v.ukey,
                        ukey: newukey,
                      };

                      if (taxonomy) {
                        newobj['taxonomy'] = taxonomy;
                      }

                      if (location && location.country) {
                        newobj['country'] = location.country;
                      }
                      if (location && location.loc) {
                        newobj['loc'] = location.loc;
                      }

                      console.log('md5new', newobj);
                      console.log('path', `PlacesNamesMd5/${newukey}/${v.ukey}`);
                      // this.is.setObjNoLog(`PlacesNamesMd5/${newukey}/${v.ukey}`, newobj);
                    }

                  });
                }


              });

            });
          }
        });
      });
    });
  }


  namemd5next() {
    this.is.getObject('Topics/TempInfo/Counts/namemd5').take(1).subscribe(place => {
      console.log('place', place);
      this.is.getLimitToNextnKeys(1500, 'PlaceExists', place).take(1).subscribe(vals => {
        console.log('VALS LENGTH H', vals.length);
        const endkey = vals[vals.length - 1].ukey;
        console.log('endkey', endkey);
        this.is.setObjNoLog('Topics/TempInfo/Counts/namemd5', endkey);
        vals.pop();

        let cnt = 0;
        vals.forEach(v => {
          if (v.ukey) {
            this.is.getObject(`Places/${v.ukey}/name`).take(1).subscribe(namereg => {

              this.is.getObject(`Places/${v.ukey}/taxonomy`).take(1).subscribe(taxonomy => {
                cnt = cnt + 1;
                console.log('Taonsol', taxonomy);

                if (namereg && namereg.length) {
                  let name = namereg.toLowerCase();
                  if (name.includes(',')) {
                    name = name.split(',')[0];
                  }

                  this.is.getObject(`Places/${v.ukey}/location`).take(1).subscribe(location => {
                    console.log('LOCATION', location);
                    /*
                    store in
                    {md5, ukey: datapid, country, loc, address}
                     */
                    const ukey = md5(name);
                    const obj = {
                      name,
                      datapid: v.ukey,
                      ukey,
                    };

                    if (taxonomy) {
                      obj['taxonomy'] = taxonomy;
                    }

                    if (location && location.country) {
                      obj['country'] = location.country;
                    }
                    if (location && location.loc) {
                      obj['loc'] = location.loc;
                    }

                    console.log(cnt, 'md5old', obj);
                    console.log(cnt, 'path', `PlacesNamesMd5/${ukey}/${v.ukey}`);
                    this.is.setObjNoLog(`PlacesNamesMd5/${ukey}/${v.ukey}`, obj);

                    /*
                                        if (name.includes(',')) {
                                          const newname = name.split(',')[0];
                                          const newukey = md5(newname);
                                          const newobj = {
                                            name: newname,
                                            datapid: v.ukey,
                                            ukey: newukey,
                                          };

                                          if (taxonomy) {
                                            newobj['taxonomy'] = taxonomy;
                                          }

                                          if (location && location.country) {
                                            newobj['country'] = location.country;
                                          }
                                          if (location && location.loc) {
                                            newobj['loc'] = location.loc;
                                          }

                                          console.log('md5new', newobj);
                                          console.log('path', `PlacesNamesMd5/${newukey}/${v.ukey}`);
                                          this.is.setObjNoLog(`PlacesNamesMd5/${newukey}/${v.ukey}`, newobj);
                                        }
                    */

                    if (taxonomy && taxonomy['kingdom'] === 'Admin') {
                      // do it for loc
                      if (location && location.loc) {
                        const newukey = md5(location.loc.toLowerCase());
                        const newobj = {
                          name: location.loc.toLowerCase(),
                          datapid: v.ukey,
                          ukey: newukey,
                        };

                        if (taxonomy) {
                          newobj['taxonomy'] = taxonomy;
                        }

                        if (location && location.country) {
                          newobj['country'] = location.country;
                        }
                        if (location && location.loc) {
                          newobj['loc'] = location.loc;
                        }

                        console.log('loc-md5new', newobj);
                        console.log('loc-path', `PlacesNamesMd5/${newukey}/${v.ukey}`);
                        this.is.setObjNoLog(`PlacesNamesMd5/${newukey}/${v.ukey}`, newobj);
                      }
                      // do it for name, country

                      if (location && location.country && name !== location.country) {
                        const ctry = name + ', ' + location.country.toLowerCase();
                        const newukey = md5(ctry);
                        const newobj = {
                          name: ctry,
                          datapid: v.ukey,
                          ukey: newukey,
                        };

                        if (taxonomy) {
                          newobj['taxonomy'] = taxonomy;
                        }

                        if (location && location.country) {
                          newobj['country'] = location.country;
                        }
                        if (location && location.loc) {
                          newobj['loc'] = location.loc;
                        }

                        console.log('ctry-md5new', newobj);
                        console.log('ctry-path', `PlacesNamesMd5/${newukey}/${v.ukey}`);
                        this.is.setObjNoLog(`PlacesNamesMd5/${newukey}/${v.ukey}`, newobj);
                      }

                    }


                  });
                }


              });

            });
          }
        });
      });
    });
  }


  wikime() {
    console.log('wikiing');
    this.is.getObject('Topics/TempInfo/Counts/place').take(1).subscribe(place => {
      console.log('place', place);
      this.is.getLimitToNextnKeys(1500, 'PlaceExists', place).take(1).subscribe(vals => {
        console.log('VALS LENGTH', vals.length);
        const endkey = vals[vals.length - 1].ukey;
        console.log('endkey', endkey);
        this.is.setObjNoLog('Topics/TempInfo/Counts/place', endkey);


        vals.forEach(v => {
          if (v.ukey && v.ukey.length > 10) {
            console.log('UKEY', v.ukey);
            this.is.getObject(`Places/${v.ukey}/name`).take(1).subscribe(name => {
              if (!name) {
                this.is.getList(`Places/${v.ukey}/md5`).take(1).subscribe(md5s => {
                  if (md5s) {
                    md5s.forEach(m => {
                      console.log('deletemd5', `PlacesMd5/${m}`);
                      if (m && m.length) {
                        this.is.deleteObjNoLog(`PlacesMd5/${m}`);
                      }
                    });
                  }
                  console.log('FUCKED', v.ukey);
                  console.log('delete', `Places/${v.ukey}`);
                  this.is.deleteObjNoLog(`Places/${v.ukey}`);
                  console.log('delete exists', `PlaceExists/${v.ukey}`);
                  this.is.deleteObjNoLog(`PlaceExists/${v.ukey}`);
                });
              }
            });

            /*
                        this.is.getObject(`Places/${v.ukey}/moreinfo/Wikipedia`).take(1).subscribe(wiki => {
                          console.log('wiki', wiki);
                          if (wiki) {
                            if (wiki === 'https://en.wikipedia.org/wiki/Wikipedia') {
                              this.is.deleteObjNoLog(`Places/${v.ukey}/moreinfo/Wikipedia`);
                            } else {
                              this.is.getObject(`Places/${v.ukey}/name`).take(1).subscribe(name => {
                                if (name) {
                                  console.log(v.ukey, wiki, name);
                                  this.is.onPlaceMd5(v.ukey, wiki, name);
                                }
                              });
                            }
                          }
                        })
            */


          }

        });
      });
    });
  }

  onMarkerClick(p, i) {
    if (p === 0) {
      // posibles
      this.curguide['possibles'].splice(i, 1);
    } else if (p === 1) {
      // locations
      this.curguide.trip.splice(i, 1);
    } else if (p === 2) {
      // mentions
      this.curguide.mentions.splice(i, 1);
    }
    this.calcMap();
  }


  onCancelWP() {
    this.wordpress = false;
  }

  onSaveWP() {
    this.onCancelWP();
    const obj = {
      isWordPress: this.iswordpress,
      theme: this.wordpresstheme,
      plugins: this.wordpressplugins,
      ukey: this.tukey
    };
    console.log('savewp', obj);
    this.is.setObjNoLog(`Institutions/${this.tukey}/wordpress`, obj);
    if (this.iswordpress) {
      this.is.setObjNoLog(`Topics/TempInfo/wordpress/${this.tukey}`, obj);
    }
  }

  onWPBoard() {
    this.is.getObject('Topics/TempInfo/Counts/wpboadcheck').take(1).subscribe(place => {
      console.log('place', place);
      this.is.getLimitToNextnKeys(2, 'Topics/TempInfo/touristboards', place).take(1).subscribe(vals => {
        console.log('VALS LENGTH', vals.length);
        const endkey = vals[vals.length - 1];
        console.log('endkey', endkey);
        this.is.setObjNoLog('Topics/TempInfo/Counts/wpboadcheck', endkey);
        vals.pop();

        vals.forEach(v => {
          console.log('VVVVV', v);
          this.is.getObject(`Institutions/${v}/websiteurl`).take(1).subscribe(board => {
            this.tukey = v;
            this.turl = board;
            this.iswordpress = false;
            this.wordpresstheme = '';
            this.wordpressplugins = '';
            this.wordpress = true;
          });
        });
      });
    });
  }


  stuffWine() {
    const stores = [
      {ukey: 'www2020winescom', url: 'https://www.2020wines.com'},
      {ukey: 'www21stamendmentcom', url: 'https://www.21stamendment.com'},
      {ukey: 'www36thavewinecom', url: 'https://www.36thavewine.com'},
      {ukey: 'www8shirescom', url: 'https://www.8shires.com'},
      {ukey: 'wwwabbelio-winescomhk', url: 'https://www.abbelio-wines.com.hk'},
      {ukey: 'wwwabcfwscom', url: 'https://www.abcfws.com'},
      {ukey: 'wwwabcliquorsdecom', url: 'https://www.abcliquorsde.com'},
      {ukey: 'wwwacespiritscom', url: 'https://www.acespirits.com'},
      {ukey: 'wwwackerwinescom', url: 'https://www.ackerwines.com'},
      {ukey: 'wwwacmeliquorcom', url: 'https://www.acmeliquor.com'},
      {ukey: 'wwwacmemarketscom', url: 'https://www.acmemarkets.com'},
      {ukey: 'wwwacquistapacecom', url: 'https://www.acquistapace.com'},
      {ukey: 'wwwacquistapacescom', url: 'https://www.acquistapaces.com'},
      {ukey: 'wwwactonwinecom', url: 'https://www.actonwine.com'},
      {ukey: 'wwwadegawinecellarscom', url: 'https://www.adegawinecellars.com'},
      {ukey: 'wwwadellanyccom', url: 'https://www.adellanyc.com'},
    ];

    stores.forEach(store => {
      this.is.setObjNoLog(`Topics/TempInfo/WineStores/${store.ukey}`, store.url);
    });
  }

  foodBlog() {
    const blogs = [
      'stevethebartender',
      'theneffkitchen',
      'coles',
      'sugarandsoul',
      'whereismyspoon',
      'theproteinchef',
      'sweetashoney',
      'perfectlyprovence',
      'tasty',
      'barefeetinthekitchen',
      'budgetbytes',
      'tastesoflizzyt',
      'southernliving',
      'spendwithpennies',
      'cupcakesandkalechips',
      'bonappetit',
      'tasteofhome',
      'carlsbadcravings',
      'allrecipes',
      'overthefirecooking',
      'leitesculinaria',
      'mommyhatescooking',
      'whiteonricecouple',
      'meadowcreekbbqsupply',
      'lenaskitchenblog',
      'heygrillhey',
      'smoking-meat',
      'epicurious',
      'allthingsmamma',
      'therecipecritic',
      'lilluna',
      'tasteandtellblog',
      'amandascookin',
      'thefirstyearblog',
      'cookiesandcups',
      'smalltownwoman',
      'maryswholelife',
      'whatagirleats',
      'delish',
      'midwestfoodieblog',
      'pipandebby',
      'readyseteat',
      'housewivesoffrederickcounty',
      'mccormick',
      'thesuburbansoapbox',
      'kitchenfunwithmy3sons',
      'momontimeout',
      'shugarysweets',
      'dinnerthendessert',
      'crazyforcrust',
      'gonnawantseconds',
      'saltysidedish',
      'noracooks',
      'chelseasmessyapron',
      'detoxinista',
      'dishingouthealth',
      'twopeasandtheirpod',
      'downshiftology',
      'skinnytaste',
      'recipetineats',
      'cookingclassy',
      'rainbowplantlife',
      'thefoodietakesflight',
      'acouplecooks',
      'cookieandkate',
      'foodbymaria',
      'gimmesomeoven',
      'simpleveganblog',
      'wellplated',
      'modernfoodstories',
      'julieblanner',
      'callmepmc',
      'bluelightgin',
      'sabrinacurrie',
      'growingupcali',
      'thebar',
      'britneybreaksbread',
      'justinecelina',
      'driscolls',
      'berlyskitchen',
      'thecookierookie',
      'theviewfromgreatisland',
      'julieseatsandtreats',
      'dancearoundthekitchen',
      'dessertsonadime',
      'getinspiredeveryday',
      'rachlmansfield',
      'letsdishrecipes',
      'dinneratthezoo',
      'chilipeppermadness',
      'rasamalaysia',
      'chinasichuanfood',
      'beefitswhatsfordinner',
      'omnivorescookbook',
      'spoonforkbacon',
      'simplyrecipes',
      'sipandfeast',
      'foodiecrush',
      'theendlessmeal',
      'foodandwine',
      'mykoreankitchen',
      'maangchi',
      'grandbaby-cakes',
      'houseofnasheats',
      'preppykitchen',
      'bromabakery',
      'addapinch',
      'sugarspunrun',
      'sallysbakingaddiction',
      'bbcgoodfood',
      'livforcake',
      'bowl-me-over',
      'wholesomeyum',
      'cafedelites',
      'fifteenspatulas',
      'gooddinnermom',
      'asweetpeachef',
      'thebigmansworld',
      'nomnompaleo',
      'thealmondeater',
      'foodfaithfitness',
      'theworktop',
      'thepancakeprincess',
      'hurrythefoodup',
      'altonbrown',
      'juliarecipes',
      'joyfilledeats',
      'bakerita',
      'modernhoney',
      'kickassbaker',
      'displacedhousewife',
      'siftnwhisk',
      'kleinworthco',
      'bakingthissimple',
      'sweettreatsandsavouryeats',
      'kalejunkie',
      'homemadeinterest',
      'butternutbakeryblog',
      'iwashyoudry',
      'flavormosaic',
      'lifecurrentsblog',
      'vegetablerecipes',
      'theblondcook',
      'thehonoursystem',
      'bettycrocker',
      'centslessdeals',
      'highlandsranchfoodie',
      'themodernproper',
      'sunbasket',
      'freshaprilflours',
      'littlesunnykitchen',
      'staysnatched',
      'eatingonadime',
      'easyfamilyrecipes',
      'thefreshcooky',
      'thenovicechefblog',
      'aheadofthyme',
      'thekitchn',
      'sundaysuppermovement',
      'freshexpress',
      'dailydishrecipes',
      'lifesambrosia',
      'recipeforaparty',
      'thekitchengirl',
      'belquistwist',
      'messycuttingboard',
      'thesaltycooker',
      'saltandlavender',
      'drivemehungry',
      'lecremedelacrumb',
      'themediterraneandish',
      'natashaskitchen',
      'halfbakedharvest',
      'pinchofyum',
      'keepingthepeas',
      'theplantbasedschool',
      'minimalistbaker',
      'burrataandbubbles',
      'butterbeready',
      'jessicagavin',
      'tastesbetterfromscratch',
      'southernbite',
      'spicysouthernkitchen',
      'seriouseats',
      'billyparisi',
      'thespruceeats',
      'dirndlkitchen',
      'greenhealthycooking',
      'elavegan',
      'carolinescooking',
      'savingdessert',
      'recipesfromeurope',
      'livingsmartandhealthy',
      'hostthetoast',
      'whatscookinitalianstylecuisine',
      'olgasflavorfactory',
      'mamagourmand',
      'amazingribs',
      'hungryenoughtoeatsix',
      'blue-kitchen',
      'americastestkitchen',
      'everydaypie',
      'anediblemosaic',
      'thecozyplum',
      'simplegraytshirt',
      'foodwithfeeling',
      'ohmyveggies',
      'fromvalerieskitchen',
      'girlversusdough',
      'whitneybond',
      'the-pasta-project',
      'mangiawithmichele',
      'insidetherustickitchen',
      'giallozafferano',
      'thebakingexplorer',
      'howtomakeeasyfudge',
      'piesandtacos',
      'onesweetappetite',
      'midgetmomma',
      'gingersnapsbakingaffairs',
      'sugarsaltmagic',
      'butterwithasideofbread',
      'onmykidsplate',
      'thechunkychef',
      'abajillianrecipes',
      'tablespoon',
      'cookingwithkarli',
      'balancingmotherhood',
      'crayonsandcravings',
      'kitchendivas',
      'theslowroasteditalian',
      'pillsbury',
      'fivehearthome',
      'iamhomesteader',
      'healthyseasonalrecipes',
      'ambitiouskitchen',
      'thegirlinspired',
      'spaceshipsandlaserbeams',
      'theconsciousplantkitchen',
      'salimaskitchen',
      'simplejoy',
      'favfamilyrecipes',
      'sprinklesforbreakfast',
      'thefoodcharlatan',
      'livewellbakeoften',
      'divascancook',
      'alldayidreamaboutfood',
      'abeautifulmess',
      'kimchimari',
      'vaimomatskuu',
      'twoplaidaprons',
      'stellanspice',
      'cookerru',
      'balancewithjess',
      'chopsticksandflour',
      'seonkyounglongest',
      'futuredish',
      'asouthernsoul',
      'chewoutloud',
      'veggieanh',
      'zhangcatherine',
      'thewoksoflife',
      'funkyasiankitchen',
      'teakandthyme',
      'mjandhungryman',
      'justonecookbook',
      'pickledplum',
      'beyondkimchee',
      'koreanbapsang',
      'chefchrischo',
      'mattsfitchef',
      'chocolatecoveredkatie',
      'eatingbirdfood',
      'jordosworld',
      'thewholecook',
      'vanguardofhollywood',
      'debsdailydish',
      'saladinajar',
      'lifeloveandsugar',
      'aspicyperspective',
      'sparklesofsunshine',
      'iamafoodblog',
      'farahjeats',
      'thedefineddish',
      'food52',
      'preciouscore',
      'africanbites',
      'cottercrunch',
      'myprettybrowneats',
      'sweetpotatosoul',
      'sweetpeasandsaffron',
      'flavourandsavour',
      'veggiedesserts',
      'eatingwell',
      'nicolestastingspoon',
      'andiemitchell',
      'thepioneerwoman',
      'culinaryhill',
      'thecozycook',
      'willcookforsmiles',
      'bhg',
      'peppersandpennies',
      'bobbiskozykitchen',
      'thefoodieaffair',
      'carmyy',
      'thereciperebel',
      'lexiscleankitchen',
      'jocooks',
      'happyhealthymama',
      'browneyedbaker',
      'greensnchocolate',
      'naturalcomfortkitchen',
      'vanillaandbean',
      'suebeehomemaker',
      'simplechinesefood',
      'siftandsimmer',
      'whattocooktoday',
      'deliciousnotgorgeous',
      'zenaskitchen',
      'plantbasedjess',
      'thewanderlustkitchen',
      'createmindfully',
      'myplantifulcooking',
      'joyfoodsunshine',
      'myeclecticbites',
      'theveglife',
      'thissavoryvegan',
      'muybuenoblog',
      'saltandbaker',
      'mexicoinmykitchen',
      'spanishsabores',
      'keviniscooking',
      'boulderlocavore',
      'everydaylatina',
      'thecleaneatingcouple',
      'abeautifulplate',
      'simple-veganista',
      'selfproclaimedfoodie',
      'flavorfuleats',
      'richanddelish',
      'jamieoliver',
      'barleyandsage',
      'chsugar',
      'lazycatkitchen',
      'agirldefloured',
      'domesticate-me',
      'eatthebite',
      'anitalianinmykitchen',
      'southernplate',
      'theseasonedmom',
      'newsouthcharm',
      'thepalatablelife',
      'ihearteating',
      'kimscravings',
      'freshcoasteats',
      'oliviascuisine',
      'soulfullymade',
      'littlesweetbaker',
      'flavorthemoments',
      'cloudykitchen',
      'yourhomebasedmom',
      'oreo',
      'bakingamoment',
      'littlespoonfarm',
      'allthehealthythings',
      'manilaspoon',
      'restlesschipotle',
      'cleverlysimple',
      'goodhousekeeping',
      'casuallypeckish',
      'veganpunks',
      'sixhungryfeet',
      'kitchensanctuary',
      'feastingathome',
      'evolvingtable',
      'familyfreshmeals',
      'melskitchencafe',
      'cookingformysoul',
      'nospoonnecessary',
      'wyseguide',
      'theflavorbender',
      'hostessatheart',
      'marthastewart',
      'pinchandswirl',
      'marystestkitchen',
      'purplechives',
      'okonomikitchen',
      'cosetteskitchen',
      'platingsandpairings',
      'familystylefood',
      'justinesnacks',
      'theoriginaldish',
      'kristineskitchenblog',
      'prettysimplesweet',
      'mybakingaddiction',
      'yellowblissroad',
      'howsweeteats',
      'girlheartfood',
      'passmesometasty',
      'toriavey',
      'creative-culinary',
      'alliannaskitchen',
      'deliciouslittlebites',
      'thesoccermomblog',
      'howtofeedaloon',
      'nourishedkitchen',
      'justapinch',
      'wildernesswife',
      'thesaltedpepper',
      'davidlebovitz',
      'cookingwiththeskinnyguinea',
      'plattertalk',
      'savoryexperiments',
      'servedfromscratch',
      'washingtonpost',
      'thewickednoodle',
      'theseoldcookbooks',
      'goodlifeeats',
      'buttermilkbysam',
      'abountifulkitchen',
      'brownedbutterblondie',
      'healthienut',
      'thevegan8',
      'acozykitchen',
      'thestayathomechef',
      'whatsgabycooking',
      'getsetvegan',
      'bakedbyclaire',
      'figjar',
      'organicallyaddison',
      'myveganminimalist',
      'joskitchenlarder',
      'fandbrecipes',
      'pureandsimplenourishment',
      'realfoodrealdeals',
      'beyondthechickencoop',
      'mypocketkitchen',
      '40aprons',
      'mylatinatable',
      'jaroflemons',
      'austria',
      'bellyfull',
      'iheartnaptime',
      'inspiredtaste',
      'iambaker',
      'thecountrycook',
      'feelgoodfoodie',
      'damndelicious',
      'angsarap',
      'simplystacie',
      'theroastedroot',
      'honest-food',
      'veganeasy',
      'tastelife',
    ];

    blogs.forEach(b => {
      this.is.getObject(`Topics/Foodblogs/${b}`).take(1).subscribe(l => {
        if (!l) {
          console.log(b);
        }
      });
    });
  }


  delayx(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async stuffFestival() {
    const inputs = [
      {url: 'https://festivalportasdosol.pt/', ukey: 'festivalportasdosol', name: 'Portas do Sol Festival'},
    ];
    for (const i of inputs) {
      await this.delayx(30 * 1000); // Wait for 60 seconds
      const ukey = i['ukey'];
      console.log('PATH: ', `Topics/TempInfo/newfestivals/${ukey}`);
      this.is.setObjNoLog(`Topics/TempInfo/newfestivals/${ukey}`, i);
    }
  }


  removeDuplicates(articles: Wish[][]): Wish[] {
    const articleMap = new Map<string, Wish>();
    const geohashLengthMap = new Map<string, number>();

    // Flatten the articles while keeping track of the geohash array length
    articles.forEach((articleArray, index) => {
      const arrayLength = articleArray.length;

      articleArray.forEach(article => {
        const existingLength = geohashLengthMap.get(article.ukey);

        // Check if this article has been seen in a shorter geohash array or not seen at all
        if (existingLength === undefined || arrayLength < existingLength) {
          articleMap.set(article.ukey, article);
          geohashLengthMap.set(article.ukey, arrayLength);
        }
      });
    });

    // Return the unique set of articles from the map
    return Array.from(articleMap.values());
  }

  findBoundingBoxCorners(coords: LatLng[]): any {
    if (coords.length === 0) {
      throw new Error('No coordinates provided');
    }

    console.log('COORD', coords);

    let minLat = coords[0].lat;
    let maxLat = coords[0].lat;
    let minLng = coords[0].lng;
    let maxLng = coords[0].lng;

    coords.forEach(coord => {
      if (coord.lat < minLat) {
        minLat = coord.lat;
      }
      if (coord.lat > maxLat) {
        maxLat = coord.lat;
      }
      if (coord.lng < minLng) {
        minLng = coord.lng;
      }
      if (coord.lng > maxLng) {
        maxLng = coord.lng;
      }
    });

    return {
      southWest: {lat: minLat, lng: minLng},
      northEast: {lat: maxLat, lng: maxLng}
    };
  }

  getArticles(wish: Wish, ukey: string) {
    let about = null;
    let p = null;
    if (wish.trip && wish.trip.length) {
      wish.trip.forEach(t => {
        if (t.aboutit) {
          about = t.ukey;
          p = {lat: t.lat, lng: t.long};
        }
      });
    }


    if (!about && wish.mentions && wish.mentions.length) {
      wish.mentions.forEach(t => {
        if (t.aboutit) {
          about = t.ukey;
          p = {lat: t.lat, lng: t.long};
        }
      });
    }


    if (!about && wish.trip && wish.trip.length) {
      about = wish.trip[0].ukey;
      p = {lat: wish.trip[0].lat, lng: wish.trip[0].long};
    }


    if (!about && wish.mentions && wish.mentions.length) {
      about = wish.mentions[0].ukey;
      p = {lat: wish.mentions[0].lat, lng: wish.mentions[0].long};
    }

    if (about) {
      this.gs.fetchArticlesForGeohashes({
        latitude: p['lat'],
        longitude: p['lng']
      }, 20)
        .then(observable => {
          observable.subscribe({
            next: articles => {
              // this.articles = articles;
              console.log('Articles fetched:', articles);
              if (articles && articles.length) {
                const removedups = this.removeDuplicates(articles);

                if (removedups && removedups.length) {

                  const arr: any[] = [];
                  removedups.forEach(dupe => {
                    if (dupe.trip && dupe.trip.length) {
                      dupe.trip.forEach(trip => {
                        if (trip.hash === dupe['ux']) {
                          const lnglat = {lat: parseFloat(trip.lat), lng: parseFloat(trip.long)};
                          dupe['lnglat'] = lnglat;
                          arr.push(lnglat);
                          trip.aboutit = true;
                        } else {
                          trip.aboutit = false;
                        }
                      });
                    }

                    if (dupe.mentions && dupe.mentions.length) {
                      dupe.mentions.forEach(mention => {
                        if (mention.hash === dupe['ux']) {
                          const lnglat = {lat: parseFloat(mention.lat), lng: parseFloat(mention.long)};
                          dupe['lnglat'] = lnglat;
                          arr.push(lnglat);
                          mention.aboutit = true;
                        } else {
                          mention.aboutit = false;
                        }
                      });
                    }


                  });

                  console.log('REMOVES', removedups);
                  const bbox = this.findBoundingBoxCorners(arr);
                  console.log('BBOX', bbox);

                  // the 20 in the address below refers to the radius in miles that we looked for articles
                  this.is.setObjNoLog(`Topics/TravelNotificationsSupport/articles/${wish.ukey}/20/articles`, removedups);
                  const date = new Date().getTime();
                  this.is.setObjNoLog(`Topics/TravelNotificationsSupport/articles/${wish.ukey}/20/meta`,
                    {
                      date,
                      bbox,
                      caller: `Topics/TravelNotifications/${wish.ukey}`
                    });
                  this.is.setObjNoLog(`Topics/TempInfo/guidescache/${ukey}/nearbyarticles`, `Topics/TravelNotificationsSupport/articles/${wish.ukey}/20`);
                }


              }
            },
            error: err => {
              console.log('error');
            }
          });
        })
        .catch(err => {
          console.error('Failed to fetch geohashes or articles:', err);
        });
    }
  }


  repairPlace() {
    const badukey = 'ChIJ5eAM-CiZ7ocRvEHh9RN7WcE';
    const goodukey = 'ChIJyz62I7rQXBgRjhP8bqtIyFI';
    /*
        this.is.getList(`Places/${goodukey}/guides`).take(1).subscribe(objs => {
          objs.forEach(obj => {
            console.log('PATH', `Places/${badukey}/guides/${obj['ukey']}`)
            this.is.setObjNoLog(`Places/${badukey}/guides/${obj['ukey']}`, obj)
          })

        })
    */


    this.is.getObject(`Places/${badukey}`).take(1).subscribe(obj => {

      let objstring = JSON.stringify(obj);
      const pattern = new RegExp(badukey, 'g');
      objstring = objstring.replace(pattern, goodukey); // Assign the result back to objstring
      const newobj = JSON.parse(objstring);

      console.log('newobj', newobj);
      //this.is.setObjNoLog(`Places/${goodukey}`, newobj)

      if (newobj.guides) {
        const newguides = Object.values(newobj.guides);

        console.log('NEWGUIDE', newguides);

        newguides.forEach(guide => {
          this.is.getObject(`Topics/TravelNotifications/${guide['ukey']}`).take(1).subscribe(article => {
            if (article) {
              console.log('ArticlePath', `Topics/TravelNotifications/${guide['ukey']}`);
              console.log('Article', guide);
              this.is.setObjNoLog(`Topics/TravelNotifications/${guide['ukey']}`, guide);
            }
          });


        });
      }
    });

  }

  check() {
    const urls = [
      {ukey: 'ChIJF4J2odGkYYgRfezA53OroOM', country: 'Alabama'},
      {ukey: 'ChIJbeNmnljVYYgRtXManwtkzB0', country: 'Alabama'},
      {ukey: 'ChIJOSO8gCaW71YRNMoOwrp-S4I', country: 'Alaska'},
      {ukey: 'ChIJE-20n-0s0lARk3RiU6AV2_g', country: 'Alaska'},
      {ukey: 'ChIJmcj5hnFmzFARQItAvFyfk60', country: 'Alaska'},
      {ukey: 'ChIJCaoIBmOcx1YRZNwuJ-08FBI', country: 'Alaska'},
      {ukey: 'ChIJGU7QANioqlYRwDdzQIbyHR8', country: 'Alaska'},
      {ukey: 'ChIJNQfD_jexq1cRBWjmCjBPbF0', country: 'Alaska'},
      {ukey: 'ChIJ3zB887XwxFYRyVL1kgdfI24', country: 'Alaska'},
      {ukey: 'ChIJBRbQiP_zzVYRpTYZExU7tcY', country: 'Alaska'},
      {ukey: 'ChIJK_UwKpOss1YRasgF0kXiiBU', country: 'Alaska'},
      {ukey: 'ChIJbcXUoz4vKFERYkhcRDyuSp0', country: 'Alaska'},
      {ukey: 'ChIJlVS0K-7C6VYR8unqDr4HTUw', country: 'Alaska'},
      {ukey: 'ChIJRx3UY-WEpnERNJZ__Rw4Hy8', country: 'American Samoa'},
      {ukey: 'ChIJnRoh8NyQOocR34gtmApA864', country: 'Arizona'},
      {ukey: 'ChIJdwsZMhjU0IYR4RvdyhSyKiw', country: 'Arizona'},
      {ukey: 'ChIJySA8pmfD2YYRKYgd6SHpWwQ', country: 'Arizona'},
      {ukey: 'ChIJ84dVWswTMocRXIcKtMGTHNE', country: 'Arizona'},
      {ukey: '2ChIJu5D_YT-NLYcR0HeMq65lAdg', country: 'Arizona'},
      {ukey: 'ChIJ3bwaMAVBKocR1BEf5sgdGf8', country: 'Arizona'},
      {ukey: 'ChIJeV-6He2Iy4ARGrlUHSJkHbw', country: 'Arizona'},
      {ukey: 'ChIJQ4BQcIpY1YARoNSUlhK4WGM', country: 'Arizona'},
      {ukey: 'ChIJY3ZWFrpv1oYRKo6u5b-Wx5E', country: 'Arizona'},
      {ukey: 'ChIJVzKfs23fK4cRAzakfKryyMk', country: 'Arizona'},
      {ukey: 'ChIJc8JPWteg1oYRTV3cJUPFuzU', country: 'Arizona'},
      {ukey: 'ChIJfXug0zUOLYcRGmxY_2Qp3pQ', country: 'Arizona'},
      {ukey: 'ChIJDySciR51MocRjO0cO3L3STo', country: 'Arizona'},
      {ukey: 'ChIJFU2bda4SM4cRKSCRyb6pOB8', country: 'Arizona'},
      {ukey: 'ChIJ_5k27bkxMYcRwzRtR6pa-Gc', country: 'Arizona'},
      {ukey: 'ChIJr6R5vy0iyYcR7ErImUaurnc', country: 'Arkansas'},
      {ukey: 'ChIJlfUUPk8qzYcR3UgpdjlmLVg', country: 'Arkansas'},
      {ukey: 'ChIJlwIoKEy70ocRPuD1ukBzVB8', country: 'Arkansas'},
      {ukey: 'ChIJHXKUS-jbhYARPV3_2s8dToM', country: 'California'},
      {ukey: 'ChIJrWJnvbSRhYAR-a__aHZY_3o', country: 'California'},
      {ukey: 'ChIJPRpAsX54hYARamj2b8OoYMc', country: 'California'},
      {ukey: 'ChIJ0abUxAeCz4AReorhmt9tbRo', country: 'California'},
      {ukey: 'ChIJUSOAoyYGz4ARO1FWxnoAWns', country: 'California'},
      {ukey: 'ChIJ7bPtqOGAhYARlc1YRlOfGrc', country: 'California'},
      {ukey: 'ChIJFXm2LM5S6IARzDo6cliV5PU', country: 'California'},
      {ukey: 'ChIJeQujjQLx0lQRzrq0MLTs2p8', country: 'California'},
      {ukey: 'ChIJ9QcCAMAh6IARZYhbHkakBfY', country: 'California'},
      {ukey: 'ChIJR4qudndLx4ARVLDye3zwycw', country: 'California'},
      {ukey: 'ChIJe6hluYWP2oAR4p3rOqftdxk', country: 'California'},
      {ukey: 'ChIJn93OiYBDkoAR7kSomO77gps', country: 'California'},
      {ukey: 'ChIJpX9B9TZm0FQRjl87lWYyzzY', country: 'California'},
      {ukey: 'ChIJvzhBwQdWnYARQmdmeqfYNI8', country: 'California'},
      {ukey: 'ChIJxdYX1GGOhYARiIigVMJ9TOY', country: 'California'},
      {ukey: 'ChIJxeyK9Z3wloAR_gOA7SycJC0', country: 'California'},
      {ukey: 'ChIJpxYJijkcDIcRutbollNh3nE', country: 'Colorado'},
      {ukey: 'ChIJ6QNZReR5aYcRF4KOp0PuJ_o', country: 'Colorado'},
      {ukey: 'ChIJD4gn9F12P4cRPxxm6rvL4Ro', country: 'Colorado'},
      {ukey: 'ChIJH3HXLr9gOYcRYxj2G6KV9nk', country: 'Colorado'},
      {ukey: 'ChIJM3xUHp-ZOYcRDuKQA1Cv-uo', country: 'Colorado'},
      {ukey: 'ChIJaUPDHsEDR4cR7cAxmf8VdRU', country: 'Colorado'},
      {ukey: 'ChIJx8vZHeYvFIcR2YMP0IqR1zM', country: 'Colorado'},
      {ukey: 'ChIJ_RKsRAUC6IkRtRbUi4SOsQ4', country: 'Connecticut'},
      {ukey: 'ChIJO9Q6LXADx4kRrv6coR7mHPw', country: 'Delaware'},
      {ukey: 'ChIJJQI5PpeZ5ogRjz5d0IE_8_Q', country: 'Florida'},
      {ukey: 'ChIJ2aqzHqXL4IgRnrjaoVQqFRM', country: 'Florida'},
      {ukey: 'ChIJV1BRyvUD9YgREE_OCsyMEBw', country: 'Georgia'},
      {ukey: 'ChIJCc3ghkPi5IgRu9qt92GrB7E', country: 'Georgia'},
      {ukey: 'ChIJbfcDO6QV9YgR1VrRaP2WVbM', country: 'Georgia'},
      {ukey: 'ChIJSV4UTxf-84gRS1bjXgPazg4', country: 'Georgia'},
      {ukey: 'ChIJT1VElh0O9YgRAnfI-QEyK48', country: 'Georgia'},
      {ukey: 'ChIJgZqvnip3H2cRKG8wbBr0_2A', country: 'Guam'},
      {ukey: 'ChIJZ5ZndgsOVHkRHBiZEwSmzRQ', country: 'Hawaii'},
      {ukey: 'ChIJscm5wLZhUXkRJq6EPCZ7Wz4', country: 'Hawaii'},
      {ukey: 'ChIJ0UugJTS3VHkRyvT16Ij9I5w', country: 'Hawaii'},
      {ukey: 'ChIJ8Rta7MsBVHkRlOJC-rSP0aQ', country: 'Hawaii'},
      {ukey: 'ChIJsYrTGczHqn4RkEjJxroBbAo', country: 'Hawaii'},
      {ukey: 'ChIJzXm60_tGqoARr__paiQWatQ', country: 'Idaho'},
      {ukey: 'ChIJRblZCYYmDogRG6EuHCzAdBE', country: 'Illinois'},
      {ukey: 'ChIJ42pvr3z_bYgRRdr-b4aJ7_8', country: 'Indiana'},
      {ukey: 'ChIJkf8u-Bty5IcRicbTuorJzX0', country: 'Iowa'},
      {ukey: 'ChIJJwe7j7CC_IcRnBxCFvGefSk', country: 'Iowa'},
      {ukey: 'ChIJK24Qz-kCRYcRPf3mCvjLJlE', country: 'Jensen'},
      {ukey: 'ChIJBVYSjxmNaIgRO3Hv_lwikZ0', country: 'Kentucky'},
      {ukey: 'ChIJy5ua4k6dXIgRr2NYdrmy-yk', country: 'Kentucky'},
      {ukey: 'ChIJq6qqDlxOXYgR_ii6oySN4R0', country: 'Kentucky'},
      {ukey: 'ChIJC7IMMLX0QogRSuAQt5wDEBY', country: 'Kentucky'},
      {ukey: 'ChIJRxmleGJ4MIYRQrcQgsVkxOY', country: 'Louisiana'},
      {ukey: 'ChIJ850nNYvGLoYRfVw-RHh5Fvc', country: 'Louisiana'},
      {ukey: 'ChIJ6xwex32YIIYRshnjL-oYt8U', country: 'Louisiana'},
      {ukey: 'ChIJr35D2Q3QukwRUFj7gK6IXVk', country: 'Maine'},
      {ukey: 'ChIJJSmiDrKjrkwRhFVV_A4i32I', country: 'Maine'},
      {ukey: 'ChIJA9TDRJYDyIkRoutpfytaUKQ', country: 'Maryland'},
      {ukey: 'ChIJz2yfIJgDyIkREhJ4NEghTeo', country: 'Maryland'},
      {ukey: 'ChIJ6yp3SmDKuYkRB0pp381zYpI', country: 'Maryland'},
      {ukey: 'ChIJ_atQhxmwt4kRSXEK4aStgeY', country: 'Maryland'},
      {ukey: 'ChIJl4TCYXKvt4kRF2noYzM0LZ4', country: 'Maryland'},
      {ukey: 'ChIJFTV_kfrZyYkRpcRXrgna3lI', country: 'Maryland'},
      {ukey: 'ChIJ4Uwo9lrKt4kR_qOgXjs4oIk', country: 'Maryland'},
      {ukey: 'ChIJOy4VUVrKt4kRimB6Gei8eP0', country: 'Maryland'},
      {ukey: 'ChIJO28VH5Kkt4kRqOveZpIQY9c', country: 'Maryland'},
      {ukey: 'ChIJlfElC63lyYkRDfDomSVurWs', country: 'Maryland'},
      {ukey: 'ChIJVfrJ7NQ4uYkRjDPiJ8c5LbI', country: 'Maryland'},
      {ukey: 'ChIJ_9i3O4Cwt4kRZh0n0JzZzcI', country: 'Maryland'},
      {ukey: 'ChIJ38Vox9xC-4kRCPZi8cL20ag', country: 'Massachusetts'},
      {ukey: 'ChIJm1PrHPJw44kRDcBCdZT3MUM', country: 'Massachusetts'},
      {ukey: 'ChIJq5NOTPHj5IkR36G3_pWG598', country: 'Massachusetts'},
      {ukey: 'ChIJbbPB5rB844kR7hOzzjBr4Cs', country: 'Massachusetts'},
      {ukey: 'ChIJS19dTWYU44kROIYHLCK5R3A', country: 'Massachusetts'},
      {ukey: 'ChIJww3TtySa44kRer5fz4_PmeA', country: 'Massachusetts'},
      {ukey: 'ChIJSdCSfa23UE0R48sEvBtbfHM', country: 'Michigan'},
      {ukey: 'ChIJoZYDbByDWU0RXzugIWJZcsI', country: 'Michigan'},
      {ukey: 'ChIJzfO3gExuO4gRf-_7nb7oTSM', country: 'Michigan'},
      {ukey: 'ChIJr_POi3UeHogRIZNo27kSAkw', country: 'Michigan'},
      {ukey: 'ChIJrbQythhkWE0RZCg-ZYG_VBQ', country: 'Minnesota'},
      {ukey: 'ChIJHdyLsZwq9ocRBXrrMHRemq8', country: 'Minnesota'},
      {ukey: 'ChIJ80MXQF7_fYgRvuZWPEkyQ8s', country: 'Mississippi'},
      {ukey: 'ChIJaQKnrWlMh4gRwUS37gftzbY', country: 'Mississippi'},
      {ukey: 'ChIJBebRstvhKIYRg9N3vHDfoEc', country: 'Mississippi'},
      {ukey: 'ChIJjxpQXuL2JYYRCu4Srz_70G0', country: 'Mississippi'},
      {ukey: 'ChIJtbwVbuKy2IcR8dRuQlc8OPg', country: 'Missouri'},
      {ukey: 'ChIJW73IpoaB2IcR1lE6ixfbdJI', country: 'Missouri'},
      {ukey: 'ChIJW0E0sJJnz4cRrN3UwwO7kLM', country: 'Missouri'},
      {ukey: 'ChIJW6TeAWUV14cRJQmV99vhx30', country: 'Missouri'},
      {ukey: 'ChIJof1CcwyJyIcRWWz3l89g-_4', country: 'Missouri'},
      {ukey: 'ChIJC73pDG62SVMRUVX1b9gq3Ns', country: 'Montana'},
      {ukey: 'ChIJlTL0wl-CXFMR5Uc2R9hVOrs', country: 'Montana'},
      {ukey: 'ChIJB_wnC3p0WVMRzh8CVw5khB4', country: 'Montana'},
      {ukey: 'ChIJVVVVVRWQaFMR7F3FFSK8Fq8', country: 'Montana'},
      {ukey: 'ChIJv4G_cKJvlocRP55DtE0QuoQ', country: 'Nebraska'},
      {ukey: 'ChIJa0iNJ_bZZIcR973ytF0x4rw', country: 'Nebraska'},
      {ukey: 'ChIJAw3M1yVcsYARMb2-rw911Dw', country: 'Nevada'},
      {ukey: 'ChIJ____UEVAwYkR1bqvM3SLvjM', country: 'New Jersey'},
      {ukey: 'ChIJG-vyNc_9wokRZ9ntKcnS1tc', country: 'New Jersey'},
      {ukey: 'ChIJzXV5k5Gmw4kR2-DiwJiwi5g', country: 'New Jersey'},
      {ukey: 'ChIJyQSMEVpsIocR8rIioI2Vta8', country: 'New Mexico'},
      {ukey: 'ChIJW9e4xBN544YRvbI7vfc91G4', country: 'New Mexico'},
      {ukey: 'ChIJbd1AXYd2PIcRqcvvx8haWVc', country: 'New Mexico'},
      {ukey: 'ChIJ-SSYZGAAGYcRSlQyMKzdBvc', country: 'New Mexico'},
      {ukey: 'ChIJ83IQBwNj4IYRwyC_Z4cY0TY', country: 'New Mexico'},
      {ukey: 'ChIJKUIDHdGoPIcREkWgK6CkrYU', country: 'New Mexico'},
      {ukey: 'ChIJP4i51p1tGIcRvYnIv_TTxh4', country: 'New Mexico'},
      {ukey: 'ChIJ-_m-2dEk3okRGXKXwMq9TE8', country: 'New York'},
      {ukey: 'ChIJ_9IMOtK30IkRP_-Gek0dWMs', country: 'New York'},
      {ukey: 'ChIJeyMe6olL6IkRlDNF4seDH1w', country: 'New York'},
      {ukey: 'ChIJM-wiZHCDqIkRnUzpO-IYryo', country: 'North Carolina'},
      {ukey: 'ChIJJ3qVPPovqokR458YjVLg_Ls', country: 'North Carolina'},
      {ukey: 'ChIJXasCYi0cU4gRjqFezuokWME', country: 'North Carolina'},
      {ukey: 'ChIJkTu2v2wwpIkRhtig_WIr3oo', country: 'North Carolina'},
      {ukey: 'ChIJOfrW_4ZWOogR1LNsvnnUtOE', country: 'Ohio'},
      {ukey: 'ChIJW41W6ikQR4gRWfRGBFRUAM8', country: 'Ohio'},
      {ukey: 'ChIJdfUpIkjeMIgRquWefEq-yco', country: 'Ohio'},
      {ukey: 'ChIJNYcgAMwos4cRi8DweU7Qqpw', country: 'Oklahoma'},
      {ukey: 'ChIJfRLy-3nuz1QRcz6zAslvj5I', country: 'Oregon'},
      {ukey: 'ChIJOePlQAgXxlQRo-MvReHyK5A', country: 'Oregon'},
      {ukey: 'ChIJez1L_tgMvFQRYUInHEvBo1o', country: 'Oregon'},
      {ukey: 'ChIJt5jBk2j4o1QRRzX1dwNjBbo', country: 'Oregon'},
      {ukey: 'ChIJj7H7urtHNYgRde9j6ODlP-w', country: 'Pennsylvania'},
      {ukey: 'ChIJwbUEi-9xNYgRRkhOcPSQoMk', country: 'Pennsylvania'},
      {ukey: 'ChIJGVDvkoTIxokRIQNxPpqAR4Y', country: 'Pennsylvania'},
      {ukey: 'ChIJQd8arWHiyokRKthBRHyXxw0', country: 'Pennsylvania'},
      {ukey: 'ChIJb_iFIvKTxokR-9Ha5lAtuG4', country: 'Pennsylvania'},
      {ukey: 'ChIJ3apebHJiw4kR-VuCgo-lOf0', country: 'Pennsylvannia'},
      {ukey: 'ChIJG8vfQ2Vd5IkR4TdTyVXOoXw', country: 'Rhode Island'},
      {ukey: 'ChIJra7cnxlF5IkRy6lURQFivEQ', country: 'Rhode Island'},
      {ukey: 'ChIJ8WvU9hcT_IgRi3bAMIAuufI', country: 'South Carolina'},
      {ukey: 'ChIJY18O8j4P-IgRkk-Z57Bx3z4', country: 'South Carolina'},
      {ukey: 'ChIJBwa8iRQ1_4gRmiPbgxV26CE', country: 'South Carolina'},
      {ukey: 'ChIJxVh5jFHkVogR9UsVUNe7aHM', country: 'South Carolina'},
      {ukey: 'ChIJCRythmbDYocRFojmT-EmAMM', country: 'South Dakota'},
      {ukey: 'ChIJc9VWJGjWfIcRf6VZJY9Q7Tk', country: 'South Dakota'},
      {ukey: 'ChIJIyAFGAyHfocR8Hfm2jFeaGk', country: 'South Dakota'},
      {ukey: 'ChIJX6l4o96hj4cRK5XxoQAbOuI', country: 'South Dakota'},
      {ukey: 'ChIJJTHDowQIZIgREZ7T6hJweg0', country: 'Tennessee'},
      {ukey: 'ChIJMaqSUqEYe4gR1S1KotrSANM', country: 'Tennessee'},
      {ukey: 'ChIJtTBl_i7AXYgRrJYRxMe7Nc4', country: 'Tennessee'},
      {ukey: 'ChIJ5Q1gqRZ0XYgRuuOf4kGOs7s', country: 'Tennessee'},
      {ukey: 'ChIJ5zEY1WuyfYgRXs2w64Pr5eQ', country: 'Tennessee'},
      {ukey: 'ChIJFRj7iGuYXogRYSR1p8Fvkjw', country: 'Tennessee'},
      {ukey: 'ChIJcZNYLBZLW4YR2u1D58qOVZU', country: 'Texas'},
      {ukey: 'ChIJNyNcWpKXb4YRXgzCgXbRuY4', country: 'Texas'},
      {ukey: 'ChIJ6QybPlLHBocReUhBkOXOa2U', country: 'Texas'},
      {ukey: 'ChIJr7gIEV_r74YRhWA7uogpb2s', country: 'Texas'},
      {ukey: 'ChIJM0zoOlWDT4YRYn5ucQ-_hcA', country: 'Texas'},
      {ukey: 'ChIJBRDHA88Z5IYRrQYT7d6zhNw', country: 'Texas'},
      {ukey: 'ChIJIakuJC33aIYRYK_SNaO2Alk', country: 'Texas'},
      {ukey: 'ChIJK-_lS3AX8oYRD4WDJ7x9RmQ', country: 'Texas'},
      {ukey: 'ChIJN3pIv973XIYRu_gPhfzki94', country: 'Texas'},
      {ukey: 'ChIJVbNo2niYW4YRRwvfNo9kA7w', country: 'Texas'},
      {ukey: 'ChIJm6uiKCASBYwRBgHYhIjkW7c', country: 'The U.S. Virgin Islands'},
      {ukey: 'ChIJww1quu21GowRD8wD18g_ThQ', country: 'The U.S. Virgin Islands'},
      {ukey: 'ChIJyRp0ZVXKGowRQ7QlScQQCIE', country: 'The U.S. Virgin Islands'},
      {ukey: 'ChIJr8Hm-RWsVIcRdJ7UfOXhobs', country: 'Utah'},
      {ukey: 'ChIJUaoNhhr2yoARlcQo0WnqQk8', country: 'Utah'},
      {ukey: 'ChIJU30g_n9htYARfjl4mtXiIp8', country: 'Utah'},
      {ukey: 'ChIJJUbATtdfUocR0zR-R0ZM7as', country: 'Utah'},
      {ukey: 'ChIJKfjMD42gN4cRF-18uRLeV24', country: 'Utah'},
      {ukey: 'ChIJqbW0YKEUNIcRgXACEpFgMPs', country: 'Utah'},
      {ukey: 'ChIJ2fhEiNDqyoAR9VY2qhU6Lnw', country: 'Utah'},
      {ukey: 'ChIJLevDAsZrNYcRBm2svvvY6Ws', country: 'Utah'},
      {ukey: 'ChIJU6LnB_8ASocRB_9PSFPsO94', country: 'Utah'},
      {ukey: 'ChIJmV4oRDZpNocRID0q91SxkVY', country: 'Utah'},
      {ukey: 'ChIJqaYYRe7hR4cRquYCxalSpBU', country: 'Utah'},
      {ukey: 'ChIJXY5LYx7Y4YkReuUqQyfphuI', country: 'Vermont'},
      {ukey: 'ChIJCfcozoz8sokRXjswdERbdcQ', country: 'Virginia'},
      {ukey: 'ChIJmyW5tMj9wokR-tt4fTtU6GQ', country: 'Virginia'},
      {ukey: 'ChIJvWf9tdGauokRl1eW9W1kysc', country: 'Virginia'},
      {ukey: 'ChIJq7866JKosYkRnFIpiNC5_3w', country: 'Virginia'},
      {ukey: 'ChIJ93RagxNKtokRP83pbY44vWU', country: 'Virginia'},
      {ukey: 'ChIJ8921Cnz3tokRqAutVHRCj1U', country: 'Virginia'},
      {ukey: 'ChIJESb8ihdhtIkRMYiMZWR5F-Y', country: 'Virginia'},
      {ukey: 'ChIJYVYjPfJntokRc-8wdY7ANXY', country: 'Virginia'},
      {ukey: 'ChIJf9tiiLWMj1QRrxtbtUt0e0Y', country: 'Washington'},
      {ukey: 'ChIJyRpA6a98j1QRbFws_GilBwE', country: 'Washington'},
      {ukey: 'ChIJ0XIEzwmAjlQRUXl9squHIAA', country: 'Washington'},
      {ukey: 'ChIJEfRsULgShVQR7TNkrrBPCFI', country: 'Washington'},
      {ukey: 'ChIJVVVVVQHAnVQRlwGriYtRyIE', country: 'Washington'},
      {ukey: 'ChIJh0vJ7ubNkFQRGKSRT_uh9Fw', country: 'Washington'},
      {ukey: 'ChIJZ39vBmm5t4kRkTCFLVYwNYo', country: 'Washington, D.C.'},
      {ukey: 'ChIJy_35qFsdtokRRZ61H23WXqQ', country: 'West Virginia'},
      {ukey: 'ChIJ9bKCveaVTogRBg6SCGan2xw', country: 'West Virginia'},
      {ukey: 'ChIJ6e7B9MWxTogRo8GcFdKEA60', country: 'West Virginia'},
      {ukey: 'ChIJx9GYurOaslIRRv1qbAc8Zdw', country: 'Wisconsin'},
      {ukey: 'ChIJNVKOKKb-qFIRo7WAm48sdwQ', country: 'Wisconsin'},
      {ukey: 'ChIJn6RFjoKzZYcRW6QjCr_kTh4', country: 'Wyoming'},
      {ukey: 'ChIJqRtdyZ5RUlMRN6ORzI64oKU', country: 'Wyoming'},
      {ukey: 'ChIJVVVVVVXlUVMRu-GPNDD5qKw', country: 'Wyoming'},];


    urls.forEach(obj => {
      this.is.setObjNoLog(`Places/${obj.ukey}/location/country`, obj.country);
    });

  }


  ngOnInit() {

/*
    this.is.getList('Topics/TempInfo/guidescachetmp').take(1).subscribe(list => {
      list.forEach(l => {
        if (!l['mentions'] && !l['trip']) {
          console.log(l['ukey'])
        }
      })
    })
*/

    // this.check()
    //
    // this.is.deleteObjNoLog('Topics/TmpTravelpods/guides')
    // this.is.deleteObjNoLog('Topics/TmpTravelmags/guids')

    // this.stuffFestival()
//
    // this.stuffWine();

    /*
        this.http.compareRecipes().then(res => {
          console.log('RES', res)
        })

    */


//    window.open('https://www.instagram.com', '_blank');
    /*
      setInterval(() => {
        window.open('https://www.instagram.com', '_blank');

      }, 600000)

     */


    // this.wikime();

    // todo: DO NOT DO NOT DELETE
    this.createRBush() // last created 12/19/24
    // this.boardArticles() // 1714431583399 last run on 10/14/24 - shouldnt need to use this anymore

    // this.fixArticlesHash()

//TmpTravelInstagrams
    /*this.is.getList('Topics/InstyTravelFeed').take(1).subscribe(val => {
      console.log('LENGTH', val.length)
    })*/


    /*
        setInterval(() => {
          console.log('test')
            this.boardArticles()
        }, 2000);
    */


    /*
    this.is.getLimitToNextnKeys(5000, 'PlaceExists', 'ChIJxcZRn193cUgR09fAewnBVAQ').take(1).subscribe(places => {
              console.log('LENGTHx', places.length);
              places.forEach(p => {
                console.log('LAST', p.ukey);
                if (p.ukey && !p.ukey.startsWith('alais')) {
                  this.is.getObject(`Places/${p.ukey}/ukey`).take(1).subscribe(uk => {
                    console.log('pop', uk, p.ukey)
                    if (uk && uk === p.ukey) {
                      this.is.getObject(`Places/${p.ukey}/location/country`).take(1).subscribe(country => {
                        if (!country) {
                          console.log('no country', p.ukey)
                          this.is.getObject(`Places/${p.ukey}/location/CountryName`).take(1).subscribe(countryname => {
                            if (countryname) {
                              console.log('countryName', p.ukey)
                              this.is.setObjNoLog(`Places/${p.ukey}/location/country`, countryname)
                            } else {
                              this.is.setObjNoLog(`Topics/TempInfo/fix/${p.ukey}`, {ukey: p.ukey});
                            }
                          })
                        } else {
                          console.log(country)
                        }
                      });
                    } else {
                      this.is.setObjNoLog(`Topics/TempInfo/fixdead/${p.ukey}`, {ukey: p.ukey});
                    }
                  });
                }
              });
            });
    */


    /* const deviceid = '1007529';
     this.is.getObject(`Topics/TempInfo/workspace/${deviceid}`).subscribe(res => {
       if (res) {
         this.is.deleteObjNoLog(`Topics/TempInfo/workspace/${deviceid}`);
         const key = Object.keys(res)[0];
         // todo: need to close everything and zero everything out
         this.onCloseLoc();
         this.onCloseMention();
         this.clipped = res[key];
         const ret = confirm(`Add: ${this.clipped.name}?`);
         if (ret) {
           if (this.clipped['fmt']) {
             this.onGoogle(false, 'Google');
             this.onFinishBoard('location');
           } else {
             this.onAddMention();
             this.onFinishBoard('mentions');
           }
         } else {

         }
       }
     });*/

    this.kingdomarr = _.orderBy([...new Set(this.classifications.map(c => c.kingdom))]);

    this.cruisespos = ThingsSeeDo.findIndex(d => d.name === 'Cruises');
    this.pointspos = TravelTips.findIndex(d => d.name === 'Reward Points');

    this.familypos = WhoFor.findIndex(d => d.name === 'Family');
    this.hikingpos = ThingsSeeDo.findIndex(d => d.name === 'Hiking');
    this.disneypos = ThingsSeeDo.findIndex(d => d.name === 'Disney');
    this.gearpos = TravelTips.findIndex(d => d.name === 'Gear');
    this.wellnesspos = ThingsSeeDo.findIndex(d => d.name === 'Wellness');
    this.resortspos = PlacesStay.findIndex(d => d.name === 'Resorts');

    // Commerce GA 1676038157244

    // this.fixGuide()
    //this.fixSingleGuide()

    /*this.is.getList('Topics/Travelblogs').take(1).subscribe(list => {
      list.forEach(l => {
        if (!l.description || !l.description.en.description || l.description.en.description.length === 0) {
          console.log('description,', l.ukey)
        }
        if (!l.icon) {
          console.log('icon,', l.ukey)
        }
        if (!l.cardimg) {
          console.log('image,', l.image)
        }
      })
    })*/


    /*
        this.http.getArticleData('tes').then(res => {
          console.log('get article res: ', res)
        })
    */


    // this.onStartCrawl()


    // add this back when want to add awards or designations
    // this.getDesignations();

    // get the threads
    this.is.getList(`Threads`).take(1).subscribe(threads => {
      this.threads = threads;
    });


    this.geocoder = new google.maps.Geocoder();

    /* save this bit of code to fix it when TravelNotification ukeys get messed up
            this.is.getObject(`Topics/TravelNotifications/e6ce27268f60931c4e5411e9b7af09bc`).subscribe(val => {
              if (val) {
                const w = val as Wish;
                this.curwish = _.cloneDeep(w);
                // w.date = new Date(w.date).getTime()
                w.ukey = w.date.toString(10);
                this.is.setObjNoLog(`Topics/TmpTravelmags/guides/${w.date}`, w);
                console.log('path', `Topics/TmpTravelmags/guides/${w.date}`)
                if (this.curwish && this.curwish.ukey) {
                  this.onDel();
                }
              }
        })
    */


    /*
        this.is.getList(`Topics/TempInfo/tags`).take(1).subscribe(tags => {
          this.tags = _.orderBy(tags);
        });
    */


    /*
        this.is.getQuery(`Viator/destinations`, 'destinationName', 'Essex').subscribe(vals => {
          console.log('vals', vals)
        })
    */

    console.log(3);
  }


  onTryGetTaxonomys(name: string) {
    if (name && name.length) {
      const lowername = name.toLowerCase();


      // lowername.includes(c.class.toLowerCase())

      const z = this.classifications.findIndex(c =>
        c.class &&
        lowername.match(`\\b${c.class.toLowerCase()}\\b`)
      );
      if (z > -1) {
        this.classvar = this.classifications[z].class;
        this.kingdomvar = this.classifications[z].kingdom;
        this.phylumvar = this.classifications[z].phylum;
      } else {
        const x = this.classifications.findIndex(c =>
          c.phylum &&
          lowername.match(`\\b${c.phylum.toLowerCase()}\\b`)
        );

        //lowername.includes(c.phylum.toLowerCase())

        if (x > -1) {
          this.phylumvar = this.classifications[x].phylum;
          this.kingdomvar = this.classifications[x].kingdom;
        } else {
          const y = this.classifications.findIndex(c =>
            lowername.match(`\\b${c.kingdom.toLowerCase()}\\b`)
          );
          //lowername.includes(c.kingdom.toLowerCase())


          if (y > -1) {
            this.kingdomvar = this.classifications[y].kingdom;
          }
        }
      }

      if (this.kingdomvar && this.kingdomvar.length) {
        this.phylumarr =
          _.orderBy([...new Set(this.classifications.filter(p => p.kingdom === this.kingdomvar).map(val => val.phylum))]);
      }

      if (this.phylumvar && this.phylumvar.length) {
        this.classarr =
          _.orderBy([...new Set(this.classifications.filter(p => p.phylum === this.phylumvar).map(val => val.class))]);
      }

    }
  }

  onKingdomChange() {
    console.log('king changing');
    console.log('phlumcar 2');
    this.phylumvar = '';
    this.phylumarr = _.orderBy([...new Set(this.classifications.filter(p => p.kingdom === this.kingdomvar).map(val => val.phylum))]);
  }

  onPhylumChange() {
    console.log('phylum changing');
    this.classvar = '';
    this.classarr =
      _.orderBy([...new Set(this.classifications.filter(p => p.phylum === this.phylumvar).map(val => val.class))]);
  }

  addTotal() {
    this.is.getObject(`Topics/TempInfo/TotAtt`).take(1).subscribe(cnt => {
      cnt = cnt + this.attractions.length;
      this.is.setObjNoLog(`Topics/TempInfo/TotAtt`, cnt);
    });
  }


  saveDestination(a) {
    a.lastdate = new Date().getTime();
    if (a.name && a.location) {
      a.location.name = a.name;
    }
    if (a && a.ukey && a.ukey > 0) {
      this.is.setObjNoLog(`Viator/destinations/${a.ukey}`, a);
    }
  }

  onShowInstagram() {
    const sc = this.curguide.m['shortcode'];
    const jump = `https://www.instagram.com/p/${sc}/`;
    window.open(jump, '_blank');
  }

  onInstLocMen(p, i, loc) {
    console.log(i, 'possibles', this.curguide['possiblesextra']);

    this.is.getObject(`Places/${p.ukey}/ukey`).take(1).subscribe(ukey => {
      if (!ukey) {
        window.open(`https://www.google.com/maps/place/?q=place_id:${p.ukey}`, '_blank');
      }
      this.curguide.loc = p.name;

      if (loc) {
        // add to trip
        if (!this.curguide.trip) {
          this.curguide.trip = [];
        }
        this.curguide.trip.push(this.curguide['possiblesextra'][i]);
      } else {
        // add to mentions
        if (!this.curguide.mentions) {
          this.curguide.mentions = [];
        }
        this.curguide.mentions.push(this.curguide['possiblesextra'][i]);
      }
      this.curguide['possiblesextra'].splice(i, 1);
    });

    /*
    1. does place exist in database. if so, work is done
    2. if not i should get it via the places api??? ot the clipboard


     */
  }

  onLocMen(p, i, loc) {
    console.log(i, 'possibles', this.curguide['possibles']);

    if (loc) {
      // add to trip
      if (!this.curguide.trip) {
        this.curguide.trip = [];
      }
      this.curguide.trip.push(this.curguide['possibles'][i]);
    } else {
      // add to mentions
      if (!this.curguide.mentions) {
        this.curguide.mentions = [];
      }
      this.curguide.mentions.push(this.curguide['possibles'][i]);
    }
    this.curguide['possibles'].splice(i, 1);
  }

  saveAttraction(a) {
    a.lastdate = new Date().getTime();
    a.location.name = a.name;
    a.location.loc = a.loc;
    // this.is.setObjNoLog(`Viator/attractions/${this.country}/${a.ukey}`, a);
  }

  onDeleteAd(i) {
    this.is.deleteObjNoLog(`Invites/${this.invites[i].ukey}`);
    this.invites.splice(i, 1);
  }


  onGetBackground(str) {
    return 'url(' + str + ')';
  }

  onDeleteFrontEl(i) {
    this.curad.front.elements.splice(i, 1);
  }

  onDeleteBackEl(i) {
    this.curad.back.elements.splice(i, 1);
  }

  venClickBack(event, e) {
    event.stopPropagation();
    if (!this.curelement) {
      this.curelement = e;
      this.curelement['resize'] = true;
      this.detailsback = true;
      this.tmpright = 0;
    } else if (this.curelement !== e) {
      this.curelement['resize'] = null;
      this.curelement = null;
      this.curelement = e;
      this.tmpright = 0;
      this.curelement['resize'] = true;
    }
  }


  onClickFront(event, e) {
    event.stopPropagation();
    if (!this.curelement) {
      this.curelement = e;
      this.curelement['resize'] = true;
      this.detailsfront = true;
      this.tmpright = 0;
    } else if (this.curelement !== e) {
      this.curelement['resize'] = null;
      this.curelement = null;
      this.curelement = e;
      this.tmpright = 0;
      this.curelement['resize'] = true;
    }
  }

  onStart(e) {
    this.curelement = e;
    if (!this.curelement['templeft']) {
      this.curelement['templeft'] = e.left;
    }
    if (!this.curelement['temptop']) {
      this.curelement['temptop'] = e.top;
    }
  }

  onWidth(event, el) {
    this.curelement.width = this.curelement.width + (event.distance.x / 400) * 100;
    if (this.curelement.width < 5) {
      this.curelement.width = 5;
      this.curelement['resize'] = null;
      this.curelement = null;
      this.detailsfront = false;
      this.detailsback = false;
    } else {
      this.tmpright = this.tmpright + event.distance.x;
    }
  }

  onClear() {
    if (this.curelement) {
      this.curelement['resize'] = null;
      this.curelement = null;
      this.detailsfront = false;
      this.detailsback = false;
    }
  }

  onEnd(event) {
    const topper = this.curelement['temptop'] / 100;
    const curheight = topper * this.curad.height;
    const newheight = curheight + event.distance.y;
    const newper = newheight / this.curad.height;
    const newp = newper * 100;
    this.curelement['temptop'] = newp;
    this.curelement['templeft'] = ((((this.curelement['templeft'] / 100) * 400) + event.distance.x) / 400) * 100;
  }

  onStrip(str: string) {
    return str.replace(/(<([^>]+)>)/gi, '');
  }


  onAddTag() {
    if (this.tg) {
      this.is.setObjNoLog(`Topics/TempInfo/tags/${this.tags.length}`, this.tg);
      this.tags.push(this.tg);
      this.tags = _.orderBy(this.tags);
      this.tg = '';
    }

    this.addtag = false;
  }

  onAddAd() {
    if (!this.admin.pulse) {
      this.curad = new CappAd();
      this.detailsad = true;
    }
  }

  onEditAd(inv) {
    if (!this.admin.pulse) {
      this.curad = inv;
      this.detailsad = true;
    }
  }


  onSaveFront() {
    if (this.curelement) {
      if (this.curelement.ukey) {
        const x = this.curad.front.elements.findIndex(el => el.ukey === this.curelement.ukey);
        if (x > -1) {
          this.curad.front.elements[x] = this.curelement;
        }
      } else {
        this.curelement.ukey = this.db.createPushId();
        if (!this.curad.front.elements) {
          this.curad.front.elements = [];
        }
        this.curad.front.elements.push(this.curelement);
      }
    }

    this.detailsfront = false;
  }

  onSaveBack() {
    if (this.curelement) {
      if (this.curelement.ukey) {
        const x = this.curad.back.elements.findIndex(el => el.ukey === this.curelement.ukey);
        if (x > -1) {
          this.curad.back.elements[x] = this.curelement;
        }
      } else {
        this.curelement.ukey = this.db.createPushId();
        if (!this.curad.back.elements) {
          this.curad.back.elements = [];
        }
        this.curad.back.elements.push(this.curelement);
      }
    }

    this.detailsback = false;
  }

  onAddFrontEl(type: string) {
    if (!this.admin.pulse) {
      this.detailsfront = true;
      this.curelement = new CappElement(type);
    }
  }

  onAddBackEl(type: string) {
    if (!this.admin.pulse) {
      this.detailsback = true;
      this.curelement = new CappElement(type);
    }
  }

  onEditFrontElement(el, i) {
    if (!this.admin.pulse) {
      this.detailsfront = true;
      this.curelement = el;
    }
  }

  onEditBackElement(el, i) {
    if (!this.admin.pulse) {
      this.detailsback = true;
      this.curelement = el;
    }
  }


  onFrontBackground() {
    if (!this.admin.pulse) {
      this.detailsfront = true;
      this.curelement = null;
    }
  }

  onBackBackground() {
    if (!this.admin.pulse) {
      this.detailsback = true;
      this.curelement = null;
    }
  }


  onSave() {
    if (this.curad.front.elements && this.curad.front.elements.length > 0) {
      this.curad.front.elements.forEach(e => {
        if (e['templeft']) {
          e.left = e['templeft'];
          e['templeft'] = null;
        }
        if (e['temptop']) {
          e.top = e['temptop'];
          e['temptop'] = null;
        }
      });

    }

    if (!this.curad.ukey) {
      this.curad.ukey = this.db.createPushId();
      this.invites.push(this.curad);
    }

    this.is.setObjNoLog(`Invites/${this.curad.ukey}`, this.curad);
    this.curad = null;
    this.detailsad = false;
  }

  onChangePoi(event) {
    if (this.googletype === 'address') {
      this.poicomplete.setTypes([]);
    } else {
      this.poicomplete.setTypes([this.googletype]);
    }
  }

  onChange(event) {
    if (this.googletype === 'address') {
      this.loccomplete.setTypes([]);
    } else {
      this.loccomplete.setTypes([this.googletype]);
    }
  }

  onChangeMention(event) {
    if (this.googletype === 'address') {
      this.mentioncomplete.setTypes([]);
    } else {
      this.mentioncomplete.setTypes([this.googletype]);
    }
  }

  onChangeQuick(event) {
    if (this.googletype === 'address v') {
      this.locquickcomplete.setTypes([]);
    } else {
      this.locquickcomplete.setTypes([this.googletype]);
    }
  }

  onChangeMentionQuick(event) {
    if (this.googletype === 'address') {
      this.mentionquickcomplete.setTypes([]);
    } else {
      this.mentionquickcomplete.setTypes([this.googletype]);
    }
  }

  onClick() {
    this.cntx = this.cntx + 1;
    this.onStartPoi();
  }

  onStartPoi() {
    this.location = `${this.attractions[this.cntx].title} ${this.attractions[this.cntx].primaryDestinationName}`;
    if (this.attractions[this.cntx].thumbnailHiResURL) {
      this.googlephotos = [];
      this.googlephotos.push({who: 'Viator', caption: '', url: this.attractions[this.cntx].thumbnailHiResURL});
    } else {
      this.http.getViatorPhotos(this.attractions[this.cntx].seoId).take(1).subscribe(photos => {
        this.googlephotos = [];
        if (photos && photos.data) {
          photos.data.forEach(d => {
            this.googlephotos.push({who: 'Viator', caption: d.caption, url: d.photoURL});
          });
        }
      });
    }
  }


  ngAfterViewInit() {

    this.onSwitchEdit();

    console.log('address list', this.addresslist);
    this.addresslist.changes.subscribe((d) => {
      console.log('DDDX', d);
      if (d.last) {
        this.el = d.last;
        this.hit = null;
        this.addresscomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.autoCompleteOptions);
        // this.addresscomplete.setTypes(['establishment']);


        this.addresscomplete.addListener('place_changed', () => {
          this.onGetHit();
        });
      }
    });


    // this.createPOI();

    // IMPORTANT: add back for tourism boards
    // this.createAutos();

  }

  createPOI() {
    if (!this.poicomplete) {
      const el = this.poitext.nativeElement;
      this.poicomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);

      this.poicomplete.addListener('place_changed', () => {
        this.moveball = true;
        this.onGetPlace();
      });
    }
  }

  onYarr(y) {
    this.yelp = y;
    if (this.yelp.image_url) {
      this.googlephotos.push({who: 'Yelp', caption: '', url: this.yelp.image_url});
    }
  }

  onWikiClear() {
    this.wikiurl = '';
    this.wiki = null;
  }

  onWiki(w) {
    this.wikiurl = `http://en.wikipedia.org/?curid=${w.pageid}`;
  }

  onOpen(ukey) {
    window.open(`https://www.google.com/maps/place/?q=place_id:${ukey}`, '_blank');
  }

  onOpenWP(ukey) {
    window.open('view-source:' + ukey, '_blank');
  }

  onDeletePossible(i) {
    this.curguide['possibles'].splice(i, 1);
  }

  onDeletePossibleExtra(i) {
    this.curguide['possiblesextra'].splice(i, 1);
  }

  onWikiOpen(w) {
    window.open(`http://en.wikipedia.org/?curid=${w.pageid}`, '_blank');
  }


  onYelpPick(y) {
    console.log('yelp pick', y);
  }

  onCloseMention() {
    this.addingmention = null;
    this.jumpto = '';
    this.includein = false;
    this.weburl = '';
    this.poiartist = null;
    this.poiartistnote = '';
    this.curassignthread = null;
    this.curthread = null;
    this.poiroute = null;
    this.poiroutenote = '';
    this.clipped = null;
    this.clearClassifications();
  }

  clearClassifications() {
    console.log('claring classifications');
    this.kingdomvar = '';
    console.log('phlumcar 3');
    this.phylumvar = '';
    this.phylumarr = [];
    this.classvar = '';
    this.classarr = [];
    this.checktype = '';

  }

  onCloseVendor() {
    this.addingvendor = null;
    this.jumpto = '';
  }

  onCloseArtist() {
    this.addingartist = null;
    this.jumpto = '';
  }

  onCloseRoute() {
    this.addingroute = null;
    this.jumpto = '';
  }

  onCloseFestival() {
    this.addingfestival = null;
    this.jumpto = '';
  }

  onCloseEvent() {
    this.addingevent = null;
    this.curevent = null;
  }

  onCloseLoc() {
    this.adding = null;
    this.clipped = null;
    this.addingtrip = false;
    this.isPOI = true;
    this.weburl = '';
    this.touristurl = '';
    this.chain = [];
    this.touristchain = [];
    this.chainstring = '';
    this.touristchainstring = '';
    this.location = '';
    this.countryname = '';

  }


  onDeadPoi() {
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/dead`, true);
    this.timetosave = false;
    this.moveball = false;
    this.wiki = null;
    this.wikiurl = '';
    this.touristurl = '';
    this.isPOI = true;
    this.weburl = '';
    this.chain = [];
    this.placeID = '';
    this.address = '';
    this.location = '';
    this.countryname = '';
    this.comment = '';
    this.newloc = null;

  }

  onSavePoi() {
    if (this.wikiurl) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/wikiurl`, this.wikiurl);
    }
    if (this.googlephotos && this.googlephotos.length) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/photos`, this.googlephotos);
    }
    if (this.newloc) {
      if (this.yelp) {
        const key1 = new Key();
        key1.id = this.yelp.id;
        key1.name = 'Yelp';
        this.newloc.keys.push(key1);
      }
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/location`, this.newloc);
    }

    if (this.comment) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/viatorcomment`, this.comment);
    }

    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/ukey`, this.attractions[this.cntx].seoId);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/viatorcountry`, this.country);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/placeID`, this.placeID);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/loc`, this.newloc.loc);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/name`, this.newloc.location);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/viator`, `a${this.attractions[this.cntx].seoId}`);

    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/already`, false);

    if (this.yelp) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/yelp`, this.yelp);
    }


    this.clearIt();
  }

  clearIt() {
    this.timetosave = false;
    this.moveball = false;
    this.yelp = null;
    this.yarr = null;
    this.foursquare = null;
    this.wiki = null;
    this.wikiurl = '';
    this.aerial = null;
    this.localwikiurl = '';
    this.isPOI = true;
    this.weburl = '';
    this.touristurl = '';
    this.chain = [];
    this.touristchain = [];
    this.touristchainstring = '';
    this.chainstring = '';
    this.location = '';
    this.countryname = '';
    this.placeID = '';
    this.address = '';
    this.comment = '';
    this.newloc = null;
    this.temp = null;
    this.ukey = null;
    console.log('clearIt 1');

  }

  onCancelPoi() {
    this.clearIt();
    this.onStartPoi();

  }

  onNextPoi() {
    if (this.temp && this.temp.length) {
      // this place already exists
      console.log('this place already exists');
    } else {
      // this place does not already exist
      const place = this.poicomplete.getPlace();
      if (place) {
        // 1. check to see if Place/placeid/settings already exits
        this.is.getObject(`Places/${place.place_id}/location/loc`)
          .take(1)
          .subscribe(loc => {
            this.placeID = place.place_id;
            // 1. create a PlaceLoc
            this.newloc = new PlaceLoc();
            const country = this.admin.getCountry(place.address_components, true);
            const state = this.admin.getState(place.address_components, true);
            const city = this.admin.getCity(place.address_components, true);
            if (country === 'US' || country === 'USA') {
              this.newloc.loc = `${city}, ${state}`;
            } else if (city) {
              this.newloc.loc = `${city}, ${country}`;
            } else {
              this.newloc.loc = `${country}`;
            }
            this.newloc.location = place.name;


            this.newloc.long = place.geometry.location.lng();
            this.newloc.lat = place.geometry.location.lat();
            if (place.geometry.bounds) {
              this.newloc.bounds = place.geometry.bounds;
            }
            this.newloc.viewport = place.geometry.viewport;

            this.newloc.hash = geohash.encode(this.newloc.lat, this.newloc.long);

            if (place.formatted_address) {
              this.newloc.address = place.formatted_address;
            }

            if (place.utc_offset_minutes) {
              this.newloc.utc_offset_minutes = place.utc_offset_minutes;
            }
            this.newloc.keys = [];
            const key = new Key();
            key.id = place.place_id;
            key.name = 'Google';
            this.newloc.keys.push(key);
            const key1 = new Key();
            key1.id = 'a' + this.attractions[this.cntx].seoId;
            key1.name = 'Viator';
            this.newloc.keys.push(key1);
            // newloc.tzone: string;

            /*
                        this.http.getYelp(this.newloc.long, this.newloc.lat, null, 0, 10, 500)
                          .then(yelps => {
                            if (yelps && yelps.businesses && yelps.businesses.length) {
                              this.yarr = yelps.businesses;
                              console.log('yarr', this.yarr);
                            }
                          });
            */


            this.http.getWiki(place.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')).take(1).subscribe(wiki => {
              if (wiki) {
                this.wiki = Object.values(wiki);
                if (this.wiki && this.wiki.length && this.wiki[0].pageid) {
                  this.wikiurl = `http://en.wikipedia.org/?curid=${this.wiki[0].pageid}`;
                }
              }
            });

            this.timetosave = true;


            // 3. save record to Places
            if (!loc) {
              // TODO: this should really be in a cloud function
              // this.is.setObjNoLog(`Places/${place.place_id}/location`, newloc);
            }


            this.addpoi = false;
            this.poi = '';
          });
      }
    }
  }


  onGetPlace() {
    if (this.poitext.nativeElement.value && this.poitext.nativeElement.value.length) {
      const varr = this.poitext.nativeElement.value.split(',');
      const str = varr[0];
      this.temp = null;
      this.haveloc = '';
      if (str) {
        this.is.getQuery(`Places`, 'name', str)
          .take(1)
          .subscribe(lox => {
            if (lox && lox[0] && lox[0]['location']) {
              this.temp = lox[0];
            }
          });
      }
    }
  }

  onAlready() {
    // todo: when save from viator/attractions to places then need to copy over certain pieces of in
    if (this.wikiurl) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/wikiurl`, this.wikiurl);
    }
    if (this.yelp) {
      this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/yelp`, this.yelp);
    }

    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/already`, true);
    this.is.setObjNoLog(`Viator/attractions/${this.country}/${this.attractions[this.cntx].seoId}/placeId`, this.temp['ukey']);
    this.clearIt();
  }

  createAutos() {

    console.log('createAUto', this.loctext);
    if (!this.loccomplete) {
      const el = this.loctext.nativeElement;
      this.hit = null;
      this.loccomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
      this.loccomplete.addListener('place_changed', () => {
        console.log(301, this.location);
        this.onGetHit();
      });
    }

    if (!this.mentioncomplete) {
      const el = this.mentiontext.nativeElement;
      this.hit = null;
      this.mentioncomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
      this.mentioncomplete.addListener('place_changed', () => {
        this.onGetMentionHit();
      });
    }

    /*
        if (!this.locquickcomplete) {
          const el = this.loctextquick.nativeElement;
          this.hit = null;
          this.locquickcomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
          this.locquickcomplete.addListener('place_changed', () => {
            this.onGetQuickHit();
          });
        }

        if (!this.mentionquickcomplete) {
          const el = this.mentiontextquick.nativeElement;
          this.hit = null;
          this.mentionquickcomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
          this.mentionquickcomplete.addListener('place_changed', () => {
            this.onGetMentionHitQuick();
          });
        }
    */

    if (!this.eventcomplete) {
      const el = this.eventtext.nativeElement;
      this.hit = null;
      this.eventcomplete = new google.maps.places.Autocomplete(el, this.autoCompleteOptions);
      this.eventcomplete.addListener('place_changed', () => {
        this.onGetEventHit();
      });
    }
  }


  onCompleteHit(loc, ukey, chainstring, touristchainstring, photo: string, type?) {
    if (!this.curguide.trip) {
      this.curguide.trip = [];
    }
    if (!this.curguide.pops) {
      this.curguide.pops = [];
    }

    const nm = this.location;

    let lat = loc.lat;
    if (loc && loc.lat && (typeof loc.lat === 'string')) {
      lat = parseFloat(loc.lat);
    }
    let long = loc.long;
    if (loc && loc.long && (typeof loc.long === 'string')) {
      long = parseFloat(loc.long);
    }


    console.log('ZZZZ', loc);
    this.hit = {
      objectID: ukey,
      country: loc.country,
      loc: loc.loc,
      photo,
      hash: loc.hash,
      propogate: true,
      aboutit: this.aboutit,
      jumpto: '',
      ukey,
      name: nm,
      long,
      lat,
    };

    if (chainstring) {
      this.hit['chainstring'] = chainstring;
    }

    if (touristchainstring) {
      this.hit['touristchainstring'] = touristchainstring;
    }

    if (this.viator) {
      this.hit['viator'] = this.viator;
    }

  }

  onCompleteEventHit(loc, ukey, type?) {
    if (!this.curguide.events) {
      this.curguide.events = [];
    }

    let nm;
    if (type && (type === 'POI' || (loc && loc.loc && (!loc.loc.includes(',') || ((loc.loc.includes(', US') && loc.loc.length === 6)))))) {
      nm = this.location;
    } else {
      nm = loc.loc;
    }

    let lat = loc.lat;
    if (loc && loc.lat && (typeof loc.lat === 'string')) {
      lat = parseFloat(loc.lat);
    }
    let long = loc.long;
    if (loc && loc.long && (typeof loc.long === 'string')) {
      long = parseFloat(loc.long);
    }

    this.hit = {
      objectID: ukey,
      country: loc.country,
      hash: loc.hash,
      offset: loc.utc_offset_minutes,
      propogate: true,
      jumpto: '',
      ukey,
      name: nm,
      long,
      lat,
    };
    if (this.viator) {
      this.hit['viator'] = this.viator;
    }
  }


  onCompleteMentionHit(loc, ukey, photo, type?) {
    console.log('on complete mention hit', loc);
    if (!this.curguide.mentions) {
      this.curguide.mentions = [];
    }
    const nm = this.location;

    let lat = loc.lat;
    if (loc && loc.lat && (typeof loc.lat === 'string')) {
      lat = parseFloat(loc.lat);
    }
    let long = loc.long;
    if (loc && loc.long && (typeof loc.long === 'string')) {
      long = parseFloat(loc.long);
    }

    let jumpto = '';
    if (this.jumpto) {
      jumpto = this.jumpto;
    }

    console.log('yyyyy');
    this.hit = {
      objectID: ukey,
      loc: nm,
      photo,
      hash: loc.hash,
      country: loc.country,
      propogate: true,
      jumpto,
      includein: this.includein,
      aboutit: this.includein,
      ukey,
      name: nm,
      long,
      lat,
    };
    if (this.viator) {
      this.hit['viator'] = this.viator;
    }
  }

  /*
    onNextGeo() {
      // 2758
      const clock = setInterval(() => {
        if (this.cntx < 2758  ) {
          this.onDoIt();
        } else {
          clearInterval(clock);
        }
      }, 2500);
    }

    onDoIt() {
      this.cntx = this.cntx + 1;
      if (this.geocoder) {
        this.is.getObject(`Viator/destinations/${this.cntx}/loc`).subscribe(loc => {
          if (loc) {
            const arr = loc.split(',')
            let location = loc;
  /!*
            if (arr.length === 3) {
              location = `${arr[0]} ${arr[2]}, ${arr[1]}`;
            } else {
              location = loc.replace(',', '')
            }
  *!/
            location = loc.replace(',', '')
            this.geocoder.geocode({'address': location}, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                this.is.setObjNoLog(`Viator/destinations/${this.cntx}/placeID`,  results[0].place_id)
              } else {
                console.log('cant find')
              }
            });
          }
        });





      }

    }

  */

  /*
  const autoComleteFields = [
  'address_components',
  'formatted_address',
  'formatted_phone_number',
  'geometry',
  'name',
  'photos',
  'place_id',
  'url',
  'utc_offset_minutes',
  'website',
]
   */


  saveMd5(md5var, placeID) {
    if (md5var && placeID) {
      const md5obj = {
        ukey: md5var,
        placeID: placeID,
        name: name,
        source: 'Auto',
      };
      // source = Auto means that the website and the place_id were not verified that they belong together and
      // thus, at some point may need to be verified

      // this.is.setObjNoLog(`Places/${placeID}/md5`, md5var);  // hash
      console.log('path 1', `Places/${placeID}/md5`);
      // this.is.setObjNoLog(`PlacesMd5/${md5var}`, md5obj);  // hash
      console.log('path 2', `PlacesMd5/${md5var}`);
    }
  }


  onStartCrawl() {
    const n = 1000;

    this.is.getLimitToNextnKeys(n, `Places`, this.startkey)
      .take(1)
      .subscribe(vals => {
        let cnt = 0;
        if (vals && vals.length) {
          for (let v of vals) {
            if (v['ukey'] === 'ChIJU5jnB_i8vXYR_fQUNFSdm7M') {
              break;
            } else {
              if (cnt < vals.length - 1) {
                const place = v as any;

                // this is where i do the actual work

                // this should fix the description problem
                if (place.descriptions && place.descriptions.length) {
                  let x = 0;
                  const parr = [];
                  place.descriptions.forEach(ds => {
                    console.log(v.ukey, ': ', ds);
                    if (ds && ds[0]) {
                      console.log('fix desc 1', v.ukey);
                      parr.push(ds[0]);
                    }
                    if (ds && ds.en) {
                      console.log('fix desc 2', v.ukey);
                      const obj = {desc: ds, source: ''};
                      parr.push(obj);
                    }
                    x += 1;
                  });
                  if (parr && parr.length) {
                    this.is.setObjNoLog(`Places/${v.ukey}/descriptions`, parr);
                  }
                } else if (place.description) {
                  console.log('fix desc 3', v.ukey);
                  this.is.setObjNoLog(`Places/${v.ukey}/descriptions/0/desc`, place.description);
                }

                const keysarr = Object.keys(place);
                if (keysarr && keysarr.length) {
                  keysarr.forEach(key => {
                    if (key.startsWith('ChI') && v.ukey && v.ukey.length > 5) {
                      console.log('FIXING: ', `Places/${v.ukey}/pois/${key}`, place[key]);
                      this.is.setObjNoLog(`Places/${v.ukey}/pois/${key}`, place[key]);
                      this.is.deleteObjNoLog(`Places/${v.ukey}/${key}`);
                    }
                  });
                }


                cnt = cnt + 1;
                this.max = this.max + 1;
              } else if ((vals.length === n) && (this.startkey != vals[vals.length - 1].ukey)) {
                this.startkey = vals[vals.length - 1].ukey;
                this.onStartCrawl();
                break;
              }
            }
          }
        }
      });
  }


  getPhotos(parr, ukey) {
    this.http.getGoogleJustPhotos(parr).then(
      photosreturn => {

        const photos = Object.values(photosreturn);
        if (photos && photos.length > 0) {
          const googlephotos = [];
          photos.forEach(p => {
            googlephotos.push({url: p, who: 'Google'});
          });
          // todo: save the photos
          this.is.setObjNoLog(`Viator/destinations/${ukey}/photos`, googlephotos);

          // todo: emmit done

          const time = setTimeout(() => {
            clearTimeout(time);
            this._cur.next('done');
          }, 2500);
        }

      }, error => {
        const time = setTimeout(() => {
          clearTimeout(time);
          this._cur.next('done');
        }, 7000);
      });
  }

  onFileBoard() {
    console.log('ukey', this.ukey);
    console.log('tourist board', this.touristurl);
    if (this.ukey && this.ukey.length > 5 && this.touristurl && this.touristurl.length > 5) {
      this.is.setObjNoLog(`Topics/TempInfo/newboards/${this.ukey}`, {ukey: this.ukey, tourist: this.touristurl});
    }
  }

  onTabChange(event) {
    if (event.index === 2) {
      this.doguide = true;
    } else {
      this.doguide = false;
    }
  }

  onGetEventHit() {
    this.hit = null;
    this.ukey = null;
    this.viator = null;
    this.isPOI = true;
    if (this.eventtext.nativeElement.value && this.eventtext.nativeElement.value.length) {
      const varr = this.eventtext.nativeElement.value.split(',');
      this.location = varr[0];
      if (this.location) {
        const place = this.eventcomplete.getPlace();
        if (place) {
          this.ukey = place.place_id;
          this.is.getObject(`Places/${this.ukey}/location`).take(1).subscribe(loc => {
            if (loc) {
              if (loc && loc.country) {
                this.countryname = loc.country;
              }

              if (loc.utc_offset_minutes) {
                this.is.getObject(`Places/${this.ukey}/viator`).take(1).subscribe(via => {
                  if (via) {
                    this.viator = via;
                  }
                  this.is.getObject(`Places/${this.ukey}/taxonomy/kingdom`).take(1).subscribe(type => {
                    if (type && (type === 'POI') && loc.name) {
                      this.location = loc.name;
                    }

                    this.is.getObject(`Places/${this.ukey}/chain`).take(1).subscribe(sites => {
                      if (sites) {
                        this.chain = sites;
                      }
                      this.onCompleteEventHit(loc, this.ukey, type);
                    });
                  });
                });
              } else {
                alert(`NO TIME OFFSET IN THE DATABASE: ${place.place_id}`);
              }
            }
          });
        }
      }
    }
  }

  rebuildLocs() {
    const tarr = [];
    this.curguide.tandl = [];
    if (this.curguide.trip && this.curguide.trip.length) {
      this.curguide.trip.forEach(t => {
        if (t.aboutit && t.name && t.photo) {
          this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
        }
      });
    }
    if (this.curguide.pops && this.curguide.pops.length) {
      this.curguide.pops.forEach(t => {
        if (t.aboutit && t.name && t.photo) {
          this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
        }
      });
    }
    if (this.curguide.mentions && this.curguide.mentions.length) {
      this.curguide.mentions.forEach(t => {
        if (t.aboutit && t.name && t.photo) {
          this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
        }
      });
    }
    /* why was this here?????
        if (this.curguide.trip && this.curguide.trip.length) {
          this.curguide.trip.forEach(t => {
            if (!t.aboutit && t.name && t.photo) {
              this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
            }
          });
        }
        if (this.curguide.pops && this.curguide.pops.length) {
          this.curguide.pops.forEach(t => {
            if (!t.aboutit && t.name && t.photo) {
              this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
            }
          });
        }
        if (this.curguide.mentions && this.curguide.mentions.length) {
          this.curguide.mentions.forEach(t => {
            if (!t.aboutit && t.name && t.photo) {
              this.curguide.tandl.push({name: t.name, ukey: t.ukey, photo: t.photo});
            }
          });
        }
    */

  }

  idNotExist(karr, str): boolean {
    let notexist = true;
    karr.forEach(k => {
      if (k['name'] === str) {
        notexist = false;
      }
    });

    return notexist;
  }


  onGetMentionHit() {
    this.hit = null;
    this.ukey = null;
    this.viator = null;
    this.isPOI = true;
    this.fullname = '';
    this.aerialview = null;
    this.checktype = '';

    if (this.mentiontext.nativeElement.value && this.mentiontext.nativeElement.value.length) {
      const varr = this.mentiontext.nativeElement.value.split(',');
      this.location = varr[0];
      if (this.location) {
        const place = this.mentioncomplete.getPlace();
        if (place) {
          this.ukey = place.place_id;
          this.is.getObject(`Places/${this.ukey}`).take(1).subscribe(pl => {
            console.log('place place', pl);
            //'Topics/TempInfo/PlaceNames/ChIJ7ZXdCghBNBMRfxtm4STA86A/FullName
            this.fullname = '';
            this.countryrequired = '';
            this.aerialview = null;

            this.is.getObject(`Topics/TempInfo/PlaceNames/${this.ukey}`).take(1).subscribe(placename => {
              if (placename) {
                this.fullname = placename.FullName;
                if (placename.Country) {
                  this.countryrequired = placename.Country;
                }
              }

              this.kingdomvar = '';
              this.phylumvar = '';
              console.log('phlumcar 4x');
              this.classvar = '';
              if (pl) {
                if (pl.taxonomy) {
                  this.checktype = pl.taxonomy.kingdom;
                  this.kingdomvar = pl.taxonomy.kingdom;
                  this.onKingdomChange();

                  if (pl.taxonomy.phylum) {
                    this.checktype = this.checktype + ' - ' + pl.taxonomy.phylum;
                    this.phylumvar = pl.taxonomy.phylum;
                    this.onPhylumChange();

                  }
                  if (pl.taxonomy.class) {
                    this.checktype = this.checktype + ' - ' + pl.taxonomy.class;
                    this.classvar = pl.taxonomy.class;
                  }
                } else {
                  this.onTryGetTaxonomys(pl.name);
                }
              } else {
                this.onTryGetTaxonomys(this.location);
              }

              if (pl && pl.aerialview) {
                this.aerialview = pl.aerialview;
              }

              if (pl && pl.location) {
                this.touristurl = '';
                this.countryname = '';

                if (pl.location.country) {
                  this.countryname = pl.location.country;
                }

                if (pl.location.keys && pl.location.loc) {
                  let karr;
                  if (typeof pl.location.keys === 'object') {
                    karr = Object.values(pl.location.keys);
                  } else {
                    karr = pl.location.keys;
                  }

                  if (pl.location && pl.location.lat && pl.location.long) {
                    // foursquare
                    if (this.idNotExist(karr, 'Foursquare')) {
                      this.spinningfour = true;
                      this.http.getFourSquare(pl.location.lat, pl.location.long, pl.name).then(foursquare => {
                        this.foursquare = {};
                        this.foursquare['ukey'] = pl.ukey;
                        this.foursquare['placename'] = pl.name;
                        this.spinningfour = false;

                        if (foursquare && foursquare.results && foursquare.results.length) {
                          foursquare.results.forEach(f => {
                            if (f.geocodes && f.geocodes.main && f.geocodes.main.latitude) {
                              const x = computeDistanceBetween(
                                new google.maps.LatLng(pl.location.lat, pl.location.long),
                                new google.maps.LatLng(f.geocodes.main.latitude, f.geocodes.main.longitude));
                              f['distance'] = Math.round(x * 0.000621371 * 100) / 100;
                            }
                          });
                          this.foursquare['array'] = _.orderBy(foursquare.results, 'distance', 'asc');

                        }
                      })
                        .catch(errf => {
                          this.spinningfour = false;
                        });
                    }


                    if (this.idNotExist(karr, 'Yelp')) {
                      const locarr = pl.location.loc.split(', ');
                      if (locarr && locarr.length === 2) {
                        this.yelpexists = {};
                        if (pl.address) {
                          this.yelpexists['address'] = pl.address;
                        } else {
                          this.yelpexists['address'] = pl.location.address;
                        }

                        this.yelpexists['lat'] = pl.location.lat;
                        this.yelpexists['lng'] = pl.location.long;
                        this.yelpexists['name'] = pl.name;

                        const states = ['AK',
                          'CT',
                          'DC',
                          'FL',
                          'HI',
                          'IA',
                          'KS',
                          'MI',
                          'NV',
                          'NH',
                          'NJ',
                          'NM',
                          'NY',
                          'ND',
                          'OH',
                          'OK',
                          'OR',
                          'RI',
                          'TX',
                          'UT',
                          'VT',
                          'WA',
                          'WI',
                          'WY',];
                        const x = states.findIndex(s => s === locarr[1]);
                        if (pl.countrycode === 'US' || this.yelpexists['address'].includes('USA') || x > -1) {
                          this.yelpexists['state'] = '  ';
                          this.yelpexists['ctry'] = 'US';
                        } else {
                          this.yelpexists['state'] = '  ';
                          if (pl.countrycode) {
                            this.yelpexists['ctry'] = pl.countrycode;
                          } else {
                            this.yelpexists['ctry'] = locarr[1];
                          }
                        }

                        this.http.getYelpMatch(this.yelpexists['name'], this.yelpexists['address'], this.yelpexists['state'], this.yelpexists['ctry'])
                          .then(yelps => {
                            this.spinning = false;
                            if (yelps && yelps['businesses'] && yelps['businesses'].length && yelps['businesses'][0]) {
                              this.yarr = yelps['businesses'][0];
                              this.yarr['caller'] = 'old';

                              this.yarr['ukey'] = pl.ukey;
                              this.yarr['placename'] = pl.name;

                              if (this.yarr['coordinates'] && this.yarr['coordinates']['latitude'] && this.yarr['coordinates']['longitude']) {
                                const xj = computeDistanceBetween(
                                  new google.maps.LatLng(this.yelpexists['lat'], this.yelpexists['lng']),
                                  new google.maps.LatLng(this.yarr['coordinates']['latitude'], this.yarr['coordinates']['longitude']));
                                this.yarr['distance'] = Math.round(xj * 0.000621371 * 100) / 100;
                              }
                            }
                          }).catch(err => {
                          this.spinning = false;
                        });
                      }

                      let tx = 'Tourist Attraction';
                      if (pl.taxonomy && pl.taxonomy.phylum) {
                        tx = pl.taxonomy.phylum;
                      }

                      // todo: add back tx as the 3rd parameter
                      this.http.getYelp(pl.location.long, pl.location.lat, '', 0, 0, 0).then(yelps => {
                        if (yelps && yelps['businesses'] && yelps['businesses'].length) {
                          this.yelp = {};
                          this.yelp['ukey'] = pl.ukey;
                          this.yelp['placename'] = pl.name;
                          this.yelp['caller'] = 'old';

                          this.yelp['array'] = yelps['businesses'];

                          this.yelp['array'].forEach(yarr => {
                            if (yarr['coordinates'] && yarr['coordinates']['latitude'] && yarr['coordinates']['longitude']) {
                              const x = computeDistanceBetween(
                                new google.maps.LatLng(pl.location.lat, pl.location.long),
                                new google.maps.LatLng(yarr['coordinates']['latitude'], yarr['coordinates']['longitude']));
                              yarr['distance'] = Math.round(x * 0.000621371 * 100) / 100;
                            }
                          });

                          this.yelp['array'] = _.orderBy(this.yelp['array'], 'distance', 'asc');


                        }
                      }).catch(err => {
                        console.log('snd match error');
                      });
                    }
                  }
                }

                if (pl.website) {
                  this.weburl = pl.website;
                } else if (pl.moreinfo && pl.moreinfo.website) {
                  this.weburl = pl.moreinfo.website;
                }
                if (pl.viator) {
                  this.viator = pl.viator;
                }

                if (pl.name) {
                  this.location = pl.name;
                } else if (pl.location && pl.location.name) {
                  this.location = pl.location.name;
                }

                if (pl.location && pl.location.country) {
                  this.countryname = pl.location.country;
                }

                this.savelocation = '';
                if (pl.location && pl.location.loc) {
                  this.savelocation = pl.location.loc;
                }

                if (pl.tourist_pids) {
                  this.touristchain = pl.tourist_pids;
                  this.touristchainstring = JSON.stringify(pl.tourist_pids);
                }
                if (pl.chain) {
                  this.chain = pl.chain;
                  this.chainstring = JSON.stringify(pl.chain);
                }

                if (pl.moreinfo && pl.moreinfo.touristurl) {
                  this.touristurl = pl.moreinfo.touristurl;
                }

                if (pl.moreinfo && pl.moreinfo.Wikipedia) {
                  this.wikiurl = pl.moreinfo.Wikipedia;

                  if (pl.moreinfo && pl.moreinfo.Wikipedia) {
                    this.wikiurl = pl.moreinfo.Wikipedia;

                    if (pl.location.keys) {
                      const karr = Object.values(pl.location.keys) as any;
                      let nowiki = true;
                      karr.forEach(k => {
                        if (k.name === 'wikiDataId') {
                          nowiki = false;
                        }
                      });

                      if (nowiki) {
                        this.http.wikiCall(this.wikiurl, pl.ukey).take(1).subscribe(ret => {
                          console.log('wikiCall', this.wikiurl, pl.ukey);
                        });
                      }
                    }
                  }

                }

                if (pl.moreinfo && pl.moreinfo.GoogleSearch) {
                  this.googleurl = pl.moreinfo.GoogleSearch;
                }

                if (pl.altnames) {
                  this.altnames = pl.altnames;
                }

                if (pl.moreinfo && pl.moreinfo.Britanica) {
                  this.briturl = pl.moreinfo.Britanica;
                }


                let photo = '';
                if ((pl.moreinfo || pl.guides || pl.repos) && pl.photos) {
                  const photos = Object.values(pl.photos);
                  photo = photos[0]['url'];
                }

                this.onCompleteMentionHit(pl.location, this.ukey, photo, pl.type);

              }
            });

          });


          /*
                    this.is.getObject(`Places/${this.ukey}/location`).take(1).subscribe(loc => {
                      if (loc) {
                        this.is.getObject(`Places/${this.ukey}/moreinfo/website`).take(1).subscribe(weburl => {
                          this.weburl = weburl;
                        });

                        this.is.getObject(`Places/${this.ukey}/viator`).take(1).subscribe(via => {
                          if (via) {
                            this.viator = via;
                          }
                          this.is.getObject(`Places/${this.ukey}/type`).take(1).subscribe(type => {
                            if (type && (type === 'POI') && loc.name) {
                              this.location = loc.name;
                            }

                            this.is.getObject(`Places/${this.ukey}/chain`).take(1).subscribe(sites => {
                              if (sites) {
                                this.chain = sites;
                              }
                              this.onCompleteMentionHit(loc, this.ukey, type);
                            });
                          });
                        });
                      }
                    });
          */
        }
      }
    }
  }

  onAboutItChange(event) {
    if (event) {
      if (this.curguide.trip && this.curguide.trip.length) {
        this.curguide.trip.forEach(t => {
          t.aboutit = false;
        });
      }
      if (this.curguide.mentions && this.curguide.mentions.length) {
        this.curguide.mentions.forEach(t => {
          t.mentions = false;
        });
      }
    }
  }

  onGetHitPlace(plid: string, clipped?) {
    this.ukey = plid;

    this.is.getObject(`Places/${this.ukey}`).take(1).subscribe(place => {
      if (place && place.location) {
        if (!clipped) {
          this.touristurl = '';
          this.briturl = '';
          this.googleurl = '';
          this.altnames = '';
          this.wikiurl = '';
          this.localwikiurl = '';
          this.fullname = '';
          this.aerialview = null;
        }

        //'Topics/TempInfo/PlaceNames/ChIJ7ZXdCghBNBMRfxtm4STA86A/FullName
        this.is.getObject(`Topics/TempInfo/PlaceNames/${this.ukey}`).take(1).subscribe(placename => {
          if (placename) {
            this.fullname = placename.FullName;
            this.countryrequired = placename.Country;
          }
        });

        if (place.aerialview) {
          this.aerialview = place.aerialview;
        }

        if (place.viator) {
          this.viator = place.viator;
        }
        if (place.taxonomy && (place.taxonomy.kingdom === 'POI') && place.location && place.location.name) {
          this.location = place.location.name;
        }

        this.countryname = '';
        if (place.location && place.location.country) {
          this.countryname = place.location.country;
        }


        if (place.tourist_pids) {
          this.touristchain = place.tourist_pids;
          this.touristchainstring = JSON.stringify(place.tourist_pids);
        }
        if (place.chain) {
          this.chain = place.chain;
          this.chainstring = JSON.stringify(place.chain);
        }

        if (place.moreinfo && place.moreinfo.touristurl) {
          this.touristurl = place.moreinfo.touristurl;
        } else {
          console.log('NO TOURIST URL FOUND', this.ukey);
        }

        if (place.moreinfo && place.moreinfo.LocalWiki) {
          this.localwikiurl = place.moreinfo.LocalWiki;
        }

        if (place.moreinfo && place.moreinfo.Wikipedia) {
          this.wikiurl = place.moreinfo.Wikipedia;

          if (place.location.keys) {
            const karr = Object.values(place.location.keys) as any;
            let nowiki = true;
            karr.forEach(k => {
              if (k.name === 'wikiDataId') {
                nowiki = false;
              }
            });
            if (nowiki) {
              this.http.wikiCall(this.wikiurl, place.ukey).take(1).subscribe(ret => {
              });
            }
          }

        }


        if (place.moreinfo && place.moreinfo.GoogleSearch) {
          this.googleurl = place.moreinfo.GoogleSearch;
        }

        if (place.altnames) {
          this.altnames = place.altnames;
        }

        if (place.moreinfo && place.moreinfo.Britanica) {
          this.briturl = place.moreinfo.Britanica;
        }


        let photo = '';
        if ((place.moreinfo || place.guides || place.repos) && place.photos) {
          const photos = Object.values(place.photos);
          photo = photos[0]['url'];
        }

        if (place.taxonomy && !this.phylumvar) {
          console.log('filling it');
          this.checktype = place.taxonomy.kingdom;
          this.kingdomvar = place.taxonomy.kingdom;
          this.onKingdomChange();

          if (place.taxonomy.phylum) {
            this.checktype = this.checktype + ' - ' + place.taxonomy.phylum;
            this.phylumvar = place.taxonomy.phylum;
            this.onPhylumChange();
          }
        }

        if (this.kingdomvar === 'Admin' && !place.location.keys.Geoapify) {
          this.http.getBoundary(place.ukey).then(res => {
            if (res && res.id) {
              this.is.setObjNoLog(`Places/${place.ukey}/location/keys/Geoapify`, res);
            }
          });
        }


        this.onCompleteHit(place.location, this.ukey, this.chainstring, this.touristchainstring, photo, place.type);
      } else {
        console.log('NOT IN DATABASE');
        this.notindatabase = true;
        this.checktype = '';
        if (!this.phylumvar) {
          this.kingdomvar = 'Admin';
          this.onKingdomChange();
        }
      }
    });

    /*
        this.is.getObject(`Places/${this.ukey}/location`).take(1).subscribe(loc => {
          if (loc) {
            this.touristurl = '';
            this.is.getObject(`Places/${this.ukey}/viator`).take(1).subscribe(via => {
              if (via) {
                this.viator = via;
              }
              this.is.getObject(`Places/${this.ukey}/type`).take(1).subscribe(type => {
                if (type && (type === 'POI') && loc.name) {
                  this.location = loc.name;
                }

                this.is.getObject(`Places/${this.ukey}/tourist_pids`).take(1).subscribe(tc => {
                  if (tc) {
                    this.touristchain = tc;
                    this.touristchainstring = JSON.stringify(tc);
                  }

                  this.is.getObject(`Places/${this.ukey}/chain`).take(1).subscribe(sites => {
                    if (sites) {
                      this.chain = sites;
                      this.chainstring = JSON.stringify(sites);
                    }

                    this.is.getObject(`Places/${this.ukey}/touristurl`).take(1).subscribe(turl => {
                      if (turl) {
                        this.touristurl = turl;
                      }

                      this.onCompleteHit(loc, this.ukey, this.chainstring, this.touristchainstring, type);

                    });

                  });
                });
              });
            });
          }
        });
    */

  }


  onGetHit() {
    this.hit = null;
    this.ukey = null;
    this.viator = null;
    this.isPOI = true;
    if (!this.doguide && this.el && this.el.nativeElement.value && this.el.nativeElement.value.length) {
      const varr = this.el.nativeElement.value.split(',');
      this.location = varr[0];
      if (this.location) {
        const place = this.addresscomplete.getPlace();
        if (place) {
          this.is.getObject(`PlaceExists/${place.place_id}/ukey`).take(1).subscribe(ukey => {
            if (ukey && ukey.includes('alais')) {
              const key = ukey.replace('alais', '');
              this.onGetHitPlace(key);
            } else {
              this.onGetHitPlace(place.place_id);
            }
          });
        }
      }
    } else if (this.doguide && this.loctext && this.loctext.nativeElement.value && this.loctext.nativeElement.value.length) {
      const varr = this.loctext.nativeElement.value.split(',');
      this.location = varr[0];
      if (this.location) {
        const place = this.loccomplete.getPlace();
        if (place) {
          this.is.getObject(`PlaceExists/${place.place_id}/ukey`).take(1).subscribe(ukey => {
            if (ukey && ukey.includes('alais')) {
              const key = ukey.replace('alais', '');
              this.onGetHitPlace(key);
            } else {
              this.onGetHitPlace(place.place_id);
            }
          });

          /*
                    this.ukey = place.place_id;
                    this.is.getObject(`Places/${this.ukey}/location`).take(1).subscribe(loc => {
                      if (loc) {
                        this.is.getObject(`Places/${this.ukey}/viator`).take(1).subscribe(via => {
                          if (via) {
                            this.viator = via;
                          }
                          this.is.getObject(`Places/${this.ukey}/type`).take(1).subscribe(type => {
                            if (type && (type === 'POI') && loc.name) {
                              this.location = loc.name;
                            }

                            this.is.getObject(`Places/${this.ukey}/tourist_pids`).take(1).subscribe(tc => {
                              if (tc) {
                                this.touristchain = tc;
                                this.touristchainstring = JSON.stringify(tc);
                              }

                              this.is.getObject(`Places/${this.ukey}/chain`).take(1).subscribe(sites => {
                                if (sites) {
                                  this.chain = sites;
                                  this.chainstring = JSON.stringify(sites);
                                }
                                this.onCompleteHit(loc, this.ukey, this.chainstring, this.touristchainstring, type);
                              });
                            });

                          });
                        });
                      }
                    });
          */

        }
      }
    }
  }


  onAddGuide() {
    this.guidedetails = true;
    this.updateguideimg = true;
    this.pubdate = new Date();
    this.xdate = null;
    this.onClearGuideImg();
    this.curguide = new Wish('', null);
    this.zerom();

    this.createAutos();
  }


  onDeleteTmpGuide(g: Wish, i: number) {
    this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${g.ukey}`);
    this.fulllist.splice(i, 1);
  }

  onDeleteGuide() {
    if (this.switchedit === 'addarticle' || this.switchedit === 'addvideo') {
      this.addcustom = this.switchedit;
      this.clearIt();
      this.guidedetails = false;
    } else {
      console.log('curguide', this.curguide);
      const x = this.fulllist.findIndex(g => g.ukey === this.curguide.ukey);
      console.log(x, 'd3', this.fulllist);
      if (x > -1) {
        this.fulllist.splice(x, 1);
        if (this.switchedit === 'TempInfo/guidescachetmp') {
          this.is.deleteObjNoLog(`Topics/TempInfo/guidescachetmp/${this.curguide.ukey}`);
        } else {
          this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${this.curguide.ukey}`);
        }

        if (this.quickp) {
          console.log('quickp1', this.fulllist[0]);
          if (this.fulllist && this.fulllist.length && this.quickp) {
            this.onEditGuide(this.fulllist[0]);
          }
        } else {
          this.clearIt();
          this.guidedetails = false;
        }
      }
    }
  }

  onClearGuideImg() {
    this.guideimg = null;
    this.imgx = 0;
    this.imgy = 0;
    this.scale = 101;
    this.ic = false;
  }

  onWorks() {
    this.inframe = true;
    this.is.setObjNoLog(`Institutions/${this.curguide.cf}/obj/inframe`, true);
  }

  onCanQuick() {
    this.canquick = true;
    this.is.setObjNoLog(`Institutions/${this.curguide.cf}/obj/canquick`, true);

  }


  onThingsChange(flip = false) {
    this.things = '';
    const arr = this.ThingsSeeDoarr.filter(t => t.val);
    if (arr && arr.length) {
      this.things = arr.map(z => z.name).join(', ');
      if (flip) {
        this.curguide.m.propfeed = false;
      }
    }
  }

  onWhoChange(flip = false) {
    this.who = '';
    const arr = this.WhoForarr.filter(t => t.val);
    if (arr && arr.length) {
      this.who = arr.map(z => z.name).join(', ');
    }
    if (flip) {
      this.curguide.m.propfeed = false;
    }
  }

  onOtherChange() {
    this.other = '';
    const arr = this.TravelOtherarr.filter(t => t.val);
    if (arr && arr.length) {
      this.other = arr.map(z => z.name).join(', ');
    }
  }

  onTipsChange(flip = false) {
    this.tips = '';
    const arr = this.TravelTipsarr.filter(t => t.val);
    if (arr && arr.length) {
      this.tips = arr.map(z => z.name).join(', ');
      if (flip) {
        this.curguide.m.propfeed = false;
      }
    }
  }

  onPlacesChange() {
    this.places = '';
    const arr = this.PlacesStayarr.filter(t => t.val);
    if (arr && arr.length) {
      this.places = arr.map(z => z.name).join(', ');
    }
  }

  onSeasonsChange() {
    this.seasons = '';
    const arr = this.Seasonarr.filter(t => t.val);
    if (arr && arr.length) {
      this.seasons = arr.map(z => z.name).join(', ');
    }
  }

  onEatChange() {
    this.eat = '';
    const arr = this.PlacesEatDrinkShoparr.filter(t => t.val);
    if (arr && arr.length) {
      this.eat = arr.map(z => z.name).join(', ');
    }
  }

  onMealChange() {
    this.meal = '';
    const arr = this.Mealarr.filter(t => t.val);
    if (arr && arr.length) {
      this.meal = arr.map(z => z.name).join(', ');
    }
  }

  onMethodChange() {
    this.method = '';
    const arr = this.Methodarr.filter(t => t.val);
    if (arr && arr.length) {
      this.method = arr.map(z => z.name).join(', ');
    }
  }

  onDrinksChange() {
    this.drink = '';
    const arr = this.Drinksarr.filter(t => t.val);
    if (arr && arr.length) {
      this.drink = arr.map(z => z.name).join(', ');
    }
  }

  onIngredientChange() {
    this.ingredient = [];
    const arr = this.Ingredientarr.filter(t => t.val);
    if (arr && arr.length) {
      this.ingredient = arr.map(z => z.name);
    }
  }

  onIngredientSelected(event, ing: string) {
    event.stopPropagation();

    const x = this.Ingredientarr.findIndex(t => t.name === ing);
    console.log('picked', ing, x);
    if (x > -1) {
      this.Ingredientarr[x].val = false;
      this.onIngredientChange();
    }
  }

  onFlavorChange() {

    this.flavor = '';
    const arr = this.Flavorarr.filter(t => t.val);
    if (arr && arr.length) {
      this.flavor = arr.map(z => z.name).join(', ');
    }

  }

  onSpreadsChange() {
    this.spreads = '';
    const arr = this.Spreadsarr.filter(t => t.val);
    if (arr && arr.length) {
      this.spreads = arr.map(z => z.name).join(', ');
    }
  }

  onCuisineChange() {
    this.cuisine = '';
    const arr = this.Cuisinearr.filter(t => t.val);
    if (arr && arr.length) {
      this.cuisine = arr.map(z => z.name).join(', ');
    }
  }

  onDietChange() {
    this.diet = '';
    const arr = this.Dietarr.filter(t => t.val);
    if (arr && arr.length) {
      this.diet = arr.map(z => z.name).join(', ');
    }
  }

  onEverydayChange() {
    this.everyday = '';
    const arr = this.Everydayarr.filter(t => t.val);
    if (arr && arr.length) {
      this.everyday = arr.map(z => z.name).join(', ');
    }
  }

  onCourseChange() {
    this.course = '';
    const arr = this.Coursearr.filter(t => t.val);
    if (arr && arr.length) {
      this.course = arr.map(z => z.name).join(', ');
    }
  }

  onBakeryChange() {
    console.log('into bakers');
    this.bakery = '';
    const arr = this.Bakeryarr.filter(t => t.val);
    if (arr && arr.length) {
      console.log('ARr', arr);
      this.bakery = arr.map(z => z.name).join(', ');
      console.log('baker', this.bakery);
    }
  }

  onSweetChange() {
    this.sweets = '';
    const arr = this.Sweetsarr.filter(t => t.val);
    if (arr && arr.length) {
      this.sweets = arr.map(z => z.name).join(', ');
    }
  }

  onGearChange() {
    this.gear = '';
    const arr = this.Geararr.filter(t => t.val);
    if (arr && arr.length) {
      this.gear = arr.map(z => z.name).join(', ');
    }
  }


  fnMatches(arr, str) {
    // \bcat\b
    //return str.match(new RegExp(`(\b)${arr.join("|")}(\b)`, 'gi'));

    if (str.includes('(')) {
      return;
    } else {
      return str.match(new RegExp(arr.join('|'), 'gi'));
    }
  }

  clearCappID() {
    this.capplat = -1;
    this.capplng = -1;
    this.cappID = '';
    this.cappaddress = '';
    this.cappname = '';
    this.cappphoto = '';
    this.capplocation = '';
    this.cappoffset = 0;
  }


  onClearArr(str) {
    this.things = '';
    this.who = '';
    this.seasons = '';
    this.tips = '';
    this.places = '';
    this.other = '';
    this.eat = '';

    this.meal = '';
    this.method = '';
    this.drink = '';
    this.ingredient = [];
    this.spreads = '';
    this.cuisine = '';
    this.diet = '';
    this.everyday = '';
    this.course = '';
    this.bakery = '';
    this.sweets = '';
    this.gear = '';
    this.flavor = '';

    this.Seasonarr.forEach(d => {
      d.val = false;
    });
    this.PlacesStayarr.forEach(d => {
      d.val = false;
    });
    this.TravelTipsarr.forEach(d => {
      d.val = false;
    });
    this.TravelOtherarr.forEach(d => {
      d.val = false;
    });
    this.WhoForarr.forEach(d => {
      d.val = false;
    });
    this.PlacesEatDrinkShoparr.forEach(d => {
      d.val = false;
    });
    this.ThingsSeeDoarr.forEach(d => {
      d.val = false;
    });

    this.Mealarr.forEach(d => {
      d.val = false;
    });

    this.Methodarr.forEach(d => {
      d.val = false;
    });

    this.Drinksarr.forEach(d => {
      d.val = false;
    });

    this.Ingredientarr.forEach(d => {
      d.val = false;
    });

    this.Flavorarr.forEach(d => {
      d.val = false;
    });
    this.Spreadsarr.forEach(d => {
      d.val = false;
    });
    this.Cuisinearr.forEach(d => {
      d.val = false;
    });
    this.Dietarr.forEach(d => {
      d.val = false;
    });
    this.Everydayarr.forEach(d => {
      d.val = false;
    });
    this.Coursearr.forEach(d => {
      d.val = false;
    });
    this.Bakeryarr.forEach(d => {
      d.val = false;
    });
    this.Sweetsarr.forEach(d => {
      d.val = false;
    });
    this.Geararr.forEach(d => {
      d.val = false;
    });

    if (str && str.length) {
      if (str === 'Things to See and Do') {
        this.ThingsSeeDoarr.find(s => s.name === str).val = true;
        this.things = str;

      }
      if (str === 'Places to Visit') {
        this.ThingsSeeDoarr.find(s => s.name === str).val = true;
        this.things = str;
      }
      if (str === 'Guide') {
        this.ThingsSeeDoarr.find(s => s.name === str).val = true;
        this.things = str;
      }
      if (str === 'Accommodations') {
        this.PlacesStayarr.find(s => s.name === str).val = true;
        this.places = str;
      }
      if (str === 'Places to Eat or Drink') {
        this.PlacesEatDrinkShoparr.find(s => s.name === str).val = true;
        this.eat = str;
      }
    }

    this.cd.detectChanges();
  }

  getSinglePhoto() {
    console.log('i am here');
    this.http.getSinglePhoto(this.clipped.datapid).then(res => {
      console.log('did i get it?', res);
    });
  }

  stripearly(str: string) {
    if (str.indexOf('\n') < 25 && str.indexOf('\n') !== -1) {
      str = str.substring(str.indexOf('\n') + 1);
    }

    // str = str.toLowerCase().replace(/(^|\s)([a-z])/g, (m) => m.toUpperCase());
    str = str.replace(/\n/g, '  ');

    return str.trim();
  };

  capitalize(str: string) {
    if (str.indexOf('\n') < 25 && str.indexOf('\n') !== -1) {
      str = str.substring(str.indexOf('\n') + 1);
    }

    str = str.toLowerCase().replace(/(^|\s)([a-z])/g, (m) => m.toUpperCase());
    str = str.replace(/\n/g, '  ');

    const pattern = /([!{(?.;,"-])\s*([a-z])/g;
    return str.replace(pattern, (match, p1, p2) => p1 + p2.toUpperCase());


    // return str;
  };

  onGrabType() {
    const arr = ['Air Fryer ', 'Best ', 'Cheesy ', 'Classic ', 'Creamy ', 'One Pot ',
      'Healthy ', ' Recipe', 'Homemade ', 'Insta Pot ', 'Instant Pot ', 'Slow Cooker ', 'Easy '];
    let desc = this.curguide.ssoc[this.curlang].description;
    arr.forEach(a => {
      const regex = new RegExp(a, 'g');
      desc = desc.replace(regex, '');

      const regex1 = /\s*\([^)]*\)/g;
      desc = desc.replace(regex1, '');

      const regex2 = /\s*{[^}]*}/g;
      desc = desc.replace(regex2, '');
    });
    this.curguide.type = desc;

  }

  removeLastDotIfFourDots(str: string): string {
    // Regular expression to match exactly four dots at the end of the string
    const regex = /\.{4}$/;

    // Check if the string ends with four dots
    if (regex.test(str)) {
      // Remove the last dot
      return str.slice(0, -1);
    }

    // Return the original string if it doesn't end with four dots
    return str;
  }

  onEditFoodGuide() {
    if (this.curguide.recipe.headline) {
      this.curguide.ssoc[this.curlang].description = this.curguide.recipe.headline.trim();
    }

    if (this.curguide.recipe.description) {
      this.curguide.fullmsg[this.curlang].description = this.curguide.recipe.description.trim() + '...';
      this.curguide.fullmsg[this.curlang].description = this.removeLastDotIfFourDots(this.curguide.fullmsg[this.curlang].description);
    }


    if (this.curguide.recipe.thumbnailUrl) {
      this.curguide.e = this.curguide.recipe.thumbnailUrl;
    }

  }

  extractIngredients(recipeIngredients: string[]): string[] {
    const matchedIngredients: string[] = [];

    recipeIngredients.forEach((recipeIngredient) => {
      const cleanedRecipeIngredient = recipeIngredient.replace(/[0-9.-]/g, '').trim();

      const matchedIngredientsForRecipe = this.Ingredientarr
        .filter((ingredient) =>
          cleanedRecipeIngredient.toLowerCase().includes(ingredient.name.toLowerCase())
        )
        .sort((a, b) => b.name.length - a.name.length) // Sort by length in descending order
        .map((ingredient) => ingredient.name);

      matchedIngredientsForRecipe.forEach((ingredient) => {
        if (!matchedIngredients.some((matched) => ingredient.includes(matched))) {
          matchedIngredients.push(ingredient);
        }
      });
    });

    return matchedIngredients;
  };

  onToggleMap() {
    this.showmap = !this.showmap;
    if (this.showmap) {
      this.calcMap();
    }
  }

  calcMap() {
    this.showmap = false;
    this.bounds = null;

    let totalLong = 0;
    let totalLat = 0;
    let minLong = Infinity;
    let minLat = Infinity;
    let maxLong = -Infinity;
    let maxLat = -Infinity;

    if (this.curguide['possibles'] && this.curguide['possibles'].length > 0) {
      this.curguide['possibles'].forEach(place => {
        totalLong += place.long;
        totalLat += place.lat;

        if (place.long < minLong) {
          minLong = place.long;
        }
        if (place.long > maxLong) {
          maxLong = place.long;
        }
        if (place.lat < minLat) {
          minLat = place.lat;
        }
        if (place.lat > maxLat) {
          maxLat = place.lat;
        }
      });
    }

    if (this.curguide.trip && this.curguide.trip.length > 0) {
      this.curguide.trip.forEach(place => {
        if (place.long < minLong) {
          minLong = place.long;
        }
        if (place.long > maxLong) {
          maxLong = place.long;
        }
        if (place.lat < minLat) {
          minLat = place.lat;
        }
        if (place.lat > maxLat) {
          maxLat = place.lat;
        }

      });
    }

    if (this.curguide.mentions && this.curguide.mentions.length > 0) {
      this.curguide.mentions.forEach(place => {
        if (place.long < minLong) {
          minLong = place.long;
        }
        if (place.long > maxLong) {
          maxLong = place.long;
        }
        if (place.lat < minLat) {
          minLat = place.lat;
        }
        if (place.lat > maxLat) {
          maxLat = place.lat;
        }

      });
    }

    if (maxLat !== -Infinity) {
      this.bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(minLat, minLong), // Southwest corner (x, y)
        new google.maps.LatLng(maxLat, maxLong)  // Northeast corner (a, b)
      );

      //this.long = totalLong / this.curguide['possibles'].length;
      //this.lat = totalLat / this.curguide['possibles'].length;
      this.showmap = true;
    }
  }


  onEditGuide(g?) {
    this.things = '';
    this.who = '';
    this.seasons = '';
    this.tips = '';
    this.places = '';
    this.other = '';
    this.eat = '';

    // todo: delete once onEditFoodGuide(g?) is working
    this.meal = '';
    this.method = '';
    this.drink = '';
    this.ingredient = [];
    this.spreads = '';
    this.cuisine = '';
    this.diet = '';
    this.everyday = '';
    this.course = '';
    this.bakery = '';
    this.sweets = '';
    this.gear = '';
    this.flavor = '';

    this.otherfeed = false;
    this.boozefeed = false;
    this.otherfeedonly = false;

    this.clearCappID();

    this.Seasonarr = _.cloneDeep(Season);
    this.PlacesStayarr = _.cloneDeep(PlacesStay);
    this.TravelTipsarr = _.cloneDeep(TravelTips);
    this.TravelOtherarr = _.cloneDeep(TravelOther);
    this.WhoForarr = _.cloneDeep(WhoFor);
    this.PlacesEatDrinkShoparr = _.cloneDeep(PlacesEatDrinkShop);
    this.ThingsSeeDoarr = _.cloneDeep(ThingsSeeDo);

    this.Mealarr = _.cloneDeep(Meal);
    this.Methodarr = _.cloneDeep(Method);
    this.Drinksarr = _.cloneDeep(Drinks);
    this.Ingredientarr = _.cloneDeep(Ingredient);
    this.Flavorarr = _.cloneDeep(Flavor);
    this.Spreadsarr = _.cloneDeep(Spreads);
    this.Cuisinearr = _.cloneDeep(Cuisine);
    this.Dietarr = _.cloneDeep(Diet);
    this.Everydayarr = _.cloneDeep(Everyday);
    this.Coursearr = _.cloneDeep(Course);
    this.Bakeryarr = _.cloneDeep(Bakery);
    this.Sweetsarr = _.cloneDeep(Sweets);
    this.Geararr = _.cloneDeep(Gear);


    this.guidedetails = true;
    this.imageseditted = false;

    if (g) {
      this.curguide = g;
      if (this.curguide.ssoc[this.curlang].description && this.curguide.ssoc[this.curlang].description.length > 0) {
        this.curguide.ssoc[this.curlang].description = this.curguide.ssoc[this.curlang].description.trim();
      }

      if (this.curguide.fullmsg[this.curlang].description && this.curguide.fullmsg[this.curlang].description.length > 0) {
        this.curguide.fullmsg[this.curlang].description = this.curguide.fullmsg[this.curlang].description.trim();
      }
      if (this.curguide.e && this.curguide.e.includes('.mp3')) {
        this.curguide.e = '';
      }
      if (this.curguide.fullmsg[this.curlang].description && this.curguide.fullmsg[this.curlang].description.length) {
        // eliminating " because chatgpt tends to add them
        this.curguide.fullmsg[this.curlang].description = this.curguide.fullmsg[this.curlang].description.replace(/"/g, '');
        const len = this.curguide.fullmsg[this.curlang].description.length;
        this.curguide.fullmsg[this.curlang].description = this.stripearly(this.curguide.fullmsg[this.curlang].description);
        if (this.curguide.fullmsg[this.curlang].description[len - 1] === '.' && this.curguide.fullmsg[this.curlang].description[len - 2] !== '.') {
          this.curguide.fullmsg[this.curlang].description += '..';
        }
      }


      const dmatches = this.fnMatches(AutoDelete, this.curguide.ssoc[this.curlang].description);
      if (dmatches && dmatches.length) {
        console.log('DELETINGS AUTO AUTO');
        // this.onDeleteGuide();
        // return;
      }

      this.tempdesc = this.curguide.fullmsg[this.curlang].description;

      this.curguide.tandl = [];

      if (this.curguide.trip && typeof this.curguide.trip === 'object') {
        this.curguide.trip = Object.values(this.curguide.trip);
      }
      if (this.curguide.mentions && typeof this.curguide.mentions === 'object') {
        this.curguide.mentions = Object.values(this.curguide.mentions);
      }
      console.log(3)

      if (this.curguide.trip && this.curguide.trip.length) {
        if (this.curguide.trip.length === 1) {
          this.is.getObject(`Places/${this.curguide.trip[0].ukey}/chain`).take(1).subscribe(chain => {
            if (chain) {
              this.chainstring = JSON.stringify(chain);
            }
          });
          this.is.getObject(`Places/${this.curguide.trip[0].ukey}/tourist_pids`).take(1).subscribe(touristpids => {
            if (touristpids) {
              this.chainstring = JSON.stringify(touristpids);
            }
          });
        }
      }
      console.log(4)

      if (this.curguide['tempplaces']) {
        console.log(5)
        this.curguide['tempplaces'].forEach(tmp => {
          let ok = true;
          if (this.curguide.trip && this.curguide.trip.length) {
            const x = this.curguide.trip.findIndex(t => t.ukey === tmp);
            if (x > -1) {
              ok = false;
            }
          }

          if (ok) {
            this.is.getObject(`Places/${tmp}/location`).take(1).subscribe(loc => {
              // need to build a trip
              if (loc && loc['name']) {

                this.is.getObject(`Places/${tmp}/viator`).take(1).subscribe(viator => {

                  const tp = {};
                  tp['ukey'] = tmp;
                  tp['objectID'] = tmp;
                  tp['aboutit'] = false;
                  tp['propogate'] = true;
                  if (loc.hash) {
                    tp['hash'] = loc.hash;
                  }
                  if (loc.lat) {
                    tp['lat'] = loc.lat;
                  }
                  if (loc.long) {
                    tp['long'] = loc.long;
                  }
                  if (loc.country) {
                    tp['country'] = loc.country;
                  }
                  if (loc.name) {
                    tp['name'] = loc.name;
                  }

                  if (viator) {
                    tp['viator'] = viator;
                  }

                  if (!this.curguide.trip) {
                    this.curguide.trip = [];
                  }

                  this.curguide.trip.push(tp);
                });
              }
            });
          }
        });
      }

      console.log(6)
      if (this.curguide['possibles']) {
        console.log(7)
        const temparr = Object.values(this.curguide['possibles']);
        let parr = [];
        temparr.forEach(ps => {
          if (Array.isArray(ps)) {
            ps.forEach(p => {
              parr.push(p);
            });
          } else {
            parr.push(ps);
          }
        });

        parr = [...new Map(parr.map(item => [item.ukey, item])).values()];
        this.curguide['possibles'] = parr.sort((a, b) => a.country.localeCompare(b.country));

        if (this.curguide.trip) {
          // Create a set containing all ids present in arrayB
          const idsInB = new Set(this.curguide.trip.map(obj => obj.ukey));

          // Filter out objects from arrayA whose ids are in arrayB
          this.curguide['possibles'] = this.curguide['possibles'].filter(obj => !idsInB.has(obj.ukey));
        }

        if (this.curguide.mentions) {
          // Create a set containing all ids present in arrayB
          const idsInB = new Set(this.curguide.mentions.map(obj => obj.ukey));

          // Filter out objects from arrayA whose ids are in arrayB
          this.curguide['possibles'] = this.curguide['possibles'].filter(obj => !idsInB.has(obj.ukey));
        }

        this.calcMap();

      }


      let something = false;

      /*
      const fmarr = MealMatch.map(t => t.match);
      const fmmatches = this.fnMatches(fmarr, this.curguide.ssoc[this.curlang].description);
      if (fmmatches && fmmatches.length) {
        fmmatches.forEach(m => {
          MealMatch.forEach(t => {
            if (t.match && m && t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Mealarr.findIndex(i => i.name === t.name);
              this.Mealarr[a].val = true;
              something = true;
              this.onMealChange();
            }
          });
        });
      }


      const hmarr = MethodMatch.map(t => t.match);
      const hmmatches = this.fnMatches(hmarr, this.curguide.ssoc[this.curlang].description);
      if (hmmatches && hmmatches.length) {
        hmmatches.forEach(m => {
          MethodMatch.forEach(t => {
            if (t.match && m && t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Methodarr.findIndex(i => i.name === t.name);
              this.Methodarr[a].val = true;
              something = true;
              this.onMethodChange();
            }
          });
        });
      }
      */

      /*
    const kmarr = DrinksMatch.map(t => t.match);
    const kmmatches = this.fnMatches(kmarr, this.curguide.ssoc[this.curlang].description);
    if (kmmatches && kmmatches.length) {
      kmmatches.forEach(m => {
        DrinksMatch.forEach(t => {
          if (t.match && m && t.match.toLowerCase() === m.toLowerCase()) {
            const a = this.Drinksarr.findIndex(i => i.name === t.name);
            this.Drinksarr[a].val = true;
            something = true;
            this.onDrinksChange();
          }
        });
      });
    }

       */

      /*
      const imarr = IngredientMatch.map(t => t.match);
      const immatches = this.fnMatches(imarr, this.curguide.ssoc[this.curlang].description);
      if (immatches && immatches.length) {
        immatches.forEach(m => {
          IngredientMatch.forEach(t => {
            if (t.match && m && t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Ingredientarr.findIndex(i => i.name === t.name);
              this.Ingredientarr[a].val = true;
              something = true;
              this.onIngredientChange();
            }
          });
        });
      }

            const vmarr = FlavorMatch.map(t => t.match);
            const vmmatches = this.fnMatches(vmarr, this.curguide.ssoc[this.curlang].description);
            if (vmmatches && vmmatches.length) {
              vmmatches.forEach(m => {
                FlavorMatch.forEach(t => {
                  if (t.match.toLowerCase() === m.toLowerCase()) {
                    const a = this.Flavorarr.findIndex(i => i.name === t.name);
                    this.Flavorarr[a].val = true;
                    something = true;
                    this.onFlavorChange();
                  }
                });
              });
            }


      const csmarr = SpreadsMatch.map(t => t.match);
      const csmatches = this.fnMatches(csmarr, this.curguide.ssoc[this.curlang].description);
      if (csmatches && csmatches.length) {
        csmatches.forEach(m => {
          SpreadsMatch.forEach(t => {
            if (t.match && m && t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Spreadsarr.findIndex(i => i.name === t.name);
              this.Spreadsarr[a].val = true;
              something = true;
              this.onSpreadsChange();
            }
          });
        });
      }
*/


      const cmarr = CuisineMatch.map(t => t.match);
      const cmmatches = this.fnMatches(cmarr, this.curguide.ssoc[this.curlang].description);
      if (cmmatches && cmmatches.length) {
        cmmatches.forEach(m => {
          CuisineMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Cuisinearr.findIndex(i => i.name === t.name);
              this.Cuisinearr[a].val = true;
              something = true;
              this.onCuisineChange();
            }
          });
        });
      }

      const dmarr = DietMatch.map(t => t.match);
      const dmmatches = this.fnMatches(dmarr, this.curguide.ssoc[this.curlang].description);
      if (dmmatches && dmmatches.length) {
        dmmatches.forEach(m => {
          DietMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Dietarr.findIndex(i => i.name === t.name);
              this.Dietarr[a].val = true;
              something = true;
              this.onDietChange();
            }
          });
        });
      }

      const emarr = EverydayMatch.map(t => t.match);
      const emmatches = this.fnMatches(emarr, this.curguide.ssoc[this.curlang].description);
      if (emmatches && emmatches.length) {
        emmatches.forEach(m => {
          EverydayMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Everydayarr.findIndex(i => i.name === t.name);
              this.Everydayarr[a].val = true;
              something = true;
              this.onEverydayChange();
            }
          });
        });
      }


      /*
      const omarr = CourseMatch.map(t => t.match);
      const ommatches = this.fnMatches(omarr, this.curguide.ssoc[this.curlang].description);
      if (ommatches && ommatches.length) {
        ommatches.forEach(m => {
          CourseMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Coursearr.findIndex(i => i.name === t.name);
              this.Coursearr[a].val = true;
              something = true;
              this.onCourseChange();
            }
          });
        });
      }
       */

      /*
      const bmarr = BakeryMatch.map(t => t.match);
      const bmmatches = this.fnMatches(bmarr, this.curguide.ssoc[this.curlang].description);
      if (bmmatches && bmmatches.length) {
        bmmatches.forEach(m => {
          BakeryMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Bakeryarr.findIndex(i => i.name === t.name);
              this.Bakeryarr[a].val = true;
              something = true;
              this.onBakeryChange();
            }
          });
        });
      }

      const smarr = SweetsMatch.map(t => t.match);
      const smmatches = this.fnMatches(smarr, this.curguide.ssoc[this.curlang].description);
      if (smmatches && smmatches.length) {
        smmatches.forEach(m => {
          SweetsMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Sweetsarr.findIndex(i => i.name === t.name);
              this.Sweetsarr[a].val = true;
              something = true;
              this.onSweetChange();
            }
          });
        });
      }
      */

      console.log(9)
      if (this.curguide.recipe) {

        this.onEditFoodGuide();


        /*
                if (this.curguide.recipe['recipeIngredient']) {
                  const matchedIngredients = this.extractIngredients(this.curguide.recipe['recipeIngredient']);
                }
        */

        if (this.curguide.recipe['keywords'] && typeof this.curguide.recipe['keywords'] === 'string') {
          this.curguide.recipe['keywords'] = this.curguide.recipe['keywords'].split(',');
        }

        const routes = [
          'keywords',
          'recipeCategory',
          'recipeCuisine'];


        routes.forEach(route => {
          if (this.curguide.recipe[route]) {
            const arrs = this.curguide.recipe[route] as any[];
            if (arrs && arrs.length && typeof arrs !== 'string') {
              arrs.forEach(m => {
                DrinksMatch.forEach(t => {
                  if (t.match.toLowerCase() === m.toLowerCase()) {
                    const a = this.Drinksarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Drinksarr[a].val = true;
                    something = true;
                    this.onDrinksChange();
                  }
                });
                MethodMatch.forEach(t => {
                  if (t.match.toLowerCase() === m.toLowerCase()) {
                    const a = this.Methodarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Methodarr[a].val = true;
                    something = true;
                    this.onMethodChange();
                  }
                });
                /*
                              MealMatch.forEach(t => {
                                if (t.match.toLowerCase() === m.toLowerCase()) {
                                  const a = this.Mealarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                                  this.Mealarr[a].val = true;
                                  something = true;
                                  this.onMealChange();
                                }
                              });
                */
                IngredientMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Ingredientarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Ingredientarr[a].val = true;
                    something = true;
                    this.onIngredientChange();
                  }
                });
                SpreadsMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Spreadsarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Spreadsarr[a].val = true;
                    something = true;
                    this.onSpreadsChange();
                  }
                });
                DietMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Dietarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Dietarr[a].val = true;
                    something = true;
                    this.onDietChange();
                  }
                });
                CuisineMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Cuisinearr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Cuisinearr[a].val = true;
                    something = true;
                    this.onCuisineChange();
                  }
                });
                EverydayMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Everydayarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Everydayarr[a].val = true;
                    something = true;
                    this.onEverydayChange();
                  }
                });
                /*
                console.log(53)
                CourseMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Coursearr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Coursearr[a].val = true;
                    something = true;
                    this.onCourseChange();
                  }
                });
                console.log(54)
                BakeryMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Bakeryarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Bakeryarr[a].val = true;
                    something = true;
                    console.log('bmatched');
                    this.onBakeryChange();
                  }
                });
                console.log(55)
                SweetsMatch.forEach(t => {
                  if (m.toLowerCase().includes(t.match.toLowerCase())) {
                    const a = this.Sweetsarr.findIndex(i => i.name.toLowerCase() === t.name.toLowerCase());
                    this.Sweetsarr[a].val = true;
                    something = true;
                    this.onSweetChange();
                  }
                });
                */
              });
            }
          }

        });

      }

      const arr = ThingsSeeDoMatch.map(t => t.match);
      const matches = this.fnMatches(arr, this.curguide.ssoc[this.curlang].description);
      if (matches && matches.length) {
        matches.forEach(m => {
          ThingsSeeDoMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.ThingsSeeDoarr.findIndex(i => i.name === t.name);
              this.ThingsSeeDoarr[a].val = true;
              something = true;
              this.onThingsChange();
            }
          });
        });
      }


      const gmarr = GearMatch.map(t => t.match);
      const gmmatches = this.fnMatches(gmarr, this.curguide.ssoc[this.curlang].description);
      if (gmmatches && gmmatches.length) {
        gmmatches.forEach(m => {
          GearMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Geararr.findIndex(i => i.name === t.name);
              this.Geararr[a].val = true;
              something = true;
              this.onGearChange();
            }
          });
        });
      }

      console.log(99)

      const sarr = PlacesEatDrinkShopMatch.map(t => t.match);
      const smatches = this.fnMatches(sarr, this.curguide.ssoc[this.curlang].description);
      if (smatches && smatches.length) {
        smatches.forEach(m => {
          PlacesEatDrinkShopMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.PlacesEatDrinkShoparr.findIndex(i => i.name === t.name);
              this.PlacesEatDrinkShoparr[a].val = true;
              something = true;
              this.onEatChange();
            }
          });
        });
      }


      const harr = PlacesStayMatch.map(t => t.match);
      const hmatches = this.fnMatches(harr, this.curguide.ssoc[this.curlang].description);
      if (hmatches && hmatches.length) {
        hmatches.forEach(m => {
          PlacesStayMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.PlacesStayarr.findIndex(i => i.name === t.name);
              this.PlacesStayarr[a].val = true;
              something = true;
              this.onPlacesChange();
            }
          });
        });
      }


      const tarr = TravelTipsMatch.map(t => t.match);
      const tmatches = this.fnMatches(tarr, this.curguide.ssoc[this.curlang].description);
      if (tmatches && tmatches.length) {
        tmatches.forEach(m => {
          TravelTipsMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.TravelTipsarr.findIndex(i => i.name === t.name);
              if (a > -1) {
                this.TravelTipsarr[a].val = true;
                something = true;
                this.onTipsChange();
              }
            }
          });
        });
      }

      const warr = SeasonMatch.map(t => t.match);
      const wmatches = this.fnMatches(warr, this.curguide.ssoc[this.curlang].description);
      //  const nogowarr = NogoSeasonMatch.map(t => t.match);
      if (wmatches && wmatches.length) {
        wmatches.forEach(m => {
          SeasonMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.Seasonarr.findIndex(i => i.name === t.name);
              const notwarr = NogoSeasonMatch.reduce((z, e, i) => {
                if (e.match === this.Seasonarr[a].name) {
                  z.push(NogoSeasonMatch[i].nogo);
                }
                return z;
              }, []);

              let nmatches;
              if (notwarr && notwarr.length) {
                nmatches = this.fnMatches(notwarr, this.curguide.ssoc[this.curlang].description);
              }
              if (!nmatches || nmatches.length === 0) {
                this.Seasonarr[a].val = true;
                something = true;
                this.onSeasonsChange();
              }
            }
          });
        });
      }


      const oarr = TravelOtherMatch.map(t => t.match);
      const omatches = this.fnMatches(oarr, this.curguide.ssoc[this.curlang].description);
      if (omatches && omatches.length) {
        omatches.forEach(m => {
          TravelOtherMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              //NogoTravelOtherMatch
              const a = this.TravelOtherarr.findIndex(i => i.name === t.name);
              const notoarr = NogoTravelOtherMatch.reduce((z, e, i) => {
                if (e.match === this.TravelOtherarr[a].name) {
                  z.push(NogoTravelOtherMatch[i].nogo);
                }
                return z;
              }, []);

              let nmatches;
              if (notoarr && notoarr.length) {
                nmatches = this.fnMatches(notoarr, this.curguide.ssoc[this.curlang].description);
              }
              if (!nmatches || nmatches.length === 0) {
                this.TravelOtherarr[a].val = true;
                something = true;
                this.onOtherChange();
              }
            }
          });
        });
      }


      const farr = WhoForMatch.map(t => t.match);
      const fmatches = this.fnMatches(farr, this.curguide.ssoc[this.curlang].description);
      if (fmatches && fmatches.length) {
        fmatches.forEach(m => {
          WhoForMatch.forEach(t => {
            if (t.match.toLowerCase() === m.toLowerCase()) {
              const a = this.WhoForarr.findIndex(i => i.name === t.name);
              this.WhoForarr[a].val = true;
              something = true;
              this.onWhoChange();
            }
          });
        });
      }

      /*
            if (!something) {
              const a = this.ThingsSeeDoarr.findIndex(i => i.name === 'Things to See and Do');
              this.ThingsSeeDoarr[a].val = true;
              this.onThingsChange();
            }
      */


      console.log(12)
      this.inframe = false;
      this.is.getObject(`Institutions/${this.curguide.cf}/obj/inframe`).take(1).subscribe(val => {
        if (val) {
          console.log('INFRAME', val);
          this.inframe = val;
        }
      });


      this.is.getObject(`Institutions/${this.curguide.cf}/obj/canquick`).take(1).subscribe(val => {
        this.canquick = val;
      });

      if (this.curguide.ssoc) {
        this.curguide.ssoc[this.curlang].description = this.capitalize(this.curguide.ssoc[this.curlang].description);

      }


      if (this.curguide && this.curguide.ty && this.curguide.ty === 'vlog' && this.curguide.m['youtubeid']) {
        // 'https://www.youtube.com/shorts/3EUT0obEXo8'); or 'https://www.youtube.com/watch/3EUT0obEXo8'); or 'https://www.youtube.com/embed/3EUT0obEXo8');
        this.videourl = this.dom.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.curguide.m['youtubeid']);
      }

      if (this.curguide && this.curguide.ty && this.curguide.ty === 'podcast' && this.curguide.e && !this.curguide.l) {
        this.curguide.l = this.curguide.e;
      }

      let hostname;
      if (this.curguide.l) {
        hostname = new URL(this.curguide.l).origin;
      }

      if (hostname && this.curguide['data'] && this.curguide['data']['images']) {
        let cnt = 0;
        this.curguide['data']['images'].forEach(i => {
          if (i.startsWith('/')) {
            this.curguide['data']['images'][cnt] = hostname + i;
          }
          cnt++;
        });
      }


      if (!this.curguide.topics.includes('/')) {
        this.curguide.topics = `${this.switchedit}/${this.curguide.cf}`;
      }
      this.zerom();

      // this bit of code is ONLY for tourist bureau stuff
      if (!this.curguide.trip) {
        this.curguide.trip = [];
        if (this.curguide['chain']) {
          this.curguide['chain'].forEach(c => {
            const hit = {
              objectID: c['Data_Pid'],
              ukey: c['Data_Pid'],
              name: c['Name'],
              propogate: true
            };
            this.curguide.trip.push(hit);
          });
        }

      } else if (this.curguide.trip && this.curguide.trip.length) {

        this.is.getObject(`Places/${this.curguide.trip[0]['ukey']}/viator`).take(1).subscribe(viator => {
          if (viator) {
            this.curguide.trip[0].viator = viator;
          }
        });

        this.rebuildLocs();
        console.log('MIDWAY 4.3');

      }

      this.domedlink = this.dom.bypassSecurityTrustResourceUrl(this.curguide.l);

    } else {
      console.log('MIDWAY 4.4');

      this.curguide = new Wish('', null);
      if (this.switchedit === 'Foodblogs') {
        this.curguide.app = 'Food';
      } else {
        this.curguide.app = 'Adventures';
      }
      this.curguide.as = true;
      this.curguide.cf = 'random';
      this.curguide.curlang = 'en';
      const date = new Date().getTime();
      this.curguide.ukey = date.toString();
      this.curguide.dt = '';
      this.curguide.flip = false;
      this.curguide.g = '';
      this.curguide.i = '';
      this.curguide.icon = '';
      this.curguide.m = {};
      this.curguide.m.catonly = false;
      this.curguide.m.droptitle = false;
      this.curguide.m.sponsored = false;
      this.curguide.topics = 'random';
      this.curguide.ukey = date.toString();
      this.curguide.ty = 'feed';
      this.curguide.v = 'U';
      this.curguide.vex = -1;

      // curguide.m.name = 'Matador Network';
      // curguide.cn = 'Matador Network';
    }

    this.curguide.types = [];
    this.curguide.attribs = [];

    console.log('MIDWAY 5');


    this.onClearGuideImg();
    this.createAutos();
    if (this.curguide.date) {
      this.pubdate = new Date(this.curguide.date);
    }

    if (this.curguide.findate) {
      this.xdate = new Date(this.curguide.findate);
    }

  }

  isConnect(): boolean {
    const str = this.curguide.types.join(',');
    if (str && (str.includes('Short Trips') ||
      str.includes('Cruises') ||
      str.includes('Hiking') ||
      str.includes('Biking') ||
      str.includes('Road Trips') ||
      str.includes('Air Travel'))) {
      return true;
    } else {
      return false;
    }
  }

  isShort() {
    if (this.curguide && this.curguide.types) {
      if (this.isConnect()) {
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }


  makeCategories(l) {
    if (!l.types) {
      // if no types then save to PlacesTags/ThingsSeeDo
      this.is.setObjNoLog(`PlacesTags/ThingsSeeDo/${l.ukey}`, l);
    } else {
      let ps = false;
      let tt = false;
      let se = false;
      let pd = false;
      let xx = false;

      l.types.forEach(t => {

        const x = PlacesStay.findIndex(p => p === t);
        if (x > -1 && !ps) {
          ps = true;
          this.is.setObjNoLog(`PlacesTags/PlacesStay/${l.ukey}`, l);
        }

        const y = TravelTips.findIndex(p => p === t);
        if (y > -1 && !tt) {
          tt = true;
          this.is.setObjNoLog(`PlacesTags/TravelTips/${l.ukey}`, l);
        }

        const b = Season.findIndex(p => p === t);
        if (b > -1 && !se) {
          se = true;
          this.is.setObjNoLog(`PlacesTags/Season/${l.ukey}`, l);
        }

        const e = PlacesEatDrinkShop.findIndex(p => p === t);
        if (e > -1 && !pd) {
          pd = true;
          this.is.setObjNoLog(`PlacesTags/PlacesEatDrinkShop/${l.ukey}`, l);
        }

        const f = ThingsSeeDo.findIndex(p => p === t);
        if (f > -1 && !xx) {
          xx = true;
          this.is.setObjNoLog(`PlacesTags/ThingsSeeDo/${l.ukey}`, l);
        }
      });
    }
  }

  // todo: this is messed up
  /*
    savePOIS() {
      const ukey = this.newcurguide.trip[0].ukey;
      if (ukey && ukey.length > 10) {
        this.newcurguide.mentions.forEach(m => {
          if (m['kingdom'] && m['kingdom'].length) {
            this.is.setObjNoLog(`Places/${m.ukey}/taxonomy/kingdom`, m['kingdom']);
          }

          if (m['phylum']) {
            if (m['phylum'] && m['phylum'].length > 5) {
              this.is.setObjNoLog(`Places/${m.ukey}/taxonomy/phylum`, m['phylum']);
            }

            const x = this.classifications.findIndex(c => c.phylum == m['phylum'])
            if (x > -1) {
              this.is.setObjNoLog(`Places/${ukey}/${this.classifications[x].action}/${m.ukey}`, m);
              this.is.getObject(`Places/${ukey}/${this.classifications[x].action}/${m.ukey}/articles`).take(1).subscribe(art => {
                if (!art) {
                  this.is.setObjNoLog(`Places/${m.ukey}/parents/${ukey}`, {ukey, ty: this.classifications[x].action});
                }

                this.is.setObjNoLog(`Places/${ukey}/${this.classifications[x].action}/${m.ukey}/articles/${this.newcurguide.ukey}`, this.newcurguide.ukey);
              });
            }



          }
        });

      }
    }
  */

  onGetBlogOld() {

    try {
      this.http.getFestival(this.curtopix.feed).then(result => {
        console.log('RESULT', result);
        const res = JSON.parse(result);
        console.log('RESULT', res);


      });
    } catch (error) {
      console.error('Invalid URL:', error);
      return {homepage: '', domain: ''};
    }


    /*
      try {
        this.http.getWineSpec(this.curtopix.feed).then(result => {
          console.log('RESULT Wine', result)
          const res = JSON.parse(result)
          console.log('RESULT', res)


        })
      } catch (error) {
        console.error('Invalid URL:', error);
        return {homepage: '', domain: ''};
      }
  */
  }

  onGetBlog() {
    try {
      const urlObj = new URL(this.curtopix.feed);
      this.curtopix.ukey = urlObj.hostname.includes('www.') ? urlObj.hostname.split('www.')[1] : urlObj.hostname;
      this.curtopix.ukey = this.curtopix.ukey.split('.')[0];
      this.curtopix.domain = `${urlObj.protocol}//${urlObj.hostname}`;
      this.http.scrapeBlog(this.curtopix.domain).then(result => {
        const res = JSON.parse(result);
        if (res['name_of_website']) {
          this.curtopix.name = res['name_of_website'];
        }

        if (res['email'] && res['email'] !== 'N.A.') {
          this.curtopix.adminemail = res['email'];
        }

        if (res['apple_touch_icon_link'] && res['apple_touch_icon_linl'] !== 'N.A.') {
          this.curtopix.icon = res['apple_touch_icon_link'];
        }

        if (res['name'] && res['name'] !== 'N.A.') {
          this.curtopix.adminname = res['name'];
        }

        if (res['description'] && res['description'] !== 'N.A.') {
          this.curtopix.description.en.description = res['description'];
        }

        if (res['image'] && res['image'] !== 'n.a.') {
          this.curtopix['cardimg'] = res['image'];
        }

        /*

        RESULT {
          "name_of_website": "Handle the Heat",
          "contact_email": "n.a.",
          "fav_icon_link": "https://handletheheat.com/wp-content/uploads/2019/07/cropped-Handle-the-Heat-Logo-32x32.png",
          "apple_touch_icon_link": "https://handletheheat.com/wp-content/uploads/2019/07/cropped-Handle-the-Heat-Logo-180x180.png",
          "creator_name": "Tessa Arias",
          "website_description": "Handle the Heat is a popular food blog created by Tessa Arias. With a focus on baking and dessert recipes, Tessa provides detailed instructions and tips to help home bakers achieve delicious and pr

         "creator_name": "Lori",
         "website_description": "Foxes Love Lemons is a culinary blog featuring simple yet special recipes for the home chef. Created by Lori, a Detroit-area culinary school graduate and food photographer, the blog showcases original recipes that are both accessible and unique. It's a destination for food enthusiasts who appreciate easy-to-follow recipes that can elevate everyday cooking.",
         "creator_photograph_link":
        */


      });
    } catch (error) {
      console.error('Invalid URL:', error);
      return {homepage: '', domain: ''};
    }
  }

  onCappID() {
    if (this.capplat !== -1 && this.capplng !== -1) {
      const hash = geohash.encode(this.capplat, this.capplng);
      this.cappID = 'cappID' + hash;

      console.log('cappId', this.cappID);
      const hit = {
        objectID: this.cappID,
        photo: this.cappphoto,
        loc: this.capplocation,
        ukey: this.cappID,
        name: this.cappname,
        propogate: true,
      };

      if (this.cappID && this.cappID.length > 3) {

        // 1. go do PlacesExist to see if it all ready exits
        this.is.getObject(`PlaceExists/${this.cappID}/ukey`).take(1).subscribe(ukey => {
          if (!this.curguide.mentions) {
            this.curguide.mentions = [];
          }
          if (this.aerialview) {
            this.hit['aerialview'] = this.aerialview;
            this.aerialview = null;
          }
          if (ukey) {
            console.log('mentions 1');
            this.curguide.mentions.push(hit);
          } else {
            console.log('mentions 2');
            this.curguide.mentions.push(hit);

            this.is.setObjNoLog(`PlacesCapp/${this.cappID}/name`, this.cappname);
            this.is.setObjNoLog(`PlacesCapp/${this.cappID}/hash`, hash);
            this.is.setObjNoLog(`PlacesCapp/${this.cappID}/ukey`, this.cappID);

            /*
                        this.is.setObjNoLog(`PlacesCapp/${this.cappID}/location`,
                          {address: this.cappaddress, lat: this.capplat, long: this.capplng, name: this.cappname, hash: hash});
                        this.is.setObjNoLog(`PlacesCapp/${this.cappID}/guides`, this.curguide);
            */

            const newloc = new PlaceLoc();
            newloc.loc = this.capplocation;
            newloc.location = this.capplocation;
            newloc.long = this.capplng;
            newloc.lat = this.capplat;
            // newloc.bounds = place.geometry.bounds;
            // newloc.viewport = place.geometry.viewport;
            newloc.hash = hash;
            newloc.address = this.cappaddress;
            newloc.utc_offset_minutes = this.cappoffset;

            newloc.keys = [];
            const key = new Key();
            key.id = this.cappID;
            key.name = 'Google';
            newloc.keys.push(key);

            this.is.setObjNoLog(`Places/${this.cappID}/ukey`, this.cappID);  // ukey
            this.is.setObjNoLog(`Places/${this.cappID}/hash`, hash);  // hash
            this.is.setObjNoLog(`Places/${this.cappID}/name`, this.cappname);  // name
            this.is.setObjNoLog(`Places/${this.cappID}/location`, newloc); // location
            this.is.setObjNoLog(`Places/${this.cappID}/address`, this.cappaddress); // address
            this.is.setObjNoLog(`Places/${this.cappID}/taxonomy/kingdom`, 'POI'); // type
            this.is.setObjNoLog(`Places/${this.cappID}/photos`, [{url: this.cappphoto, who: 'Google'}]); // photos

            this.is.setObjNoLog(`PlaceExists/${this.cappID}/code`, 'NLG');
            this.is.setObjNoLog(`PlaceExists/${this.cappID}/ukey`, this.cappID);


          }
        });
      }
    }
  }

  onSavePhoto() {
    if (this.curguide && this.curguide.e) {
      let caption = '';
      if (this.photocaption && this.photocaption.length) {
        caption = this.photocaption;
      }
      let who = this.curguide.cn;
      if (this.photowho && this.photowho.length) {
        who = this.photowho;
      }


    }

    this.photowho = '';
    this.photocaption = '';

    console.log('aftershow curguide', this.curguide);
  }

  onDeeletetTouristBoard() {
    this.curguide['tb'] = null;
  }

  onTouristBoard() {
    if (this.curguide.trip || this.curguide.mentions) {
      let about;
      if (this.curguide.trip && this.curguide.trip.length) {
        this.curguide.trip.forEach(tr => {
          if (tr.aboutit) {
            about = tr;
          }
        });
      }

      if (!about && this.curguide.mentions && this.curguide.mentions.length) {
        this.curguide.mentions.forEach(tr => {
          if (tr.aboutit) {
            about = tr;
          }
        });
      }

      if (!about && this.curguide.trip && this.curguide.trip.length) {
        about = this.curguide.trip[0];
      }

      if (!about && this.curguide.mentions && this.curguide.mentions) {
        about = this.curguide.mentions[0];
      }

      if (about && about.country) {
        const tbloc = {
          long: about.long,
          lat: about.lat,
          country: about.country
        };

        this.touristBoardService.getFilteredTouristBoardIdsLoc(tbloc).then(ids => {
          console.log('IDS', ids);

          if (ids && ids.length) {
            const dialogRef = this.dialog.open(IdSelectionDialogComponent, {
              width: '300px',
              data: {ids: ids},
            });

            dialogRef.afterClosed().subscribe((selectedId) => {
              if (selectedId !== '') {

                // Proceed to fetch the name
                this.is
                  .getObject(`Institutions/${selectedId}/websitename`)
                  .take(1)
                  .subscribe((name) => {
                    this.curguide['tb'] = {ukey: selectedId, name};
                  });
              }
            });
          } else {
            this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
          }
        });
      }
    }
  }

  completeSaveGuide(directory: string, days, foodcomps?) {
    if (this.xdate) {
      this.curguide.findate = new Date(this.xdate).getTime() - Math.floor(Math.random() * 10000);
      this.xdate = null;
    } else if (days > 0) {
      this.curguide.findate = new Date().getTime() + days * 24 * 60 * 60 * 1000;
    }

    this.poiartist = null;
    this.poiartistnote = '';
    this.curassignthread = null;
    this.curthread = null;
    this.poiroute = null;
    this.poiroutenote = '';


    this.curguide.g = this.switchedit;
    const ukey = this.curguide.ukey;
    if (this.guids === 'guids') {
      const fact = Math.trunc(Math.random() * 10000000);
      this.curguide.date = this.pubdate.getTime() + fact;
      this.curguide.ukey = this.curguide.date.toString(10);
    }


    if (directory === `Tmp${this.switchedit}/${this.guids}` || (this.switchedit === 'addarticle') || (this.switchedit === 'TempInfo/guidescachetmp')) {

      let tbloc;
      this.curguide.m.inframe = this.inframe;
      this.curguide.m.canquick = this.canquick;
      const arr = [];
      arr.push(...this.Seasonarr.filter(t => t.val));
      arr.push(...this.PlacesStayarr.filter(t => t.val));
      arr.push(...this.TravelTipsarr.filter(t => t.val));
      arr.push(...this.TravelOtherarr.filter(t => t.val));
      arr.push(...this.WhoForarr.filter(t => t.val));
      arr.push(...this.PlacesEatDrinkShoparr.filter(t => t.val));
      arr.push(...this.ThingsSeeDoarr.filter(t => t.val));

      arr.push(...this.Mealarr.filter(t => t.val));
      arr.push(...this.Methodarr.filter(t => t.val));
      arr.push(...this.Drinksarr.filter(t => t.val));
      arr.push(...this.Ingredientarr.filter(t => t.val));
      arr.push(...this.Flavorarr.filter(t => t.val));
      arr.push(...this.Cuisinearr.filter(t => t.val));
      arr.push(...this.Spreadsarr.filter(t => t.val));
      arr.push(...this.Sweetsarr.filter(t => t.val));
      arr.push(...this.Bakeryarr.filter(t => t.val));
      arr.push(...this.Dietarr.filter(t => t.val));
      arr.push(...this.Everydayarr.filter(t => t.val));
      arr.push(...this.Coursearr.filter(t => t.val));
      arr.push(...this.Geararr.filter(t => t.val));
      this.curguide.types = arr.map(z => z.name);


      if (arr && arr.length) {
        const vtags = this.vs.getViatorTags(this.curguide);
        if (vtags && vtags.length) {
          this.curguide.m.vtags = vtags;
        }
      }

      this.onSavePhoto();

      const ix = this.TravelOtherarr.findIndex(o => o.name === 'Good to Know');
      if (ix > -1) {
        if (this.curguide.trip && this.curguide.trip.length) {
          if (this.TravelOtherarr[ix].val && this.curguide.trip[0] && this.curguide.trip[0].ukey) {
            this.is.setObjNoLog(`Places/${this.curguide.trip[0].ukey}/goodtoknow/${this.curguide.ukey}`, this.curguide);
          }
        } else if (this.curguide.mentions && this.curguide.mentions.length) {
          if (this.TravelOtherarr[ix].val && this.curguide.mentions[0] && this.curguide.mentions[0].ukey) {
            this.is.setObjNoLog(`Places/${this.curguide.mentions[0].ukey}/goodtoknow/${this.curguide.ukey}`, this.curguide);
          }
        }
      }


      const iv = this.TravelOtherarr.findIndex(o => o.name === 'Discovery');
      if (iv > -1) {
        if (this.curguide.trip && this.curguide.trip.length) {
          if (this.TravelOtherarr[ix].val && this.curguide.trip[0] && this.curguide.trip[0].ukey) {
            this.is.setObjNoLog(`Places/${this.curguide.trip[0].ukey}/discovery/${this.curguide.ukey}`, this.curguide);
          }
        } else if (this.curguide.mentions && this.curguide.mentions.length) {
          if (this.TravelOtherarr[ix].val && this.curguide.mentions[0] && this.curguide.mentions[0].ukey) {
            this.is.setObjNoLog(`Places/${this.curguide.mentions[0].ukey}/discovery/${this.curguide.ukey}`, this.curguide);
          }
        }
      }

      if (this.curguide && this.curguide.mentions && this.curguide.mentions.length) {
        const ixx = this.TravelOtherarr.findIndex(o => o.name === 'Included In');
        if (ixx > -1) {
          this.curguide.mentions.forEach(mention => {
            if (this.TravelOtherarr[ix].val && mention.ukey) {
              this.is.setObjNoLog(`Places/${mention.ukey}/includedin/${this.curguide.ukey}`, this.curguide);
            }
          });
        }
      }


      if (this.switchedit.includes('Food') && this.curguide.type && this.curguide.type.length) {
        const farr = this.curguide.type.split(',');
        const fname = farr[0];
        this.is.pushObj(`Topics/FoodTypes/${fname}`, this.curguide.ukey);

        let altnames = '';
        if (farr.length > 1) {
          farr.shift();
          altnames = farr.join(',');
        }


        const img = {
          image: this.curguide.i,
          source: this.curguide.cf,
          sourcename: this.curguide.cn,
          sourcelink: this.curguide.l
        };
        this.is.getObject(`Topics/TempInfo/dishes/${fname}/name`).take(1).subscribe(food => {
          if (food) {
            // save the images
            this.is.pushObj(`Topics/TempInfo/dishes/${fname}/images`, img);
          } else {
            const images = [];
            images.push(img);
            const foodobj = {
              name: fname,
              altnames,
              images
            };
            this.is.setObjNoLog(`Topics/TempInfo/dishes/${fname}`, foodobj);
          }
        });

      }

      if (this.curguide && this.curguide.m && this.curguide.m.propfeed) {
        this.onUpdateCount();

        let arv = false;
        if (this.curguide.mentions) {
          this.curguide.mentions.forEach(m => {
            if (m['aerialtemp']) {
              arv = true;
            }
          });
        }

        if (arv) {
          this.is.setObjNoLog(`Topics/TempInfo/aerialcache/${this.curguide.ukey}`, this.curguide);
        } else if (this.switchedit === 'Foodblogs') {
          this.is.setObjNoLog(`Topics/TempInfo/foodguidescache/${this.curguide.ukey}`, this.curguide);
          if (this.otherfeed) {
            this.curguide.app = 'Adventures';
            this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
            this.curguide.app = 'Food';
          }
          if (this.boozefeed) {
            this.curguide.app = 'Drink';
            this.is.setObjNoLog(`Topics/TempInfo/drinkguidescache/${this.curguide.ukey}`, this.curguide);
            this.curguide.app = 'Food';
          }
        } else {

          if (!this.otherfeedonly) {

            // this is the main propogation for the Adventures Feed

            /* when saving an article i want to save the tourist board of the about curwish.tb  place. */
            // todo: put this back when ???
            if (this.curguide.trip || this.curguide.mentions) {
              let about;
              if (this.curguide.trip && this.curguide.trip.length) {
                this.curguide.trip.forEach(tr => {
                  if (tr.aboutit) {
                    about = tr;
                  }
                });
              }

              if (!about && this.curguide.mentions && this.curguide.mentions.length) {
                this.curguide.mentions.forEach(tr => {
                  if (tr.aboutit) {
                    about = tr;
                  }
                });
              }

              if (!about && this.curguide.trip && this.curguide.trip.length) {
                about = this.curguide.trip[0];
              }

              if (!about && this.curguide.mentions && this.curguide.mentions) {
                about = this.curguide.mentions[0];
              }

              if (about && about.country && !this.curguide['tb']) {
                tbloc = {
                  long: about.long,
                  lat: about.lat,
                  country: about.country
                };

                if (!this.curguide['tb']) {
                  this.touristBoardService.getFilteredTouristBoardIdsLoc(tbloc).then(ids => {
                    console.log('IDS', ids);
                    if (ids && ids.length) {
                      const dialogRef = this.dialog.open(IdSelectionDialogComponent, {
                        width: '300px',
                        data: {ids: ids},
                      });

                      dialogRef.afterClosed().subscribe((selectedId) => {
                        if (selectedId === '') {
                          // User chose to skip
                          this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
                          const x = this.fulllist.findIndex(l => l.ukey === this.curguide.ukey);
                          if (x > -1) {
                            this.fulllist.splice(x, 1);
                            if (this.switchedit !== 'addarticle' && this.switchedit !== 'addvideo') {
                              this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${ukey}`);
                            }
                          }

                          if (this.fulllist && this.fulllist.length) {
                            this.onEditGuide(this.fulllist[0]);
                          }
                        } else {

                          // Proceed to fetch the name
                          this.is
                            .getObject(`Institutions/${selectedId}/websitename`)
                            .take(1)
                            .subscribe((name) => {
                              this.curguide['tb'] = {ukey: selectedId, name};
                              console.log('SAVING', `Topics/TempInfo/guidescache/${this.curguide.ukey}`);
                              console.log('TBTB', this.curguide['tb']);
                              this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);

                              const x = this.fulllist.findIndex(l => l.ukey === this.curguide.ukey);
                              this.fulllist.splice(x, 1);
                              if (this.switchedit === 'TempInfo/guidescachetmp') {
                                this.is.deleteObjNoLog(`Topics/TempInfo/guidescachetmp/${this.curguide.ukey}`);
                              } else if (this.switchedit !== 'addarticle' && this.switchedit !== 'addvideo') {
                                this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${ukey}`);
                              }


                              if (this.fulllist && this.fulllist.length) {
                                this.onEditGuide(this.fulllist[0]);
                              }

                            });
                        }
                      });
                    } else {
                      this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
                    }


                  });
                } else {
                  this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
                }

              } else {
                this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
              }

            } else {

              this.is.setObjNoLog(`Topics/TempInfo/guidescache/${this.curguide.ukey}`, this.curguide);
            }


            // todo: uncomment this when ready to publish
            // this.getArticles(this.curguide, this.curguide.ukey)
          }
          if (this.otherfeed) {
            this.curguide.app = 'Food';
            this.is.setObjNoLog(`Topics/TempInfo/foodguidescache/${this.curguide.ukey}`, this.curguide);
            this.curguide.app = 'Adventures';
          }
        }
      } else {
        console.log('error');
        /*
                this.http.propGuide(this.curguide).take(1).subscribe(res => {
                  console.log('RESULT', res);
                });
        */
      }


      if (!tbloc) {
        const x = this.fulllist.findIndex(l => l.ukey === this.curguide.ukey);
        this.fulllist.splice(x, 1);
        if (this.switchedit === 'TempInfo/guidescachetmp') {
          this.is.deleteObjNoLog(`Topics/TempInfo/guidescachetmp/${this.curguide.ukey}`);
        } else if (this.switchedit !== 'addarticle' && this.switchedit !== 'addvideo') {
          this.is.deleteObjNoLog(`Topics/Tmp${this.switchedit}/${this.guids}/${ukey}`);
        }


        if (this.switchedit === 'Foodblogs' && foodcomps) {
          if (this.curguide['recipe'] && Array.isArray(this.curguide['recipe']['recipeIngredient'])) {
            console.log('HAS RECIPE');
            this.is.setObjNoLog(`Topics/TempInfo/RecipeTypes/${this.curguide.ukey}`, this.curguide);
          } else {
            console.log('NO RECIPE');
          }
        }


        if (this.switchedit === 'addarticle') {
          this.addcustom = 'addarticle';
          this.clearIt();
          this.guidedetails = false;
        } else if (this.switchedit === 'addvideo') {
          this.addcustom = 'addvideo';
          this.clearIt();
          this.guidedetails = false;
        } else if (this.quickp) {
          if (this.fulllist && this.fulllist.length && this.quickp) {
            this.onEditGuide(this.fulllist[0]);
          }
        } else {
          console.log('completeSaveGuide 01');
          this.clearIt();
          this.guidedetails = false;
        }
      }

    }
  }

  onNews() {
    this.is.setObjNoLog(`Topics/TempInfo/Articles/${this.curguide.ukey}`, this.curguide);
    this.onDeleteGuide();
  }


  onGetGuides() {
    this.http.parserRSS(this.curtopix.feed, this.curtopix.ukey, this.curtopix.name).subscribe(guides => {
      console.log('get guides', guides);
    });
  }


  // can use this function in the Blogger console when they are creating guides with our software. until then probably don't need it
  onSaveGuide() {
    if (!this.curguide.ukey) {
      this.curguide.ukey = this.db.createPushId();
    }

    if (this.guideimg) {
      html2canvas(this.guidecanvas.nativeElement, {useCORS: true}).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        let blogukey;

        if (this.curguide.cf) {
          blogukey = this.curguide.cf;
        } else {
          blogukey = this.curblog.ukey;
        }


        const filename = `Rss/${blogukey}/${this.curguide.ukey}.png`;

        const fileRef = this.afStorage.ref(filename);
        fileRef.putString(dataURL, 'data_url').then(res => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          this.afStorage.storage.refFromURL(s).getDownloadURL()
            .then(url => {

              /*  todo: not sure????
                            this.curguide.src = url;
              */

              this.updateguideimg = false;
              this.completeSaveGuide('to come', 0);
            });
        });


      });
    } else {
      this.completeSaveGuide('to come', 0);
    }
  }

  onCancelGuide() {
    this.clearIt();
    this.guidedetails = false;
    if (this.switchedit === 'addarticle' || this.switchedit === 'addvideo') {
      this.addcustom = this.switchedit;
    }
  }


  saveBlogComplete() {
    // need to save it to the Algolia index.
    if (this.curtopix.ukey) {
      this.curtopix.ukey = this.curtopix.ukey.trim();
      this.curtopix.objectID = this.curtopix.ukey;
      this.curtopix.topic = this.curtopix.ukey;
    }

    if (this.blogtgs && this.blogtgs.length > 0) {
      this.curtopix.tags = this.blogtgs.join(',');
    }


    if (this.curtopix.name) {
      this.curtopix.name = this.curtopix.name.trim();
    }

    if (this.curtopix.domain) {
      this.curtopix.domain = this.curtopix.domain.trim();
    }

    if (this.curtopix.feed) {
      this.curtopix.feed = this.curtopix.feed.trim();
    }

    if (this.curtopix.org) {
      this.curtopix.org = this.curtopix.org.trim();
    }

    if (this.curtopix.icon) {
      this.curtopix.icon = this.curtopix.icon.trim();
    }

    /*
        const x = this.travelblogs.findIndex(b => b.ukey === this.curtopix.ukey);
        if (x === -1) {
          this.travelblogs.push(this.curtopix);
        }
    */
    if (this.curtopix.ukey) {
      if (this.foodblog) {
        this.curtopix.edition = 'Food';
        this.is.setObjNoLog(`Topics/Foodblogs/${this.curtopix.ukey}`, this.curtopix);
        this.foodblog = false;
      } else {
        this.curtopix.edition = 'Adventures';
        this.is.setObjNoLog(`Topics/Travelblogs/${this.curtopix.ukey}`, this.curtopix);
      }
    }

    /*
        const y = this.blogs.findIndex(b => b.ukey === this.curtopix.ukey);
        if (y > -1) {
          this.is.deleteObjNoLog(`Topics/TmpTravel/${this.curtopix.ukey}`);
          this.blogs.splice(y, 1);
        }
    */


    /*
        if (this.curtopix['_highlightResult']) {
          this.curtopix['_highlightResult'] = null;
        }
        if (this.curtopix.prosurveydesc) {
          this.curtopix.prosurveydesc = null;
        }

        this.http.postAlgoliaEntry('Topics', this.curtopix, true);
    */

    this.onDelBlog();

    this.curtopix = null;
  }

  saveCardImgOnly() {
    if (this.cardcanvas && this.cardcanvas.nativeElement && this.curtopix.ukey) {
      html2canvas(this.cardcanvas.nativeElement, {useCORS: true}).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        const filename = `Rss/${this.curtopix.ukey}/cardimg.png`;

        const fileRef = this.afStorage.ref(filename);
        fileRef.putString(dataURL, 'data_url').then(res => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          this.afStorage.storage.refFromURL(s).getDownloadURL()
            .then(url => {
              this.curtopix.cardimg = url;
              alert('Image successfully saved.');
            });
        });
      });
    }
  }

  saveCardImg() {
    if (this.cardcanvas && this.cardcanvas.nativeElement && !this.curtopix.cardimg) {
      html2canvas(this.cardcanvas.nativeElement, {useCORS: true}).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        const filename = `Rss/${this.curtopix.ukey}/cardimg.png`;

        const fileRef = this.afStorage.ref(filename);
        fileRef.putString(dataURL, 'data_url').then(res => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          this.afStorage.storage.refFromURL(s).getDownloadURL()
            .then(url => {
              this.curtopix.cardimg = url;
              this.updatecardimg = false;
              this.saveBlogComplete();
            });
        });
      });
    } else {
      this.updatecardimg = false;
      this.saveBlogComplete();
    }
  }

  onSaveBlog() {
    if (this.tmpimg) {
      html2canvas(this.canvas.nativeElement, {useCORS: true}).then(canvas => {
        const dataURL = canvas.toDataURL('image/png');
        const filename = `Rss/${this.curtopix.ukey}/marketing.png`;

        const fileRef = this.afStorage.ref(filename);
        fileRef.putString(dataURL, 'data_url').then(res => {
          const s = `gs://library-titleix.appspot.com/${filename}`;
          this.afStorage.storage.refFromURL(s).getDownloadURL()
            .then(url => {
              this.curtopix.mktlogo = url;
              this.updatemktlogo = false;

              if (this.cardimg) {
                this.saveCardImg();
              } else {
                this.saveBlogComplete();
              }
            });
        });
      });
    } else {
      if (this.cardimg) {
        this.saveCardImg();
      } else {
        this.saveBlogComplete();
      }
    }
  }

  fileChangeGuideEvent(event: any): void {
    this.imageChangedEvent = event;
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.guideimg = reader.result;
    };
  }

  fileChangeCardEvent(event: any): void {
    this.imageChangedEvent = event;
    const files = event.target.files;
    if (files.length === 0
    ) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.cardimg = reader.result;
    };
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    const files = event.target.files;
    if (files.length === 0
    ) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.tmpimg = reader.result;
    };
  }

  onCancelBlog() {
    this.curtopix = null;
  }


  onAddBlog() {
    this.onClearImg(2);
    this.blogtgs = [];
    this.foodblog = false;

    this.updatemktlogo = true;
    this.updatecardimg = true;
    this.curtopix = new Topix();
  }

  onOpenURL(url) {
    window.open(url, '_blank');
  }


  onEditTopix(hit, i, open) {
    this.onClearImg(2);
    this.curtopix = hit;
    if (open) {
      window.open(this.curtopix['tmpimage'], '_blank');
    }
    if (this.curtopix.tags) {
      this.blogtgs = this.curtopix.tags.split(',');
    }
    if (this.curtopix.mktlogo) {
      this.updatemktlogo = false;
    } else {
      this.updatemktlogo = true;
    }
    if (this.curtopix.cardimg) {
      this.updatecardimg = false;
    } else {
      this.updatecardimg = true;
    }
  }

  imageCropped(event
                 :
                 ImageCroppedEvent
  ) {
    this.cansave = true;
    this.croppedImage = event.base64;
  }


  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  onPlus() {
    this.ic = true;
  }

  endMove(event) {
    this.startmove = false;
  }

  startMove(event) {
    this.startmove = true;
  }


  imgMove(event) {
    if (this.startmove) {
      this.imgx = this.imgx + event.movementX;
      this.imgy = this.imgy + event.movementY;
    }
  }

  onClearImg(pick: number) {
    if (this.curtopix && this.curtopix.cardimg) {
      this.curtopix.cardimg = null;
    }
    this.imgx = 0;
    this.imgy = 0;
    this.scale = 110;
    if (pick === 0 || pick === 2) {
      this.tmpimg = null;
    }
    if (pick === 1 || pick === 2) {
      this.cardimg = null;
    }
    this.ic = false;
  }

  onDeleteMention(i, addtrip) {
    this.curguide.mentions.splice(i, 1);
    this.rebuildLocs();
  }

  onDeleteEvent(i, addtrip) {
    this.curguide.events.splice(i, 1);
  }

  onDeleteProducts(i, addtrip) {
    this.curguide.products.splice(i, 1);
  }

  onDeleteVendors(i) {
    this.curguide.vendors.splice(i, 1);
  }

  onDeleteArtists(i) {
    this.curguide.artists.splice(i, 1);
  }

  onDeleteRoutes(i) {
    this.curguide.routes.splice(i, 1);
  }

  onDeleteFestivals(i) {
    this.curguide.festivals.splice(i, 1);
  }

  onDeleteLoc(i, addtrip) {
    if (addtrip) {
      this.curguide.pops.splice(i, 1);
    } else {
      this.curguide.trip.splice(i, 1);
    }
    this.rebuildLocs();
  }

  onPullChains() {
    if (this.curguide.trip.length > 0) {
      if (this.curguide.trip[0].chainstring) {
        const str = `{"Data_Pid": "${this.ukey}", "Name": "${this.location}"}`;
        const temp = this.curguide.trip[0].chainstring.slice(1);
        this.chainstring = `[${str},${temp}`;
        this.chain = null;
      }
      if (this.curguide.trip[0].touristchainstring) {
        if (this.touristurl) {
          const str = `{"Data_Pid": "${this.ukey}", "Name": "${this.location}"}`;
          const temp = this.curguide.trip[0].touristchainstring.slice(1);
          this.touristchainstring = `[${str},${temp}`;
        } else {
          this.touristchainstring = this.curguide.trip[0].touristchainstring;
        }
      }
    }
  }


  onPickPlace(hit, i) {
    this.hit = hit;
    this.hitpos = i;
  }

  onPickLocation(hit, i) {
    if (!this.curguide.trip) {
      this.curguide.trip = [];
    }

    hit['hash'] = geohash.encode(hit.lat, hit.long);
    this.curguide.trip.push(hit);
    this.hit = null;
    this.hitpos = -1;
  }


  onClipBoard(who: string) {
    this.clipped = null;
    navigator.clipboard.readText().then(val => {
      if (val) {
        this.clipped = JSON.parse(val);
        this.onFinishBoard(who);
      }
    });
  }


  onFinishBoard(who: string) {
    if (!this.clipped || !this.clipped.datapid) {
      alert('bad data in clipboard');
      this.clipped = null;
    } else {
      if (!this.ukey || this.clipped.datapid !== this.ukey) {
        if (this.location && this.location.length) {
          alert('the copied place is not the same as the location. using copied location');
        }
        this.location = this.clipped.name;
        this.ukey = this.clipped.datapid;
      }

      if (this.curguide && !this.doguide) {
        if (this.clipped.name === this.curguide.m.loc) {
          this.curguide.loc = this.clipped.name;
        } else {
          this.curguide.loc = `${this.clipped.name} - ${this.curguide.m.loc}`;
        }

      }

      if (this.clipped.websiteurl) {
        if (who === 'location') {
          this.touristurl = this.clipped.websiteurl;
        } else {
          this.weburl = this.clipped.websiteurl;
        }
      }
      if (this.clipped.dataurl) {
        this.googleurl = this.clipped.dataurl;
      }
      if (this.clipped.wikipedia) {
        this.wikiurl = this.clipped.wikipedia;
      }
      if (this.clipped.britanica) {
        this.briturl = this.clipped.britanica;
      }

      if (this.clipped.subtitle) {
        const arr = this.clipped.subtitle.split(' in ')[0];
        this.onTryGetTaxonomys(arr);
      }

      this.is.getObject(`PlaceExists/${this.ukey}/ukey`).take(1).subscribe(ukey => {
        if (ukey && ukey.includes('alais')) {
          const key = ukey.replace('alais', '');
          this.ukey = key;
          this.onGetHitPlace(key, true);
        } else {
          this.onGetHitPlace(this.ukey, true);
        }
      });
    }
  }


  onGoogle(trip: boolean, who: string) {
    this.location = '';
    this.countryname = '';
    this.adding = who;
    this.addingmention = null;
    this.aboutit = false;
    this.countryrequired = '';
    this.addingevent = null;
    this.ukey = null;
    this.kingdomvar = '';
    this.phylumvar = '';
    this.classvar = '';

    this.addingtrip = trip;
  }

  onAddMention() {
    this.location = '';
    this.countryname = '';
    this.aboutit = false;
    this.kingdomvar = '';
    this.phylumvar = '';
    console.log('phlumcar 6');
    this.classvar = '';
    this.curassignthread = new AssignThread();

    this.countryrequired = '';
    this.adding = null;
    this.addingevent = null;
    this.addingmention = 'Google';
    this.ukey = null;

  }


  onUpdateVendorCats() {
    this.catvendors = this.vendors.filter(v => v.type === this.vendorcat);
  }

  onUpdateArtistCats() {
    this.catartists = this.artists.filter(v => v.type === this.artistcat);
  }

  onUpdateRoutesCats() {
    this.catroutes = this.routes.filter(v => v.type === this.routecat);
  }

  onUpdateFestivalsCats() {
    this.catfestivals = this.festivals.filter(v => v.type === this.festivalcat);
  }


  onUpdateVendorProducts() {
    console.log('products');

  }

  // types of gear
  // clothing: ['socks', 'footwear', 'eyewear', 'coats', ]
  // ['water bottles]
  // luggage, beach gear (like chairs)

  onUpdateVendorGearCats() {
    this.gearcatvendors = this.gearvendors.filter(v => v.type === this.vendorgearcat);
  }


  onPickVendor() {
    console.log('vendor', this.vendor);
  }

  onPickArtist() {
    console.log('artist', this.artist);
  }

  onPickRoute() {
    console.log('route', this.route);
  }

  onPickFestival() {
    console.log('festival', this.festival);
  }

  onNewVendor() {
    if (this.curvendor && this.curvendor.ukey) {
      this.is.setObjNoLog(`Topics/TempInfo/Vendors/${this.curvendor.ukey}`, this.curvendor);
      this.vendors.push(this.curvendor);
      this.vendorcat = this.curvendor.type;
      this.catvendors = this.vendors.filter(v => v.type === this.vendorcat);
      this.addvendor = false;
      this.curvendor = null;
    }
  }

  onNewArtist() {
    if (this.curartist && this.curartist.ukey) {
      this.is.setObjNoLog(`Topics/TempInfo/Artists/${this.curartist.ukey}`, this.curartist);
      this.artists.push(this.curartist);
      this.artistcat = this.curartist.type;
      this.catartists = this.artists.filter(v => v.type === this.artistcat);
      this.addartist = false;
      this.curartist = null;
    }
  }

  onNewRoute() {
    if (this.curroute && this.curroute.ukey) {
      this.is.setObjNoLog(`Topics/TempInfo/Routes/${this.curroute.ukey}`, this.curroute);
      this.routes.push(this.curroute);
      this.routecat = this.curroute.type;
      this.catroutes = this.routes.filter(v => v.type === this.routecat);
      this.addroute = false;
      this.curroute = null;
    }
  }

  onFesChangeStart(event) {
    console.log('event', event);
    //this.start = event;
    this.festivalstart = new Date(event).getTime();
  }

  onFesChangeEnd(event) {
    // this.end = event;
    this.festivalend = new Date(event).getTime();
  }

  onNewFestival() {
    if (this.curfestival && this.curfestival.ukey) {
      this.is.setObjNoLog(`Topics/TempInfo/Festivals/${this.curfestival.ukey}`, this.curfestival);

      if (this.festivalyear && this.festivalyear > 2022 && this.festivalyear < 2030) {
        let start = this.festivalstart;
        let end = this.festivalend;
        const curdate = new Date().getTime();
        if ((curdate < start) && (curdate < end) && (start < end)) {
          start = this.admin.toIntTime(start, this.festivaltimeoffsethours);
          end = this.admin.toIntTime(end, this.festivaltimeoffsethours);
          this.is.setObjNoLog(`Topics/TempInfo/Festivals/${this.curfestival.ukey}/date/${this.festivalyear}`, {
            start,
            end,
            offset: this.festivaltimeoffsethours
          });
        }
      }

      this.festivals.push(this.curfestival);
      this.festivalcat = this.curfestival.type;
      this.catfestivals = this.festivals.filter(v => v.type === this.festivalcat);
      this.addfestival = false;
      this.curfestival = null;
    }
  }


  onSaveProduct() {
    if (this.curproduct.ukey && this.curproduct.name) {
      if (this.curphoto) {
        if (!this.curproduct.photos) {
          this.curproduct.photos = [];
        }
        this.curproduct.photos.push(this.curphoto);
      }

      this.is.setObjNoLog(`Topics/TempInfo/GearVendors/${this.curgearvendor.ukey}/products/${this.curproduct.ukey}`, this.curproduct);

      if (this.curgearvendor && !this.curgearvendor.products) {
        this.curgearvendor.products = [];
      }
      this.curgearvendor.products.push(this.curproduct);
      this.curproduct = null;
      this.addingproduct = false;
    }
  }

  onAddNewProduct() {
    this.curproduct = {};
    this.addingproduct = true;
  }

  onNewGearVendor() {
    if (this.curgearvendor && this.curgearvendor.ukey) {
      this.is.setObjNoLog(`Topics/TempInfo/GearVendors/${this.curgearvendor.ukey}`, this.curgearvendor);
      this.gearvendors.push(this.curgearvendor);
      this.vendorgearcat = this.curgearvendor.type;
      this.gearcatvendors = this.gearvendors.filter(v => v.type === this.vendorgearcat);
      this.addgearvendor = false;
      this.curgearvendor = null;
    }
  }

  onAddThread() {
    this.curthread = new Thread;
    this.addingthread = true;
  }

  onSaveThread() {
    if (this.curthread && this.curthread.name && this.curthread.name.length && this.curthread.ukey.length > 5) {
      this.is.setObjNoLog(`Threads/${this.curthread.ukey}`, this.curthread);
      if (!this.threads) {
        this.threads = [];
      }
      this.threads.push(this.curthread);
      this.threads = _.orderBy(this.threads, 'name', 'asc');
      this.onCancelThread();
    }
  }

  onCancelThread() {
    this.curthread = null;
    this.addingthread = false;
  }

  onEditThread() {
    console.log('edit thread');
  }

  onAddArtist() {
    if (!this.artists) {
      this.is.getList(`Topics/TempInfo/Artists`).take(1).subscribe(vs => {
        this.artists = vs;
        this.artistcat = 'Glass';
        // this.artistcats = _.uniqBy(this.artists.map(v => v.type));
        this.artistcats = ['Architect', 'Artist', 'Painter', 'Sculptor', 'Glass Blower', 'Designer', 'Photographer', 'Writer', 'Dance', 'Music', 'Film'];

        this.catartists = this.artists.filter(v => v.type === this.artistcat);
      });
    }
    this.addingartist = 'Google';
    this.nameartwork = '';
    this.jumpto = '';
  }

  onAddRoutes() {
    if (!this.routes) {
      this.is.getList(`Topics/TempInfo/Routes`).take(1).subscribe(vs => {
        this.routes = vs;

        this.routecat = 'Road Trip';
        this.routecats = ['Cultural Trail', 'Byway', 'Road Trip', 'Hiking', 'Pilgrimage', 'Train', 'On the Water', 'Biking', 'Multi Use', 'Race', 'Walking Tour'];

        this.catroutes = this.routes.filter(v => v.type === this.routecat);
      });
    }
    this.addingroute = 'Google';

  }

  onAddFestivals() {
    if (!this.festival) {
      this.is.getList(`Topics/TempInfo/Festivals`).take(1).subscribe(vs => {
        this.festivals = vs;
        this.festivalcat = 'Music';
        this.festivalcats = ['Art', 'Carnival', 'Fair', 'Film', 'Fire', 'Food', 'Harvest', 'Music', 'Nature', 'Spirits', 'Sports', 'Religious', 'Renaissance Fair', 'Other'];

        this.catfestivals = this.festivals.filter(v => v.type === this.festivalcat);
      });
    }
    this.addingfestival = 'Google';
  }

  onAddVendor() {
    if (!this.vendors) {
      this.is.getList(`Topics/TempInfo/Vendors`).take(1).subscribe(vs => {
        this.vendors = vs;
        this.vendorcat = 'Airline';
        this.vendorcats = _.uniqBy(this.vendors.map(v => v.type));
        this.catvendors = this.vendors.filter(v => v.type === this.vendorcat);
      });
    }
    this.addingvendor = 'Google';

  }


  onAddGear() {
    this.curgearvendor = null;
    this.curproduct = null;
    this.jumpto = '';

    if (!this.gearvendors) {
      this.gearvendors = [];
      this.vendorgearcat = 'Luggage';
      this.vendorgearcats = [];
      this.gearcatvendors = [];
      this.is.getList(`Topics/TempInfo/GearVendors`).take(1).subscribe(vs => {
        if (vs && vs.length) {
          this.gearvendors = vs;
          this.gearvendors.forEach(g => {
            if (g.products) {
              g.products = Object.values(g.products);
            }
          });
          this.vendorgearcats = _.uniqBy(this.gearvendors.map(v => v.type));
          this.gearcatvendors = this.gearvendors.filter(v => v.type === this.vendorgearcat);
        }
      });
    }
    this.addinggear = 'Google';
  }


  onAddEvent() {
    this.location = '';
    this.countryname = '';
    this.adding = null;
    this.addingmention = null;
    this.addingevent = 'Google';
    this.ukey = null;
    this.alreadyoncalendar = false;
    this.start = new Date().getTime();
    this.end = new Date().getTime();
    this.curevent = new Wish('', null);
  }

  onChangeStart(event) {

  }

  onChangeEnd(event) {

  }

  dropRSS(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.trip, event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.trip, event.previousIndex, event.currentIndex);
    this.rebuildLocs();
  }

  droptrip(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.pops, event.previousIndex, event.currentIndex);
    this.rebuildLocs();

  }

  dropevents(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.events, event.previousIndex, event.currentIndex);
  }

  dropproducts(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.products, event.previousIndex, event.currentIndex);
  }

  dropvendorproducts(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curvendor.products, event.previousIndex, event.currentIndex);
  }

  dropvendors(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.vendors, event.previousIndex, event.currentIndex);
  }

  dropartists(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.artists, event.previousIndex, event.currentIndex);
  }

  droproutes(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.routes, event.previousIndex, event.currentIndex);
  }

  dropfestivals(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.festivals, event.previousIndex, event.currentIndex);
  }

  dropmentions(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.curguide.mentions, event.previousIndex, event.currentIndex);
    this.rebuildLocs();

  }

  onAddVend() {
    this.addvendor = true;
    this.addingvendor = 'Google';
    this.curvendor = {};
  }

  onAddArt() {
    this.addartist = true;
    this.addingartist = 'Google';
    this.curartist = {};
  }

  onAddRoute() {
    this.addroute = true;
    this.addingroute = 'Google';
    this.curroute = {};
  }

  onAddFestival() {
    this.addfestival = true;
    this.festivalstart = new Date().getTime();
    this.festivalend = new Date().getTime();
    this.festivaltimeoffsethours = 0;
    this.addingfestival = 'Google';
    this.curfestival = {};
  }

  onAddProduct() {
    this.curproduct = {};
    this.addingproduct = true;
  }

  onAddGearVend() {
    this.addgearvendor = true;
    this.addinggear = 'Google';
    this.curgearvendor = {};
  }

  onSaveGearToArticle() {
    if (this.curgearvendor && this.curproduct) {
      if (!this.curguide.products) {
        this.curguide.products = [];
      }
      const obj = {};
      obj['name'] = this.curgearvendor.name + ' ' + this.curproduct.name;
      obj['vendorukey'] = this.curgearvendor.ukey;
      obj['productukey'] = this.curproduct.ukey;
      obj['jumpto'] = this.jumpto;

      this.curguide.products.unshift(obj);
      this.addinggear = null;
    }
  }

  onVendor(cls = false) {
    if (this.vendor) {
      if (!this.curguide.vendors) {
        this.curguide.vendors = [];
      }
      const obj = {};
      obj['name'] = this.vendor.name;
      obj['ukey'] = this.vendor.ukey;
      obj['jumpto'] = this.jumpto;

      this.curguide.vendors.unshift(obj);


      if (cls) {
        this.addingvendor = null;
      }

    }
  }

  onArtist(cls = false) {
    if (this.artist) {
      if (!this.curguide.artists) {
        this.curguide.artists = [];
      }
      const obj = {};
      obj['name'] = this.artist.name;
      obj['ukey'] = this.artist.ukey;
      obj['nameartwork'] = this.nameartwork; // name of the artwork mentioned in the article
      obj['jumpto'] = this.jumpto;

      this.curguide.artists.unshift(obj);
      this.artist.name = '';
      this.artist.ukey = '';
      this.jumpto = '';
      this.nameartwork = '';

      if (cls) {
        this.addingartist = null;
      }


    }
  }

  onRoute(cls = false) {
    if (this.route) {
      if (!this.curguide.routes) {
        this.curguide.routes = [];
      }
      const obj = {};
      obj['name'] = this.route.name;
      obj['ukey'] = this.route.ukey;
      obj['jumpto'] = this.jumpto;

      this.curguide.routes.unshift(obj);
      this.route.name = '';
      this.route.ukey = '';
      this.jumpto = '';

      if (cls) {
        this.addingroute = null;
      }


      const x = this.TravelOtherarr.findIndex(o => o.name === 'Routes');
      if (x > -1) {
        this.TravelOtherarr[x]['val'] = true;
        this.onOtherChange();
      }
    }
  }

  onFestival(cls = false) {
    if (this.festival) {
      if (!this.curguide.festivals) {
        this.curguide.festivals = [];
      }
      const obj = {};
      obj['name'] = this.festival.name;
      obj['ukey'] = this.festival.ukey;
      obj['jumpto'] = this.jumpto;

      this.curguide.festivals.unshift(obj);
      this.festival.name = '';
      this.festival.ukey = '';
      this.jumpto = '';

      if (cls) {
        this.addingfestival = null;
      }


      const x = this.ThingsSeeDoarr.findIndex(o => o.name === 'Festivals');
      if (x > -1) {
        this.ThingsSeeDoarr[x]['val'] = true;
        this.onThingsChange();
      }
    }
  }


  onSavePOItoAdminFinish(ukey: string) {
    if (ukey && ukey.length && this.newhit.ukey && this.newhit.ukey.length > 5) {
      if (ukey !== this.newhit.ukey) {
        const x = this.classifications.findIndex(c => c.phylum === this.newhit.phylumvar);
        if (x > -1) {
          this.is.setObjNoLog(`Places/${this.newhit.ukey}/parents/${ukey}`, {ukey, ty: this.classifications[x].action});
          this.is.setObjNoLog(`Places/${ukey}/${this.classifications[x].action}/${this.newhit.ukey}`, this.newhit);
        }
      }


      if (this.kingdomvar && this.kingdomvar.length > 5) {
        this.is.setObjNoLog(`Places/${this.newhit.ukey}/taxonomy/kingdom`, this.kingdomvar);
      }
      if (this.phylumvar && this.phylumvar.length > 5) {
        this.is.setObjNoLog(`Places/${this.newhit.ukey}/taxonomy/phylum`, this.phylumvar);
      }
      if (this.classvar && this.classvar.length > 5) {
        this.is.setObjNoLog(`Places/${this.newhit.ukey}/taxonomy/class`, this.classvar);
      }
    }
  }


  onOpenType() {
    window.open(`https://www.google.com/search?q=${this.curguide.type}`, '_blank');
  }

  onVerify() {
    // https://www.google.com/maps/place/?q=place_id:ChIJS9tYo2_YhVQRFbA8JLidpX0
    //ChIJS9tYo2_YhVQRFbA8JLidpX0
    this.wind = window.open(`https://www.google.com/maps/place/?q=place_id:${this.savepoiukey}`, '_blank');
    console.log('onVerify', this.wind);
    this.needsverify = false;
  }

  /*
  saving new loc Chicago, Illinois
  main.js:28635 processing new loc
  main.js:27432 SAVEPOI
   */

  onCancelVerify() {
    this.hit = null;
    this.newhit = null;
    this.savepoiukey = '';
    this.needsverify = false;
  }

  onSavePOItoAdmin() {
    // if it's the right place then have to see if it's in PlacesExists
    let key = this.savepoiukey;
    if (this.wind) {
      this.wind.close();
      this.wind = null;
    }

    this.is.getObject(`PlaceExists/${this.savepoiukey}/ukey`).take(1).subscribe(ukey => {
      if (ukey) {
        if (ukey.includes('alais')) {
          key = ukey.replace('alais', '');
        }
        this.onSavePOItoAdminFinish(key);
      } else {
        this.hit = null;
        this.ukey = key;
        console.log('this.ukadsfkey', this.ukey);
        this.onLocation(5);
      }
    });

    this.savepoiukey = '';
  }

  savePOI(location, ukey) {
    console.log('SAVEPOI', location);
    this.savepoiukey = '';
    const request = {
      query: location,
      fields: [
        'place_id',
      ]
    };
    this.spinning = true;
    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.findPlaceFromQuery(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place && place.length > 0) {
        // at this point hopefully have and admin area data-pid
        this.is.getObject(`Places/${ukey}/parents/${place[0].place_id}`).take(1).subscribe(parent => {
          if (!parent) {
            this.savepoiukey = place[0].place_id;
          } else {
            this.needsverify = false;
          }
          this.spinning = false;
        });

      } else {
        this.spinning = false;
        this.needsverify = false;
      }
    });
  }

  updateForChecks() {
    this.hit['includein'] = this.includein;
    this.hit['aboutit'] = this.aboutit;
    this.hit['kingdom'] = this.kingdomvar;
    this.hit['phylum'] = this.phylumvar;
    this.hit['class'] = this.classvar;
  }

  onGetID() {
    this.cappidfound = false;
    this.is.getObject(`Places/${this.getID}`).take(1).subscribe(place => {
      if (place) {
        this.cappidfound = true;
        this.location = place.name;
        this.ukey = this.getID;
        this.hash = place.hash;
      }
    });
  }

  onSaveAssignThread() {
    if (this.hit && this.hit.ukey && this.hit.ukey.length && this.curthread && this.curthread.ukey && this.curthread.ukey.length && this.curassignthread && this.curassignthread.description) {
      this.curassignthread.threadukey = this.curthread.ukey;
      this.curassignthread.placehash = this.hit.hash;
      this.curassignthread.placeukey = this.hit.ukey;
      this.curassignthread.namethread = this.curthread.name;
      this.curassignthread.name = this.hit.name;

      // 1. save to Threads/{threadukey}places/{placeukey}
      this.is.setObjNoLog(`Threads/${this.curassignthread.threadukey}/places/${this.hit.ukey}`, this.hit.ukey);

      // 2. save to Threads/{threadukey}placeshash/{placehash}:
      const obj = {placehash: this.hit.hash, placeukey: this.hit.ukey};
      this.is.setObjNoLog(`Threads/${this.curassignthread.threadukey}/placeshash/${this.hit.hash}`, obj);

      // 3. save to Places/{placeukey}/threads/{threadukey}
      this.is.setObjNoLog(`Places/${this.hit.ukey}/threads/${this.curassignthread.threadukey}`, this.curassignthread);

      // 4. save to ThreadsHash/{hash}/{threadukey}
      this.is.setObjNoLog(`ThreadsHash/${this.hit.hash}/${this.curassignthread.threadukey}`, this.curassignthread);

      this.curassignthread = new AssignThread();
    } else {
      console.log('no description');
    }
  }

  setTypes(placeID) {
    if (this.hit['phylum'] && this.hit['phylum'].length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/phylum`, this.hit['phylum']);
    }
    if (this.hit['class'] && this.hit['class'].length) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/class`, this.hit['class']);
    }
    if (this.hit['kingdom']) {
      this.is.setObjNoLog(`Places/${placeID}/taxonomy/kingdom`, this.hit['kingdom']);
    }
  }

  onSavePOIArtist() {
    if (this.poiartist) {
      const obj = {};
      obj['ukey'] = this.hit.ukey;
      obj['note'] = this.poiartistnote;
      this.is.setObjNoLog(`Topics/TempInfo/Artists/${this.poiartist.ukey}/places/${this.hit.ukey}`, obj);
    }

    if (this.hit.ukey && this.saveid && this.curguide && this.curguide.m && this.curguide.m.instyid) {
      const newkey = new Key();
      newkey.id = this.curguide.m.instyid;
      newkey.name = 'Instagram LocID';
      this.is.pushObj(`Places/${this.hit.ukey}/location/keys`, newkey);
      this.saveid = false;
    }
  }

  onSavePOIRoute() {
    if (this.poiroute) {
      const obj = {};
      obj['ukey'] = this.hit.ukey;
      obj['note'] = this.poiroutenote;
      this.is.setObjNoLog(`Topics/TempInfo/Routes/${this.poiroute.ukey}/places/${this.hit.ukey}`, obj);
    }
  }

  // called by onFrackingMention
  writeToPlaces(place, newloc, websitex) {
    let website;
    if (this.weburl) {
      website = this.weburl;
    } else {
      website = website;
    }

    const obj = {
      ukey: place.place_id,
      hash: newloc.hash,
      name: place.name,
      address: place.formatted_address,
      location: newloc,
      types: place.types,
      website
    };

    if (newloc.country) {

      const tix = {
        name: place.name,
        address: place.formatted_address,
        placeUkey: place.place_id,
        category: '',
        lat: newloc.lat,
        long: newloc.long,
        order: -1,
        path: ''
      };

      const loc = {
        long: newloc.long,
        lat: newloc.lat,
        country: newloc.country
      };
      this.touristBoardWork(loc, place.place_id, tix);
    }

    if (this.tmpyelpphoto) {
      obj['photos'] = [this.tmpyelpphoto];
      this.tmpyelpphoto = null;
    }


    if (this.tmpyelpkey) {
      newloc.keys.push(this.tmpyelpkey);
      this.tmpyelpkey = null;
    }

    this.onSavePOIArtist();
    this.onSavePOIRoute();
    this.onSaveAssignThread();

    this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
    this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
    this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
    this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); //location
    this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
    this.is.setObjNoLog(`Places/${place.place_id}/types`, obj.types); // types

    this.setTypes(place.place_id);


    if (obj.website) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/website`, obj.website); // website
      this.is.onPlaceMd5(place.place_id, obj.website, obj.name);
    }

    if (this.wikiurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Wikipedia`, this.wikiurl); // website
    }
    if (this.briturl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Britanica`, this.briturl); // website
    }
    if (this.googleurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/GoogleSearch`, this.googleurl); // website
    }
    if (this.altnames) {
      this.is.setObjNoLog(`Places/${place.place_id}/altnames`, this.altnames); // website
    }

    if (!this.curguide.mentions) {
      this.curguide.mentions = [];
    }

    this.newhit = _.cloneDeep(this.hit);
    if (this.phylumvar) {
      this.newhit['phylumvar'] = this.phylumvar;
    }

    this.updateForChecks();

    if (this.aerialview) {
      this.hit['aerialview'] = _.cloneDeep(this.aerialview);
      this.aerialview = null;
    }
    if (this.aerial) {
      if (!this.aerial.poster) {
        this.hit['aerialtemp'] = _.cloneDeep(this.aerial);
      } else {
        this.hit['aerialview'] = _.cloneDeep(this.aerial);
      }
      this.aerial = null;
    }
    this.curguide.mentions.push(this.hit);
    console.log('Mentions 2', this.curguide.mentions);

    this.placeExists(obj);

    // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
    this.ukey = '';
    this.weburl = '';
    this.aerial = null;
    this.wikiurl = '';
    this.briturl = '';
    this.googleurl = '';
    this.altnames = '';

    this.rebuildLocs();
    this.spinning = false;
  }

  onFracFrac(place, newloc, website, param, clse, photosreturn) {
    const googlephotos = [];
    const photos = Object.values(photosreturn);
    if (photos && photos.length > 0) {
      photos.forEach(p => {
        googlephotos.push({url: p, who: 'Google'});
      });
      this.hit['photo'] = photos[0];
    }

    if (this.tmpyelpphoto) {
      googlephotos.push(this.tmpyelpphoto);
      this.tmpyelpphoto = null;
    }

    if (this.tmpyelpkey) {
      newloc.keys.push(this.tmpyelpkey);
      this.tmpyelpkey = null;
    }

    if (!this.curguide.mentions) {
      this.curguide.mentions = [];
    }

    console.log('pushing111', this.hit);
    if (this.aerialview) {
      this.hit['aerialview'] = _.cloneDeep(this.aerialview);
      this.aerialview = null;
    }
    if (this.aerial) {
      if (!this.aerial.poster) {
        this.hit['aerialtemp'] = _.cloneDeep(this.aerial);
      } else {
        this.hit['aerialview'] = _.cloneDeep(this.aerial);
      }
      this.aerial = null;
    }
    this.curguide.mentions.push(this.hit);
    console.log('Mentions 3', this.curguide.mentions);


    const obj = {
      ukey: place.place_id,
      hash: newloc.hash,
      name: place.name,
      location: newloc,
      address: place.formatted_address,
      website,
      types: place.types,
      photos: googlephotos
    };

    if (newloc.country) {
      const tix = {
        name: place.name,
        address: place.formatted_address,
        placeUkey: place.place_id,
        category: '',
        lat: newloc.lat,
        long: newloc.long,
        order: -1,
        path: ''
      };

      const loc = {
        long: newloc.long,
        lat: newloc.lat,
        country: newloc.country
      };
      this.touristBoardWork(loc, place.place_id, tix);
    }

    this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
    this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
    this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
    this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
    this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
    this.is.setObjNoLog(`Places/${place.place_id}/types`, obj.types); // types

    this.is.setObjNoLog(`Places/${place.place_id}/photos`, obj.photos); // photos
    this.setTypes(place.place_id);

    this.onSavePOIArtist();
    this.onSavePOIRoute();
    this.onSaveAssignThread();

    if (this.weburl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/weburl`, this.weburl); // website
      this.is.onPlaceMd5(place.place_id, this.weburl, obj.name);
    } else if (obj.website) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/website`, obj.website); // website
      this.is.onPlaceMd5(place.place_id, obj.website, obj.name);
    }

    if (this.wikiurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Wikipedia`, this.wikiurl); // website
    }
    if (this.briturl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Britanica`, this.briturl); // website
    }
    if (this.googleurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/GoogleSearch`, this.googleurl); // website
    }
    if (this.altnames) {
      this.is.setObjNoLog(`Places/${place.place_id}/altnames`, this.altnames); // website
    }

    this.newhit = _.cloneDeep(this.hit);
    if (this.phylumvar) {
      this.newhit['phylumvar'] = this.phylumvar;
    }

    this.placeExists(obj);
    // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
    this.ukey = '';
    this.weburl = '';
    this.wikiurl = '';
    this.aerial = null;
    this.briturl = '';
    this.googleurl = '';
    this.altnames = '';


    this.rebuildLocs();
    this.spinning = false;

  }

  onFrackingMention(place, newloc, website, param, clse) {
    // save record to Places
    if (place.photos && place.photos.length > 0) {
      const parr = [];
      place.photos.forEach(p => {
        parr.push(p.getUrl());
      });

      this.updateForChecks();

      this.http.getGoogleJustPhotos(parr).then(
        photosreturn => {
          this.onFracFrac(place, newloc, website, param, clse, photosreturn);
        }).catch(err => {
        this.http.getGoogleJustPhotos(parr).then(
          newphotosreturn => {
            this.onFracFrac(place, newloc, website, param, clse, newphotosreturn);
          }).catch(errx => {
          this.writeToPlaces(place, newloc, website);
        });

      });
    } else {
      this.writeToPlaces(place, newloc, website);
    }

    if (param === 3) {
      let cr = false;
      let fullname = this.hit.name;
      if (this.countryrequired && this.countryrequired.length) {
        fullname = `${this.hit.name}, ${this.countryrequired}`;
        cr = true;
      }

      let alt = '';
      if (this.altnames) {
        alt = this.altnames.replace(',', '|');
      }


      const obj = {
        Continent: '',
        Country: this.countryrequired,
        CountryRequired: cr,
        Demonyms: '',
        FullName: fullname,
        Name: this.hit.name,
        Notes: '',
        OtherNames: alt,
        hash: this.hit.hash,
        lat: this.hit.lat,
        long: this.hit.long,
        type: 'POI',
        ukey: this.hit.objectID
      };

      this.countryrequired = '';

      this.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${obj.ukey}`, obj);
    }

    this.location = '';
    this.countryname = '';
    this.includein = false;
    this.jumpto = '';
    if (clse) {
      this.onCloseMention();
    }
  }

  onMention(clse: boolean, param = 0) {
    this.address = '';
    this.yarr = null;

    if (this.cappidfound) {
      this.curassignthread.threadukey = this.curthread.ukey;
      this.curassignthread.placehash = this.hash;
      this.curassignthread.placeukey = this.ukey;
      this.curassignthread.namethread = this.curthread.name;
      this.curassignthread.name = this.location;


      // 1. save to Threads/{threadukey}places/{placeukey}
      this.is.setObjNoLog(`Threads/${this.curassignthread.threadukey}/places/${this.ukey}`, this.ukey);

      // 2. save to Threads/{threadukey}placeshash/{placehash}:
      const obj = {placehash: this.hash, placeukey: this.ukey};
      this.is.setObjNoLog(`Threads/${this.curassignthread.threadukey}/placeshash/${this.hash}`, obj);

      // 3. save to Places/{placeukey}/threads/{threadukey}
      this.is.setObjNoLog(`Places/${this.ukey}/threads/${this.curassignthread.threadukey}`, this.curassignthread);

      // 4. save to ThreadsHash/{hash}/{threadukey}
      this.is.setObjNoLog(`ThreadsHash/${this.hash}/${this.curassignthread.threadukey}`, this.curassignthread);

      this.cappidfound = false;
      this.curassignthread = new AssignThread();

      this.onCloseHit();
    } else {
      if (this.hit) {
        if (param === 3) {
          let fullname = this.hit.name;
          let cr = false;
          if (this.countryrequired && this.countryrequired.length) {
            fullname = `${this.hit.name}, ${this.countryrequired}`;
            cr = true;
          }

          let alt = '';
          if (this.altnames) {
            alt = this.altnames.replace(',', '|');
          }

          const obj = {
            Continent: '',
            Country: this.countryrequired,
            CountryRequired: cr,
            Demonyms: '',
            FullName: fullname,
            Name: this.hit.name,
            Notes: '',
            OtherNames: alt,
            hash: this.hit.hash,
            lat: this.hit.lat,
            long: this.hit.long,
            ukey: this.hit.objectID
          };
          this.countryrequired = '';
          this.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${obj.ukey}`, obj);
        }

        // if it's already in Places
        if (this.jumpto) {
          this.hit['jumpto'] = this.jumpto;
        }
        this.updateForChecks();

        this.aboutit = false;
        this.countryrequired = '';

        if (!this.curguide.mentions) {
          this.curguide.mentions = [];
        }

        console.log('mentions 22222', this.hit);

        if (!this.hit.country && this.countryname) {
          this.hit.country = this.countryname;
          this.is.setObjNoLog(`Places/${this.ukey}/location/country`, this.hit.country);
        }
        if (!this.hit.loc) {
          this.hit.loc = this.hit.country;
        }

        this.is.getObject(`Places/${this.ukey}/openstreetmap/name`)
          .take(1)
          .subscribe(name => {
            const uukey = this.ukey;
            if (!name) {
              this.http.getGeoApify(this.hit.lat, this.hit.long).take(1)
                .subscribe(geoappify => {
                  console.log('have geo', geoappify);
                  if (uukey && uukey.length) {
                    if (geoappify.features[0] && geoappify.features[0].bbox) {
                      this.is.setObjNoLog(`Places/${uukey}/location/bounds`, geoappify.features[0].bbox);
                    }

                    const openstreetmap = geoappify.features[0].properties;
                    this.is.setObjNoLog(`Places/${uukey}/openstreetmap`, openstreetmap);
                    if (openstreetmap.place_id) {
                      const newkey = new Key();
                      newkey.id = openstreetmap.place_id;
                      newkey.name = 'Open Street Map';
                      this.is.pushObj(`Places/${uukey}/location/keys`, newkey);
                    }
                  }
                });
            }

            console.log('the aerial', this.aerial);
            if (this.aerialview) {
              this.hit['aerialview'] = _.cloneDeep(this.aerialview);
              this.aerialview = null;
            }
            if (this.aerial) {
              if (!this.aerial.poster) {
                this.hit['aerialtemp'] = _.cloneDeep(this.aerial);
              } else {
                this.hit['aerialview'] = _.cloneDeep(this.aerial);
              }
              this.aerial = null;
            }
            this.curguide.mentions.push(this.hit);
            console.log('Mentions 1', this.curguide.mentions);

            if (this.ukey) {
              if (this.weburl) {
                this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/website`, this.weburl);
                this.is.onPlaceMd5(this.ukey, this.weburl, this.hit.name, 'Manual');
              }

              if (this.wikiurl) {
                this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/Wikipedia`, this.wikiurl); // website
              }

              if (this.briturl) {
                this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/Britanica`, this.briturl); // website
              }
              if (this.googleurl) {
                this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/GoogleSearch`, this.googleurl); // website
              }

              if (this.altnames) {
                this.is.setObjNoLog(`Places/${this.ukey}/altnames`, this.altnames); // website
              }

              if (this.hit['phylum'] && this.hit['phylum'].length) {
                this.is.setObjNoLog(`Places/${this.ukey}/taxonomy/phylum`, this.hit['phylum']);
              }

              if (this.hit['class'] && this.hit['class'].length) {
                this.is.setObjNoLog(`Places/${this.ukey}/taxonomy/class`, this.hit['class']);
              }

              if (this.hit['kingdom']) {
                this.is.setObjNoLog(`Places/${this.ukey}/taxonomy/kingdom`, this.hit['kingdom']);
              }

            }


            // if place already exists
            this.onSavePOIArtist();
            this.onSavePOIRoute();
            this.onSaveAssignThread();

            if (param === 4 && this.savelocation && this.savelocation.length) {
              this.newhit = _.cloneDeep(this.hit);
              if (this.phylumvar) {
                this.newhit['phylumvar'] = this.phylumvar;
              }
              this.savePOI(this.savelocation, this.ukey);
            }

            this.rebuildLocs();
            this.onCloseHit();

            if (clse) {
              this.onCloseMention();
            }
          });


      } else if (this.clipped) {
        this.needsverify = true;
        this.onSaveClipped('mention', param);

      } else {

        this.needsverify = true;
        const request = {
          placeId: this.ukey,
          fields: [
            'place_id',
            'address_components',
            'formatted_address',
            'name',
            'types',
            'geometry',
            'utc_offset_minutes',
            'photos',
            'website'
          ]
        };

        const service = new google.maps.places.PlacesService($('#service-helper').get(0));
        service.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const newloc = new PlaceLoc();
            newloc.long = place.geometry.location.lng();
            newloc.lat = place.geometry.location.lat();

            this.address = place.formatted_address;
            this.spinning = true;
            const country = this.admin.getCountry(place.address_components, true);
            const countrylong = this.admin.getLongNameCountry(place.address_components);
            newloc.countrycode = country;
            const state = this.admin.getState(place.address_components, true);
            const city = this.admin.getCity(place.address_components, true);
            let ctry = 'US';
            if (country === 'US') {
              if (city) {
                newloc.loc = `${city}, ${state}`;
                newloc.location = `${city}, ${state}`;
              } else {
                newloc.loc = `${state}, ${country}`;
                newloc.location = `${state}, ${country}`;
              }
            } else {
              newloc.loc = `${city}, ${country}`;
              newloc.location = `${city}, ${country}`;
              ctry = country;
            }

            newloc.keys = [];
            const key = new Key();
            key.id = place.place_id;
            key.name = 'Google';
            newloc.keys.push(key);

            if (param === 4 && newloc.location) {
              this.savePOI(newloc.location, place.place_id);
            }

            if (place.geometry.bounds) {
              newloc.bounds = place.geometry.bounds;
            }
            if (place.geometry.viewport) {
              newloc.viewport = place.geometry.viewport;
            }
            newloc.hash = geohash.encode(newloc.lat, newloc.long);
            newloc.address = place.formatted_address; // this is an address
            if (place.utc_offset_minutes) {
              newloc.utc_offset_minutes = place.utc_offset_minutes;
            }

            // newloc.tzone: string;
            let website = '';
            if (place.website) {
              website = place.website;
            }

            const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());
            const nm = place.name;

            this.hit = {
              objectID: place.place_id,
              country: countrylong,
              hash,
              ukey: place.place_id,
              name: nm,
              long: place.geometry.location.lng(),
              lat: place.geometry.location.lat(),
              jumpto: this.jumpto,
              includein: this.includein,
              kingdom: this.kingdomvar,
              phylum: this.phylumvar,
              class: this.classvar,
              aboutit: this.aboutit
            };
            this.aboutit = false;

            console.log('newloc', newloc);
            this.onFrackingMention(place, newloc, website, param, clse);

          } else {
            this.spinning = false;
          }
        });
      }

    }

  }

  onSaveFoursquareFromList(y) {
    if (this.foursquare['ukey'] && this.foursquare['ukey'].length) {

      const keyx = new Key();
      keyx.id = y['fsq_id'];
      keyx.name = 'Foursquare';

      this.is.pushObj(`Places/${this.foursquare['ukey']}/location/keys`, keyx);
    }

    this.foursquare = null;
  }

  onSaveYelpFromList(y: any) {
    if (this.yelp['ukey'] && this.yelp['ukey'].length) {

      const keyx = new Key();
      keyx.id = y['id'];
      keyx.name = 'Yelp';

      this.is.pushObj(`Places/${this.yelp['ukey']}/location/keys`, keyx);

      if (y['image_url']) {
        const photo = {who: 'Yelp', caption: '', url: y.image_url};
        this.is.pushObj(`Places/${this.yelp['ukey']}/photos`, photo);
      }
    }
    this.yelp = null;
    this.yarr = null;

  }

  onSaveYelp() {

    if (this.yarr && this.yarr['ukey'] && this.yarr['ukey'].length) {
      const keyx = new Key();
      keyx.id = this.yarr['id'];
      keyx.name = 'Yelp';
      this.is.pushObj(`Places/${this.yarr['ukey']}/location/keys`, keyx);
    }
    this.yelp = null;
    this.yarr = null;
  }

  /*
  alias
  :
  "villa-franca-positano-positano"
  coordinates
  :
  {latitude: 40.6287492, longitude: 14.4833646}
  display_phone
  :
  "+39 089 875655"
  id
  :
  "G5yDoZCHvjgRrY4lN81khg"
  location
  :
  address1
  :
  "Viale Pasitea 318"
  address2
  :
  null
  address3
  :
  ""
  city
  :
  "Positano"
  country
  :
  "IT"
  display_address
  :
  (3) ['Viale Pasitea 318', '84017 Positano', 'Italy']
  state
  :
  "SA"
  zip_code
  :
  "84017"
  [[Prototype]]
  :
  Object
  name
  :
  "Villa Franca Positano"
  phone
  :
  "+39089875655"
  placename
  :
  "Hotel Villa Franca Positano"
  ukey
  :
  "ChIJe3cWPmaXOxMR0_Ri-k9rFeo"
   */


  onEvent() {
    // TODO: What if an event has multiple locations???
    if (this.hit) {
      this.curevent.trip.push(this.hit);
      this.location = '';
      this.countryname = '';
      this.hit = null;
    } else {
      const request = {
        placeId: this.ukey,
        fields: [
          'place_id',
          'address_components',
          'formatted_address',
          'name',
          'types',
          'geometry',
          'utc_offset_minutes',
          'photos',
          'website'
        ]
      };

      const service = new google.maps.places.PlacesService($('#service-helper').get(0));
      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          // 1. create a PlaceLoc
          const newloc = new PlaceLoc();
          this.address = place.formatted_address;
          const country = this.admin.getCountry(place.address_components, true);
          const countrylong = this.admin.getLongNameCountry(place.address_components);
          const state = this.admin.getState(place.address_components, true);
          const city = this.admin.getCity(place.address_components, true);
          if (country === 'US') {
            if (city) {
              newloc.loc = `${city}, ${state}`;
              newloc.location = `${city}, ${state}`;
            } else {
              newloc.loc = `${state}, ${country}`;
              newloc.location = `${state}, ${country}`;
            }
          } else {
            newloc.loc = `${city}, ${country}`;
            newloc.location = `${city}, ${country}`;
          }
          newloc.long = place.geometry.location.lng();
          newloc.lat = place.geometry.location.lat();
          if (place.geometry.bounds) {
            newloc.bounds = place.geometry.bounds;
          }
          if (place.geometry.viewport) {
            newloc.viewport = place.geometry.viewport;
          }
          newloc.hash = geohash.encode(newloc.lat, newloc.long);
          newloc.address = place.formatted_address; // this is an address
          if (place.utc_offset_minutes) {
            newloc.utc_offset_minutes = place.utc_offset_minutes;
          }

          newloc.keys = [];
          const key = new Key();
          key.id = this.ukey;
          key.name = 'Google';
          newloc.keys.push(key);
          // newloc.tzone: string;
          let website = '';
          if (place.website) {
            website = place.website;
          }


          const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());
          const nm = place.name;


          let offset = 0;
          if (place.utc_offset_minutes) {
            offset = place.utc_offset_minutes;
          }


          this.hit = {
            objectID: this.ukey,
            hash,
            ukey: this.ukey,
            country: countrylong,
            offset,
            name: nm,
            long: place.geometry.location.lng(),
            lat: place.geometry.location.lat(),
            jumpto: this.jumpto,
            includein: this.includein,
            aboutit: this.aboutit
          };

          // save record to Places
          if (place.photos && place.photos.length > 0) {
            const parr = [];
            let googlephotos;
            place.photos.forEach(p => {
              parr.push(p.getUrl());
            });

            this.http.getGoogleJustPhotos(parr).then(
              photosreturn => {

                const photos = Object.values(photosreturn);
                if (photos && photos.length > 0) {
                  googlephotos = [];
                  photos.forEach(p => {
                    googlephotos.push({url: p, who: 'Google'});
                  });
                }

                const obj = {
                  ukey: this.ukey,
                  hash: newloc.hash,
                  name: place.name,
                  location: newloc,
                  address: place.formatted_address,
                  website,
                  types: place.types,
                  photos: googlephotos
                };

                if (newloc.country) {
                  const tix = {
                    name: place.name,
                    address: place.formatted_address,
                    placeUkey: place.place_id,
                    category: '',
                    lat: newloc.lat,
                    long: newloc.long,
                    order: -1,
                    path: ''
                  };

                  const loc = {
                    long: newloc.long,
                    lat: newloc.lat,
                    country: newloc.country
                  };
                  this.touristBoardWork(loc, place.place_id, tix);
                }

                this.is.setObjNoLog(`Places/${this.ukey}/ukey`, obj.ukey);  // ukey
                this.is.setObjNoLog(`Places/${this.ukey}/hash`, obj.hash);  // hash
                this.is.setObjNoLog(`Places/${this.ukey}/name`, obj.name);  // name
                this.is.setObjNoLog(`Places/${this.ukey}/location`, obj.location); //location
                this.is.setObjNoLog(`Places/${this.ukey}/address`, obj.address); // address
                this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/website`, obj.website); // website
                this.onSetType(place.place_id, obj.website, obj.name, obj.types);
                this.is.setObjNoLog(`Places/${this.ukey}/photos`, obj.photos); // photos


                this.placeExists(obj);
                // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
              });
          } else {
            const obj = {
              ukey: this.ukey,
              hash: newloc.hash,
              name: place.name,
              address: place.formatted_address,
              location: newloc,
              types: place.types,
              website
            };

            if (newloc.country) {
              const tix = {
                name: place.name,
                address: place.formatted_address,
                placeUkey: place.place_id,
                category: '',
                lat: newloc.lat,
                long: newloc.long,
                order: -1,
                path: ''
              };

              const loc = {
                long: newloc.long,
                lat: newloc.lat,
                country: newloc.country
              };
              this.touristBoardWork(loc, place.place_id, tix);
            }

            this.is.setObjNoLog(`Places/${this.ukey}/ukey`, obj.ukey);  // ukey
            this.is.setObjNoLog(`Places/${this.ukey}/hash`, obj.hash);  // hash
            this.is.setObjNoLog(`Places/${this.ukey}/name`, obj.name);  // name
            this.is.setObjNoLog(`Places/${this.ukey}/location`, obj.location); //location
            this.is.setObjNoLog(`Places/${this.ukey}/address`, obj.address); // address
            this.is.setObjNoLog(`Places/${this.ukey}/moreinfo/website`, obj.website); // website
            this.onSetType(place.place_id, obj.website, obj.name, obj.types);

            this.placeExists(obj);
            // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
          }

          if (!this.curguide.trip) {
            this.curguide.trip = [];
          }


          if (this.addingtrip) {
            if (!this.curguide.pops) {
              this.curguide.pops = [];
            }
            this.curguide.pops.unshift(this.hit);
          } else {
            this.curguide.trip.unshift(this.hit);
          }
          this.location = '';
          this.countryname = '';
          this.jumpto = '';
          this.weburl = '';
          this.includein = false;
        }
      });
    }
  }

  fillInChain() {
    const parr = [];
    let cnt = 0;
    this.chain.forEach(a => {
      if (cnt > 0) {
        const ukey = a['Data_Pid'];
        const p = this.fs.titleIXdb.object<any>(`Places/${ukey}/photos/0/url`)
          .snapshotChanges();
        parr.push(p);
      }
      cnt++;
    });


    if (parr && parr.length > 0) {
      const chain = _.cloneDeep(this.chain);
      const ht = _.cloneDeep(this.hit);
      Observable.zip(...parr).take(1).subscribe(vals => {
        if (vals && vals.length > 0) {
          vals.forEach(res => {
            const result = res as any;
            // now that i have the photo i can build the hit so that i can reference the damn thing later on.
            const x = chain.findIndex(c => c['Data_Pid'] === result.payload.ref_.path.pieces_[1]);
            if (x > -1 && result.payload.val()) {
              console.log('VVVVVVV');
              const hit = {
                objectID: chain[x]['Data_Pid'],
                photo: result.payload.val(),
                loc: chain[x]['Name'],
                ukey: chain[x]['Data_Pid'],
                name: chain[x]['Name'],
                propogate: true,
              };
              this.curguide.trip.push(hit);
            }
          });
        }

        this.curguide.trip.unshift(ht);
        this.rebuildLocs();
      });
    } else {
      this.curguide.trip.unshift(this.hit);
      this.rebuildLocs();
    }
  }

  // this is nedded because i messed up a number of places
  onRepair() {
    const request = {
      placeId: 'ChIJ_b9z6W1l44kRHA2DVTbQxkU',
      fields: [
        'place_id',
        'address_components',
        'formatted_address',
        'name',
        'types',
        'geometry',
        'utc_offset_minutes',
        'photos',
        'website'
      ]
    };

    const service = new google.maps.places.PlacesService($('#service-helper').get(0));
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // 1. create a PlaceLoc
        const newloc = new PlaceLoc();
        this.address = place.formatted_address;
        const country = this.admin.getCountry(place.address_components, true);
        const state = this.admin.getState(place.address_components, true);
        const city = this.admin.getCity(place.address_components, true);
        if (country === 'US') {
          if (city) {
            newloc.loc = `${city}, ${state}`;
            newloc.location = `${city}, ${state}`;
          } else {
            newloc.loc = `${state}, ${country}`;
            newloc.location = `${state}, ${country}`;
          }
        } else {
          newloc.loc = `${city}, ${country}`;
          newloc.location = `${city}, ${country}`;
        }
        newloc.long = place.geometry.location.lng();
        newloc.lat = place.geometry.location.lat();
        if (place.geometry.bounds) {
          newloc.bounds = place.geometry.bounds;
        }
        if (place.geometry.viewport) {
          newloc.viewport = place.geometry.viewport;
        }
        newloc.hash = geohash.encode(newloc.lat, newloc.long);
        newloc.address = place.formatted_address; // this is an address
        if (place.utc_offset_minutes) {
          newloc.utc_offset_minutes = place.utc_offset_minutes;
        }

        newloc.keys = [];
        const key = new Key();
        key.id = place.place_id;
        key.name = 'Google';
        newloc.keys.push(key);
        let website = '';
        if (place.website) {
          website = place.website;
        }

        const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());

        const obj = {
          ukey: place.place_id,
          hash: newloc.hash,
          name: place.name,
          address: place.formatted_address,
          location: newloc,
          types: place.types,
          website
        };


        this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
        this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
        this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
        this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
        this.is.setObjNoLog(`Places/${place.place_id}/website`, obj.website); // website
        this.onSetType(place.place_id, obj.website, obj.name, obj.types);
      }
    });
  }


  onLocationFrac(place, website, chain, touristchain, param, photosreturn, googlephotos, newloc) {
    const photos = Object.values(photosreturn);
    if (photos && photos.length > 0) {
      googlephotos = [];
      photos.forEach(p => {
        googlephotos.push({url: p, who: 'Google'});
      });
      this.hit['photo'] = photos[0];
    }

    if (!this.curguide.trip) {
      this.curguide.trip = [];
    }

    if (this.addingtrip) {
      if (!this.curguide.pops) {
        this.curguide.pops = [];
      }
      this.curguide.pops.unshift(this.hit);
    } else {
      this.curguide.trip.unshift(this.hit);
    }

    const obj = {
      ukey: place.place_id,
      hash: newloc.hash,
      name: place.name,
      location: newloc,
      address: place.formatted_address,
      website,
      types: place.types,
      photos: googlephotos
    };

    if (newloc.country) {
      const tix = {
        name: place.name,
        address: place.formatted_address,
        placeUkey: place.place_id,
        category: '',
        lat: newloc.lat,
        long: newloc.long,
        order: -1,
        path: ''
      };
      const loc = {
        long: newloc.long,
        lat: newloc.lat,
        country: newloc.country
      };
      this.touristBoardWork(loc, place.place_id, tix);
    }

    this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
    this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
    this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
    this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); //location
    this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
    this.onSetType(place.place_id, obj.website, obj.name, obj.types);

    this.is.setObjNoLog(`Places/${place.place_id}/photos`, obj.photos); // photos


    if (chain) {
      obj['chain'] = chain;
      this.is.setObjNoLog(`Places/${place.place_id}/chain`, chain); // chain
    }
    if (touristchain) {
      obj['tourist_pids'] = touristchain;
      this.is.setObjNoLog(`Places/${place.place_id}/tourist_pids`, touristchain); // touristchain
    }
    if (this.touristurl) {
      obj['touristurl'] = this.touristurl;
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/touristurl`, this.touristurl); // touristurl
      this.is.onPlaceMd5(place.place_id, obj['touristurl'], obj.name);
    }

    if (this.wikiurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Wikipedia`, this.wikiurl); // website
    }
    if (this.localwikiurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/LocalWiki`, this.localwikiurl); // website
    }
    if (this.briturl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Britanica`, this.briturl); // website
    }
    if (this.googleurl) {
      this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/GoogleSearch`, this.googleurl); // website
    }

    if (this.altnames) {
      this.is.setObjNoLog(`Places/${place.place_id}/altnames`, this.altnames); // website
    }


    this.placeExists(obj);
    // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
    this.weburl = '';
    this.wikiurl = '';
    this.aerial = null;
    this.localwikiurl = '';
    if (param === 3) {
      this.onDeleteLocalWiki();
    }

    this.curwiki = null;
    this.briturl = '';
    this.googleurl = '';
    this.altnames = '';
    this.rebuildLocs();

    if (param === 5) {
      this.onSavePOItoAdminFinish(this.hit.ukey);
    }
  }

  onCloseHit() {
    this.touristchainstring = '';
    this.chainstring = '';
    this.touristurl = '';
    this.localwikiurl = '';
    this.location = '';
    this.countryname = '';
    this.hit = null;
    this.jumpto = '';
    this.weburl = '';
    this.wikiurl = '';
    this.aerial = null;
    this.briturl = '';
    this.googleurl = '';
    this.altnames = '';
    this.includein = false;
    this.ukey = '';
    this.kingdomvar = '';
    this.phylumvar = '';
    this.phylumarr = [];
    this.classvar = '';
    this.classarr = [];
    this.checktype = '';
  }

  // this function is about making sure that each place has a reference to their touristboad
  touristBoardWork(loc: any, placeUkey: string, tix) {
    if (!this.touristurl && placeUkey && placeUkey.length) {
      this.touristBoardService.getFilteredTouristBoardIdsLoc(loc).then(ids => {
        console.log('JJJJ', ids);
        if (ids && ids.length) {
          // 1. save the touristukey to moreinfo
          if (ids[0] !== 'N.A.') {
            this.is.setObjNoLog(`Places/${placeUkey}/moreinfo/touristukey`, ids[0]);
          }

          // 2. save the taxonomy for later separation in touristboards
          const taxonomy = {
            kingdom: this.kingdomvar,
            phylum: this.phylumvar,
            class: this.classvar
          };
          ids.forEach(id => {
            if (id !== 'N.A.') {
              const obj = {
                taxonomy,
                tix,
                ukey: placeUkey
              };
              this.is.setObjNoLog(`Institutions/${id}/potentialplaces/${placeUkey}`, obj);
            }
          });

        }
      });
    }
  }

  async onSaveClipped(caller, param) {
    const newclipped = _.cloneDeep(this.clipped);

    const geoappify = await new Promise<any>(resolve =>
      this.http.getGeoApify(newclipped.lat, newclipped.long).take(1)
        .subscribe(translated => {
          resolve(translated);
        }));

    const ukey = await new Promise<any>(resolve =>
      this.is.getObject(`PlaceExists/${newclipped.datapid}/ukey`)
        .take(1)
        .subscribe(translated => {
          resolve(translated);
        }));

    let openexist = false;
    if (ukey) {
      openexist = await new Promise<any>(resolve =>
        this.is.getObject(`Places/${newclipped.datapid}/openstreetmap/name`)
          .take(1)
          .subscribe(name => {
            resolve(name && name.length);
          }));

    }

    let hash;
    if (newclipped.long && newclipped.lat) {
      hash = geohash.encode(newclipped.lat, newclipped.long);
    }

    if (!ukey) {
      console.log('saving new', newclipped);
      this.is.setObjNoLog(`Places/${newclipped.datapid}/ukey`, newclipped.datapid);  // ukey
      this.is.setObjNoLog(`Places/${newclipped.datapid}/name`, this.location);  // name

      if (newclipped.address) {
        this.address = newclipped.address;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/address`, newclipped.address);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/address`, newclipped.address); // address
      }

      if (newclipped.long) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/long`, newclipped.long);
      }

      if (newclipped.lat) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/lat`, newclipped.lat);
      }

      if (hash) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/hash`, hash);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/hash`, hash);  // hash
      }

      const key = new Key();
      key.id = newclipped.datapid;
      key.name = 'Google';
      this.is.setObjNoLog(`Places/${newclipped.datapid}/location/keys/0`, key);
    }

    this.hit = {
      objectID: newclipped.datapid,
      ukey: newclipped.datapid,
      name: this.location,
      hash: hash,
      long: newclipped.long,
      lat: newclipped.lat,
      aboutit: this.aboutit
    };

    // if place already exists
    this.onSavePOIArtist();
    this.onSavePOIRoute();
    this.onSaveAssignThread();


    this.newhit = _.cloneDeep(this.hit);
    if (this.phylumvar) {
      this.newhit['phylumvar'] = this.phylumvar;
    }

    this.aboutit = false;
    let newlocation;

    if (!openexist && geoappify && geoappify.features && geoappify.features[0] && geoappify.features[0].properties) {
      if (geoappify.features[0] && geoappify.features[0].bbox) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/bounds`, geoappify.features[0].bbox);
      }

      const openstreetmap = geoappify.features[0].properties;
      this.is.setObjNoLog(`Places/${newclipped.datapid}/openstreetmap`, openstreetmap);
      if (openstreetmap.place_id) {
        const newkey = new Key();
        newkey.id = openstreetmap.place_id;
        newkey.name = 'Open Street Map';
        this.is.pushObj(`Places/${newclipped.datapid}/location/keys`, newkey);
      }

      let country;
      if (openstreetmap.country_code) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/countrycode`, openstreetmap.country_code.toUpperCase());
        country = openstreetmap.country_code.toUpperCase();
      }
      let state;
      if (openstreetmap.state) {
        state = openstreetmap.state;
      }
      let city;
      if (openstreetmap.city) {
        city = openstreetmap.city;
      }
      if (country && country === 'US') {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.state);
        this.hit['country'] = openstreetmap.state;
        let loc;
        if (city && state) {
          loc = `${city}, ${state}`;
          newlocation = `${city}, ${state}`;
        } else if (city && country) {
          loc = `${state}, ${country}`;
          newlocation = `${state}, ${country}`;
        }

        if (loc) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, loc);
        }
        if (newlocation) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, newlocation);
        }

      } else if (city && country) {
        this.hit['country'] = openstreetmap.country;
        newlocation = `${city}, ${country}`;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/country`, openstreetmap.country);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/loc`, `${city}, ${country}`);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/location/location`, `${city}, ${country}`);
      }
    }

    if (this.hit['country']) {
      const tix = {
        name: this.location,
        address: newclipped.address,
        placeUkey: newclipped.datapid,
        category: '',
        lat: newclipped.lat,
        long: newclipped.long,
        order: -1,
        path: ''
      };

      const loc = {
        long: newclipped.long,
        lat: newclipped.lat,
        country: this.hit['country']
      };
      this.touristBoardWork(loc, newclipped.datapid, tix);
    }

    if (ukey) {
      if (newclipped.description && newclipped.description.length) {
        this.is.getObject(`Places/${newclipped.datapid}/descriptions/0/source`).take(1).subscribe(source => {
          if (!source) {
            const desc = new LangStringBlock();
            desc.en.description = newclipped.description;
            this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/desc`, desc);

            if (newclipped.descriptionsourcelink && newclipped.descriptionsourcelink.length) {
              this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/source`, newclipped.descriptionsourcelink);
            }
          }
        });
      }

      console.log('images1', newclipped.images);
      if (newclipped.images) {
        this.is.pushObj(`Places/${newclipped.datapid}/photos`, {url: newclipped.images, who: 'Google'}); // address
      }

    } else {
      // if get here it means that the place does not exist
      console.log('images2', newclipped.images);

      if (newclipped.description && newclipped.description.length) {

        const desc = new LangStringBlock();
        desc.en.description = newclipped.description;
        this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/desc`, desc);

        if (newclipped.descriptionsourcelink && newclipped.descriptionsourcelink.length) {
          this.is.setObjNoLog(`Places/${newclipped.datapid}/descriptions/0/source`, newclipped.descriptionsourcelink);
        }

      }
      if (newclipped.images) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/photos/0`, {url: newclipped.images, who: 'Google'}); // address
      }
    }

    if (caller === 'location') {
      if (!this.curguide.trip) {
        this.curguide.trip = [];
      }
      this.curguide.trip.unshift(this.hit);
      this.rebuildLocs();

      this.onSetType(newclipped.datapid, this.touristurl, this.location, []);

      if (this.chainstring) {
        const chain = JSON.parse(this.chainstring);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/chain`, chain);
      }
      if (this.touristchainstring) {
        const touristchain = JSON.parse(this.touristchainstring);
        this.is.setObjNoLog(`Places/${newclipped.datapid}/tourist_pids`, touristchain); // touristchain
      }

      if (this.touristurl) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/touristurl`, this.touristurl); // touristurl
        this.is.onPlaceMd5(newclipped.datapid, this.touristurl, this.location);
      }

    } else {
      if (!this.curguide.mentions) {
        this.curguide.mentions = [];
      }
      this.curguide.mentions.unshift(this.hit);
      this.rebuildLocs();

      this.onSetType(newclipped.datapid, this.weburl, this.location, []);

      if (this.weburl) {
        this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/website`, this.weburl); // touristurl
        this.is.onPlaceMd5(newclipped.datapid, this.weburl, this.location);
      }

      if (param === 4 && newlocation) {
        console.log('processing new loc');
        this.savePOI(newlocation, newclipped.datapid);
      } else {
        this.addingmention = null;
        this.needsverify = false;
      }
    }


    if (this.wikiurl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Wikipedia`, this.wikiurl); // website
    }
    if (this.localwikiurl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/LocalWiki`, this.localwikiurl); // website
    }
    if (this.briturl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/Britanica`, this.briturl); // website
    }
    if (this.googleurl) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/moreinfo/GoogleSearch`, this.googleurl); // website
    }
    if (this.altnames) {
      this.is.setObjNoLog(`Places/${newclipped.datapid}/altnames`, this.altnames); // website
    }

    this.is.setObjNoLog(`PlaceExists/${newclipped.datapid}`, {code: 'NLG', ukey: newclipped.datapid});
    if (param === 3) {
      let fullname = this.hit.name;
      let cr = false;
      if (this.countryrequired && this.countryrequired.length) {
        fullname = `${this.hit.name}, ${this.countryrequired}`;
        cr = true;
      }

      let alt = '';
      if (this.altnames) {
        alt = this.altnames.replace(',', '|');
      }

      const obj = {
        Continent: '',
        Country: this.countryrequired,
        CountryRequired: cr,
        Demonyms: '',
        FullName: fullname,
        Name: this.location,
        Notes: '',
        OtherNames: alt,
        hash: hash,
        lat: newclipped.lat,
        long: newclipped.long,
        type: 'Admin',
        ukey: newclipped.datapid
      };

      this.countryrequired = '';
      this.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${newclipped.datapid}`, obj);
    }

    this.onCloseHit();
    this.clipped = null;
  }

  onLocation(param: number) {
    this.address = '';
    if (this.hit) {

      // if it's already in Places
      this.hit['aboutit'] = this.aboutit;
      this.aboutit = false;

      if (this.addingtrip) {
        this.curguide.pops.unshift(this.hit);
      } else {
        this.curguide.trip.unshift(this.hit);
        this.rebuildLocs();

        if (this.chainstring) {
          this.is.setObjNoLog(`Places/${this.hit.objectID}/chain`, JSON.parse(this.chainstring));
        }

        if (this.touristchainstring) {
          this.is.setObjNoLog(`Places/${this.hit.objectID}/tourist_pids`, JSON.parse(this.touristchainstring));
        }

      }

      if (this.touristurl) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/moreinfo/touristurl`, this.touristurl);
        this.is.onPlaceMd5(this.hit.objectID, this.touristurl, this.hit.name);
      }


      if (this.wikiurl) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/moreinfo/Wikipedia`, this.wikiurl); // website
      }
      if (this.localwikiurl) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/moreinfo/LocalWiki`, this.localwikiurl); // website
      }

      if (this.briturl) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/moreinfo/Britanica`, this.briturl); // website
      }
      if (this.googleurl) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/moreinfo/GoogleSearch`, this.googleurl); // website
      }

      if (this.altnames) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/altnames`, this.altnames); // website
      }

      if (this.kingdomvar && this.kingdomvar.length) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/taxonomy/kingdom`, this.kingdomvar);
      }

      if (this.phylumvar && this.phylumvar.length) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/taxonomy/phylum`, this.phylumvar);
      }

      if (this.classvar && this.classvar.length) {
        this.is.setObjNoLog(`Places/${this.hit.objectID}/taxonomy/class`, this.classvar);
      }

      if (param === 3) {
        let cr = false;
        let fullname = this.hit.name;
        if (this.countryrequired && this.countryrequired.length) {
          fullname = `${this.hit.name}, ${this.countryrequired}`;
          cr = true;
        }

        let alt = '';
        if (this.altnames) {
          alt = this.altnames.replace(',', '|');
        }

        const obj = {
          Continent: '',
          Country: this.countryrequired,
          CountryRequired: cr,
          Demonyms: '',
          FullName: fullname,
          Name: this.hit.name,
          Notes: '',
          OtherNames: alt,
          hash: this.hit.hash,
          lat: this.hit.lat,
          long: this.hit.long,
          type: 'Admin',
          ukey: this.hit.objectID
        };

        this.countryrequired = '';

        this.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${obj.ukey}`, obj);
      }
      this.onCloseHit();

      this.hit = null;
      if (param === 3) {
        this.onDeleteLocalWiki();
      }
      if (param === 1) {
        this.onCloseLoc();
      }
    } else if (this.clipped) {

      this.onSaveClipped('location', param);

    } else {
      // if it's not already in Places
      const request = {
        placeId: this.ukey,
        fields: [
          'place_id',
          'address_components',
          'formatted_address',
          'name',
          'types',
          'geometry',
          'utc_offset_minutes',
          'photos',
          'website'
        ]
      };

      const service = new google.maps.places.PlacesService($('#service-helper').get(0));
      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (place.place_id !== this.ukey) {
            console.log('FUCK UP FUCK UP');
          } else {
            console.log('place id: ', place.place_id);
          }

          // 1. create a PlaceLoc
          const newloc = new PlaceLoc();
          this.address = place.formatted_address;
          const country = this.admin.getCountry(place.address_components, true);
          const countrylong = this.admin.getLongNameCountry(place.address_components);
          const state = this.admin.getState(place.address_components, true);
          const city = this.admin.getCity(place.address_components, true);
          if (country === 'US') {
            if (city) {
              newloc.loc = `${city}, ${state}`;
              newloc.location = `${city}, ${state}`;
            } else {
              newloc.loc = `${state}, ${country}`;
              newloc.location = `${state}, ${country}`;
            }
          } else {
            newloc.loc = `${city}, ${country}`;
            newloc.location = `${city}, ${country}`;
          }


          newloc.long = place.geometry.location.lng();
          newloc.lat = place.geometry.location.lat();
          if (place.geometry.bounds) {
            newloc.bounds = place.geometry.bounds;
          }
          if (place.geometry.viewport) {
            newloc.viewport = place.geometry.viewport;
          }
          newloc.hash = geohash.encode(newloc.lat, newloc.long);
          newloc.address = place.formatted_address; // this is an address
          if (place.utc_offset_minutes) {
            newloc.utc_offset_minutes = place.utc_offset_minutes;
          }

          newloc.keys = [];
          const key = new Key();
          key.id = place.place_id;
          key.name = 'Google';
          newloc.keys.push(key);
          let website = '';
          if (place.website) {
            website = place.website;
          }

          const hash = geohash.encode(place.geometry.location.lat(), place.geometry.location.lng());

          this.hit = {
            objectID: place.place_id,
            hash,
            ukey: place.place_id,
            country: countrylong,
            name: place.name,
            long: place.geometry.location.lng(),
            lat: place.geometry.location.lat(),
            jumpto: this.jumpto,
            includein: this.includein,
            aboutit: this.aboutit
          };
          this.aboutit = false;

          let chain;
          if (this.chainstring) {
            this.hit['chainstring'] = this.chainstring;
            chain = JSON.parse(this.chainstring);
          }
          let touristchain;
          if (this.touristchainstring) {
            this.hit['touristchainstring'] = this.touristchainstring;
            touristchain = JSON.parse(this.touristchainstring);
          }

          // save record to Places
          if (place.photos && place.photos.length > 0) {
            const parr = [];
            let googlephotos;
            place.photos.forEach(p => {
              parr.push(p.getUrl());
            });

            this.spinning = true;
            this.http.getGoogleJustPhotos(parr).then(
              photosreturn => {
                this.spinning = false;
                this.onLocationFrac(place, website, chain, touristchain, param, photosreturn, googlephotos, newloc);
              }).catch(err => {
                this.http.getGoogleJustPhotos(parr).then(
                  photosreturn => {
                    this.spinning = false;
                    this.onLocationFrac(place, website, chain, touristchain, param, photosreturn, googlephotos, newloc);
                  }).catch(newerr => {
                  this.spinning = true;
                });
              }
            );
          } else {

            const obj = {
              ukey: place.place_id,
              hash: newloc.hash,
              name: place.name,
              address: place.formatted_address,
              location: newloc,
              types: place.types,
              website
            };

            if (newloc.country) {
              const tix = {
                name: place.name,
                address: place.formatted_address,
                placeUkey: place.place_id,
                category: '',
                lat: newloc.lat,
                long: newloc.long,
                order: -1,
                path: ''
              };

              const loc = {
                long: newloc.long,
                lat: newloc.lat,
                country: newloc.country
              };
              this.touristBoardWork(loc, place.place_id, tix);
            }

            this.is.setObjNoLog(`Places/${place.place_id}/ukey`, obj.ukey);  // ukey
            this.is.setObjNoLog(`Places/${place.place_id}/hash`, obj.hash);  // hash
            this.is.setObjNoLog(`Places/${place.place_id}/name`, obj.name);  // name
            this.is.setObjNoLog(`Places/${place.place_id}/location`, obj.location); // location
            this.is.setObjNoLog(`Places/${place.place_id}/address`, obj.address); // address
            this.onSetType(place.place_id, obj.website, obj.name, obj.types);

            if (chain) {
              obj['chain'] = chain;
              this.is.setObjNoLog(`Places/${place.place_id}/chain`, chain); // chain
            }
            if (touristchain) {
              obj['tourist_pids'] = touristchain;
              this.is.setObjNoLog(`Places/${place.place_id}/tourist_pids`, touristchain); // touristchain
            }
            if (this.touristurl) {
              obj['touristurl'] = this.touristurl;
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/touristurl`, this.touristurl); // touristurl
              this.is.onPlaceMd5(place.place_id, obj['touristurl'], obj.name);
            }

            if (this.wikiurl) {
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Wikipedia`, this.wikiurl); // website
            }
            if (this.localwikiurl) {
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/LocalWiki`, this.localwikiurl); // website
            }
            if (this.briturl) {
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/Britanica`, this.briturl); // website
            }
            if (this.googleurl) {
              this.is.setObjNoLog(`Places/${place.place_id}/moreinfo/GoogleSearch`, this.googleurl); // website
            }
            if (this.altnames) {
              this.is.setObjNoLog(`Places/${place.place_id}/altnames`, this.altnames); // website
            }

            this.placeExists(obj);
            // this.is.setObjNoLog(`PlacesHash/${newloc.hash}`, obj);
            this.weburl = '';
            this.wikiurl = '';
            this.aerial = null;
            this.localwikiurl = '';
            if (param === 3) {
              this.onDeleteLocalWiki();
            }

            this.curwiki = null;
            this.briturl = '';
            this.googleurl = '';
            this.altnames = '';

            this.rebuildLocs();

            if (!this.curguide.trip) {
              this.curguide.trip = [];
            }


            if (this.addingtrip) {
              if (!this.curguide.pops) {
                this.curguide.pops = [];
              }
              this.curguide.pops.unshift(this.hit);
            } else {
              this.curguide.trip.unshift(this.hit);
            }

            if (param === 5) {
              this.onSavePOItoAdminFinish(this.hit.ukey);
            }
          }


          this.location = '';
          this.countryname = '';
          this.jumpto = '';
          this.weburl = '';
          this.includein = false;
          this.touristchainstring = '';
          this.chainstring = '';
          this.ukey = '';

          if (param === 3) {


            let fullname = this.hit.name;
            let cr = false;
            if (this.countryrequired && this.countryrequired.length) {
              fullname = `${this.hit.name}, ${this.countryrequired}`;
              cr = true;
            }

            let alt = '';
            if (this.altnames) {
              alt = this.altnames.replace(',', '|');
            }

            const obj = {
              Continent: '',
              Country: this.countryrequired,
              CountryRequired: cr,
              Demonyms: '',
              FullName: fullname,
              Name: place.name,
              Notes: '',
              OtherNames: alt,
              hash: newloc.hash,
              lat: newloc.lat,
              long: newloc.long,
              type: 'Admin',
              ukey: place.place_id
            };

            this.countryrequired = '';
            this.is.setObjNoLog(`Topics/TempInfo/PlaceNames/${obj.ukey}`, obj);
          }

          if (param === 1) {
            this.onCloseLoc();
          }
        }
      });
    }
  }

  onFormatChange(event) {
    // this.curguide.ty = event.value
    this.imager.onFixFormat();
  }

  onGetImage() {
    console.log('reset', this.imager);
    this.imager.resetError();
  }

  onGrabImage() {

    this.spinning = true;
    this.http.getGuideImage(this.curguide.l).take(1).subscribe(res => {
      this.spinning = false;
      console.log('res', res);
      if (res && res.image && res.image.length) {
        this.curguide.e = res.image;
        this.onGetImage();
      }
    });
  }

  makeMuse() {
    const geocoder = new google.maps.Geocoder();
    if (geocoder) {
      this.muse.forEach(m => {
        const address = m.title.trim();

        geocoder.geocode({'address': address}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (status === google.maps.GeocoderStatus.OK) {
              const place = new PlaceLoc();


              /*
                              place.location = address; // this is either a name or an address
                              place.long = results[0].geometry.location.lng();
                              place.lat = results[0].geometry.location.lat();
                              place.hash = geohash.encode(place.lat, place.long);

                              place.address = address; // this is an address
                              // place.utc_offset_minutes: number;
                              // place.tzone: string;
                              place.loc = address;
                              place.keys = [];
                              place.keys.push({id: results[0].place_id, name: 'Google'});
                              // place.keys.push({id: m.id, name: ''});

                              m['place'] = place;
                              this.is.setObjNoLog(`TmpHert/good/${results[0].place_id}`, m);
              */


            } else {
              const ukey = this.db.createPushId();
              // this.is.setObjNoLog(`TmpHert/bad/${ukey}`, m);
            }
          } else {
            const ukey = this.db.createPushId();
            // this.is.setObjNoLog(`TmpHert/bad/${ukey}`, m);
          }
        });
      });
    }

  }

  onDeleteDesignator(i) {

  }

  onAddDesignator() {
    this.adddesignator = true;
    this.curdesignator = new Designator();
  }

  onSaveDesignator() {
    this.is.setObjNoLog(`Topics/TempInfo/Designators/${this.curdesignator.ukey}`, this.curdesignator);
    this.designators.push(this.curdesignator);
    // todo: sort alphabetically
    this.adddesignator = false;
    this.curdesignator = null;
  }

  onCancelDesignator() {
    this.adddesignator = false;
    this.curdesignator = null;
  }

  onEditDesignator(i) {
    this.editdesignator = true;
    this.curdesignator = this.designators[i];
    if (!this.curdesignator.designations) {
      this.curdesignator.designations = [];
    } else {
      this.curdesignator.designations = Object.values(this.curdesignator.designations);
    }

  }

  onAddDesignation() {
    this.curdesignation = new Designation();
    this.adddesignation = true;
  }

  onDeleteDesignation(i) {
    this.curdesignator.designations.splice(i, 1);

    // todo: need to save this change
  }

  onSaveDesignation() {
    this.is.setObjNoLog(`Topics/TempInfo/Designators/${this.curdesignator.ukey}/designations/${this.curdesignation.designationukey}`, this.curdesignation);
    this.curdesignator.designations.push(this.curdesignation);
    this.curdesignation = null;
    this.adddesignation = false;
  }

  onCancelDesignation() {
    this.curdesignation = null;
    this.adddesignation = false;
  }

  onDesignationYear(i) {
    this.curdesignation = this.curdesignator.designations[i];
    if (!this.curdesignation.designationyears) {
      this.curdesignation.designationyears = [];
    } else {
      this.curdesignation.designationyears = Object.values(this.curdesignation.designationyears);
    }
    this.designationyear = true;
  }

  onAddDesignationYear() {
    this.curyear = new DesignationYear();
    this.adddesignationyear = true;
  }

  onSaveDesignationYear() {
    this.is.setObjNoLog(`Topics/TempInfo/Designators/${this.curdesignator.ukey}/designations/${this.curdesignation.designationukey}/designationyears/${this.curyear.period}`, this.curyear);
    this.curdesignation.designationyears.push(this.curyear);
    this.curyear = null;
    this.adddesignationyear = false;
  }

  onCancelDesignationYear() {
    this.curyear = null;
    this.adddesignationyear = false;

  }


  onAddDesignationCategory() {
    this.curcat = new DesignationCategory();
    this.addcat = true;
  }

  onEditDesignationCategory(i) {
    this.curcati = i;
    this.curcat = this.curyear.categories[i];
    if (this.curcat.winners) {
      this.curcat.winners = Object.values(this.curcat.winners);
    } else {
      this.curcat.winners = [];
    }
    this.winner = true;
  }

  onEditDesignationYear(i) {
    this.curyear = this.curdesignation.designationyears[i];
    if (this.curyear.categories) {
      this.curyear.categories = Object.values(this.curyear.categories);
    } else {
      this.curyear.categories = [];
    }

    this.category = true;

  }

  onSaveDesignationCategory() {
    this.is.setObjNoLog(`Topics/TempInfo/Designators/${this.curdesignator.ukey}/designations/${this.curdesignation.designationukey}/designationyears/${this.curyear.period}/categories/${this.curyear.categories.length}`, this.curcat);
    this.curyear.categories.push(this.curcat);
    this.curcat = null;
    this.addcat = false;
  }

  onAddWinner() {
    this.addwinner = true;
    this.level = '';
    this.location = '';
    this.countryname = '';
    this.datapid = '';
    this.datapidname = '';
  }

  onDeleteWinner() {

  }

  onCancelWinner() {
    this.addwinner = false;
  }

  onSaveWinner() {
    const winner = new Winner();

    if (this.datapid && this.datapid.length) {
      winner.name = this.datapidname;
      winner.level = this.level;
      winner.ukey = this.datapid;
    } else {
      if (!this.hit) {
        // todo: this happens if a place is not already in the database
        this.onLocation(0);
      }

      winner.name = this.location;
      winner.level = this.level;
      winner.ukey = this.ukey;
    }

    this.is.setObjNoLog(`Topics/TempInfo/Designators/${this.curdesignator.ukey}/designations/${this.curdesignation.designationukey}/designationyears/${this.curyear.period}/categories/${this.curcati}/winners/${this.curcat.winners.length}`, winner);

    let icon;
    if (this.curyear.icon) {
      icon = this.curyear.icon;
    } else {
      icon = this.curdesignation.icon;
    }

    const obj = {
      designator: this.curdesignator.name,
      type: this.curdesignation.type,
      designation: this.curdesignation.name,
      year: this.curyear.period,
      category: this.curcat.category,
      awardurl: this.curcat.awardurl,
      description: this.curcat.description,
      level: winner.level,
      icon: icon,
      path: `Topics/TempInfo/Designators/${this.curdesignator.ukey}/designations/${this.curdesignation.designationukey}/designationyears/${this.curyear.period}/categories/${this.curcati}/winners/${this.curcat.winners.length}`
    };
    if (winner.ukey) {
      this.is.pushObj(`Places/${winner.ukey}/designations`, obj);
    }

    // todo: need to save it the winner's pid


    this.curcat.winners.push(winner);

    this.addwinner = false;
  }

  onCancelDesignationCategory() {
    this.curcat = null;
    this.addcat = false;

  }


  onDeleteDesignationYear(i) {

  }

  getDesignations() {
    this.designators = [];
    this.is.getList('Topics/TempInfo/Designators').take(1).subscribe(list => {
      if (list) {
        this.designators = list;
        this.designators.forEach(d => {
          if (!d.designations) {
            d.designations = [];
          }
        });
      }
    });
  }

  onUpdateCount() {
    this.is.getObject('Topics/TempInfo/count').take(1).subscribe(cnt => {
      this.count = cnt + 1;
      this.is.setObjNoLog('Topics/TempInfo/count', this.count);
    });
  }

  onClearCount() {
    this.is.setObjNoLog('Topics/TempInfo/count', 0);
  }

}


