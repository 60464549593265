import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {BehaviorSubject} from 'rxjs';
import {InheritComponent} from './inherit/inherit.component';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {AgmMap} from '@agm/core';

import {
  CappParams, EdList, InterfaceService, LinkHolder, Office, OrgProfile, Policy, PublicStaff,
  TmpInherit
} from '../services/interface.service';
import {FirebaseService} from '../services/firebase.service';
import {BizhoursService, BizYear} from '../services/bizhours.service';
import {AdminService, PhoneObj} from '../services/admin.service';
import {ColTypes, DaysPerMonth, OrgAttribs, States, TimeZones} from '../services/constants';
import {OrganizationService} from '../services/organization.service';

declare var google: any;

@Component({
  selector: 'app-profile',
  templateUrl: './org-profile.component.html',
  styleUrls: ['./org-profile.component.scss']
})
export class OrgProfileComponent implements OnInit, OnDestroy {
  attribs = OrgAttribs;
  tmpinherito: TmpInherit[];
  tmpinherits: TmpInherit[];
  tmpinheritp: TmpInherit[];

  links: LinkHolder[];
  children: string[];


  regulardays: any[];
  hours: any[];
  twentfourseven: boolean;
  tmpYear: BizYear;


  orgtype: string;
  orgslist: EdList[];
  pControl: FormControl;
  parentorg: OrgProfile;
  poffices: Office[];
  pstaff: PublicStaff[];
  ppolicies: Policy[];

  phonedetails: boolean;
  editphonedetails: number;
  newphone: PhoneObj;

  tmporg: OrgProfile;
  curlang: string;

  tz = TimeZones;
  states = States;
  coltype = ColTypes;
  dayspermonth = DaysPerMonth;

  @ViewChild(AgmMap, {static: false}) mapElement: any;

  $sub1;
  $sub2;
  $sub3;
  $sub14;
  $sub15;

  haveLogo: boolean;
  logoFile: File;
  curLogo: string;
  lastupdate: Date;

  fileTypes = [
    'image/jpeg',
    'image/pjpeg',
    'image/png'
  ];

  form = new FormGroup({
    name: new FormControl(''),
    active: new FormControl(''),
    contactname: new FormControl(''),
    contactemail: new FormControl(''),
    nickname: new FormControl(''),
    street1: new FormControl(''),
    street2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zip: new FormControl(''),
    emailextentions: new FormControl(''),
    country: new FormControl(''),
    county: new FormControl(''),
    long: new FormControl(''),
    lat: new FormControl(''),
    timezone: new FormControl(''),
    url: new FormControl(''),
    facebook: new FormControl(''),
    googleplus: new FormControl(''),
    pinterest: new FormControl(''),
    snapchat: new FormControl(''),
    twitter: new FormControl(''),
    instagram: new FormControl(''),
    linkedin: new FormControl(''),
    primarycolor: new FormControl(''),
    secondarycolor: new FormControl(''),
    description: new FormControl(''),
    wikilink: new FormControl(''),
    female: new FormControl(''),
    male: new FormControl(''),
    type: new FormControl(''),
    phdescription: new FormControl(''),
    digits: new FormControl(''),
    publickey: new FormControl(''),
    privatekey: new FormControl(''),
    rfctoresourceid: new FormControl(''),
    rfctoreporterid: new FormControl(''),
    rfcfrom: new FormControl(''),

  });

  org: BehaviorSubject<string | null> = new BehaviorSubject('');
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  endAt: BehaviorSubject<string | null> = new BehaviorSubject('\uf8ff');


  constructor(private is: InterfaceService,
              private titleIXdb: FirebaseService,
              public dialog: MatDialog,
              public og: OrganizationService,
              private cd: ChangeDetectorRef,
              private bz: BizhoursService,
              public admin: AdminService) {
    this.pControl = new FormControl();
    this.orgtype = 'Colleges';
    this.curlang = this.admin.language;
  }

  setValue() {
    if (this.tmporg.parentkey) {
      this.pControl.setValue(this.tmporg.parentkey);
    }
    // todo: may be able to get rid of this on the next import
    if (!this.tmporg.rfctoresourceid) {
      this.tmporg.rfctoresourceid = '';
    }
    // todo: may be able to get rid of this on the next import
    if (!this.tmporg.rfctoreporterid) {
      this.tmporg.rfctoreporterid = '';
    }
    // todo: may be able to get rid of this on the next import
    if (!this.tmporg.rfcfrom) {
      this.tmporg.rfcfrom = '';
    }
    // todo: may be able to get rid of this on the next import
    if (!this.tmporg.privatekey) {
      this.tmporg.privatekey = '';
    }
    // todo: may be able to get rid of this on the next import
    if (!this.tmporg.cappparams) {
      this.tmporg.cappparams = new CappParams;
      this.tmporg.cappparams.publickey = '';
    }
    this.form.setValue({
      name: this.tmporg.orglegacy.name,
      active: this.tmporg.active,
      publickey: this.tmporg.cappparams.publickey,
      privatekey: this.tmporg.privatekey,
      rfctoresourceid: this.tmporg.rfctoresourceid,
      rfctoreporterid: this.tmporg.rfctoreporterid,
      rfcfrom: this.tmporg.rfcfrom,
      contactname: this.tmporg.orgdata.contactname,
      contactemail: this.tmporg.orgdata.contactemail,
      nickname: this.tmporg.orglegacy.nickname,
      street1: this.tmporg.orgdata.street1,
      street2: this.tmporg.orgdata.street2,
      city: this.tmporg.orgdata.city,
      state: this.tmporg.orgdata.state,
      zip: this.tmporg.orgdata.zip,
      emailextentions: this.tmporg.orgdata.emailextentions,
      country: this.tmporg.orgdata.country,
      county: this.tmporg.geo.county,
      long: this.tmporg.geo.long,
      lat: this.tmporg.geo.lat,
      timezone: this.tmporg.geo.timezone,
      url: this.tmporg.links.url,
      facebook: this.tmporg.links.facebook,
      googleplus: this.tmporg.links.googleplus,
      pinterest: this.tmporg.links.pinterest,
      snapchat: '',
      twitter: this.tmporg.links.twitter,
      instagram: this.tmporg.links.instagram,
      linkedin: this.tmporg.links.linkedin,
      primarycolor: this.tmporg.orglegacy.primarycolor,
      secondarycolor: this.tmporg.orglegacy.secondarycolor,
      description: this.tmporg.demo.description,
      wikilink: this.tmporg.demo.wikilink,
      female: this.tmporg.demo.female,
      male: this.tmporg.demo.male,
      type: this.tmporg.demo.type,
      phdescription: '',
      digits: '',
    });
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.$sub1 =
      this.is.getBehaviorList(this.startAt, this.endAt, `OrgsByType/${this.orgtype}`)
        .subscribe(orgs => {
          this.orgslist = orgs;
        });

    this.is.getTrans();

    this.og.getCurOrg();

    this.$sub2 = this.og.curorg$.subscribe(val => {
      if (val) {
        this.tmporg = this.og.curOrg.profile;
        // todo: after final import can delete this
        if (!this.tmporg.orgdata.emailextentions) {
          this.tmporg.orgdata.emailextentions = '';
        }


        // todo: this no longer works....
        if (this.tmporg.parentkey) {
          this.is.getObject(`Orgs/${this.tmporg.parentkey}/profile`).take(1).subscribe(porg =>
            this.parentorg = porg
          );
        }

        if (this.tmporg.orglegacy.photo) {
          this.curLogo = this.tmporg.orglegacy.photo;
          this.haveLogo = true;
        } else {
          this.curLogo = './assets/uploadlogo.png';
          this.haveLogo = false;
        }

        this.phonedetails = false;
        this.editphonedetails = -1;
        if (!this.tmporg.orgdata.phones) {
          this.tmporg.orgdata.phones = new Array();
        }


        this.newphone = new PhoneObj();
        this.newphone.phdescription = '';
        this.newphone.digits = '';

        this.setValue();

        this.filldata();
      }
    });


    if (this.admin.admin.type === 'Admin') {
      this.is.nextCom();
    }


  }

  filldata() {
    // todo: not sure if i need this line
    // this.org.next(this.admin.admin.org);

    if (this.$sub14) {
      this.$sub14.unsubscribe();
    }
    if (this.$sub15) {
      this.$sub15.unsubscribe();
    }

    // todo: this no longer works
    if (!this.admin.editchild) {
      this.$sub14 = this.is.getList(`Orgs/${this.admin.admin.org}/children`).subscribe(c => {
        this.children = c;
      });
    }

    if (this.og.curOrg.partners && this.og.curOrg.partners.request) {
      const partners = this.og.curOrg.partners.request.filter(r => r.status === 'Approved');
      this.$sub15 = this.is.getList(`Partners/Orgs/${this.admin.admin.org}`).subscribe(lins => {
        this.links = [];
        if (lins.length > 0) {
          lins.forEach(l => {
            const linkholder = new LinkHolder();
            linkholder.link = l;
            linkholder.partner = false;
            if (partners && partners.length > 0) {
              const x = partners.findIndex(p => p.who === l || p.org === l);
              if (x > -1) {
                linkholder.partner = true;
              }
            }
            this.links.push(linkholder);
          });
        }
      });
    };

  }

  onPick(i) {
    this.admin.editchild = false;
    this.admin.admin.org = this.admin.admin.orgs[i].organ;
    this.admin.getIncidentAlerts();
    this.admin.partentofcurrentorg = '';
    this.admin.admin.modules = this.admin.admin.orgs[i].modules;
    this.filldata();

  }

  get active() {
    return this.form.get('active');
  }

  get phdescription() {
    return this.form.get('phdescription');
  }

  get digits() {
    return this.form.get('digits');
  }

  get publickey() {
    return this.form.get('publickey');
  }

  get rfctoresourceid() {
    return this.form.get('rfctoresourceid');
  }

  get rfctoreporterid() {
    return this.form.get('rfctoreporterid');
  }

  get rfcfrom() {
    return this.form.get('rfcfrom');
  }


  get privatekey() {
    return this.form.get('privatekey');
  }

  get description() {
    return this.form.get('description');
  }

  get wikilink() {
    return this.form.get('wikilink');
  }

  get female() {
    return this.form.get('female');
  }

  get male() {
    return this.form.get('male');
  }

  get type() {
    return this.form.get('type');
  }

  get county() {
    return this.form.get('county');
  }

  get long() {
    return this.form.get('long');
  }

  get lat() {
    return this.form.get('lat');
  }

  get timezone() {
    return this.form.get('timezone');
  }

  get name() {
    return this.form.get('name');
  }

  get contactname() {
    return this.form.get('contactname');
  }

  get contactemail() {
    return this.form.get('contactemail');
  }

  get nickname() {
    return this.form.get('nickname');
  }

  get street1() {
    return this.form.get('street1');
  }

  get street2() {
    return this.form.get('street2');
  }

  get city() {
    return this.form.get('city');
  }

  get state() {
    return this.form.get('state');
  }

  get country() {
    return this.form.get('country');
  }

  get zip() {
    return this.form.get('zip');
  }

  get emailextentions() {
    return this.form.get('emailextentions');
  }

  get url() {
    return this.form.get('url');
  }

  get facebook() {
    return this.form.get('facebook');
  }

  get googleplus() {
    return this.form.get('googleplus');
  }

  get pinterest() {
    return this.form.get('pinterest');
  }

  get snapchat() {
    return this.form.get('snapchat');
  }

  get twitter() {
    return this.form.get('twitter');
  }

  get instagram() {
    return this.form.get('instagram');
  }

  get linkedin() {
    return this.form.get('linkedin');
  }

  get primarycolor() {
    return this.form.get('primarycolor');
  }

  get secondarycolor() {
    return this.form.get('secondarycolor');
  }


  onCancelPhone() {
    this.phonedetails = false;
    this.editphonedetails = -1;
  }

  onDeletePhone(i) {
    // Todo: need to ask if they are sure
    this.tmporg.orgdata.phones.splice(i, 1);
  }

  onEditPhone(i) {
    this.editphonedetails = i;
    this.newphone = this.tmporg.orgdata.phones[i];
    this.phdescription.setValue(this.newphone.phdescription);
    this.digits.setValue(this.newphone.digits);
    this.phonedetails = true;
  }

  onAddPhone() {
    this.newphone = new PhoneObj;
    this.newphone.phdescription = '';
    this.newphone.digits = '';
    this.phdescription.setValue('');
    this.digits.setValue('');
    this.phonedetails = true;
  }

  onSavePhone() {
    this.newphone.phdescription = this.phdescription.value;
    this.newphone.digits = this.digits.value;

    if (this.editphonedetails === -1) {
      this.tmporg.orgdata.phones.push(this.newphone);
    } else {
      this.tmporg.orgdata.phones[this.editphonedetails] = this.newphone;
    }

    this.phonedetails = false;
    this.editphonedetails = -1;
  }

  validFileType(file) {
    for (let i = 0; i < this.fileTypes.length; i++) {
      if (file.type === this.fileTypes[i]) {
        return true;
      }
    }
    return false;
  }

  onChangeLogo(files) {
    const logohere = document.getElementById('logohere');
    if (this.validFileType(files[0])) {
      this.logoFile = files[0];
      this.haveLogo = true;
      const myReader: FileReader = new FileReader();
      myReader.onload = function(e) {
        const dataURL = myReader.result;
        logohere.setAttribute('src', dataURL.toString());
      };
      myReader.readAsDataURL(files[0]);
    }
  }

  onSave() {
    if (this.curLogo) {
      this.tmporg.orglegacy.photo = this.curLogo;
    } else {
      this.tmporg.orglegacy.photo = '';
    }


    // Todo: have to implement emojis and resource codes
    this.tmporg.orglegacy.name = this.name.value;
    this.tmporg.orgdata.contactname = this.contactname.value;
    this.tmporg.orgdata.contactemail = this.contactemail.value;
    this.tmporg.orglegacy.nickname = this.nickname.value;
    this.tmporg.cappparams.publickey = this.publickey.value;
    this.tmporg.privatekey = this.privatekey.value;
    this.tmporg.rfctoresourceid = this.rfctoresourceid.value;
    this.tmporg.rfctoreporterid = this.rfctoreporterid.value;
    this.tmporg.rfcfrom = this.rfcfrom.value;
    this.tmporg.orgdata.street1 = this.street1.value;
    this.tmporg.orgdata.street2 = this.street2.value;
    this.tmporg.orgdata.city = this.city.value;
    // this.tmporg.orgdata.state = this.state.value;
    this.tmporg.orgdata.zip = this.zip.value;
    this.tmporg.orgdata.emailextentions = this.emailextentions.value;
    this.tmporg.orgdata.country = this.country.value;
    this.tmporg.geo.county = this.county.value;
    this.tmporg.geo.long = this.long.value;
    this.tmporg.geo.lat = this.lat.value;
    // this.tmporg.geo.timezone = this.timezone.value;
    this.tmporg.links.url = this.url.value;
    this.tmporg.links.facebook = this.facebook.value;
    this.tmporg.links.googleplus = this.googleplus.value;
    this.tmporg.links.pinterest = this.pinterest.value;
    this.tmporg.links.snapchat = this.snapchat.value;
    this.tmporg.links.twitter = this.twitter.value;
    this.tmporg.links.instagram = this.instagram.value;
    this.tmporg.links.linkedin = this.linkedin.value;
    this.tmporg.demo.description = this.description.value;
    this.tmporg.demo.wikilink = this.wikilink.value;
    this.tmporg.demo.female = this.female.value;
    this.tmporg.demo.male = this.male.value;
    // this.tmporg.demo.type = this.type.value;
    console.log('test')

    this.tmporg.active = this.active.value;
    this.og.onSaveProfile(this.tmporg);


    const tmp = new Object();
    tmp['collegeName'] = this.tmporg.orglegacy.name;
    tmp['collegeNickname'] = this.tmporg.orglegacy.nickname; // kwab's nickname
    tmp['primaryColor'] = this.tmporg.orglegacy.primarycolor;
    tmp['secondaryColor'] = this.tmporg.orglegacy.secondarycolor;
    tmp['logofile'] = this.tmporg.orglegacy.photo;
    tmp['cityName'] = this.tmporg.orgdata.city;
    tmp['collegeNameLowerCase'] = this.tmporg.orglegacy.name.toLowerCase(); // kwab's nameLowerCase
    this.is.updateObj(`LegacyColleges/${this.tmporg.orglegacyextra.kwabid}`, tmp, 'Legacy', '');

    const tmpfull = new Object();
    tmp[`name`] = this.tmporg.orglegacy.name;
    tmp[`primaryColor`] = this.tmporg.orglegacy.primarycolor;
    tmp[`secondaryColor`] = this.tmporg.orglegacy.secondarycolor;
    tmp[`logoFile`] = this.tmporg.orglegacy.photo;
    tmp[`cityName`] = this.tmporg.orgdata.city;
    tmp[`stateCode`] = 'US-' + this.tmporg.orgdata.state;
    tmp[`county`] = this.tmporg.geo.county;
    this.is.updateObj(`LegacyFullColleges/${this.tmporg.orglegacyextra.kwabid}`, tmp, 'Legacy', '');


    if (this.tmpYear) {
      this.bz.savebuisinesshours('Orgs/' + this.tmporg.ukey, this.tmpYear);
    }

    if (this.poffices) {
      this.og.onSaveOffices(this.poffices);
    }

    if (this.pstaff) {
      this.og.onSaveStaff(this.pstaff);
    }

    if (this.ppolicies) {
      this.og.onSavePolicies(this.ppolicies);
    }
  }


  updateparentlist(sc) {
    this.org.next(sc);
    let obj;
    this.$sub3 = this.titleIXdb.titleIXdb.list<any[]>('OrgsByType/' + this.orgtype, ref => {
      return ref.orderByChild('ukey').equalTo(sc);
    })
      .valueChanges().subscribe(data => {
        obj = data[0];
        this.tmporg.parentkey = obj.ukey;
        this.is.getObject(`Orgs/${obj.ukey}/profile`).take(1).subscribe(org => {
          this.parentorg = org;
        });


      });
  }

  searchparent() {
    if (this.pControl.value.length === 1) {
      this.pControl.setValue(this.pControl.value.toUpperCase());
    }
    const q = this.pControl.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  fetchhours(ukey) {
    this.bz.getregulardayz(ukey + '/regulardays').take(1).subscribe(hrs => {
      this.regulardays = hrs;

      this.bz.gettwentfoursevenz(ukey + '/twentyfourseven').take(1).subscribe(s => {
        this.twentfourseven = s;

        this.bz.getbizhourz(ukey + '/hours').take(1).subscribe(h => {
          this.hours = h;
          // this.bizhours = this.setuphours(this.bizhours);
          this.tmpYear = this.admin.setuphours(this.hours, this.regulardays, this.twentfourseven);
        });
      });
    });
  }


  copyvals() {
    this.attribs.forEach(at => {
      if (at.val) {
        switch (at.name) {
          case 'Nickname':
            this.tmporg.orglegacy.nickname = this.parentorg.orglegacy.nickname;
            break;
          case 'Address':
            this.tmporg.orgdata.street1 = this.parentorg.orgdata.street1;
            this.tmporg.orgdata.street2 = this.parentorg.orgdata.street2;
            this.tmporg.orgdata.city = this.parentorg.orgdata.city;
            this.tmporg.orgdata.state = this.parentorg.orgdata.state;
            this.tmporg.orgdata.country = this.parentorg.orgdata.country;
            this.tmporg.orgdata.zip = this.parentorg.orgdata.zip;
            this.tmporg.orgdata.emailextentions = this.parentorg.orgdata.emailextentions;
            this.tmporg.orgdata.country = this.parentorg.orgdata.country;
            this.tmporg.geo.timezone = this.parentorg.geo.timezone;
            this.tmporg.geo.long = this.parentorg.geo.long;
            this.tmporg.geo.lat = this.parentorg.geo.lat;
            this.tmporg.geo.county = this.parentorg.geo.county;
            break;
          case 'Hours':
            this.fetchhours('Orgs/' + this.parentorg.ukey);

            // Todo: this needs to be implemented or removed
            // var cloneOfA = JSON.parse(JSON.stringify(this.parentorg.));
            break;
          case 'Description':
            this.tmporg.demo.description = this.parentorg.demo.description;
            break;

          case 'Phone number(s)':
            this.tmporg.orgdata.phones = new Array();
            this.parentorg.orgdata.phones.forEach(p => {
              const tmp = new PhoneObj;
              tmp.phdescription = p.phdescription;
              tmp.digits = p.digits;
              this.tmporg.orgdata.phones.push(tmp);
            });
            break;
          case 'Contact':
            this.tmporg.orgdata.contactemail = this.parentorg.orgdata.contactemail;
            this.tmporg.orgdata.contactname = this.parentorg.orgdata.contactname;
            break;
          case 'Url':
            this.tmporg.links.url = this.parentorg.links.url;
            break;
          case 'Facebook':
            this.tmporg.links.facebook = this.parentorg.links.facebook;
            break;
          case 'Googleplus':
            this.tmporg.links.googleplus = this.parentorg.links.googleplus;
            break;
          case 'Pinterest':
            this.tmporg.links.pinterest = this.parentorg.links.pinterest;
            break;
          case 'Snapchat':
            this.tmporg.links.snapchat = this.parentorg.links.snapchat;
            break;
          case 'Twitter':
            this.tmporg.links.twitter = this.parentorg.links.twitter;
            break;
          case 'Instagram':
            this.tmporg.links.instagram = this.parentorg.links.instagram;
            break;
          case 'Linkedin':
            this.tmporg.links.linkedin = this.parentorg.links.linkedin;
            break;
          case 'Graphics':
            this.tmporg.orglegacy.photo = this.parentorg.orglegacy.photo;
            this.tmporg.orglegacy.smalllogo = this.parentorg.orglegacy.smalllogo;
            this.tmporg.orglegacy.secondarycolor = this.parentorg.orglegacy.secondarycolor;
            this.tmporg.orglegacy.primarycolor = this.parentorg.orglegacy.primarycolor;
            break;
          case 'Wikilink':
            this.tmporg.demo.wikilink = this.parentorg.demo.wikilink;
            break;
          case 'Type':
            this.tmporg.demo.type = this.parentorg.demo.type;
            break;
        }
      }
    });

    this.setValue();

    this.tmpinherito.forEach(item => {
      const x = this.poffices.findIndex(o => o.ukey === item.ukey);
      if (!item.val) {
        this.poffices.splice(x, 1);
      }
    });

    this.tmpinherits.forEach(item => {
      const x = this.pstaff.findIndex(o => o.ukey === item.ukey);
      if (!item.val) {
        this.pstaff.splice(x, 1);
      }
    });

    this.tmpinheritp.forEach(item => {
      const x = this.ppolicies.findIndex(o => o.ukey === item.ukey);
      if (!item.val) {
        this.ppolicies.splice(x, 1);
      }
    });
  }

  onInherit() {
    this.is.getList(`Orgs/${this.tmporg.parentkey}/resources/offices`).take(1).subscribe(o => {
      this.poffices = o;

      if (this.poffices) {
        this.tmpinherito = new Array();
        this.poffices.forEach(item => {
          const t = new TmpInherit;
          t.name = item.nameDesc[this.curlang].description;
          t.ukey = item.ukey;
          t.val = false;
          this.tmpinherito.push(t);
        });
      }

      this.is.getList(`Orgs/${this.tmporg.parentkey}/resources/staff`).take(1).subscribe(s => {
        this.pstaff = s;
        if (this.pstaff) {
          this.tmpinherits = new Array();
          this.pstaff.forEach(item => {
            const t = new TmpInherit;
            t.name = this.admin.makenPersonName(item.fname, item.mname, item.lname, item.zname);
            t.ukey = item.ukey;
            t.val = false;
            this.tmpinherits.push(t);
          });
        }

        this.is.getList(`Orgs/${this.tmporg.parentkey}/resources/policies`).take(1).subscribe(s => {
          this.ppolicies = s;
          if (this.ppolicies) {
            this.tmpinheritp = new Array();
            this.ppolicies.forEach(item => {
              const t = new TmpInherit;
              t.name = item.nameDesc[this.curlang].description;
              t.ukey = item.ukey;
              t.val = false;
              this.tmpinheritp.push(t);
            });
          }


          const dialogRef = this.dialog.open(InheritComponent, {
            width: '1000px',
            data: {
              attribs: this.attribs,
              offices: this.tmpinherito,
              staff: this.tmpinherits,
              policies: this.tmpinheritp
            }
          });

          dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.attribs = result.attribs;
                this.tmpinherito = result.offices;
                this.tmpinherits = result.staff;
                this.tmpinheritp = result.policies;
                this.copyvals();
              }
            }
          );
        });
      });
    });
  }

  onCalc() {
    const s1 = this.street1.value;
    const city = this.city.value;
    const state = this.state.value;
    const zip = this.zip.value;
    const address = s1 + ', ' + city + ', ' + state + ' ' + zip;
    if (this.mapElement) {
      this.mapElement._mapsWrapper.getNativeMap().then(map => {
        const geocoder = new google.maps.Geocoder();
        if (geocoder) {
          geocoder.geocode({'address': address}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const lat = results[0].geometry.location.lat();
              const lng = results[0].geometry.location.lng();
              this.form.controls['lat'].setValue(lat);
              this.form.controls['long'].setValue(lng);
            } else {
              console.log('no good');
            }
          });
        }
      }).catch(err => console.log('no good'));
    } else {
      console.log('no good');
    }

  }

  ngOnDestroy() {
    if (this.$sub1) {
      this.$sub1.unsubscribe();
    }
    if (this.$sub2) {
      this.$sub2.unsubscribe();
    }
    if (this.$sub3) {
      this.$sub3.unsubscribe();
    }
    if (this.$sub14) {
      this.$sub14.unsubscribe();
    }
    if (this.$sub15) {
      this.$sub15.unsubscribe();
    }
  }
}

