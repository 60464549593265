import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {InterfaceService} from '../services/interface.service';

@Component({
  selector: 'app-id-selection-dialog',
  templateUrl: './id-selection-dialog.component.html',
  styleUrls: ['./id-selection-dialog.component.scss']
})
export class IdSelectionDialogComponent {
  touristid: string;
  constructor(
    private is: InterfaceService,
    public dialogRef: MatDialogRef<IdSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ids: string[] }
  ) {}

  onSkip(): void {
    this.dialogRef.close('');
  }

  onSelect(id: string): void {
    this.dialogRef.close(id);
  }

  onSaveID() {
    this.is.getObject(`Institutions/${this.touristid}/ukey`).take(1).subscribe(ukey => {
      if (ukey) {
        this.dialogRef.close(this.touristid);
      } else {
        this.dialogRef.close('');
      }
    })

  }
}
